<div [formGroup]="parentGroup">
    <mat-form-field appearance="fill" [ngClass]="{'invisible':formElement.hidden === 'true'}">
        <mat-label>
            {{ formElement.text }}
        </mat-label>

        <mat-select disableOptionCentering [formControlName]="formElement.formControl">
            <mat-option></mat-option>

            <ng-container *ngIf="$reviewerSecurityPermissions | async as reviewerSecurityPermissions">
                <mat-option *ngFor="let option of reviewerSecurityPermissions.data" [value]="option.id">{{option.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <span
        *ngIf="formElement.tooltipText != null"
        class="material-icons-outlined tooltip-icon"
        style="margin-left: 8px; cursor: default";
        [matTooltip]="formElement.tooltipText"
        matTooltipPosition="above">
        help_outline
    </span>
</div>
