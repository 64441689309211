import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-letters-admin-section',
  templateUrl: './letters-admin-section.component.html',
  styleUrls: ['./letters-admin-section.component.scss']
})
export class LettersAdminSectionComponent implements OnInit {
  selectedTab: number = 0;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if(params['section']) {
        if(params['section'] == 'DeliveryMethods') {
          this.selectedTab = 0;
        }
      }
    });
  }

}
