import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {routes} from '@app/consts';
import {SanitiseGridDataService} from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import {TranslateService} from '@ngx-translate/core';
import {ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem} from '@progress/kendo-angular-grid';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {BehaviorSubject, Observable, from} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import { EmployeeLoginReportService } from '../../services/employee-login-report.service';
import { EmployeeLogin } from '../../models/employee-login-report.model';

@Component({
  selector: 'app-employee-login-report-data-grid',
  templateUrl: './employee-login-report-data-grid.component.html',
  styleUrls: ['./employee-login-report-data-grid.component.scss']
})
export class EmployeeLoginReportDataGridComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid: GridComponent;

  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [{dir: 'desc', field: 'logTime'}];
  public bindingType = 'array';
  public view: Observable<GridDataResult>;
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  public pageSizes: (PageSizeItem | number)[] = [
      5,
      10,
      20,
      {
          text: 'All',
          value: 100000,
      },
  ];
  isLoading: boolean;
  pageSize = 4;
  skip = 0;
  sortString: string = 'logTime-desc';
  filterString: string;
  employeeLogins: EmployeeLogin[];

  public columns: any[] = [
    {field: 'firstName', title: this.translate.instant('EmployeeLoginReport-FirstName'), dataType: 'String'},
    {field: 'lastName', title: this.translate.instant('EmployeeLoginReport-LastName'), dataType: 'String'},
    {field: 'id', title: this.translate.instant('EmployeeLoginReport-Id'), dataType: 'String'},
    {field: 'logTime', title: this.translate.instant('EmployeeLoginReport-LogTime'), dataType: 'String'},
    {field: 'provider', title: this.translate.instant('EmployeeLoginReport-Provider'), dataType: 'String'},
    {field: 'displayName', title: this.translate.instant('EmployeeLoginReport-DisplayName'), dataType: 'String'},
    {field: 'endPoint', title: this.translate.instant('EmployeeLoginReport-EndPoint'), dataType: 'String'},
    {field: 'eventType', title: this.translate.instant('EmployeeLoginReport-EventType'), dataType: 'String'},
    {field: 'localIpAddress', title: this.translate.instant('EmployeeLoginReport-LocalIpAddress'), dataType: 'String'},
    {field: 'name', title: this.translate.instant('EmployeeLoginReport-Name'), dataType: 'String'},
    {field: 'message', title: this.translate.instant('EmployeeLoginReport-Message'), dataType: 'String'},
    {field: 'remoteIpAddress', title: this.translate.instant('EmployeeLoginReport-RemoteIpAddress'), dataType: 'String'},
  ];

  public filterCategories: any[] = [
    {field: 'user.firstName', title: this.translate.instant('EmployeeLoginReport-FirstName'), dataType: 'String'},
    {field: 'user.lastName', title: this.translate.instant('EmployeeLoginReport-LastName'), dataType: 'String'},
    {field: 'user.id', title: this.translate.instant('EmployeeLoginReport-Id'), dataType: 'String'},
    {field: 'logTime', title: this.translate.instant('EmployeeLoginReport-LogTime'), dataType: 'Number'},
    {field: 'provider', title: this.translate.instant('EmployeeLoginReport-Provider'), dataType: 'String'},
    {field: 'displayName', title: this.translate.instant('EmployeeLoginReport-DisplayName'), dataType: 'String'},
    {field: 'endPoint', title: this.translate.instant('EmployeeLoginReport-EndPoint'), dataType: 'String'},
    {field: 'eventType', title: this.translate.instant('EmployeeLoginReport-EventType'), dataType: 'String'},
    {field: 'localIpAddress', title: this.translate.instant('EmployeeLoginReport-LocalIpAddress'), dataType: 'String'},
    {field: 'name', title: this.translate.instant('EmployeeLoginReport-Name'), dataType: 'String'},
    {field: 'message', title: this.translate.instant('EmployeeLoginReport-Message'), dataType: 'String'},
    {field: 'remoteIpAddress', title: this.translate.instant('EmployeeLoginReport-RemoteIpAddress'), dataType: 'String'},
  ];

  sortableColumns: any[] = [
    { field: "firstName", sortValue: "user.firstName" },
    { field: "lastName", sortValue: "user.lastName" },
    { field: "id", sortValue: "user.id" },
    { field: "logTime", sortValue: "logTime" },
    { field: "provider", sortValue: "provider" },
    { field: "displayName", sortValue: "displayName" },
    { field: "endPoint", sortValue: "endPoint" },
    { field: "eventType", sortValue: "eventType" },
    { field: "localIpAddress", sortValue: "localIpAddress" },
    { field: "name", sortValue: "name" },
    { field: "message", sortValue: "message" },
    { field: "remoteIpAddress", sortValue: "remoteIpAddress" },
  ];

  public state: State = {
      skip: 0,
      take: 50,
  };
  public query: Observable<GridDataResult>;
  private stateChange = new BehaviorSubject<State>(this.state);
  public allData = (): Observable<GridDataResult> => {
      return from(this.employeeLoginReportService.queryAll(this.state, this.filterString, this.sortString));
  };

  constructor(
      private translate: TranslateService,
      private ngZone: NgZone,
      private employeeLoginReportService: EmployeeLoginReportService,
      private sanitiseGridDataService: SanitiseGridDataService
  ) {

      this.query = this.stateChange.pipe(
          tap((state) => {
              this.state = state;
              this.isLoading = true;
          }),
          switchMap((state) => employeeLoginReportService.fetch(state, this.filterString, this.sortString, false)),
          tap(() => {
              this.isLoading = false;
          })
      );

      // Bind 'this' explicitly to capture the execution context of the component.
      this.allData = this.allData.bind(this);

  }

  ngOnInit(): void {
  }

  public pageChange(state: PageChangeEvent): void {
      this.stateChange.next(state);
  }

  public onExcelExport(e: ExcelExportEvent): void {
      e = this.sanitiseGridDataService.sanitise(e);
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.state.skip = 0;
    this.stateChange.next(this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    let sortString: string = '';

    sort.forEach(
      (sortObj, index) => {

        if(sortObj.dir === undefined){
          sort.splice(index, 1);
        }
        else {
          let sortableColumn = this.sortableColumns.find(col => col.field === sortObj.field)

          if(sortableColumn){
            sortString = this.createSortString(sortableColumn, sortObj, sortString)
          }
          else {
            sort.splice(index, 1);
          }
        }
      }
    )

    this.sortString = sortString;
    this.sort = sort;
    this.stateChange.next(this.state);
  }

  createSortString(sortableColumn, sort: SortDescriptor, sortString: string): string {
    if (Array.isArray(sortableColumn?.sortValue)) {
      sortableColumn?.sortValue.forEach(
        sortVal => {
          if(sortString !== ''){
            sortString = `${sortString}~${sortVal}-${sort.dir}`;
          }
          else{
            sortString = `${sortVal}-${sort.dir}`;
          }
        }
      )
    } else {
      if(sortString !== ''){
        sortString = `${sortString}~${sortableColumn?.sortValue}-${sort.dir}`;
      }
      else{
        sortString = `${sortableColumn?.sortValue}-${sort.dir}`;
      }
    }

    return sortString;
  }
}
