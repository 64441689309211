import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Lookup, TableList, TableListFieldOption, TableListFieldOptionSubmit, TableListFieldOptionVerbose } from '../models/lookup.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { Table } from '@app/modules/security-setup/models/table.model';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { EnvironmentService } from "@app/core/services/environment.service";


@Injectable({
    providedIn: 'root'
})
export class LookupService {

    constructor(private http: HttpClient, private envService: EnvironmentService) { }

    getTables(): Observable<PagedData<Table>> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getFields(tableId: string): Observable<TableField[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getListFields(tableId: string): Observable<TableField[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${tableId}/${api_routes.FIELDS}/ListFields`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getTableLists(): Observable<TableList[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.METADATA}/${api_routes.TABLES}/${api_routes.TABLE_LISTS}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getLookups(lookupTypeId: string): Observable<Lookup[]> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${lookupTypeId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async getListOptions(listId: string): Promise<PagedData<TableListFieldOption>> {
        let take = 100;
        let skip = 0;
        let dataToReturn = {
          skip: null,
          take: null,
          totalPages: null,
          total: null,
          data: []
        }

        while (dataToReturn.total === null || skip < dataToReturn.total) {
            let params = new HttpParams();
            params = params.append('skip', skip);
            params = params.append('take', take);

            const res =  await this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LISTS}/${listId}/${api_routes.OPTIONS}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: params
            }).toPromise();

            if (res && res.data && res.data.length > 0) {
                dataToReturn.data = dataToReturn.data.concat(res.data);
                skip += take;
                dataToReturn.total = res.total;
            } else {
                break;
            }
        }

        return dataToReturn;
    }

    getTableListFieldOptions(lookupTypeId: string, skip?: number, take?: string, filterString?: string, sortString?: string, asOf?: string): Observable<PagedData<TableListFieldOption>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sortString ? params = params.append('Sort', sortString) : null;
        asOf ? params = params.append('asOf', asOf) : null;


        return this.http.get<any>(this.envService.env.apiUrl + lookupTypeId, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        });
    }

    getTableListFieldOption(listId: string, listOptionId: string): Observable<TableListFieldOptionVerbose> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LISTS}/${listId}/${api_routes.OPTIONS}/${listOptionId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    deleteListOption(listId: string, listOptionId: string): Observable<any> {
        return this.http.delete<any>(
            `${this.envService.env.apiUrl}${api_routes.LISTS}/${listId}/${api_routes.OPTIONS}/${listOptionId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    addNewTableListFieldOption(listId: string, tableListFieldOptionSubmit: TableListFieldOptionSubmit): Observable<TableListFieldOptionVerbose> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LISTS}/${listId}/${api_routes.OPTIONS}`, tableListFieldOptionSubmit, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    updateTableListFieldOption(listId: string, listOptionId: string, tableListFieldOptionSubmit: TableListFieldOptionSubmit): Observable<TableListFieldOptionVerbose> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LISTS}/${listId}/${api_routes.OPTIONS}/${listOptionId}`, tableListFieldOptionSubmit, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    copyListOptionToGroup(listId: string, listOptionId: string, targetListId: string): Observable<Lookup> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LISTS}/${listId}/${api_routes.OPTIONS}/${listOptionId}/${api_routes.COPY}/${targetListId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getTableRoles(id: string): Observable<any[]> {
        return this.http.get<any>(this.envService.env.apiUrl + `Security/Tables/${id}/List`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getFieldRoles(id: string): Observable<any[]> {
        return this.http.get<any>(this.envService.env.apiUrl + `Security/Fields/${id}/List`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}
