<app-letters>
    <mat-card class="mat-elevation-z0">
        <mat-card-content>
            <mat-tab-group [selectedIndex]="selectedTab">
                <mat-tab *appHasFeature="features.LETTERS_ADMINISTRATION">
                    <ng-template mat-tab-label>
                        <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Letters-LetterHeaders">
                            {{ 'Letters-LetterHeaders' | translate: {Default: 'Letter Headers'} }}
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-letterheads></app-letterheads>
                    </ng-template>
                </mat-tab>
                
                <mat-tab *appHasFeature="features.LETTERS_ADMINISTRATION">
                    <ng-template mat-tab-label>
                        <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Letters-LetterFooters">
                            {{ 'Letters-LetterFooters' | translate: {Default: 'Letter Footers'} }}
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-letter-footers></app-letter-footers>
                    </ng-template>
                </mat-tab>
    
                <mat-tab *appHasFeature="features.LETTERS_ADMINISTRATION">
                    <ng-template mat-tab-label>
                        <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Letters-Templates">
                            {{ 'Letters-Templates' | translate: {Default: 'Letter Templates'} }}
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-letter-templates></app-letter-templates>
                    </ng-template>
                </mat-tab>
    
                <mat-tab *appHasFeature="generateLettersFeatures">
                    <ng-template mat-tab-label>
                        <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Letters-GenerateLetters">
                            {{ 'Letters-GenerateLetters' | translate: {Default: 'Generate Letters'} }}
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-letter-generations></app-letter-generations>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</app-letters>
