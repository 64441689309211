import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable, of } from 'rxjs';
import { api_routes } from '@app/consts';
import { ReviewRating } from '../models/review-rating.model';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class ReviewRatingsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getReviewRatings(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewRating>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_RATINGS}`, {
      headers: headers,
      params: params
    });
  }

  async getAllReviewRatings(sort?: string, filter?: string): Promise<PagedData<ReviewRating>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getReviewRatings(take.toString(), skip, sort, filter).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getReviewRating(reviewRatingId: string): Observable<ReviewRating> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_RATINGS}/${reviewRatingId}`, {
      headers: headers
    });
  }

  updateReviewRating(reviewRatingId: string, reviewRatingSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_RATINGS}/${reviewRatingId}`, reviewRatingSubmit, {
      headers: headers
    });
  }

  createReviewRating(reviewRatingSubmit: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_RATINGS}`, reviewRatingSubmit, {
      headers: headers
    });
  }

  deleteReviewRating(reviewRatingId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_RATINGS}/${reviewRatingId}`, {
      headers: headers
    });
  }

  getReviewRatingStatuses(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<any> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.RATING_STATUSES}`, {
      headers: headers,
      params: params
    });
  }
}
