import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrendModule } from 'ngx-trend';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { DrawerRailModule } from 'angular-material-rail-drawer';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { ImageCropperModule } from 'ngx-image-cropper';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSliderModule } from '@angular/material/slider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from '@app/shared/adapters/MomentUtcDateAdapter';


import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ExcelModule, GridModule, PDFModule } from "@progress/kendo-angular-grid";
import { SchedulerModule } from "@progress/kendo-angular-scheduler";

import '@progress/kendo-ui';

import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TalentTrackProfilesComponent } from './modules/talent-track/talent-track-profiles/talent-track-profiles.component';
import { NavigationComponent } from './core/header/components/navigation/navigation.component';
import { UserComponent } from './core/header/components/user/user.component';
import { HttpErrorInterceptor } from './core/interceptor/http-error.interceptor';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { AddHeaderInterceptor } from './core/interceptor/add-header.interceptor';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { LayoutComponent } from './core/layout/layout.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { AuthPageComponent } from './modules/auth/components/auth-page/auth-page.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TranslateComponent } from './core/header/components/translate/translate.component';
import { AuthCallbackComponent } from './core/auth-callback/auth-callback.component';
import { FieldRenameDialogComponent } from './shared/components/field-rename-dialog/field-rename-dialog.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { TableComponent } from './shared/components/table/table.component';
import { DataPropertyGetterPipe } from './shared/components/table/data-property-getter-pipe/data-property-getter.pipe';
import { MatSortModule } from '@angular/material/sort';
import { CardComponent } from './shared/components/card/card.component';
import { SettingsMenuComponent } from './shared/components/settings-menu/settings-menu.component';
import { EmployeeVisasAndPermitsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/employee-visas-and-permits.component';
import { TrainingAndCertificationsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/training-and-certifications/training-and-certifications.component';
import { EmployeeLanguagesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-languages/employee-languages.component';
import { EmployeeMedicalTestsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/employee-medical-tests.component';
import { EmployeeProfessionalExpertisesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-professional-expertises/employee-professional-expertises.component';
import { EmployeeAssociationsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-associations/employee-associations.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { TableDialogComponent } from './shared/components/table-dialog/table-dialog.component';
import { SkeletonTableComponent } from './shared/components/skeleton-table/skeleton-table.component';
import { CreateFormComponent } from './modules/form-generator/create-form/create-form.component';
import { FormListComponent } from './modules/form-generator/form-list/form-list.component';
import { FormsComponent } from './modules/form-generator/forms/forms.component';
import { EmergencyContactsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/emergency-contacts.component';
import { EmployeeEducationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-education/employee-education.component';
import { ExpansionTableComponent } from './shared/components/expansion-table/expansion-table.component';
import { FormFieldComponent } from './modules/form-generator/edit-form-v3/components/form-field/form-field.component';
import { SiteSearchBarComponent } from './core/header/components/site-search-bar/site-search-bar.component';
import { DynamicFormFieldComponent } from './dynamic-form/form-field/dynamic-form-field.component';
import { UploadProfileImageComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/upload-profile-image/upload-profile-image.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { EmployeeCardComponent } from './modules/talent-track/employees/components/employee-card/employee-card.component';
import { EmployeesPageComponent } from './modules/talent-track/employees/containers/employees-page/employees-page.component';
import { VisitsChartComponent } from './modules/dashboard/components/visits-chart/visits-chart.component';
import { ServerChartComponent } from './modules/dashboard/components/server-chart/server-chart.component';
import { RevenueChartComponent } from './modules/dashboard/components/revenue-chart/revenue-chart.component';
import { PerformanceChartComponent } from './modules/dashboard/components/performance-chart/performance-chart.component';
import { EmployeeLeaveComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/employee-leave.component';
import { EmployeeToilHistoryComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-toil-history/employee-toil-history.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { HeadcountReportComponent } from './modules/reports/components/headcount-report/headcount-report.component';
import { HeadcountByCountryComponent } from './modules/reports/components/headcount-report/components/headcount-by-country/headcount-by-country.component';
import { HeadcountByGenderComponent } from './modules/reports/components/headcount-report/components/headcount-by-gender/headcount-by-gender.component';
import { TalentAndCemComponent } from './modules/reports/components/headcount-report/components/talent-and-cem/talent-and-cem.component';
import { KpiOverviewComponent } from './modules/reports/components/headcount-report/components/kpi-overview/kpi-overview.component';
import { NewHiresComponent } from './modules/reports/components/headcount-report/components/new-hires/new-hires.component';
import { OnboardingDemoComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/onboarding-demo/onboarding-demo.component';
import { NewHirePacketStepperComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/onboarding-demo/new-hire-packet-stepper/new-hire-packet-stepper.component';
import { OnboardingTaskChecklistComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/onboarding-demo/onboarding-task-checklist/onboarding-task-checklist.component';
import {
    SettingsOnboardingTaskChecklistComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/onboarding-demo/settings-onboarding-task-checklist/settings-onboarding-task-checklist.component';
import { PacketsComponent } from './modules/packets/packets.component';
import { PacketComponent } from './modules/packets/components/packet/packet.component';
import { QuestionnaireComponent } from './modules/packets/components/questionnaire/questionnaire.component';
import { ChecklistComponent } from './modules/packets/components/checklist/checklist.component';
import { InfoPageComponent } from './modules/packets/components/info-page/info-page.component';
import { PdfViewerComponent } from './modules/pdf-viewer/pdf-viewer.component';
import { PdfViewerDialogComponent } from './shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { EmployeeDocumentsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/employee-documents.component';
import { DirectoryFormDialogComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/components/directory-form-dialog/directory-form-dialog.component';
import { DocumentUploadDialogComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/components/document-upload-dialog/document-upload-dialog.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileViewerDialogComponent } from './shared/components/file-viewer-dialog/file-viewer-dialog.component';
import { EditDocumentDialogComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/components/edit-document-dialog/edit-document-dialog.component';
import { LookupComponent } from './modules/lookup/lookup.component';
import { LookupListComponent } from './modules/lookup/components/lookup-list/lookup-list.component';
import { EditLookupDialogComponent } from './modules/lookup/components/edit-lookup-dialog/edit-lookup-dialog.component';
import { AddMultipleValuesComponent } from './modules/lookup/components/add-multiple-values/add-multiple-values.component';
import { AddSingleValueComponent } from './modules/lookup/components/add-single-value/add-single-value.component';
import { OverlayComponent } from './shared/components/overlay/overlay.component';
import { WithLoadingPipe } from './shared/pipes/with-loading.pipe';
import { SiteSettingsMenuComponent } from './modules/site-settings-menu/site-settings-menu.component';
import { WorkLocationsComponent } from './modules/work-locations/work-locations.component';
import { WorkLocationsListComponent } from './modules/work-locations/components/work-locations-list/work-locations-list.component';
import { WorkLocationDialogComponent } from './modules/work-locations/components/work-location-dialog/work-location-dialog.component';
import { CopyLookupDialogComponent } from './modules/lookup/components/copy-lookup-dialog/copy-lookup-dialog.component';
import { DocumentsTreeViewComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/components/documents-tree-view/documents-tree-view.component';
import {
    DocumentsDrillDownViewComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/components/documents-drill-down-view/documents-drill-down-view.component';
import { PositionsComponent } from './modules/positions/positions.component';
import { EmploymentRecordsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/employment-records.component';
import { EmployeeDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/employee-details.component';
import { EmploymentRecordsDataGridComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-records-data-grid/employment-records-data-grid.component';
import { EmploymentRecordDialogComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-dialog/employment-record-dialog.component';
import { EmployeeLayoutComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/employee-layout.component';
import { EmploymentRecordDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/employment-record-details.component';
import {
    EmploymentRecordBenefitsComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-benefits/employment-record-benefits.component';
import { EmploymentRecordComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record/employment-record.component';
import {
    EmploymentRecordBenefitDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-benefits/components/employment-record-benefit-dialog/employment-record-benefit-dialog.component';
import { EmployeeCompanyAssetComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-company-asset/employee-company-asset.component';
import {
    EmploymentRecordGrievanceComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-grievance/employment-record-grievance.component';
import {
    EmploymentRecordGrievanceDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-grievance/components/employment-record-grievance-dialog/employment-record-grievance-dialog.component';
import {
    EmploymentRecordOtherCompensationsComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-other-compensations/employment-record-other-compensations.component';
import {
    EmploymentRecordOtherCompensationDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-other-compensations/components/employment-record-other-compensation-dialog/employment-record-other-compensation-dialog.component';
import {
    EmploymentRecordPensionsComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-pensions/employment-record-pensions.component';
import {
    EmploymentRecordPensionDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-pensions/components/employment-record-pension-dialog/employment-record-pension-dialog.component';
import {
    EmploymentRecordRelocationComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-relocation/employment-record-relocation.component';
import {
    EmploymentRecordRelocationDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-relocation/components/employment-record-relocation-dialog/employment-record-relocation-dialog.component';
import {
    EmploymentRecordStockPurchasePlanComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-stock-purchase-plan/employment-record-stock-purchase-plan.component';
import {
    EmploymentRecordStockPurchasePlanDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-stock-purchase-plan/components/employment-record-stock-purchase-plan-dialog/employment-record-stock-purchase-plan-dialog.component';
import {
    EmploymentRecordCompensationComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-compensation/employment-record-compensation.component';
import {
    EmploymentRecordCompensationDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-compensation/components/employment-record-compensation-dialog/employment-record-compensation-dialog.component';
import {
    EmploymentRecordDisciplineComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-discipline/employment-record-discipline.component';
import {
    EmploymentRecordDisciplineDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-discipline/components/employment-record-discipline-dialog/employment-record-discipline-dialog.component';
import {
    EmploymentRecordInjuryIllnessComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-injury-illness/employment-record-injury-illness.component';
import {
    EmploymentRecordInjuryIllnessDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-injury-illness/components/employment-record-injury-illness-dialog/employment-record-injury-illness-dialog.component';
import { DataGridCompComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/data-grid-comp.component';
import { SecurityMenuComponent } from './modules/security-menu/security-menu.component';
import { ApiSetupComponent } from './modules/api-setup/api-setup.component';
import { ApiUsersDataGridComponent } from './modules/api-setup/components/api-users-data-grid/api-users-data-grid.component';
import { ApiUserDialogComponent } from './modules/api-setup/components/api-user-dialog/api-user-dialog.component';
import { TotalCallsChartComponent } from './modules/api-setup/components/total-calls-chart/total-calls-chart.component';
import { TotalUserCallsChartComponent } from './modules/api-setup/components/total-user-calls-chart/total-user-calls-chart.component';
import { NewApiUserDialogComponent } from './modules/api-setup/components/new-api-user-dialog/new-api-user-dialog.component';
import { LeaveRequestDialogComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/components/leave-request-dialog/leave-request-dialog.component';
import { BalanceControlComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/components/balance-control/balance-control.component';
import { EmployeeWorkExperienceComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-work-experience/employee-work-experience.component';
import { EmployeePersonalDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-personal-details/employee-personal-details.component';
import {
    EmploymentRecordPositionsComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/employment-record-positions.component';
import {
    AddPositionsDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/components/add-positions-dialog/add-positions-dialog.component';
import { EmployeeRosterDemoComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-roster-demo/employee-roster-demo.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { OrgChartComponent } from './modules/org-chart/org-chart.component';
import { OrgChartDisplayComponent } from './modules/org-chart/components/org-chart-display/org-chart-display.component';
import { SecuritySetupComponent } from '@app/modules/security-setup/security-setup.component';
import { HeadcountByMonthReportComponent } from './modules/reports/components/headcount-by-month-report/headcount-by-month-report.component';
import { TerminationsByMonthComponent } from './modules/reports/components/headcount-by-month-report/components/terminations-by-month/terminations-by-month.component';
import { HiresAndPromotionsByMonthComponent } from './modules/reports/components/headcount-by-month-report/components/hires-and-promotions-by-month/hires-and-promotions-by-month.component';
import { TenureByMonthComponent } from './modules/reports/components/headcount-by-month-report/components/tenure-by-month/tenure-by-month.component';
import { HeadcountByMonthChartTableComponent } from './modules/reports/components/headcount-by-month-report/components/headcount-by-month-chart-table/headcount-by-month-chart-table.component';
import { NewEmployeeFormDialogComponent } from './modules/talent-track/talent-track-profiles/components/new-employee-form-dialog/new-employee-form-dialog.component';
import { PositionFormDialogComponent } from './modules/org-chart/components/position-editor/position-form-dialog.component';
import { WorkforcePlanningReportComponent } from './modules/reports/components/workforce-planning-report/workforce-planning-report.component';
import { WorkforceCostVisibilityComponent } from './modules/reports/components/workforce-planning-report/components/workforce-cost-visibility/workforce-cost-visibility.component';
import { DiversityScorecardComponent } from './modules/reports/components/workforce-planning-report/components/diversity-scorecard/diversity-scorecard.component';
import { PositionedForSuccessionComponent } from './modules/reports/components/workforce-planning-report/components/positioned-for-succession/positioned-for-succession.component';
import { ContingentWorkerAnalysisComponent } from './modules/reports/components/workforce-planning-report/components/contingent-worker-analysis/contingent-worker-analysis.component';
import { DiversityBenchmarkReportComponent } from './modules/reports/components/diversity-benchmark-report/diversity-benchmark-report.component';
import { ContingentWorkerCostComponent } from './modules/reports/components/workforce-planning-report/components/contingent-worker-cost/contingent-worker-cost.component';
import { CompensationReportComponent } from '@app/modules/reports/components/compensation-report/compensation-report.component';
import { TimeOffMenuComponent } from '@app/modules/time-off/time-off-menu.component';
import { ToilMenuComponent } from '@app/modules/time-off-in-lieu/toil-menu.component';
import { AddTimeOffComponent } from '@app/modules/time-off/add-time-off-type/add-time-off.component';
import { AddToilComponent } from '@app/modules/time-off-in-lieu/add-toil-type/add-toil.component';
import { WorkRotationsComponent } from './modules/work-rotations/work-rotations.component';
import { WorkRotationsExpansionPanelsComponent } from './modules/work-rotations/components/work-rotations-expansion-panels/work-rotations-expansion-panels.component';
import { WorkRotationDialogComponent } from './modules/work-rotations/components/work-rotation-dialog/work-rotation-dialog.component';
import { WorkRotationElementsListComponent } from './modules/work-rotations/components/work-rotation-elements-list/work-rotation-elements-list.component';
import { WorkRotationElementFormComponent } from './modules/work-rotations/components/work-rotation-elements-list/components/work-rotation-element-form/work-rotation-element-form.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { LetterGeneratorComponent } from './modules/letter-generator/letter-generator.component';
import { CreatedLettersListComponent } from './modules/letter-generator/components/created-letters-list/created-letters-list.component';
import { CreateLetterComponent } from './modules/letter-generator/components/create-letter/create-letter.component';
import { SendLetterComponent } from './modules/letter-generator/components/send-letter/send-letter.component';
import { SentHistoryComponent } from './modules/letter-generator/components/sent-history/sent-history.component';
import { SelectLetterListComponent } from './modules/letter-generator/components/send-letter/components/select-letter-list/select-letter-list.component';
import { PreviewLetterDialogComponent } from './modules/letter-generator/components/send-letter/components/preview-letter-dialog/preview-letter-dialog.component';
import { SelectRecipientListComponent } from './modules/letter-generator/components/send-letter/components/select-recipient-list/select-recipient-list.component';
import { AddLetterDetailsComponent } from './modules/letter-generator/components/send-letter/components/add-letter-details/add-letter-details.component';
import { ReviewAndSendComponent } from './modules/letter-generator/components/send-letter/components/review-and-send/review-and-send.component';
import { DirectReporteesComponent } from './modules/direct-reportees/direct-reportees.component';
import { DirectReporteeCardComponent } from './modules/direct-reportees/components/direct-reportee-card/direct-reportee-card.component';
import { PositionEditorComponent } from './modules/positions/components/position-editor/position-editor.component';
import { SystemVariableFilterPipe } from './modules/letter-generator/components/create-letter/system-variable-filter.pipe';
import { ReportsListDataGridComponent } from './modules/reports/components/reports-list-data-grid/reports-list-data-grid.component';
import { NewReportDialogComponent } from './modules/reports/components/new-report-dialog/new-report-dialog.component';
import { MonthlyAbsenceReportComponent } from './modules/reports/components/monthly-absence-report/monthly-absence-report.component';
import { MonthlyAbsenceTypePieChartComponent } from './modules/reports/components/monthly-absence-report/components/monthly-absence-type-pie-chart/monthly-absence-type-pie-chart.component';
import { LeaveTypePerMonthAreaChartComponent } from './modules/reports/components/monthly-absence-report/components/leave-type-per-month-area-chart/leave-type-per-month-area-chart.component';
import { EmployeeAbsenceDataGridComponent } from './modules/reports/components/monthly-absence-report/components/employee-absence-data-grid/employee-absence-data-grid.component';
import { MonthlyHolidayUtilisationReportComponent } from './modules/reports/components/monthly-holiday-utilisation-report/monthly-holiday-utilisation-report.component';
import { MonthlyHolidayUtilisationDataGridComponent } from './modules/reports/components/monthly-holiday-utilisation-report/components/monthly-holiday-utilisation-data-grid/monthly-holiday-utilisation-data-grid.component';
import { HolidaysBarChartComponent } from './modules/reports/components/monthly-holiday-utilisation-report/components/holidays-bar-chart/holidays-bar-chart.component';
import { ToilPieChartComponent } from './modules/reports/components/monthly-holiday-utilisation-report/components/toil-pie-chart/toil-pie-chart.component';
import { EditTimeOffComponent } from '@app/modules/time-off/edit-time-off-type/edit-time-off.component';
import { EditToilComponent } from '@app/modules/time-off-in-lieu/edit-toil-type/edit-toil.component';
import { TimeOffPolicyComponent } from '@app/modules/time-off/time-off-policy/time-off-policy.component';
import { ToilPolicyComponent } from '@app/modules/time-off-in-lieu/toil-policy/toil-policy.component';
import { NewsFeedComponent } from './modules/news-feed/news-feed.component';
import { NotificationsComponent } from './core/header/components/notifications/notifications.component';
import { EmailComponent } from './core/header/components/email/email.component';
import { ShortNamePipe } from './core/header/components/email/short-name';
import { InboxComponent } from './modules/inbox/inbox.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { BirthdaysComponent } from './modules/homepage/components/birthdays/birthdays.component';
import { BirthdayTimePeriodPipe } from './modules/homepage/components/birthdays/birthday-time-period.pipe';
import { OutOfOfficeWidgetComponent } from './modules/homepage/components/out-of-office-widget/out-of-office-widget.component';
import { TasksWidgetComponent } from './modules/homepage/components/tasks-widget/tasks-widget.component';
import { NewsAndHolidaysWidgetComponent } from './modules/homepage/components/news-and-holidays-widget/news-and-holidays-widget.component';
import { ReportsRectangleDisplayListComponent } from './modules/reports/components/reports-rectangle-display-list/reports-rectangle-display-list.component';
import { TimeOffWidgetComponent } from './modules/homepage/components/time-off-widget/time-off-widget.component';
import { InboxWidgetComponent } from './modules/homepage/components/inbox-widget/inbox-widget.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {
    EmploymentRecordPayrollComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-payroll/employment-record-payroll.component';
import {
    PayrollPieChartComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-payroll/components/payroll-pie-chart/payroll-pie-chart.component';
import { CompensationSectionDemoComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/compensation-section-demo/compensation-section-demo.component';
import { CompensationDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/compensation-section-demo/components/compensation-details/compensation-details.component';
import { IntegrationConfigurationComponent } from './modules/integration-configuration/integration-configuration.component';
import {
    PayStubsDataGridComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-payroll/components/pay-stubs-data-grid/pay-stubs-data-grid.component';
import {
    DirectDepositDetailsComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-payroll/components/direct-deposit-details/direct-deposit-details.component';
import { EmployeeStatusDialogComponent } from './modules/employee-status-dialog/employee-status-dialog.component';
import { SiteSettingsConfigureComponent } from '@app/modules/site-settings-configure/site-settings-configure.component';
import { SiteSettingsCurrencyComponent } from '@app/modules/site-settings-currency/site-settings-currency.component';
import { EmployeeFamilyDependantsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-family-dependants/employee-family-dependants.component';
import { EmployeeCompensationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/employee-compensation.component';
import { api_routes } from './consts';
import { TranslationsComponent } from './modules/translations/translations.component';
import { NewTranslationDialogComponent } from './modules/translations/components/new-translation-dialog/new-translation-dialog.component';
import { ViewCurrencyComponent } from '@app/modules/site-settings-currency/view-currency/view-currency.component';
import { CostCentersComponent } from '@app/modules/cost-centers/cost-centers.component';
import { FilterControlComponent } from '@app/shared/components/filter/filter-control/filter-control.component';
import { FilterDialogComponent } from '@app/shared/components/filter/filter-dialog/filter-dialog.component';
import { WorkRotationEditorComponent } from '@app/modules/work-rotations/components/work-rotation-editor/work-rotation-editor.component';
import { EmployeeOtherCompensationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-other-compensation/employee-other-compensation.component';
import { EmployeeRelocationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-relocation/employee-relocation.component';
import { EmployeeGrievancesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-grievances/employee-grievances.component';
import { EmployeeInjuryIllnessComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-injury-illness/employee-injury-illness.component';
import { SecurityRolesComponent } from '@app/modules/security-roles/security-roles.component';
import { BenefitsComponent } from '@app/modules/benefits/benefits.component';
import { BenefitsCoveragesComponent } from './modules/benefits/components/benefits-coverages/benefits-coverages.component';
import { BenefitCoverageDialogComponent } from './modules/benefits/components/benefits-coverages/components/benefit-coverage-dialog/benefit-coverage-dialog.component';
import { BenefitsPlanTypesComponent } from './modules/benefits/components/benefits-plan-types/benefits-plan-types.component';
import { BenefitsPlanTypeDetailsComponent } from './modules/benefits/components/benefits-plan-types/components/benefits-plan-type-details/benefits-plan-type-details.component';
import { BenefitsPlansComponent } from './modules/benefits/components/benefits-plans/benefits-plans.component';
import { BenefitsGroupsComponent } from './modules/benefits/components/benefits-groups/benefits-groups.component';
import { BenefitsPlanEditorComponent } from './modules/benefits/components/benefits-plans/components/benefits-plan-editor/benefits-plan-editor.component';
import { BenefitsPlanCoverageListComponent } from './modules/benefits/components/benefits-plan-coverages/components/benefits-plan-coverage-list/benefits-plan-coverage-list.component';
import { BenefitsPlanCoverageDialogComponent } from './modules/benefits/components/benefits-plan-coverages/components/benefits-plan-coverage-dialog/benefits-plan-coverage-dialog.component';
import { BenefitGroupEditorComponent } from './modules/benefits/components/benefits-groups/components/benefit-group-editor/benefit-group-editor.component';
import { BenefitGroupPlansComponent } from './modules/benefits/components/benefit-group-plans/benefit-group-plans.component';
import { BenefitGroupPlanDialogComponent } from './modules/benefits/components/benefit-group-plans/components/benefit-group-plan-dialog/benefit-group-plan-dialog.component';
import { BenefitsPlanDocumentsComponent } from './modules/benefits/components/benefits-plan-documents/benefits-plan-documents.component';
import { FileUploadDialogComponent } from './shared/components/file-upload-dialog/file-upload-dialog.component';
import { CreateDirectoryDialogComponent } from './shared/components/create-directory-dialog/create-directory-dialog.component';
import { PositionDocumentsComponent } from './modules/positions/components/position-documents/position-documents.component';
import { BenefitGroupPlanCardComponent } from './modules/benefits/components/benefit-group-plans/components/benefit-group-plan-card/benefit-group-plan-card.component';
import { BenefitGroupPlanCoverageDialogComponent } from './modules/benefits/components/benefit-group-plans/components/benefit-group-plan-coverage-dialog/benefit-group-plan-coverage-dialog.component';
import { SecurityRoleEditorComponent } from '@app/modules/security-roles/components/security-role-editor/security-role-editor.component';
import { DropDownTreesModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormElementDialogV2Component } from './modules/form-generator/edit-form-v3/components/form-element-dialog-v2/form-element-dialog-v2.component';
import { EditFormV3Component } from './modules/form-generator/edit-form-v3/edit-form-v3.component';
import { FormDetailsFormComponent } from './modules/form-generator/edit-form-v3/components/form-details-form/form-details-form.component';
import { FormElementsDisplayComponent } from './modules/form-generator/edit-form-v3/components/form-elements-display/form-elements-display.component';
import { FormGeneratorComponent } from './shared/components/form-generator/form-generator.component';
import { FormGeneratorFormFieldComponent } from './shared/components/form-generator/components/form-generator-form-field/form-generator-form-field.component';
import { EmployeeImageComponent } from './shared/components/employee-image/employee-image.component';
import { DemoFormDialogComponent } from './modules/form-generator/edit-form-v3/components/demo-form-dialog/demo-form-dialog.component';
import { ChangeReasonDialogComponent } from './shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorDialogComponent } from './shared/components/form-generator-dialog/form-generator-dialog.component';
import { HasFeatureDirective } from '@app/shared/directives/has-feature.directive';
import { NoFeatureAccessComponent } from '@app/shared/components/no-feature-access/no-feature-access.component';
import { AccessLogsDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { AddUserDialogComponent } from '@app/modules/security-roles/components/add-user-dialog/add-user-dialog.component';
import { StatutoryHolidaysComponent } from '@app/modules/statutory-holidays/statutory-holidays.component';
import { HolidayGroupsDataGridComponent } from '@app/modules/statutory-holidays/components/holiday-groups-data-grid/holiday-groups-data-grid.component';
import { HolidayGroupsEditorComponent } from '@app/modules/statutory-holidays/components/holiday-groups-editor/holiday-groups-editor.component';
import { HolidayDayDialogComponent } from '@app/modules/statutory-holidays/components/holiday-day-dialog/holiday-day-dialog.component';
import {
    AssignEmployeePolicyDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/components/assign-employee-policy-dialog/assign-employee-policy-dialog.component';
import {
    AssignToilPolicyDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-toil-history/components/assign-toil-policy-dialog/assign-toil-policy-dialog.component';
import { OrganizationStructureComponent } from '@app/modules/organization-structure/organization-structure.component';
import { OrganizationEditorComponent } from '@app/modules/organization-structure/components/organization-editor/organization-editor.component';
import { AddOrgDialogComponent } from '@app/modules/security-roles/components/add-org-dialog/add-org-dialog.component';
import { OrganizationTypesDialogComponent } from '@app/modules/organization-structure/components/organization-types-dialog/organization-types-dialog.component';
import { OrganizationTypeEditorDialogComponent } from '@app/modules/organization-structure/components/organization-types-editor-dialog/organization-type-editor-dialog.component';
import { NewOrganizationFormDialogComponent } from '@app/modules/organization-structure/components/new-organization-form-dialog/new-organization-form-dialog.component';
import { EmployeeAbsencesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/employee-absences.component';
import { EmployeeAbsencesToilComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences-toil/employee-absences-toil.component';
import { EmployeeSecurityComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/employee-security.component';
import { EmployeePasswordResetComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/components/employee-password-reset/employee-password-reset.component';
import { EmployeeOrganizationsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/components/employee-organizations/employee-organizations.component';
import { EmployeeLoginHistoryComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/components/employee-login-history/employee-login-history.component';
import { EmployeeDeleteComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/components/employee-delete/employee-delete.component';
import { EmployeeSecurityRolesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/components/employee-security-roles/employee-security-roles.component';
import {
    AssignSecurityRoleEmployeeDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/components/assign-security-role-employee-dialog/assign-security-role-employee-dialog.component';
import { TableAccessDirective } from './shared/directives/table-access.directive';
import { CurrencySearchPipe } from './modules/site-settings-currency/currency-search.pipe';
import { EmployeeBankDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-bank-details/employee-bank-details.component';
import { EmployementRecordsV2Component } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employement-records-v2/employement-records-v2.component';
import {
    EmploymentRecordExpansionPanelComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employement-records-v2/components/employment-record-expansion-panel/employment-record-expansion-panel.component';
import { EmployeeContactCardComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/employee-contact-card.component';
import { MatInputCommifiedDirective } from '@app/shared/directives/input-commified.directive';
import { CountryConfigurationComponent } from './modules/country-configuration/country-configuration.component';
import { EmployeeEmploymentDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-employment-details/employee-employment-details.component';
import {
    EmployeeStatHolidayGroupComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/components/employee-stat-holiday-group/employee-stat-holiday-group.component';
import { EmployeeBankDetailsReportComponent } from './modules/reports/components/employee-bank-details-report/employee-bank-details-report.component';
import { BankDetailsDataGridComponent } from './modules/reports/components/employee-bank-details-report/components/bank-details-data-grid/bank-details-data-grid.component';
import { BankNameChartComponent } from './modules/reports/components/employee-bank-details-report/components/bank-name-chart/bank-name-chart.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { CopyRoleDialogComponent } from '@app/modules/security-roles/components/copy-role-dialog/copy-role-dialog.component';
import { BasicEmployeeReportComponent } from './modules/reports/components/basic-employee-report/basic-employee-report.component';
import { BasicEmployeeDetailsDataGridComponent } from './modules/reports/components/basic-employee-report/components/basic-employee-details-data-grid/basic-employee-details-data-grid.component';
import { EmployeePayrollDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-payroll-details/employee-payroll-details.component';
import { BasicHeadcountReportComponent } from './modules/reports/components/basic-headcount-report/basic-headcount-report.component';
import { HeadcountReportDataGridComponent } from './modules/reports/components/basic-headcount-report/components/headcount-report-data-grid/headcount-report-data-grid.component';
import { CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { FileSizePipe } from './shared/pipes/filesize.pipe';
import { TableFieldEditorComponent } from './modules/table-field-editor/table-field-editor.component';
import { AdminControlsComponent } from './modules/admin-controls/admin-controls.component';
import { AddDummyPositionsComponent } from './modules/admin-controls/components/add-dummy-positions/add-dummy-positions.component';
import { AddDummyOrganizationsComponent } from './modules/admin-controls/components/add-dummy-organizations/add-dummy-organizations.component';
import { AddDummyEmployeesComponent } from './modules/admin-controls/components/add-dummy-employees/add-dummy-employees.component';
import { AssignPositionsToAllComponent } from './modules/admin-controls/components/assign-positions-to-all/assign-positions-to-all.component';
import { GenerateSchedulesComponent } from './modules/generate-schedules/generate-schedules.component';
import { RunJobFormComponent } from './modules/generate-schedules/components/run-job-form/run-job-form.component';
import { JobsQueueComponent } from './modules/generate-schedules/components/jobs-queue/jobs-queue.component';
import { MyTimeOffRequestsComponent } from './modules/homepage/components/my-time-off-requests/my-time-off-requests.component';
import { MyToilRequestsComponent } from './modules/homepage/components/my-toil-requests/my-toil-requests.component';
import { PendingTimeOffApprovalsComponent } from './modules/dashboard/components/pending-time-off-approvals/pending-time-off-approvals.component';
import { PendingToilApprovalsComponent } from './modules/dashboard/components/pending-toil-approvals/pending-toil-approvals.component';
import { PendingTimeOffDialogComponent } from './modules/dashboard/components/pending-time-off-approvals/components/pending-time-off-dialog/pending-time-off-dialog.component';
import { PendingToilDialogComponent } from './modules/dashboard/components/pending-toil-approvals/components/pending-toil-dialog/pending-toil-dialog.component';
import { RequestsListItemComponent } from './modules/homepage/components/my-time-off-requests/components/requests-list-item/requests-list-item.component';
import { ToilRequestsListItemComponent } from './modules/homepage/components/my-toil-requests/components/toil-requests-list-item/toil-requests-list-item.component';
import { ApprovalListItemComponent } from './modules/dashboard/components/pending-time-off-approvals/components/approval-list-item/approval-list-item.component';
import { ToilApprovalListItemComponent } from './modules/dashboard/components/pending-toil-approvals/components/toil-approval-list-item/toil-approval-list-item.component';
import { MyTimeOffRequestDialogComponent } from './modules/homepage/components/my-time-off-requests/components/my-time-off-request-dialog/my-time-off-request-dialog.component';
import { MyToilRequestDialogComponent } from './modules/homepage/components/my-toil-requests/components/my-toil-request-dialog/my-toil-request-dialog.component';
import { TimeOffPolicyWidgetComponent } from './modules/homepage/components/time-off-policies-widget/time-off-policy-widget.component';
import { ToilPolicyWidgetComponent } from './modules/homepage/components/toil-policies-widget/toil-policy-widget.component';
import { CompensationHistoryWidgetComponent } from './modules/homepage/components/compensation-history-widget/compensation-history-widget.component';
import { CompensationHistoryWidgetDialogComponent } from './modules/homepage/components/compensation-history-widget/components/compensation-history-widget-dialog/compensation-history-widget-dialog.component';
import { ScheduleWidgetComponent } from './modules/homepage/components/schedule-widget/schedule-widget.component';
import { RequestTimeOffDialogComponent } from './modules/homepage/components/time-off-policies-widget/components/request-time-off-dialog/request-time-off-dialog.component';
import { RequestToilDialogComponent } from './modules/homepage/components/toil-policies-widget/components/request-toil-dialog/request-toil-dialog.component';
import { AccrueToilDialogComponent } from './modules/homepage/components/toil-policies-widget/components/accrue-toil-dialog/accrue-toil-dialog.component';
import { GeneratePositionScheduleComponent } from './modules/positions/components/position-editor/components/generate-position-schedule/generate-position-schedule.component';
import { CurrencyDisplayPipe } from './shared/pipes/currency-display.pipe';
import { PositionSelectionFormFieldComponent } from './shared/components/form-generator/components/position-selection-form-field/position-selection-form-field.component';
import { SearchPositionDialogComponent } from './shared/components/form-generator/components/position-selection-form-field/components/search-position-dialog/search-position-dialog.component';
import { ViewPositionDetailsDialogComponent } from './shared/components/form-generator/components/position-selection-form-field/components/view-position-details-dialog/view-position-details-dialog.component';
import { PositionEditorPageComponent } from './modules/positions/components/position-editor-page/position-editor-page.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { TimeOffTypeSearchPipe } from './modules/time-off/time-off-type-search.pipe';
import { ToilTypeSearchPipe } from './modules/time-off-in-lieu/toil-type-search.pipe';
import { WelcomeMessageComponent } from './modules/homepage/components/welcome-message/welcome-message.component';
import { LookupListSearchPipe } from './modules/lookup/lookup-list-search.pipe';
import { CustomReportsComponent } from "@app/modules/custom-reports/custom-reports.component";
import { ReportRendererComponent } from "@app/modules/custom-reports/components/report-renderer/report-renderer.component";
import { ReportsListComponent } from "@app/modules/custom-reports/components/reports-list/reports-list.component";
import { ReportBuilderComponent } from "@app/modules/custom-reports/components/report-builder/report-builder.component";
import { ReportElementDialogComponent } from "@app/modules/custom-reports/components/report-element-dialog/report-element-dialog.component";
import { ReportElementGridColumnComponent } from "@app/modules/custom-reports/components/report-element-dialog/report-element-grid-column/report-element-grid-column.component";
import { OidcAuthService } from "@app/core/services/oidc-auth.service";
import { EnvironmentService } from "@app/core/services/environment.service";
import { PerformanceComponent } from './modules/performance/performance.component';
import { GoalTypesComponent } from './modules/performance/components/goal-types/goal-types.component';
import { GoalTypeDialogComponent } from './modules/performance/components/goal-types/components/goal-type-dialog/goal-type-dialog.component';
import { GoalDialogComponent } from './modules/performance/components/goal-dialog/goal-dialog.component';
import { GoalPlansComponent } from './modules/performance/components/goal-plans/goal-plans.component';
import { GoalPlanEditorComponent } from './modules/performance/components/goal-plans/components/goal-plan-editor/goal-plan-editor.component';
import { SelectEmployeesDialogComponent } from './shared/components/select-employees-dialog/select-employees-dialog.component';
import { GoalPlanGoalsComponent } from './modules/performance/components/goal-plans/components/goal-plan-goals/goal-plan-goals.component';
import { DirectReporteesScheduleComponent } from './modules/dashboard/components/direct-reportees-schedule/direct-reportees-schedule.component';
import { GoalCommentsComponent } from './modules/performance/components/goal-dialog/components/goal-comments/goal-comments.component';
import { EmployeeGoalsComponent } from './modules/performance/components/employee-goals/employee-goals.component';
import { MultipleEmployeeGoalsComponent } from './modules/performance/components/multiple-employee-goals/multiple-employee-goals.component';
import { DirectReporteesGoalsComponent } from './modules/dashboard/direct-reportees-goals/direct-reportees-goals.component';
import { GoalCommentComponent } from './modules/performance/components/goal-dialog/components/goal-comments/goal-comment/goal-comment.component';
import { HeadcountPositionsDataGridComponent } from './modules/reports/components/basic-headcount-report/components/headcount-positions-data-grid/headcount-positions-data-grid.component';
import { BasicEmployeePositionsDataGridComponent } from './modules/reports/components/basic-employee-report/components/basic-employee-positions-data-grid/basic-employee-positions-data-grid.component';
import { EmployeeProjectsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-projects/employee-projects.component';
import { HasModuleAccessDirective } from './shared/directives/has-module-access.directive';
import { PreventDoubleClickDirective } from './shared/directives/prevent-double-click.directive';
import {
    TimeOffCalculationLogDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/components/time-off-calculation-log-dialog/time-off-calculation-log-dialog.component';
import { FilterDropdownComponent } from "@app/modules/custom-reports/components/report-element-dialog/filter-dropdown/filter-dropdown.component";
import { EmployeeScheduleComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-schedule/employee-schedule.component';
import { DirectReporteeSearchPipe } from './modules/direct-reportees/direct-reportee-search.pipe';
import { ReportElementDatagridComponent } from "@app/modules/custom-reports/components/report-element-dialog/report-element-datagrid/report-element-datagrid.component";
import { ReportElementPieChartComponent } from "@app/modules/custom-reports/components/report-element-dialog/report-element-pie-chart/report-element-pie-chart.component";
import { AllGoalsListComponent } from './modules/performance/components/all-goals-list/all-goals-list.component';
import { EmployeeProfileGoalsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-profile-goals/employee-profile-goals.component';
import { ModuleAccessByOrganizationComponent } from './modules/security-roles/components/security-role-editor/components/module-access-by-organization/module-access-by-organization.component';
import { UsersInRoleComponent } from './modules/security-roles/components/security-role-editor/components/users-in-role/users-in-role.component';
import { GoalSelectionFormFieldComponent } from './shared/components/form-generator/components/goal-selection-form-field/goal-selection-form-field.component';
import { SelectGoalDialogComponent } from './shared/components/form-generator/components/goal-selection-form-field/components/select-goal-dialog/select-goal-dialog.component';
import { GoalPlanPublishHistoryDialogComponent } from './modules/performance/components/goal-plans/components/goal-plan-publish-history-dialog/goal-plan-publish-history-dialog.component';
import { AbsenceReportComponent } from '@app/modules/reports/components/absence-report/absence-report.component';
import { AbsenceFinanceReportComponent } from '@app/modules/reports/components/absence-finance-report/absence-finance-report.component';
import { AbsenceReportDetailsDataGridComponent } from '@app/modules/reports/components/absence-report/components/absence-report-details-data-grid/absence-report-details-data-grid.component';
import { OutstandingAbsenceReportComponent } from '@app/modules/reports/components/outstanding-absence-report/outstanding-absence-report.component';
import { ToilBalanceReportComponent } from '@app/modules/reports/components/toil-balance-report/toil-balance-report.component';
import { ToilBalanceReportDetailsDataGridComponent } from '@app/modules/reports/components/toil-balance-report/components/toil-balance-report-details-data-grid/toil-balance-report-details-data-grid.component';
import { ToilOccurrenceReportComponent } from '@app/modules/reports/components/toil-occurrence-report/toil-occurrence-report.component';
import { ToilOccurrenceReportDetailsDataGridComponent } from '@app/modules/reports/components/toil-occurrence-report/components/toil-occurrence-report-details-data-grid/toil-occurrence-report-details-data-grid.component';
import { BootstrapAlertComponent } from './shared/components/bootstrap-alert/bootstrap-alert.component';
import { CustomReportsDataGridComponent } from './modules/custom-reports/components/reports-list/custom-reports-data-grid/custom-reports-data-grid.component';
import { HardcodedReportsDataGridComponent } from './modules/custom-reports/components/reports-list/hardcoded-reports-data-grid/hardcoded-reports-data-grid.component';
import { LocalDataGridCompComponent } from './shared/components/local-data-grid-comp/local-data-grid-comp.component';
import { ReportsSearchPipe } from './modules/custom-reports/components/reports-list/hardcoded-reports-data-grid/reports-search.pipe';
import { GoalPlanOwnersComponent } from './modules/performance/components/goal-plans/components/goal-plan-editor/components/goal-plan-owners/goal-plan-owners.component';
import {
    EmployeeToilRecordFormDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences-toil/components/employee-toil-record-form-dialog/employee-toil-record-form-dialog.component';
import { EmployeeToilPolicyComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-toil-history/components/employee-toil-policy/employee-toil-policy.component';
import { ToilPoliciesTypesComponent } from './modules/time-off-in-lieu/toil-policies-types/toil-policies-types.component';
import { ToilEmailsComponent } from './modules/time-off-in-lieu/toil-emails/toil-emails.component';
import { EmployeeLoginReportComponent } from './modules/reports/components/employee-login-report/employee-login-report.component';
import { EmployeeLoginReportDataGridComponent } from './modules/reports/components/employee-login-report/components/employee-login-report-data-grid/employee-login-report-data-grid.component';
import { TimeOffPoliciesTypesComponent } from './modules/time-off/time-off-policies-types/time-off-policies-types.component';
import { TimeOffEmailsComponent } from './modules/time-off/time-off-emails/time-off-emails.component';
import { AddDummyWorkRotationsComponent } from './modules/admin-controls/components/add-dummy-work-rotations/add-dummy-work-rotations.component';
import { AddDummyWorkLocationsComponent } from './modules/admin-controls/components/add-dummy-work-locations/add-dummy-work-locations.component';
import { AddDummyCostCentersComponent } from './modules/admin-controls/components/add-dummy-cost-centers/add-dummy-cost-centers.component';
import { SplashPageComponent } from './core/splash-page/splash-page.component';
import {
    ToilPolicyCalculationLogDialogComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-toil-history/components/toil-policy-calculation-log-dialog/toil-policy-calculation-log-dialog.component';
import { ToilPoliciesDataGridComponent } from './modules/time-off-in-lieu/toil-policies-data-grid/toil-policies-data-grid.component';
import { ViewFutureScheduleDialogComponent } from './modules/homepage/components/time-off-policies-widget/components/view-future-schedule-dialog/view-future-schedule-dialog.component';
import { UpdateTranslationDialogComponent } from './modules/translations/components/update-translation-dialog/update-translation-dialog.component';
import { ReviewPlansComponent } from './modules/performance/components/review-plans/review-plans.component';
import { ReviewPlanEditorComponent } from './modules/performance/components/review-plans/components/review-plan-editor/review-plan-editor.component';
import { ReviewPlanOwnersComponent } from './modules/performance/components/review-plans/components/review-plan-owners/review-plan-owners.component';
import { ReviewPlanGoalPlansComponent } from './modules/performance/components/review-plans/components/review-plan-goal-plans/review-plan-goal-plans.component';
import { GoalPlanSelectionFormFieldComponent } from './shared/components/form-generator/components/goal-plan-selection-form-field/goal-plan-selection-form-field.component';
import { SelectGoalPlanDialogComponent } from './shared/components/form-generator/components/goal-plan-selection-form-field/components/select-goal-plan-dialog/select-goal-plan-dialog.component';

import { AbsenceFinanceReportDetailsDataGridComponent } from '@app/modules/reports/components/absence-finance-report/components/absence-report-details-data-grid/absence-finance-report-details-data-grid.component';
import { OutstandingAbsenceReportDetailsDataGridComponent } from '@app/modules/reports/components/outstanding-absence-report/components/absence-report-details-data-grid/outstanding-absence-report-details-data-grid.component';
import { ReviewPlanReviewersComponent } from './modules/performance/components/review-plans/components/review-plan-reviewers/review-plan-reviewers.component';
import { EmployeeFormFieldComponent } from './shared/components/form-generator/components/employee-form-field/employee-form-field.component';
import { ReviewPlanGoalTypeWeightsComponent } from './modules/performance/components/review-plans/components/review-plan-goal-type-weights/review-plan-goal-type-weights.component';
import { ReviewRatingsComponent } from './modules/performance/components/review-ratings/review-ratings.component';
import { EmployeeIntegrationDetailsComponent } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-integration-details/employee-integration-details.component";
import { ReviewPlanOverallRatingsComponent } from './modules/performance/components/review-plans/components/review-plan-overall-ratings/review-plan-overall-ratings.component';
import { ReviewRatingsFormFieldComponent } from './shared/components/form-generator/components/review-ratings-form-field/review-ratings-form-field.component';
import { ReviewPlanReviewerDialogComponent } from './modules/performance/components/review-plans/components/review-plan-reviewers/components/review-plan-reviewer-dialog/review-plan-reviewer-dialog.component';
import { ReviewPlanEmployeesComponent } from './modules/performance/components/review-plans/components/review-plan-employees/review-plan-employees.component';
import { LocalizationPopupDirectiveDirective } from './shared/directives/localization-popup-directive.directive';
import { ReviewPlanPublishHistoryDialogComponent } from './modules/performance/components/review-plans/components/review-plan-publish-history-dialog/review-plan-publish-history-dialog.component';
import { ReviewsComponent } from './modules/performance/components/reviews/reviews.component';
import { ReviewPageComponent } from './modules/performance/components/review-page/review-page.component';
import { ReviewGoalsSectionComponent } from './modules/performance/components/review-page/components/review-goals-section/review-goals-section.component';
import { ReviewGoalReviewComponent } from './modules/performance/components/review-page/components/review-goal-review/review-goal-review.component';
import { BusinessRuleAutomationComponent } from "@app/modules/business-rule-automation/business-rule-automation.component";
import { BusinessRuleAutomationFormComponent } from "@app/modules/business-rule-automation/components/business-rule-automation-form/business-rule-automation-form.component";
import { BusinessRuleAutomationNotificationsComponent } from "@app/modules/business-rule-automation/components/business-rule-automation-notifications/business-rule-automation-notifications.component";
import { BusinessRuleAutomationRuleComponent } from "@app/modules/business-rule-automation/components/business-rule-automation-rule/business-rule-automation-rule.component";
import { NotificationDialogComponent } from "@app/modules/business-rule-automation/components/business-rule-automation-notification-dialog/notification-dialog.component";
import { ChildGoalsDataGridComponent } from './modules/performance/components/goal-dialog/components/child-goals-data-grid/child-goals-data-grid.component';
import { EmployeeGoalsPageComponent } from './modules/performance/components/employee-goals-page/employee-goals-page.component';
import { GoalTypeSelectionFormFieldComponent } from './shared/components/form-generator/components/goal-type-selection-form-field/goal-type-selection-form-field.component';
import { SelectGoalTypeDialogComponent } from './shared/components/form-generator/components/goal-type-selection-form-field/components/select-goal-type-dialog/select-goal-type-dialog.component';
import { PersonalGoalDialogComponent } from './modules/performance/components/personal-goal-dialog/personal-goal-dialog.component';
import { EmployeePersonalGoalsComponent } from './modules/performance/components/employee-personal-goals/employee-personal-goals.component';
import { EmployeePersonalGoalFormComponent } from './modules/performance/components/employee-personal-goals/components/employee-personal-goal-form/employee-personal-goal-form.component';
import { EmployeePersonalGoalChildGoalsComponent } from './modules/performance/components/employee-personal-goals/components/employee-personal-goal-child-goals/employee-personal-goal-child-goals.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SetColorDirective } from './shared/directives/set-color.directive';
import { StylingColoursFormFieldComponent } from './shared/components/form-generator/components/styling-colours-form-field/styling-colours-form-field.component';
import { SelectStylingColoursDialogComponent } from './shared/components/form-generator/components/styling-colours-form-field/components/select-styling-colours-dialog/select-styling-colours-dialog.component';

import { ModuleConfigurationComponent } from './modules/admin-controls/components/module-configuration/module-configuration.component';
import { GoalsReportComponent } from "@app/modules/reports/components/performance-goals-report/goals-report.component";
import { GoalsReportDetailsDataGridComponent } from "@app/modules/reports/components/performance-goals-report/components/goals-report-details-data-grid/goals-report-details-data-grid.component";
import { SiteSettingsStylingComponent } from './modules/site-settings-styling/site-settings-styling.component';
import { StylingColoursComponent } from './modules/site-settings-styling/components/styling-colours/styling-colours.component';
import { StylingColourEditorComponent } from './modules/site-settings-styling/components/styling-colours/components/styling-colour-editor/styling-colour-editor.component';
import { CompanyLogosComponent } from './modules/site-settings-styling/components/company-logos/company-logos.component';
import { ImageUploadFormFieldComponent } from './shared/components/form-generator/components/image-upload-form-field/image-upload-form-field.component';
import { CompanyLogoEditorComponent } from './modules/site-settings-styling/components/company-logos/components/company-logo-editor/company-logo-editor.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { FileUploadFormFieldComponent } from './shared/components/form-generator/components/file-upload-form-field/file-upload-form-field.component';
import { EditCompanyLogoDetailsComponent } from './modules/site-settings-styling/components/company-logos/components/edit-company-logo-details/edit-company-logo-details.component';
import { EditCompanyLogoFileComponent } from './modules/site-settings-styling/components/company-logos/components/edit-company-logo-file/edit-company-logo-file.component';
import { CreateCompanyLogoComponent } from './modules/site-settings-styling/components/company-logos/components/create-company-logo/create-company-logo.component';
import { LogoImageComponent } from './shared/components/logo-image/logo-image.component';
import { CompanyLogoFormFieldComponent } from './shared/components/form-generator/components/company-logo-form-field/company-logo-form-field.component';
import { SelectCompanyLogoDialogComponent } from './shared/components/form-generator/components/company-logo-form-field/components/select-company-logo-dialog/select-company-logo-dialog.component';
import { PermissibleEmployeeGoalPlansComponent } from './shared/components/form-generator/components/permissible-employee-goal-plans/permissible-employee-goal-plans.component';
import {
    SelectPermissibleEmployeeGoalPlanDialogComponent
} from './shared/components/form-generator/components/permissible-employee-goal-plans/components/select-permissible-employee-goal-plan-dialog/select-permissible-employee-goal-plan-dialog.component';
import { EmployeePublishedGoalsComponent } from './modules/performance/components/employee-published-goals/employee-published-goals.component';
import { GoalPlanEmployeeGoalsComponent } from './modules/performance/components/goal-plans/components/goal-plan-employee-goals/goal-plan-employee-goals.component';
import { EmployeeGoalPlansComponent } from './modules/performance/components/employee-goal-plans/employee-goal-plans.component';
import { EmployeeGoalPlanComponent } from './modules/performance/components/employee-goal-plans/components/employee-goal-plan/employee-goal-plan.component';
import { HomepageEmployeeGoalsWidgetComponent } from './modules/performance/components/homepage-employee-goals-widget/homepage-employee-goals-widget.component';
import { EmployeePublishedGoalParentDataGridComponent } from './modules/performance/components/employee-published-goal-parent-data-grid/employee-published-goal-parent-data-grid.component';
import { EmployeePublishedGoalChildDataGridComponent } from './modules/performance/components/employee-published-goal-child-data-grid/employee-published-goal-child-data-grid.component';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { CultureConfigurationComponent } from './modules/admin-controls/components/culture-configuration/culture-configuration.component';
import { LocaleService } from './core/services/locale.service';
import { ReviewPlanGoalSettingsComponent } from './modules/performance/components/review-plans/components/review-plan-goal-settings/review-plan-goal-settings.component';
import { ReviewPlanGoalSettingComponent } from './modules/performance/components/review-plans/components/review-plan-goal-settings/components/review-plan-goal-setting/review-plan-goal-setting.component';
import { ReviewPlanGoalSettingFormComponent } from './modules/performance/components/review-plans/components/review-plan-goal-settings/components/review-plan-goal-setting-form/review-plan-goal-setting-form.component';
import { ReviewPlanGoalPlanSettingsComponent } from './modules/performance/components/review-plans/components/review-plan-goal-settings/components/review-plan-goal-plan-settings/review-plan-goal-plan-settings.component';

import locale_FR from '@angular/common/locales/fr';
import locale_FR_CA from '@angular/common/locales/fr-CA';
import locale_EN from '@angular/common/locales/en';
import locale_EN_GB from '@angular/common/locales/en-GB';
import locale_EN_CA from '@angular/common/locales/en-CA';
import locale_ES_MX from '@angular/common/locales/es-MX';

// Load all required data for the bg locale
import "@progress/kendo-angular-intl/locales/en/all";
import "@progress/kendo-angular-intl/locales/en-GB/all";
import "@progress/kendo-angular-intl/locales/en-CA/all";
import "@progress/kendo-angular-intl/locales/fr/all";
import "@progress/kendo-angular-intl/locales/fr-CA/all";
import "@progress/kendo-angular-intl/locales/es-MX/all";
import { MyReviewsComponent } from './modules/performance/components/reviews/components/my-reviews/my-reviews.component';
import { FeedbackSettingsDropdownComponent } from './shared/components/form-generator/components/feedback-settings-dropdown/feedback-settings-dropdown.component';
import { ReviewerSecurityPermissionsDropdownComponent } from './shared/components/form-generator/components/reviewer-security-permissions-dropdown/reviewer-security-permissions-dropdown.component';
import { EmployeePersonalGoalsChildGoalsDataGridComponent } from './modules/performance/components/employee-personal-goals/components/employee-personal-goals-child-goals-data-grid/employee-personal-goals-child-goals-data-grid.component';
import { OverallRatingCommentComponent } from './modules/performance/components/review-page/components/overall-rating-comment/overall-rating-comment.component';
import { GoalPlanRatingCommentComponent } from './modules/performance/components/review-page/components/goal-plan-rating-comment/goal-plan-rating-comment.component';
import { PerformanceSectionMyReviewsComponent } from './modules/performance/components/reviews/components/performance-section-my-reviews/performance-section-my-reviews.component';
import { MultipleEmployeeSelectFormFieldComponent } from './shared/components/form-generator/components/multiple-employee-select-form-field/multiple-employee-select-form-field.component';
import { GlobalReviewsComponent } from './modules/performance/components/global-reviews/global-reviews.component';
import { GlobalReviewComponent } from './modules/performance/components/global-reviews/components/global-review/global-review.component';
import { GlobalGoalPlanReviewsComponent } from './modules/performance/components/global-reviews/components/global-goal-plan-reviews/global-goal-plan-reviews.component';
import { GlobalGoalReviewsComponent } from './modules/performance/components/global-reviews/components/global-goal-reviews/global-goal-reviews.component';
import { EmployeeDetailsDialogComponent } from './modules/performance/components/goal-plans/components/goal-plan-editor/components/employee-details-dialog/employee-details-dialog.component';
import { GoalTypeStatusFormFieldComponent } from './shared/components/form-generator/components/goal-type-status-form-field/goal-type-status-form-field.component';
import { ReviewRatingStatusFormFieldComponent } from './shared/components/form-generator/components/review-rating-status-form-field/review-rating-status-form-field.component';
import { GoalPlanStatusFormFieldComponent } from './shared/components/form-generator/components/goal-plan-status-form-field/goal-plan-status-form-field.component';
import { ReviewPlanGoalTypeDialogComponent } from './modules/performance/components/review-plans/components/review-plan-goal-type-dialog/review-plan-goal-type-dialog.component';
import { LettersComponent } from './modules/letters/letters.component';
import { LetterheadsComponent } from './modules/letters/components/letterheads/letterheads.component';
import { LocalizationTextFormFieldComponent } from './shared/components/form-generator/components/localization-text-form-field/localization-text-form-field.component';
import { LocalizationTextAreaFormFieldComponent } from './shared/components/form-generator/components/localization-text-area-form-field/localization-text-area-form-field.component';
import { KendoEditorFormFieldComponent } from './shared/components/form-generator/components/kendo-editor-form-field/kendo-editor-form-field.component';
import { LetterFootersComponent } from './modules/letters/components/letter-footers/letter-footers.component';
import { LetterPageOrientationsDropdownComponent } from './shared/components/form-generator/components/letter-page-orientations-dropdown/letter-page-orientations-dropdown.component';
import { LetterPaperSizesDropdownComponent } from './shared/components/form-generator/components/letter-paper-sizes-dropdown/letter-paper-sizes-dropdown.component';
import { LetterRecipientTypesDropdownComponent } from './shared/components/form-generator/components/letter-recipient-types-dropdown/letter-recipient-types-dropdown.component';
import { LetterDocumentTypesDropdownComponent } from './shared/components/form-generator/components/letter-document-types-dropdown/letter-document-types-dropdown.component';
import { LetterTemplatesComponent } from './modules/letters/components/letter-templates/letter-templates.component';
import { LetterHeaderDropdownComponent } from './shared/components/form-generator/components/letter-header-dropdown/letter-header-dropdown.component';
import { LetterFooterDropdownComponent } from './shared/components/form-generator/components/letter-footer-dropdown/letter-footer-dropdown.component';
import { LetterTemplateStatusesDropdownComponent } from './shared/components/form-generator/components/letter-template-statuses-dropdown/letter-template-statuses-dropdown.component';
import { MultipleOrganizationFormFieldComponent } from './shared/components/form-generator/components/multiple-organization-form-field/multiple-organization-form-field.component';
import { SelectOrganizationsDialogComponent } from './shared/components/form-generator/components/multiple-organization-form-field/components/select-organizations-dialog/select-organizations-dialog.component';
import { LetterTemplateEditorComponent } from './modules/letters/components/letter-templates/components/letter-template-editor/letter-template-editor.component';
import { IntegrationsComponent } from "@app/modules/site-settings-integrations/integrations.component";
import { IntegrationEditorComponent } from "@app/modules/site-settings-integrations/components/integration-editor/integration-editor.component";
import { IntegrationTabContainerComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/integration-tab-container.component";
import { IcimsIntegrationDetailsTabComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/icims-integration-details-tab/icims-integration-details-tab.component";
import { IntegrationMappingsTabComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/integration-mappings-tab/integration-mappings-tab.component";
import { IntegrationLogsTabComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/integration-logs-tab/integration-logs-tab.component";
import { IntegrationLogsDialogComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/integration-logs-dialog/integration-logs-dialog.component";
import { IntegrationMappingsDialogComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/integration-mappings-dialog/integration-mappings-dialog.component";
import { ReviewStatusReportComponent } from "@app/modules/reports/components/performance-review-status/review-status-report.component";
import { ReviewStatusReportDetailsDataGridComponent } from "@app/modules/reports/components/performance-review-status/components/review-status-report-details-data-grid/review-status-report-details-data-grid.component";
import { ErrorCardComponent } from './shared/components/error-card/error-card.component';
import { LetterHeaderFooterStatusDropdownComponent } from './shared/components/form-generator/components/letter-header-footer-status-dropdown/letter-header-footer-status-dropdown.component';
import { LetterGenerationsComponent } from './modules/letters/components/letter-generations/letter-generations.component';
import { LetterGenerationEditorComponent } from './modules/letters/components/letter-generations/components/letter-generation-editor/letter-generation-editor.component';
import { PreviewTemplateDialogComponent } from './modules/letters/components/letter-templates/components/preview-template-dialog/preview-template-dialog.component';
import { LetterDeliveryMethodsComponent } from './modules/letters/components/letter-delivery-methods/letter-delivery-methods.component';
import { LetterDeliveryMethodDialogComponent } from './modules/letters/components/letter-delivery-methods/components/letter-delivery-method-dialog/letter-delivery-method-dialog.component';
import { LetterDeliveryMethodsDropdownComponent } from './shared/components/form-generator/components/letter-delivery-methods-dropdown/letter-delivery-methods-dropdown.component';
import { LettersUserSectionComponent } from './modules/letters/components/letters-user-section/letters-user-section.component';
import { LettersAdminSectionComponent } from './modules/letters/components/letters-admin-section/letters-admin-section.component';
import { ReplacementsDialogComponent } from './modules/letters/components/letter-templates/components/replacements-dialog/replacements-dialog.component';
import { EmployeeListSearchPipe } from './modules/letters/components/letter-generations/components/letter-generation-editor/employees-list-search.pipe';
import { RecipientListSearchPipe } from './modules/letters/components/letter-generations/components/letter-generation-editor/recipients-list-search.pipe';
import { LetterGenerationRecipientDialogComponent } from './modules/letters/components/letter-generations/components/letter-generation-editor/components/letter-generation-recipient-dialog/letter-generation-recipient-dialog.component';
import { LetterGenerationEmployeeDialogComponent } from './modules/letters/components/letter-generations/components/letter-generation-editor/components/letter-generation-employee-dialog/letter-generation-employee-dialog.component';
import { PayworksIntegrationDetailsComponent } from './modules/site-settings-integrations/components/integration-tab-container/components/payworks/payworks-integration-details/payworks-integration-details.component';
import { PayworksMappingsComponent } from './modules/site-settings-integrations/components/integration-tab-container/components/payworks/payworks-mappings/payworks-mappings.component';
import { PayworksMappingsDialogComponent } from './modules/site-settings-integrations/components/integration-tab-container/components/payworks/payworks-mappings-dialog/payworks-mappings-dialog.component';
import { PayworksTestConnectionComponent } from './modules/site-settings-integrations/components/integration-tab-container/components/payworks/payworks-test-connection/payworks-test-connection.component';

// This will use the default value if there is no translation available https://github.com/ngx-translate/core#how-to-handle-missing-translations
export class MissingTranslationHelper implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (params.interpolateParams) {
            return params.interpolateParams["Default"] || params.key;
        }
        return params.key;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        TalentTrackProfilesComponent,
        NavigationComponent,
        // EditEmployeeComponent,
        SnackbarComponent,
        LoginComponent,
        AuthPageComponent,
        LayoutComponent,
        SidebarComponent,
        UserComponent,
        TranslateComponent,
        AuthCallbackComponent,
        FieldRenameDialogComponent,
        DynamicFormComponent,
        TableComponent,
        DataPropertyGetterPipe,
        CardComponent,
        SettingsMenuComponent,
        EmployeeVisasAndPermitsComponent,
        TrainingAndCertificationsComponent,
        ConfirmDialogComponent,
        TableDialogComponent,
        EmployeeLanguagesComponent,
        EmployeeMedicalTestsComponent,
        EmployeeProfessionalExpertisesComponent,
        EmployeeAssociationsComponent,
        SkeletonTableComponent,
        CreateFormComponent,
        FormListComponent,
        FormsComponent,
        EmergencyContactsComponent,
        EmployeeEducationComponent,
        ExpansionTableComponent,
        FormFieldComponent,
        SiteSearchBarComponent,
        DynamicFormFieldComponent,
        UploadProfileImageComponent,
        EmployeesPageComponent,
        EmployeeCardComponent,
        DashboardComponent,
        FooterComponent,
        VisitsChartComponent,
        PerformanceChartComponent,
        ServerChartComponent,
        RevenueChartComponent,
        EmployeeLeaveComponent,
        EmployeeToilHistoryComponent,
        ReportsComponent,
        HeadcountReportComponent,
        HeadcountByCountryComponent,
        HeadcountByGenderComponent,
        TalentAndCemComponent,
        KpiOverviewComponent,
        NewHiresComponent,
        OnboardingDemoComponent,
        NewHirePacketStepperComponent,
        OnboardingTaskChecklistComponent,
        PacketsComponent,
        PacketComponent,
        QuestionnaireComponent,
        ChecklistComponent,
        InfoPageComponent,
        PdfViewerComponent,
        PdfViewerDialogComponent,
        EmployeeDocumentsComponent,
        SettingsOnboardingTaskChecklistComponent,
        DirectoryFormDialogComponent,
        DocumentUploadDialogComponent,
        FileViewerDialogComponent,
        EditDocumentDialogComponent,
        LookupComponent,
        LookupListComponent,
        EditLookupDialogComponent,
        AddMultipleValuesComponent,
        AddSingleValueComponent,
        OverlayComponent,
        WithLoadingPipe,
        SiteSettingsMenuComponent,
        WorkLocationsComponent,
        WorkLocationsListComponent,
        WorkLocationDialogComponent,
        CopyLookupDialogComponent,
        DocumentsTreeViewComponent,
        DocumentsDrillDownViewComponent,
        PositionsComponent,
        EmploymentRecordsComponent,
        EmployeeDetailsComponent,
        EmploymentRecordsDataGridComponent,
        EmploymentRecordDialogComponent,
        EmployeeLayoutComponent,
        EmploymentRecordDetailsComponent,
        EmploymentRecordBenefitsComponent,
        EmploymentRecordComponent,
        EmploymentRecordBenefitDialogComponent,
        EmployeeCompanyAssetComponent,
        EmploymentRecordGrievanceComponent,
        EmploymentRecordGrievanceDialogComponent,
        EmploymentRecordOtherCompensationsComponent,
        EmploymentRecordOtherCompensationDialogComponent,
        EmploymentRecordPensionsComponent,
        EmploymentRecordPensionDialogComponent,
        EmploymentRecordRelocationComponent,
        EmploymentRecordRelocationDialogComponent,
        EmploymentRecordStockPurchasePlanComponent,
        EmploymentRecordStockPurchasePlanDialogComponent,
        EmploymentRecordCompensationComponent,
        EmploymentRecordCompensationDialogComponent,
        EmploymentRecordDisciplineComponent,
        EmploymentRecordDisciplineDialogComponent,
        EmploymentRecordInjuryIllnessComponent,
        EmploymentRecordInjuryIllnessDialogComponent,
        DataGridCompComponent,
        SecurityMenuComponent,
        SecuritySetupComponent,
        SecurityRolesComponent,
        SecurityRoleEditorComponent,
        ApiSetupComponent,
        ApiUsersDataGridComponent,
        ApiUserDialogComponent,
        TotalCallsChartComponent,
        TotalUserCallsChartComponent,
        NewApiUserDialogComponent,
        LeaveRequestDialogComponent,
        BalanceControlComponent,
        EmployeeWorkExperienceComponent,
        EmployeePersonalDetailsComponent,
        EmploymentRecordPositionsComponent,
        AddPositionsDialogComponent,
        EmployeeRosterDemoComponent,
        OrgChartComponent,
        OrgChartDisplayComponent,
        HeadcountByMonthReportComponent,
        TerminationsByMonthComponent,
        HiresAndPromotionsByMonthComponent,
        TenureByMonthComponent,
        HeadcountByMonthChartTableComponent,
        NewEmployeeFormDialogComponent,
        PositionFormDialogComponent,
        WorkforcePlanningReportComponent,
        WorkforceCostVisibilityComponent,
        DiversityScorecardComponent,
        PositionedForSuccessionComponent,
        ContingentWorkerAnalysisComponent,
        DiversityBenchmarkReportComponent,
        ContingentWorkerCostComponent,
        WorkRotationsComponent,
        WorkRotationsExpansionPanelsComponent,
        WorkRotationDialogComponent,
        WorkRotationElementsListComponent,
        WorkRotationElementFormComponent,
        WorkRotationEditorComponent,
        LetterGeneratorComponent,
        CreatedLettersListComponent,
        CreateLetterComponent,
        SendLetterComponent,
        SentHistoryComponent,
        SelectLetterListComponent,
        PreviewLetterDialogComponent,
        SelectRecipientListComponent,
        AddLetterDetailsComponent,
        ReviewAndSendComponent,
        DirectReporteesComponent,
        DirectReporteeCardComponent,
        PositionEditorComponent,
        SystemVariableFilterPipe,
        ShortNamePipe,
        BirthdayTimePeriodPipe,
        CurrencySearchPipe,
        ReportsSearchPipe,
        CurrencyDisplayPipe,
        TimeOffTypeSearchPipe,
        ToilTypeSearchPipe,
        LookupListSearchPipe,
        EmployeeListSearchPipe,
        RecipientListSearchPipe,
        ReportsListDataGridComponent,
        NewReportDialogComponent,
        MonthlyAbsenceReportComponent,
        MonthlyAbsenceTypePieChartComponent,
        LeaveTypePerMonthAreaChartComponent,
        EmployeeAbsenceDataGridComponent,
        MonthlyHolidayUtilisationReportComponent,
        MonthlyHolidayUtilisationDataGridComponent,
        HolidaysBarChartComponent,
        ToilPieChartComponent,
        ContingentWorkerCostComponent,
        CompensationReportComponent,
        TimeOffMenuComponent,
        ToilMenuComponent,
        AddTimeOffComponent,
        AddToilComponent,
        EditTimeOffComponent,
        EditToilComponent,
        TimeOffPolicyComponent,
        ToilPolicyComponent,
        StatutoryHolidaysComponent,
        HolidayGroupsDataGridComponent,
        HolidayGroupsEditorComponent,
        HolidayDayDialogComponent,
        NewsFeedComponent,
        NotificationsComponent,
        EmailComponent,
        InboxComponent,
        HomepageComponent,
        BirthdaysComponent,
        OutOfOfficeWidgetComponent,
        TasksWidgetComponent,
        NewsAndHolidaysWidgetComponent,
        ReportsRectangleDisplayListComponent,
        TimeOffWidgetComponent,
        InboxWidgetComponent,
        EmploymentRecordPayrollComponent,
        PayrollPieChartComponent,
        CompensationSectionDemoComponent,
        CompensationDetailsComponent,
        IntegrationConfigurationComponent,
        PayStubsDataGridComponent,
        DirectDepositDetailsComponent,
        EmployeeStatusDialogComponent,
        SiteSettingsConfigureComponent,
        SiteSettingsCurrencyComponent,
        EmployeeFamilyDependantsComponent,
        EmployeeCompensationComponent,
        TranslationsComponent,
        NewTranslationDialogComponent,
        FilterDialogComponent,
        ViewCurrencyComponent,
        CostCentersComponent,
        FilterControlComponent,
        EmployeeOtherCompensationComponent,
        EmployeeRelocationComponent,
        EmployeeGrievancesComponent,
        EmployeeInjuryIllnessComponent,
        BenefitsComponent,
        BenefitsCoveragesComponent,
        BenefitCoverageDialogComponent,
        BenefitsPlanTypesComponent,
        BenefitsPlanTypeDetailsComponent,
        BenefitsPlansComponent,
        BenefitsGroupsComponent,
        BenefitsPlanEditorComponent,
        BenefitsPlanCoverageListComponent,
        BenefitsPlanCoverageDialogComponent,
        BenefitGroupEditorComponent,
        BenefitGroupPlansComponent,
        BenefitGroupPlanDialogComponent,
        BenefitsPlanDocumentsComponent,
        FileUploadDialogComponent,
        CreateDirectoryDialogComponent,
        PositionDocumentsComponent,
        BenefitGroupPlanCardComponent,
        BenefitGroupPlanCoverageDialogComponent,
        FormElementDialogV2Component,
        EditFormV3Component,
        FormDetailsFormComponent,
        FormElementsDisplayComponent,
        FormGeneratorComponent,
        FormGeneratorFormFieldComponent,
        EmployeeImageComponent,
        DemoFormDialogComponent,
        ChangeReasonDialogComponent,
        FormGeneratorDialogComponent,
        HasFeatureDirective,
        NoFeatureAccessComponent,
        AccessLogsDialogComponent,
        HistoryDialogComponent,
        AddUserDialogComponent,
        AssignEmployeePolicyDialogComponent,
        AssignToilPolicyDialogComponent,
        OrganizationStructureComponent,
        OrganizationEditorComponent,
        AddOrgDialogComponent,
        OrganizationTypesDialogComponent,
        OrganizationTypeEditorDialogComponent,
        NewOrganizationFormDialogComponent,
        EmployeeAbsencesComponent,
        EmployeeAbsencesToilComponent,
        EmployeeSecurityComponent,
        EmployeePasswordResetComponent,
        EmployeeOrganizationsComponent,
        EmployeeLoginHistoryComponent,
        EmployeeDeleteComponent,
        EmployeeSecurityRolesComponent,
        AssignSecurityRoleEmployeeDialogComponent,
        TableAccessDirective,
        EmployeeBankDetailsComponent,
        EmployementRecordsV2Component,
        EmploymentRecordExpansionPanelComponent,
        EmployeeContactCardComponent,
        MatInputCommifiedDirective,
        CountryConfigurationComponent,
        EmployeeEmploymentDetailsComponent,
        EmployeeStatHolidayGroupComponent,
        EmployeeBankDetailsReportComponent,
        BankDetailsDataGridComponent,
        BankNameChartComponent,
        CopyRoleDialogComponent,
        BasicEmployeeReportComponent,
        AbsenceReportComponent,
        OutstandingAbsenceReportComponent,
        ToilBalanceReportComponent,
        ToilBalanceReportDetailsDataGridComponent,
        ToilOccurrenceReportComponent,
        ToilOccurrenceReportDetailsDataGridComponent,
        BasicEmployeeDetailsDataGridComponent,
        EmployeePayrollDetailsComponent,
        EmployeeIntegrationDetailsComponent,
        BasicHeadcountReportComponent,
        HeadcountReportDataGridComponent,
        FileSizePipe,
        TableFieldEditorComponent,
        AdminControlsComponent,
        AddDummyPositionsComponent,
        AddDummyOrganizationsComponent,
        AddDummyEmployeesComponent,
        AssignPositionsToAllComponent,
        GenerateSchedulesComponent,
        RunJobFormComponent,
        JobsQueueComponent,
        MyTimeOffRequestsComponent,
        MyToilRequestsComponent,
        PendingTimeOffApprovalsComponent,
        PendingToilApprovalsComponent,
        PendingTimeOffDialogComponent,
        PendingToilDialogComponent,
        RequestsListItemComponent,
        ToilRequestsListItemComponent,
        ApprovalListItemComponent,
        ToilApprovalListItemComponent,
        MyTimeOffRequestDialogComponent,
        MyToilRequestDialogComponent,
        TimeOffPolicyWidgetComponent,
        ToilPolicyWidgetComponent,
        CompensationHistoryWidgetComponent,
        CompensationHistoryWidgetDialogComponent,
        ScheduleWidgetComponent,
        RequestTimeOffDialogComponent,
        RequestToilDialogComponent,
        AccrueToilDialogComponent,
        GeneratePositionScheduleComponent,
        PositionSelectionFormFieldComponent,
        SearchPositionDialogComponent,
        ViewPositionDetailsDialogComponent,
        PositionEditorPageComponent,
        WelcomeMessageComponent,
        PerformanceComponent,
        GoalTypesComponent,
        GoalTypeDialogComponent,
        PositionEditorPageComponent,
        CustomReportsComponent,
        ReportsListComponent,
        ReportRendererComponent,
        ReportBuilderComponent,
        ReportElementDialogComponent,
        ReportElementGridColumnComponent,
        ReportElementDatagridComponent,
        ReportElementPieChartComponent,
        GoalPlansComponent,
        GoalPlanEditorComponent,
        SelectEmployeesDialogComponent,
        GoalPlanGoalsComponent,
        DirectReporteesScheduleComponent,
        GoalDialogComponent,
        GoalCommentsComponent,
        EmployeeGoalsComponent,
        MultipleEmployeeGoalsComponent,
        DirectReporteesGoalsComponent,
        GoalCommentComponent,
        HeadcountPositionsDataGridComponent,
        BasicEmployeePositionsDataGridComponent,
        AbsenceReportDetailsDataGridComponent,
        AbsenceFinanceReportComponent,
        AbsenceFinanceReportDetailsDataGridComponent,
        OutstandingAbsenceReportDetailsDataGridComponent,
        EmployeeProjectsComponent,
        HasModuleAccessDirective,
        PreventDoubleClickDirective,
        TimeOffCalculationLogDialogComponent,
        FilterDropdownComponent,
        EmployeeScheduleComponent,
        DirectReporteeSearchPipe,
        AllGoalsListComponent,
        EmployeeProfileGoalsComponent,
        ModuleAccessByOrganizationComponent,
        UsersInRoleComponent,
        GoalSelectionFormFieldComponent,
        SelectGoalDialogComponent,
        GoalPlanPublishHistoryDialogComponent,
        BootstrapAlertComponent,
        CustomReportsDataGridComponent,
        HardcodedReportsDataGridComponent,
        LocalDataGridCompComponent,
        GoalPlanOwnersComponent,
        EmployeeToilRecordFormDialogComponent,
        EmployeeToilPolicyComponent,
        ToilPoliciesTypesComponent,
        ToilEmailsComponent,
        EmployeeLoginReportComponent,
        EmployeeLoginReportDataGridComponent,
        TimeOffPoliciesTypesComponent,
        TimeOffEmailsComponent,
        AddDummyWorkRotationsComponent,
        AddDummyWorkLocationsComponent,
        AddDummyCostCentersComponent,
        SplashPageComponent,
        ToilPolicyCalculationLogDialogComponent,
        ToilPoliciesDataGridComponent,
        ViewFutureScheduleDialogComponent,
        UpdateTranslationDialogComponent,
        ReviewPlansComponent,
        ReviewPlanEditorComponent,
        ReviewPlanOwnersComponent,
        ReviewPlanGoalPlansComponent,
        GoalPlanSelectionFormFieldComponent,
        SelectGoalPlanDialogComponent,
        ReviewPlanReviewersComponent,
        EmployeeFormFieldComponent,
        ReviewPlanGoalTypeWeightsComponent,
        ReviewRatingsComponent,
        ReviewPlanOverallRatingsComponent,
        ReviewRatingsFormFieldComponent,
        ReviewPlanReviewerDialogComponent,
        ReviewPlanEmployeesComponent,
        LocalizationPopupDirectiveDirective,
        ReviewPlanPublishHistoryDialogComponent,
        ReviewsComponent,
        ReviewPageComponent,
        ReviewGoalsSectionComponent,
        ReviewGoalReviewComponent,
        BusinessRuleAutomationComponent,
        BusinessRuleAutomationFormComponent,
        BusinessRuleAutomationNotificationsComponent,
        BusinessRuleAutomationRuleComponent,
        NotificationDialogComponent,
        ChildGoalsDataGridComponent,
        EmployeeGoalsPageComponent,
        GoalTypeSelectionFormFieldComponent,
        SelectGoalTypeDialogComponent,
        PersonalGoalDialogComponent,
        EmployeePersonalGoalsComponent,
        EmployeePersonalGoalFormComponent,
        EmployeePersonalGoalChildGoalsComponent,
        ModuleConfigurationComponent,
        StylingColoursComponent,
        StylingColourEditorComponent,
        SetColorDirective,
        StylingColoursFormFieldComponent,
        SelectStylingColoursDialogComponent,
        SiteSettingsStylingComponent,
        IntegrationsComponent,
        IntegrationEditorComponent,
        IntegrationTabContainerComponent,
        IcimsIntegrationDetailsTabComponent,
        IntegrationMappingsTabComponent,
        IntegrationLogsTabComponent,
        IntegrationLogsDialogComponent,
        IntegrationMappingsDialogComponent,
        CompanyLogosComponent,
        ImageUploadFormFieldComponent,
        CompanyLogoEditorComponent,
        FileUploadFormFieldComponent,
        EditCompanyLogoDetailsComponent,
        EditCompanyLogoFileComponent,
        CreateCompanyLogoComponent,
        LogoImageComponent,
        CompanyLogoFormFieldComponent,
        SelectCompanyLogoDialogComponent,
        GoalsReportComponent,
        GoalsReportDetailsDataGridComponent,
        ReviewStatusReportComponent,
        ReviewStatusReportDetailsDataGridComponent,
        CultureConfigurationComponent,
        PermissibleEmployeeGoalPlansComponent,
        SelectPermissibleEmployeeGoalPlanDialogComponent,
        EmployeePublishedGoalsComponent,
        GoalPlanEmployeeGoalsComponent,
        EmployeeGoalPlansComponent,
        EmployeeGoalPlanComponent,
        HomepageEmployeeGoalsWidgetComponent,
        EmployeePublishedGoalParentDataGridComponent,
        EmployeePublishedGoalChildDataGridComponent,
        ReviewPlanGoalSettingsComponent,
        ReviewPlanGoalSettingComponent,
        ReviewPlanGoalSettingFormComponent,
        ReviewPlanGoalPlanSettingsComponent,
        MyReviewsComponent,
        FeedbackSettingsDropdownComponent,
        ReviewerSecurityPermissionsDropdownComponent,
        EmployeePersonalGoalsChildGoalsDataGridComponent,
        OverallRatingCommentComponent,
        GoalPlanRatingCommentComponent,
        PerformanceSectionMyReviewsComponent,
        MultipleEmployeeSelectFormFieldComponent,
        GlobalReviewsComponent,
        GlobalReviewComponent,
        GlobalGoalPlanReviewsComponent,
        GlobalGoalReviewsComponent,
        EmployeeDetailsDialogComponent,
        GoalTypeStatusFormFieldComponent,
        ReviewRatingStatusFormFieldComponent,
        GoalPlanStatusFormFieldComponent,
        ReviewPlanGoalTypeDialogComponent,
        LettersComponent,
        LetterheadsComponent,
        LocalizationTextFormFieldComponent,
        LocalizationTextAreaFormFieldComponent,
        KendoEditorFormFieldComponent,
        LetterFootersComponent,
        LetterPageOrientationsDropdownComponent,
        LetterPaperSizesDropdownComponent,
        LetterRecipientTypesDropdownComponent,
        LetterDocumentTypesDropdownComponent,
        LetterTemplatesComponent,
        LetterHeaderDropdownComponent,
        LetterFooterDropdownComponent,
        LetterTemplateStatusesDropdownComponent,
        MultipleOrganizationFormFieldComponent,
        SelectOrganizationsDialogComponent,
        LetterTemplateEditorComponent,
        ErrorCardComponent,
        LetterHeaderFooterStatusDropdownComponent,
        LetterGenerationsComponent,
        LetterGenerationEditorComponent,
        PreviewTemplateDialogComponent,
        LetterDeliveryMethodsComponent,
        LetterDeliveryMethodDialogComponent,
        LetterDeliveryMethodsDropdownComponent,
        LettersUserSectionComponent,
        LettersAdminSectionComponent,
        ReplacementsDialogComponent,
        LetterGenerationRecipientDialogComponent,
        LetterGenerationEmployeeDialogComponent,
        PayworksIntegrationDetailsComponent,
        PayworksMappingsComponent,
        PayworksMappingsDialogComponent,
        PayworksTestConnectionComponent
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
    ],
    imports: [
        Ng2SearchPipeModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatTableModule,
        FlexLayoutModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatCardModule,
        MatTabsModule,
        MatListModule,
        MatSidenavModule,
        MatMenuModule,
        MatDialogModule,
        MatSortModule,
        MatTooltipModule,
        NgxSkeletonLoaderModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        DragDropModule,
        TrendModule,
        MatProgressBarModule,
        NgApexchartsModule,
        MatChipsModule,
        MatBadgeModule,
        MatSliderModule,
        MatStepperModule,
        MatCheckboxModule,
        MatRadioModule,
        NgxExtendedPdfViewerModule,
        AngularSignaturePadModule,
        DrawerRailModule,
        NgxDocViewerModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
        TranslateModule.forRoot({
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MissingTranslationHelper},
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, EnvironmentService]
            }
        }),
        TreeViewModule,
        GridModule,
        PDFModule,
        ExcelModule,
        SchedulerModule,
        ListViewModule,
        NgxMaterialTimepickerModule,
        EditorModule,
        ClipboardModule,
        ImageCropperModule,
        MultiSelectModule,
        ScrollingModule,
        DropDownTreesModule,
        ChartsModule,
        InputsModule,
        UploadsModule,
    ],
    providers: [
        MatNativeDateModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true,
            deps: [OidcAuthService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthGuard,
        CanDeactivateGuard,
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: (LocaleService: { locale: string; }) => LocaleService.locale
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: DateAdapter, useClass: MomentUtcDateAdapter},
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        LocalizationService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        SnackbarComponent,
        FieldRenameDialogComponent,
        ConfirmDialogComponent,
        TableDialogComponent
    ]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient, envService: EnvironmentService): TranslateHttpLoader {
    return new TranslateHttpLoader(http, `${envService.env.apiUrl}${api_routes.CULTURES}/`, `/${api_routes.SYSTEM}`);
}

registerLocaleData(locale_FR);
registerLocaleData(locale_FR_CA);
registerLocaleData(locale_EN);
registerLocaleData(locale_EN_GB);
registerLocaleData(locale_EN_CA);
registerLocaleData(locale_ES_MX);
