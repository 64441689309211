import { Localization } from "@app/shared/models/localization.model"
import { Version } from "@app/shared/models/version.model"
export class ReviewPlan {
    id: string
    name: string
    description: string
    startDate: string
    endDate: string
    finalReview: boolean
    setOverallRating: boolean
    requireEmployeeWeightEntry: boolean
    enforceFullWeightAcrossGoalPlans: boolean
    setWeightByGoalType: boolean
    version: Version
}

export class ReviewPlanVerbose {
    id: string
    name: Localization[]
    description: Localization[]
    startDate: string
    endDate: string
    finalReview: boolean
    setOverallRating: boolean
    requireEmployeeWeightEntry: boolean
    enforceFullWeightAcrossGoalPlans: boolean
    setWeightByGoalType: boolean
    version: Version
}

export class ReviewPlanSubmit {
    id: string
    name: Localization[]
    description: Localization[]
    startDate: string
    endDate: string
    finalReview: boolean
    setOverallRating: boolean
    requireEmployeeWeightEntry: boolean
    enforceFullWeightAcrossGoalPlans: boolean
    setWeightByGoalType: boolean
    originalCreatedOn: string
    asOf: string
    changeReason: string
    changeReasonComments: string
}

export class ReviewPlanCreatedResponse {
    reviewPlanId: string
}

export class ReviewPlanEmployee {
    id: string
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    reviewPlan: {
        id: string
        name: string
    }
    version: Version
}

export class ReviewPlanEmployeeSubmit {
    employeeId: string
}

export class ReviewPlanOwnerSubmit {
    userId: string
}

export class ReviewPlanPublishHistory {
    id: string
    reviewPlan: {
        id: string
        name: string
    }
    publishedBy: {
        id: string
        firstName: string
        lastName: string
    }
    publishedOn: string
    startedAt: string
    completedAt: string
}

export class ReviewPlanGoalPlan {
    goalPlan: {
      id: string
      name: string
    }
    reviewPlan: {
      id: string
      name: string
    }
    weight: number
    enforceFullWeight: boolean
    requireChildGoalsWeight: boolean
    requireWeights: boolean
    overallRating: boolean
    overallComments: boolean
    visibleOnReview: boolean
    isGloballyRated: boolean
    globalRatingGloballyVisible: boolean
    reviewers: {
        id: string
        firstName: string
        lastName: string
    }[]
    version: Version
}

export class ReviewPlanGoalPlanSubmit {
    id: string
    originalCreatedOn: string
    asOf: string
    changeReason: string
    changeReasonComments: string
    goalPlanId: string
    weight: number
    enforceFullWeight: boolean
    requireChildGoalsWeight: boolean
    isGloballyRated: boolean
    visibleOnReivew: boolean
    globalRatingGloballyVisible: boolean
    reviewers: string[]
}

export class ReviewPlanReviewer {
    id: string
    reviewPlan: {
        id: string
        name: string
        description: string
        startDate: string
        endDate: string
    }
    reviewerType: {
        id: string
        name: string
    }
    reviewer: {
        id: string
        firstName: string
        lastName: string
    }
    orderNumber: number
    rating: {
        id: string
        name: string
    }
    comments: {
        id: string
        name: string
    }
    goalPlanOverallRating: {
        id: string
        name: string
    }
    goalPlanOverallComments: {
        id: string
        name: string
    }
    includeCalculatedRatings: boolean
    overallRating: {
        id: string
        name: string
    }
    overallComments: {
        id: string
        name: string
    }
    security: ReviewPlanReviewerSecurity[]
    version: Version
}

export class ReviewPlanReviewerSecurity {
    reviewerType: {
        id: string
        name: string
    }
    reviewer: {
        id: string
        firstName: string
        lastName: string
    }
    security: {
        reviewerType: {
            id: string
            name: string
        }
        reviewer: {
            id: string
            firstName: string
            lastName: string
        }
        viewComments: boolean
        viewRating: boolean
    }[]
}

export class ReviewPlanReviewerSubmit {
    id: string
    reviewerTypeId: string
    reviewerId: string
    orderNumber : number
    includeCalculatedRatings: boolean
    overallRating: string
    overallComments: string
    goalPlanOverallRating: string
    goalPlanOverallComments: string
    comments: string
    rating: string
    security: {
        reviewerType: string
        reviewerUser: string
        overallRatingSecurity: string
        overallCommentsSecurity: string
        security: {
            targetReviewerType: string
            targetReviewerUser: string
            viewComments: boolean
            viewRating: boolean
        }[]
    }[]
}

export class ReviewPlanReviewerType {
    id: string
    name: string
}

export class ReviewerSecurityPermission {
    id: string
    name: string
}

export class ReviewPlanGoalTypeWeight {
    id: string
    goalType: {
        id: string
        name: string
    }
    reviewPlan: {
        id: string
        name: string
    }
    weight: number
    hasRating: boolean
    hasComments: boolean
    childGoalHasComments: boolean
    childGoalHasRating: boolean
    version: Version
}

export class ReviewPlanGoalTypeWeightSubmit {
    id: string
    goalTypeId: string
    weight: number
    hasRating: boolean
    hasComments: boolean
    childGoalHasComments: boolean
    childGoalHasRating: boolean
    originalCreatedOn: string
    asOf: string
    changeReason: string
    changeReasonComments: string
}

export class ReviewPlanRating {
    id: string
    rating: {
        id: string
        name: string
        description: string
    }
    reviewPlan: {
        id: string
        name: string
    }
    sortOrder: number
    value: number
    version: Version
}

export class ReviewPlanRatingSubmit {
    id: string
    ratingId: string
    value: number
    sortOrder: number
}

export class ReviewPlanGoalSetting {
    reviewPlan: {
        id: string
        name: string
        description: string
        startDate: string
        endDate: string
    }
    goal: {
        id: string
        objective: string
        description: string
        goalPlan: {
            id: string
            name: string
            employeeCreated: boolean
            lockGoals: boolean
        }
    }
    reviewers: [
        {
            id: string
            firstName: string
            lastName: string
        }
    ]
    isGloballyRated: true
    version: Version
}

export class ReviewPlanGoalSettingSubmit {
    isGloballyRated: boolean
    reviewers: [
        string
    ]
    changeReason: string
    changeReasonComments: string
}
