import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fieldTypes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { Table } from '@app/modules/security-setup/models/table.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { PayworksIntegrationMapping } from '@app/modules/site-settings-integrations/models/integrations.model';
import { IntegrationsService } from '@app/modules/site-settings-integrations/services/integrations.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, forkJoin, from, Observable } from 'rxjs';

@Component({
  selector: 'app-payworks-mappings-dialog',
  templateUrl: './payworks-mappings-dialog.component.html',
  styleUrls: ['./payworks-mappings-dialog.component.scss']
})
export class PayworksMappingsDialogComponent implements OnInit {
  public integrationId: string;
  public integrationMapping?: PayworksIntegrationMapping;
  public form: any;
  public tableGroups: Array<any>;
  public selectedTable: Table;
  public fieldList: Array<TableField>;
  public selectedField: TableField;
  loadingTables: boolean;
  tables: any;
  loadingFields: boolean;
  payworksFields: string[] = [];

  constructor(
      private fb: UntypedFormBuilder,
      private translate: TranslateService,
      private overlayService: OverlayService,
      private snackbarService: SnackbarService,
      private securitySetupService: SecuritySetupService,
      private integrationService: IntegrationsService,
      private dialogRef: MatDialogRef<PayworksMappingsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) data
  ) {
      this.integrationId = data.integrationId;
      this.integrationMapping = data.integrationMapping;
      this.createForm();
  }

  ngOnInit(): void {
    this.getTablesAndFields();
    this.getPayworksFields();
  }

  createForm() {
      this.form = this.fb.group({
        table: [this.integrationMapping ? this.integrationMapping.table?.id : null, Validators.required],
        tableField: [this.integrationMapping ? this.integrationMapping.tableField?.id : null, Validators.required],
        payworksFieldId: [this.integrationMapping ? this.integrationMapping.payworksFieldId : null, Validators.required],
        transformations: this.fb.array([])
      });

      if (this.integrationMapping?.transformations && this.integrationMapping.transformations.length > 0) {
        this.integrationMapping.transformations.forEach(transformation => {
          this.addTransformation(transformation);
        });
      }
  }

  removeTransformation(index) {
    this.form.get('transformations').removeAt(index);
  }

  addTransformation(transformation?: {hubbub: string, payworks: string}) {
    this.form.get('transformations').push(this.fb.group({
      hubbub: [ transformation ? transformation.hubbub : '', Validators.required],
      payworks: [ transformation ? transformation.payworks : '', Validators.required]
    }));
  }

  getPayworksFields() {
    this.integrationService.getPayworksMappingFields(this.integrationId).subscribe(
      res => {
        this.payworksFields = res;
      }
    );
  }

  getTablesAndFields() {
    this.loadingTables = true;

    this.integrationService.getPayworksMappingsHubbubTables(this.integrationId)
    .pipe(
      finalize( () => {
          this.loadingTables = false;
          this.getTableFields();
        } 
      )
    )
    .subscribe(
      res => {
        this.tables = res.data;
      }
    );
  }

  getTableFields() {
    this.loadingFields = true;

    let observables: Observable<any>[] = [];

    this.tables.forEach(table => {
      observables.push(from(this.securitySetupService.getAllFields(table.id)));
    });

    forkJoin(observables)
    .pipe(
      finalize( () => {
          this.loadingFields = false;
          this.createTablesMap();
        } 
      )
    )
    .subscribe(
      (res) => {
        res.forEach((fields, index) => {
            this.tables[index].fields = fields.data;
          }
        ) 
      }
    );
  }

  createTablesMap() {
    let tableList = this.tables;
    let tableMap = [];
    tableList.map(table => {
        if (table.parentTable != null) {
            let groupIndex = tableMap.findIndex(t => t.groupId === table.parentTable.id);

            if (groupIndex >= 0) {
                tableMap[groupIndex].tables.push(table);
            } else {
                tableMap.push({groupName: table.parentTable.name, groupId: table.parentTable.id, tables: [table]});
            }
        } else {
            tableMap.push({groupName: table.name, groupId: table.id, tables: [table]});
        }
    });
    this.tableGroups = tableMap;
    
    if(this.integrationMapping.tableField?.id) {
      this.selectedTable = this.tables.find(table => table.id === this.integrationMapping.table.id);
    }
  }

  handleSelectTableClick(tableId) {
      this.selectedTable = this.tables.find(table => table.id === tableId);
      this.selectedField = null;
      this.form.get('tableField').setValue(null);
  }

  handleSelectFieldClick(tableField) {
      this.selectedField = tableField;
      this.form.get('tableField').setValue(tableField.id);
  }

  save() {
      this.overlayService.show();

      let mappingPayload = this.form.value;

      if (this.integrationMapping) {
          this.integrationService.updatePayworksMapping(this.integrationId, this.integrationMapping.id, mappingPayload).subscribe(res => {
              this.overlayService.hide();
              this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');
              this.dialogRef.close(true);

          }, err => {
              console.log("err", err);
              this.overlayService.hide();
              this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdateError'), 'clear', 'warn');
          });
      } else {
          this.integrationService.createPayworksMapping(this.integrationId, mappingPayload).subscribe(res => {
              this.overlayService.hide();
              this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');

              this.dialogRef.close(true);

          }, err => {
              console.log("err", err);
              this.overlayService.hide();
              this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdateError'), 'clear', 'warn');
          });
      }
  }

  close() {
      this.dialogRef.close();
  }

  protected readonly fieldTypes = fieldTypes;

}
