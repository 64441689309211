import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';

@Component({
  selector: 'app-kendo-editor-form-field',
  templateUrl: './kendo-editor-form-field.component.html',
  styleUrls: ['./kendo-editor-form-field.component.scss']
})
export class KendoEditorFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

}
