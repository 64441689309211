<app-site-settings-menu>
    <app-data-grid-comp
        *ngIf="hasFeatureAccess"
        [columns]="columns"
        [filterCategories]="filterCategories"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        category="BusinessRuleAutomation"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        searchable="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        [sortable]="{
            mode: 'multiple'
        }"
        [sortableColumns]="sortableColumns"
        [filterToggleDetails]="filterToggleDetails"
        (emitFilterEvent)="filterCallback($event)"
        (emitToggleFilter)="updateFilterToggleDetails($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="getBusinessRule($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteBusinessRules($event)"
        (emitSortEvent)="sortChange($event)"
        showCopyButton="true"
        (emitCopyEvent)="copyBusinessRule($event)"
    >
    </app-data-grid-comp>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>
