import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { api_routes } from '@app/consts';
import { EnvironmentService } from "@app/core/services/environment.service";
import {
    BusinessRuleAutomation,
    BusinessRuleAutomationNotification,
    BusinessRuleAutomationNotificationType,
    BusinessRuleAutomationVerbose,
    ConditionType,
    TriggerType
} from "@app/modules/business-rule-automation/models/business-rule-automation.model";

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class BusinessRuleAutomationService {

    constructor(private http: HttpClient, private envService: EnvironmentService) {
    }

    getBusinessRules(skip?: number, take?: string, filterString?: string, sortString?: string, filterInactive?: string): Observable<PagedData<BusinessRuleAutomation>> {

        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sortString ? params = params.append('Sort', sortString) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}`, {
            headers: headers,
            observe: 'response',
            params: params
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    getBusinessRuleById(businessRuleAutomationId: string): Observable<BusinessRuleAutomationVerbose> {
        return this.http
        .get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleAutomationId}`, {
            headers: headers,
            observe: 'response',
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    deleteBusinessRule(businessRuleId: string): Observable<any> {
        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}`, {
            headers: headers
        });
    }


    createBusinessRule(businessRule: BusinessRuleAutomationVerbose): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}`, businessRule, {
            headers: headers
        });
    }

    updateBusinessRule(businessRule: BusinessRuleAutomationVerbose): Observable<any> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRule.id}`, businessRule, {
            headers: headers
        });
    }

    getTriggerTypes(skip?: number, take?: number, filterString?: string, sortString?: string, asOf?: string): Observable<PagedData<TriggerType>> {

        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        sortString ? params = params.append('Sort', sortString) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.TRIGGER_TYPES}`, {
            headers: headers,
            observe: 'response',
            params: params
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    getConditionTypes(skip?: number, take?: number, filterString?: string, sortString?: string, asOf?: string): Observable<PagedData<ConditionType>> {

        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        sortString ? params = params.append('Sort', sortString) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.CONDITION_TYPES}`, {
            headers: headers,
            observe: 'response',
            params: params
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    getNotificationTypes(skip?: number, take?: number, filterString?: string, sortString?: string, asOf?: string): Observable<PagedData<BusinessRuleAutomationNotificationType>> {

        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        sortString ? params = params.append('Sort', sortString) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.NOTIFICATION_TYPES}`, {
            headers: headers,
            observe: 'response',
            params: params
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    getNotificationRecipientTypes(skip?: number, take?: number, filterString?: string, sortString?: string, asOf?: string): Observable<PagedData<BusinessRuleAutomationNotificationType>> {

        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        sortString ? params = params.append('Sort', sortString) : null;

        return this.http
        .get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.NOTIFICATION_RECIPIENT_TYPES}`, {
            headers: headers,
            observe: 'response',
            params: params
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }


    getBusinessRuleAutomationNotifications(businessRuleId: string, skip?: number, take?: number, filterString?: string, sortString?: string): Observable<PagedData<BusinessRuleAutomationNotification>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filterString ? params = params.append('Filter', filterString) : null;
        sortString ? params = params.append('Sort', sortString) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}/AutomationNotifications`, {
            headers: headers,
            observe: 'response',
            params: params
        }).pipe(
            catchError(err => throwError(err)),
            map(res => res.body)
        );
    }

    // Get a single BusinessRuleAutomationNotification by ID
    getBusinessRuleAutomationNotificationById(businessRuleId: string, notificationId: string): Observable<BusinessRuleAutomationNotification> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}/AutomationNotifications/${notificationId}`, {
            headers: headers,
            observe: 'response'
        }).pipe(
            catchError(err => throwError(err)),
            map(res => res.body)
        );
    }

    // Create a new BusinessRuleAutomationNotification
    createBusinessRuleAutomationNotification(businessRuleId: string, notification: BusinessRuleAutomationNotification): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}/AutomationNotifications/`, notification, {
            headers: headers
        });
    }

    // Update an existing BusinessRuleAutomationNotification
    updateBusinessRuleAutomationNotification(businessRuleId: string, notificationId: string, notification: BusinessRuleAutomationNotification): Observable<any> {

        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}/AutomationNotifications/${notificationId}`, notification, {
            headers: headers
        });
    }

    // Delete a BusinessRuleAutomationNotification
    deleteBusinessRuleAutomationNotification(businessRuleId: string, notificationId: string): Observable<any> {
        // Uncomment and use the actual API call when ready

        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}/AutomationNotifications/${notificationId}`, {
            headers: headers
        });
    }


    copyBusinessRule(businessRuleId: string): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.BUSINESS_RULE_AUTOMATIONS}/${api_routes.DATA_TRIGGERS}/${businessRuleId}/Copy`, null, {
            headers: headers
        });
    }
}
