import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {routes} from '@app/consts';
import {SanitiseGridDataService} from '@app/core/services/sanitise-grid-data/sanitise-grid-data.service';
import {TranslateService} from '@ngx-translate/core';
import {ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent, PageSizeItem} from '@progress/kendo-angular-grid';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {BehaviorSubject, Observable, from} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {OutstandingAbsenceReportService} from '../../services/outstanding-absence-report.service';
import {AbsenceRecord} from '@app/modules/reports/components/absence-report/models/absence-report.model';

@Component({
    selector: 'app-outstanding-absence-details-data-grid',
    templateUrl: './outstanding-absence-report-details-data-grid.component.html',
    styleUrls: ['./outstanding-absence-report-details-data-grid.component.scss']
})
export class OutstandingAbsenceReportDetailsDataGridComponent implements OnInit {
    @ViewChild(GridComponent)
    public grid: GridComponent;

    public routes: typeof routes = routes;
    public sort: SortDescriptor[] = [];
    public bindingType = 'array';
    public view: Observable<GridDataResult>;
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    public pageSizes: (PageSizeItem | number)[] = [
        5,
        10,
        20,
        {
            text: 'All',
            value: 100000,
        },
    ];
    isLoading: boolean;
    pageSize = 4;
    skip = 0;
    sortString: string;
    filterString: string;
    absences: AbsenceRecord[];
    private filterInactive = true;
    public columns: any[] = [
        {field: 'id', title: this.translate.instant('OutstandingAbsenceFinanceReport-Id')
            , tableId: 'OutstandingAbsenceFinanceReport-Id', dataType: 'String'},
        {field: 'firstName', title: this.translate.instant('OutstandingAbsenceFinanceReport-FirstName')
            , tableId: 'OutstandingAbsenceFinanceReport-FirstName', dataType: 'String'},
        {field: 'lastName', title: this.translate.instant('OutstandingAbsenceFinanceReport-LastName')
            , tableId: 'OutstandingAbsenceFinanceReport-LastName', dataType: 'String'},
        {field: 'timeOffPolicy.name', title: this.translate.instant('OutstandingAbsenceFinanceReport-PolicyName')
            , tableId: 'OutstandingAbsenceFinanceReport-PolicyName', dataType: 'String'},
        {field: 'outstandingBalance', title: this.translate.instant('OutstandingAbsenceFinanceReport-OutstandingBalance')
            , tableId: 'OutstandingAbsenceFinanceReport-OutstandingBalance', dataType: 'decimal'},
        ];

    // this.translate.instant("ToilCalculationLog-RunningTotal")
    public state: State = {
        skip: 0,
        take: 50,
    };
    public query: Observable<GridDataResult>;
    private stateChange = new BehaviorSubject<State>(this.state);
    public allData = (): Observable<GridDataResult> => {
        return from(this.absenceReportService.queryAll(this.state, this.filterString, this.sortString, this.filterInactive));
    };

    constructor(
        private translate: TranslateService,
        private ngZone: NgZone,
        private absenceReportService: OutstandingAbsenceReportService,
        private sanitiseGridDataService: SanitiseGridDataService
    ) {

        this.query = this.stateChange.pipe(
            tap((state) => {
                this.state = state;
                this.isLoading = true;
            }),
            switchMap((state) => absenceReportService.fetch(state, this.filterString, this.sortString, false,
                this.filterInactive)),
            tap(() => {
                this.isLoading = false;
            })
        );

        // Bind 'this' explicitly to capture the execution context of the component.
        this.allData = this.allData.bind(this);

    }

    ngOnInit(): void {
    }

    public pageChange(state: PageChangeEvent): void {
        this.stateChange.next(state);
    }

    public onExcelExport(e: ExcelExportEvent): void {
        e = this.sanitiseGridDataService.sanitise(e);
    }

    toggleFilterInactive($event: MatSlideToggleChange): void {
        this.filterInactive = $event.checked;
        this.stateChange.next(this.state);
    }

}
