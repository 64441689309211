import { Component, Input, OnInit } from '@angular/core';
import { miscellaneous } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-employee-image',
  templateUrl: './employee-image.component.html',
  styleUrls: ['./employee-image.component.scss']
})
export class EmployeeImageComponent implements OnInit {
  public miscellaneous: typeof miscellaneous = miscellaneous;
  @Input() employeeId: string;
  employeeImage$: Observable<any>;

  @Input() width: number;
  @Input() height: number;
  @Input() round: boolean = false;

  constructor(
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    if(this.employeeId !== undefined) {
      this.employeeImage$ = this.employeeService.getEmployeeProfilePicture(this.employeeId);
    }
  }

}
