import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { TemplateStatus } from '@app/modules/letters/models/letters.model';
import { LettersService } from '@app/modules/letters/services/letters.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-letter-header-footer-status-dropdown',
  templateUrl: './letter-header-footer-status-dropdown.component.html',
  styleUrls: ['./letter-header-footer-status-dropdown.component.scss']
})
export class LetterHeaderFooterStatusDropdownComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  isLoading: boolean;
  user$: any;
  filterString: string;
  options: Observable<PagedData<TemplateStatus>>;

  constructor(
    private lettersService: LettersService,
  ) { }

  ngOnInit(): void {
    this.options = from(this.lettersService.getLetterFooterStatuses())
  }

}
