import { Component, OnInit } from '@angular/core';
import { GlobalReviewsService } from '../../services/global-reviews.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GlobalGoalPlanReview, GlobalGoalReview } from '../../models/global-reviews.model';
import { ReviewPlan, ReviewPlanEmployee } from '../../../review-plans/models/review-plan.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { db_tables } from '@app/consts';
import { forkJoin } from 'rxjs';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';

@Component({
  selector: 'app-global-review',
  templateUrl: './global-review.component.html',
  styleUrls: ['./global-review.component.scss']
})
export class GlobalReviewComponent implements OnInit {
  reviewPlanId: string;
  isLoading: boolean = true;
  globalGoalReviews: GlobalGoalReview[];
  globalGoalPlanReviews: GlobalGoalPlanReview[];
  error: boolean;
  reviewPlan: ReviewPlan;

  goalPlans: {id: string, name: string, lockGoals: boolean, reviews?: GlobalGoalPlanReview[], goals?: GlobalGoalReview[]}[] = [];
  selectedGoalPlans: {id: string, name: string, lockGoals: boolean, reviews?: GlobalGoalPlanReview[], goals?: GlobalGoalReview[]};

  reviewPlanLabels = [
    {
      id: 'tfi_RpName',
      label: 'Name'
    },
    {
      id: 'tfi_RpDescription',
      label: 'Description'
    },
    {
      id: 'tfi_RpStartDate',
      label: 'Start Date'
    },
    {
      id: 'tfi_RpEndDate',
      label: 'End Date'
    },
    {
      id: 'tfi_RpEnforceFullWeightAcrossGoalPlans',
      label: 'Enforce Full Weight Across Goal Plans'
    },
    {
      id: 'tfi_RpFinalReview',
      label: 'Final Review'
    },
    {
      id: 'tfi_RpRequireEmployeeWeightEntry',
      label: 'Require Employee Weight Entry'
    }
  ]
  loadingDbTableFields: boolean;
  selectedGoalPlan: { id: string; name: string; lockGoals: boolean; reviews?: GlobalGoalPlanReview[]; goals?: GlobalGoalReview[]; };
  user$: any;

  constructor(
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private securitySetupService: SecuritySetupService,
    private globalReviewsService: GlobalReviewsService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.reviewPlanId = this.getIdInURL();
    this.getDbTableFields();
    this.getGlobalReviewPlan();
    this.getReviews();
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(db_tables.PerformanceReviewPlans, 0, '100')
    .subscribe(
      {
        next: (v) => {
          v.data.forEach(dataItem => {
            const labelToUpdate = this.reviewPlanLabels.find(labelItem => labelItem.id === dataItem.id);
            if (labelToUpdate) {
                labelToUpdate.label = dataItem.name; // Updating label value from data
            }
          });
        },
        error: (e) => this.error = true,
        complete: () => this.loadingDbTableFields = false
      }
    );
  }

  getLabel(id: string){
    const reviewPlanLabel = this.reviewPlanLabels.find(labelItem => labelItem.id === id);

    return reviewPlanLabel?.label
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('reviewPlanId')
    );

    return IdInURL;
  }

  getGlobalReviewPlan() {
    this.globalReviewsService.getReviewPlans('1', 0, null, `(id = "${this.reviewPlanId}")`)
    .subscribe(
      {
        next: (v) => {
          this.reviewPlan = v.data?.[0];
        },
        error: (e) => this.error = true,
        complete: () => this.isLoading = false
      }
    );
  }

  getReviews() {
    forkJoin(
      [
        this.globalReviewsService.getGlobalGoalPlanReviews('100', 0, null, `(ReviewPlan.id = "${this.reviewPlanId}")`), 
        this.globalReviewsService.getGlobalGoalReviews('100', 0, null, `(ReviewPlan.id = "${this.reviewPlanId}")`)
      ]
    )
    .subscribe(
      {
        next: (v) => {
          this.globalGoalPlanReviews = v[0].data;
          this.globalGoalReviews = v[1].data;

          this.goalPlans = [
            ...this.globalGoalReviews.map( x => x.goalPlan ),
            ...this.globalGoalPlanReviews.map( x => x.goalPlan )
          ].filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            ))
          )

          this.goalPlans.forEach(
            goalPlan => {
              let goalPlanReviews = [];
              let goalReviews = [];
              
              this.globalGoalPlanReviews.forEach(x => {
                if(x.goalPlan.id === goalPlan.id) {
                  goalPlanReviews.push(JSON.parse(JSON.stringify(x)));
                }
              });

              this.globalGoalReviews.forEach(x => {
                if(x.goalPlan.id === goalPlan.id) {
                  goalReviews.push(JSON.parse(JSON.stringify(x)));
                }
              });

              goalPlan.reviews = [...goalPlanReviews];
              goalPlan.goals = [...goalReviews];
            }
          )

          if(this.goalPlans.length > 0) {
            this.selectedGoalPlan = this.goalPlans[0]
          }

        },
        error: (e) => this.error = true,
        complete: () => this.isLoading = false
      }
    );
  }

  refreshPage(event: boolean) {
    if(event === true) {
      this.getReviews();
    }
  }

}
