<ng-container>

    <app-data-grid-comp
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        category="Mappings"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        [searchable]="false"
        [filter]="null"
        [filterable]="null"
        [filterCategories]="null"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToMapping($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
    ></app-data-grid-comp>


</ng-container>
