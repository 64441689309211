import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { features } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-letters-user-section',
  templateUrl: './letters-user-section.component.html',
  styleUrls: ['./letters-user-section.component.scss']
})
export class LettersUserSectionComponent implements OnInit {
  selectedTab: number = 0;
  public features: typeof features = features;
  generateLettersFeatures = [
    features.LETTERS_PUBLISHER,
    features.LETTERS_ADMINISTRATION
  ];

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if(params['section']) {
        if(params['section'] == 'Generations') {
          this.selectedTab = 3;
        }
        else if(params['section'] == 'Templates') {
          this.selectedTab = 2;
        }
        else if(params['section'] == 'Headers') {
          this.selectedTab = 0;
        }
        else if(params['section'] == 'Footers') {
          this.selectedTab = 1;
        }
      }
    });
  }

}
