<h5 mat-dialog-title>{{ 'SelectOrganizations' | translate: {Default: "Select Organizations"} }}</h5>

<mat-dialog-content>
    <div *ngIf="selectedOrganizations.length > 0" class="selected-goal page-description-section">
        <h6>{{ 'SelectedOrganizations' | translate: {Default: "Selected Organizations"} }}: 
            <span *ngFor="let organization of selectedOrganizations; index as i">
                <span *ngIf="i > 0">, </span>{{organization.name}}
            </span>
        </h6>
    </div>

    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        [selectedRowsButtonSettings]="{
            show: false
        }"
        clickableRows="true"
        searchable="true"
        hideActionButtons="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        [filterToggleDetails]="filterToggleDetails"
        (emitToggleFilter)="updateFilterToggleDetails($event)"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>
    
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="selectedOrganizations.length === 0">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>