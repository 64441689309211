import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { LetterFooterVerbose, LetterheadVerbose, LetterTemplateSubmit, LetterTemplateVerbose, TemplateOrganization, TemplateOwner, TemplatePublisher } from '@app/modules/letters/models/letters.model';
import { LettersService } from '@app/modules/letters/services/letters.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, forkJoin, from, Observable } from 'rxjs';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { api_routes } from '@app/consts';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { ReplacementsDialogComponent } from '../replacements-dialog/replacements-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';

/**
 * Component for editing letter templates.
 * 
 * @remarks
 * This component allows users to edit letter templates, including their name, description, HTML content, status, and other properties.
 * It also provides functionality for saving the changes made to the template.
 * 
 * @example
 * ```typescript
 * const editor = new LetterTemplateEditorComponent();
 * editor.getLetterTemplate('123');
 * editor.save();
 * ```
 * 
 * @beta
 */
@Component({
  selector: 'app-letter-template-editor',
  templateUrl: './letter-template-editor.component.html',
  styleUrls: ['./letter-template-editor.component.scss']
})
export class LetterTemplateEditorComponent implements OnInit {
  letterTemplate: LetterTemplateVerbose;
  error: boolean;

  tables: any;
  tableList: Observable<any>

  changeReasonFormId: string = 'frm_hVYMxKQvhkv1jF';
  
  // step 1
  step1FormData: any;
  getStep1FormData: boolean = false;
  step1FormValid: boolean = false;
  step1FormPristine: boolean = true;
  step1HtmlFormData: any;
  getStep1HtmlFormData: boolean = false;
  step1HtmlFormValid: boolean = false;
  step1HtmlFormPristine: boolean = true

  // step 2
  step2FormData: any;
  getStep2FormData: boolean = false;
  step2FormValid: boolean = false;
  step2FormPristine: boolean = true;
  letterOrganizations: TemplateOrganization[] = [];
  letterOwners: TemplateOwner[];
  letterPublishers: TemplatePublisher[];

  replacementsVariables: {
    filter: string
    sort: string
    identifier: string
    timelineOption: {
      id: string,
      name: string
    }
    fields: {
      id: string
      name: string
      format: string
    }[]
    table: {
        id: string
        name: string
    }
  }[] = [];
  letterheadDetails: LetterheadVerbose;
  letterFooterDetails: LetterFooterVerbose;

  loadingTemplate: boolean = true;
  loadingOrganizations: boolean = true;
  loadingTables: boolean = true;
  loadingFields: boolean = true;
  replacementsForm: any = this.fb.group(
    {
      replacements: this.fb.array(
        [
          
        ]
      )
    }
  );

  //Field filter variables
  filterOperators = {
    "TEXT": [
      {
        label: 'Like',
        value: 'like'
      },
      {
        label: 'Starts With',
        value: 'startswith'
      },
      {
        label: 'Ends With',
        value: 'endswith'
      },
    ],
    "Number": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "Percentage": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "DATETIME": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "DROPDOWN": [
      {
        label: '=',
        value: '='
      },
    ],
    "ProvidedList": [
      {
        label: '=',
        value: '='
      },
    ],
    "SpecialLookup": [
      {
        label: '=',
        value: '='
      },
    ],
  }
  fieldPermissions: any[] = [];

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    public lettersService: LettersService,
    private fb: FormBuilder,
    private lookupService: LookupService,
    private securitySetupService: SecuritySetupService,
    private metadataTableService: MetadataTableService
  ) { }

  ngOnInit(): void {
    this.getTablesAndFields();
    this.getLetterTemplatesFieldDetails();
  }

  get replacements(): FormArray {
    return this.replacementsForm.get('replacements') as FormArray;
  }

  get showSendToActiveUsersOnlyToggle() {
    return this.fieldPermissions?.find(x => x.id === 'tfi_LtrTemplateSendToActiveUsers').enabled;
  }

  get showTemplateAbilityToSetTagsToggle() {
    return this.fieldPermissions?.find(x => x.id === 'tfi_LtrTemplateAbilityToSetTags').enabled;
  }

  getLetterTemplatesFieldDetails() {
    this.metadataTableService.getTableFields('tbl_LettersTemplates', 0, '100')
    .subscribe(
      {
        next: (v) => {
          this.fieldPermissions = v.data;
        }
      }
    );
  }


  /**
   * Retrieves the correct filter operators based on the replacement and field indices.
   *
   * @param {number} replacementIndex - The index of the replacement in the replacements array.
   * @param {number} fieldIndex - The index of the field in the fields array.
   * @returns {Array} An array of filter operators corresponding to the field type.
   */
  getCorrectFilterOperators(replacementIndex, fieldIndex) {
    let tableId = this.replacements.at(replacementIndex).get('table').value;
    let fields = this.getFields(replacementIndex);
    let fieldId = fields.at(fieldIndex).value?.id;

    if(tableId === '' || fieldId === '') return [];

    let fieldType = this.tables.find(table => table.id === tableId).fields.find(field => field.id === fieldId).fieldType.id;

    return this.filterOperators[fieldType];
  }
  
  /**
   * Retrieves the fields of the selected table based on the replacement index.
   *
   * @param {number} replacementIndex - The index of the replacement to get the table fields for.
   * @returns {Array<any>} An array of fields for the selected table. Returns an empty array if no table is selected.
   */
  getSelectedTableFields(replacementIndex: number) {
    let tableId = this.replacements.at(replacementIndex).get('table').value;

    if(tableId === '') return [];

    return this.tables.find(table => table.id === tableId).fields;
  }

  /**
   * Retrieves the form array of fields for a given replacement index.
   *
   * @param {number} replacementIndex - The index of the replacement to get the fields from.
   * @returns {FormArray} The form array of fields associated with the specified replacement index.
   */
  getFields(replacementIndex: number): FormArray {
    return this.replacements.at(replacementIndex).get('fields') as FormArray;
  }
  
  addReplacement(): void {
    this.replacements.push(this.createReplacement());
  }
  
  removeReplacement(index: number): void {
    this.replacements.removeAt(index);
  }
  
  addField(replacementIndex: number): void {
    this.getFields(replacementIndex).push(this.createField());
  }
  
  removeField(replacementIndex: number, fieldIndex: number): void {
    this.getFields(replacementIndex).removeAt(fieldIndex);
  }

  createReplacement(): FormGroup {
    return this.fb.group({
      identifier: ['', Validators.required],
      table: ['', Validators.required],
      timelineOption: ['CURRENT', Validators.required],
      fields: this.fb.array([this.createField()]),
      filter: [''],
      sort: ['']
    });
  }
  
  createField(): FormGroup {
    return this.fb.group({
      id: ['', Validators.required],
      sortDirection: [null],
      filterOperator: [null],
      filterValue: [null]
    });
  }

  /**
   * Retrieves a letter template by its ID.
   * 
   * @param id - The ID of the letter template to retrieve.
   */
  getLetterTemplate(id: string) {
    this.loadingTemplate = true;
    // this.overlayService.show('Loading...');

    this.lettersService.getLetterTemplate(id)
    .subscribe(
      {
        next: (v) => {
          this.letterTemplate = v;
          this.replacementsVariables = v.templateReplacements;
          this.buildReplacementsFormData();
          this.buildStep1FormData();
          this.getTemplateOrganizations(id);
        },
        error: (e) => {
          this.error = true,
          this.loadingTemplate = false
        },
        complete: () => this.loadingTemplate = false
      }
    );
  }

  /**
   * Retrieves the template organizations for a given template ID.
   * 
   * @param id - The ID of the template.
   */
  getTemplateOrganizations(id: string) {
    this.loadingOrganizations = true;
    // this.overlayService.show('Loading...');

    forkJoin([
      this.lettersService.getTemplateOwners(100, 0, null, `(template.id = "${id}")`),
      this.lettersService.getTemplatePublishers(100, 0, null, `(template.id = "${id}")`),
      from(this.lettersService.getAllTemplateOrganizations(null, `(template.id = "${id}")`))
    ])
    .subscribe(
      {
        next: (v) => {
          this.letterOwners = v[0].data;
          this.letterPublishers = v[1].data;
          this.letterOrganizations = v[2].data;
          this.buildStep2FormData();
        },
        error: (e) => {
          this.error = true,
          this.loadingOrganizations = false
        },
        complete: () => this.loadingOrganizations = false
      }
    );
  }

  /**
   * Builds the form data for step 1 of the letter template editor.
   * 
   * @param letterTemplate - The letter template object.
   */
  buildStep1FormData() {
    this.step1FormData = {
      id: this.letterTemplate?.id || null,
      name: this.letterTemplate?.name || null,
      description: this.letterTemplate?.description || null,
      letterhead: this.letterTemplate?.letterHead?.id || null,
      footer: this.letterTemplate?.footer?.id || null,
      status: this.letterTemplate?.status?.id || null,
      topMargin: this.letterTemplate?.topMargin || 0,
      bottomMargin: this.letterTemplate?.bottomMargin || 0,
      pageMargin: this.letterTemplate?.pageMargin || 0,
    }

    this.step1HtmlFormData = {
      html: this.letterTemplate?.html || null,
    }
  }

  buildReplacementsFormData() {
    this.letterTemplate?.templateReplacements.forEach(replacement => {

      //Create the sort objects for each field
      let sorts = replacement.sort?.split('~');
      let sortObjects = sorts?.map(sort => {
        let [id, direction] = sort.split('-');
        return { id, direction };
      });

      //Create the filter objects for each field
      let filters = replacement.filter?.split(' AND ');
      let filterObjects = filters?.map(filter => {
        let match = filter.match(/(\w+)\s+(startswith|endswith|like|=|>=|<=|>|<)\s+"(.*?)"/);
        if (match) {
          return {
            propertyName: match[1],
            operator: match[2],
            value: match[3]
          };
        }
        return null;
      }).filter(filter => filter !== null);

      this.replacements.push(
        this.fb.group({
          identifier: replacement.identifier,
          table: replacement.table.id,
          fields: this.fb.array(
            replacement.fields.map(field => {
                let fieldDetails = this.tables.find(table => table.id === replacement.table.id).fields.find(tableField => tableField.id === field.id);

                return this.fb.group({
                  id: field.id,
                  sortDirection: sortObjects?.find(sort => sort.id === field.id)?.direction || null,
                  filterOperator: filterObjects?.find(filter => filter.propertyName === fieldDetails.propertyName)?.operator || null,
                  filterValue: filterObjects?.find(filter => filter.propertyName === fieldDetails.propertyName)?.value || null
                })
              }
            )
          ),
          timelineOption: replacement.timelineOption?.id,
          filter: replacement.filter,
          sort: replacement.sort
        })
      );

    });
  }

  openReplacementDialog(replacement?: any) {
    let newReplacement = false;

    if(replacement === undefined) {
      this.addReplacement();
      newReplacement = true;
      replacement = replacement || this.replacements.at(this.replacements.length - 1);
    }

    const dialogRef = this.dialog.open(ReplacementsDialogComponent, {
      width: '800px',
      data: {
        replacement: replacement,
        tables: this.tables
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === false && newReplacement === true) {
        this.removeReplacement(this.replacements.length - 1);
      }
    });
  }
  
  /**
   * Builds the form data for step 2 of the letter template editor.
   * @returns {void}
   */
  buildStep2FormData() {
    this.step2FormData = {
      status: this.letterTemplate?.status?.id || null,
      sendToActiveUsersOnly: this.letterTemplate?.sendToActiveUsersOnly || false,
      abilityToSetTags: this.letterTemplate?.abilityToSetTags || false,
      owners: this.letterOwners?.map(x => x.owner) || null,
      publishers: this.letterPublishers?.map(x => x.publisher) || null,
      organizations: this.letterOrganizations?.map(x => x.organization) || null,
    }
  }

  formDataEmitted(formData, step: string) {
    if(step === 'step1') this.step1FormData = formData;
    else if(step === 'step1Html') this.step1HtmlFormData = formData;
    else if(step === 'step2') this.step2FormData = formData;
  }

  formStatusUpdated(formValid, step: string) {
    if(step === 'step1') this.step1FormValid = formValid;
    else if(step === 'step1Html') this.step1HtmlFormValid = formValid;
    else if(step === 'step2') this.step2FormValid = formValid;
  }

  formPristineEmitted(formPristine: boolean, step: string) {
    if(step === 'step1') this.step1FormPristine = formPristine;
    else if(step === 'step1Html') this.step1HtmlFormPristine = formPristine;
    else if(step === 'step2') this.step2FormPristine = formPristine;
  }

  getTablesAndFields() {
    this.loadingTables = true;
    const allowableTables = [
      'tbl_Employees',
      'tbl_EmploymentRecords',
      'tbl_Compensations',
      'tbl_BankDetails',
      'tbl_PayrollDetails',
      'tbl_EmploymentDetails',
      'tbl_PositionsEmployees',
      'tbl_Associations',
      'tbl_Absences',
      'tbl_CompanyAssets',
      'tbl_Education',
      'tbl_EmergencyContacts',
      'tbl_Family',
      'tbl_InjuryIllness',
      'tbl_Languages',
      'tbl_MedicalTestings',
      'tbl_OtherCompensations',
      'tbl_ProfessionalExpertises',
      'tbl_Projects',
      'tbl_Relocations',
      'tbl_TrainingAndCertifications',
      'tbl_VisaPermits',
      'tbl_WorkExperiences'
    ];

    from(this.securitySetupService.getAllTables())
    .pipe(
      finalize( () => {
          this.loadingTables = false;
          this.getTableFields();
        } 
      )
    )
    .subscribe(
      res => {
        this.tables = res.data.filter(table => allowableTables.includes(table.id));
      }
    );
  }

  getTableFields() {
    this.loadingFields = true;

    let observables: Observable<any>[] = [];

    this.tables.forEach(table => {
      observables.push(from(this.securitySetupService.getAllFields(table.id)));
    });

    forkJoin(observables)
    .pipe(
      finalize( () => {
          this.loadingFields = false;

          this.route.params.subscribe(params => {
            if(params['letterId']) {
              this.getLetterTemplate(params['letterId']);
            }
            else {
              this.buildStep1FormData();
              this.buildStep2FormData();
              this.loadingTemplate = false;
              this.loadingOrganizations = false;
            }
          });
        } 
      )
    )
    .subscribe(
      (res) => {
        res.forEach((fields, index) => {
            this.tables[index].fields = fields.data;
          }
        ) 
      }
    );
  }

  /**
   * Predicate function that only allows even numbers to be
   * sorted into even indices and odd numbers at odd indices.
   */
  sortPredicate(index: number, item: CdkDrag<number>) {
    return false
  }

  backToTemplates() {
    this.router.navigate([`/Letters/LettersUserSection/Templates`]);
  }

  /**
   * Handles the drag event for a table and field.
   * 
   * @param event - The DragEvent object.
   * @param replacement - the replacement formcontrol.
   */
  drag(event: DragEvent, replacement: any) {
    if (event.dataTransfer) {
      if(replacement.get('identifier').value !== null && replacement.get('identifier').value !== '') {
        event.dataTransfer.setData('text/plain', `{hhr:${replacement.get('identifier').value}}`);
      }
    }
  }

  extractStrings(): string[] {
    const regex = /{hhr:(.*?)}/g;
    const matches = [];
    let match;

    while ((match = regex.exec(this.step1HtmlFormData.html)) !== null) {
      matches.push(match[1]);
    }

    return matches;
  }

  stepChange(event: StepperSelectionEvent) {
    if(event.selectedIndex === 2) {
      this.getLetterheadDetails();
      this.getFooterDetails();
    }
  }

  getLetterheadDetails() {
    if(this.step1FormData?.letterhead !== null && this.step1FormData?.letterhead !== undefined) {
      this.lettersService.getLetterhead(this.step1FormData.letterhead)
      .subscribe(
        {
          next: (v) => {
            this.letterheadDetails = v;
          },
          error: (e) => {}
        }
      );
    }
  }

  getFooterDetails() {
    if(this.step1FormData?.footer !== null && this.step1FormData?.footer !== undefined) {
      this.lettersService.getLetterFooter(this.step1FormData.footer)
      .subscribe(
        {
          next: (v) => {
            this.letterFooterDetails = v;
          },
          error: (e) => {}
        }
      );
    }
  }

  openChangeReasonDialog() {
    if(this.step1FormValid && this.step1HtmlFormValid && this.step2FormValid) {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
  
      dialogConfig.data = {
        formId: this.changeReasonFormId
      };
  
      const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data) {
            this.save(data);
          }
        }
      );
    }
  }

  save(changeReasonFormData: any) {
    if(this.step1FormValid && this.step1HtmlFormValid && this.step2FormValid) {


      let replacementIdsInHtml = this.extractStrings();
  
      /**
       * Generates an array of replacement objects based on the given replacementIdsInHtml.
       * Each replacement object contains the filter, sort, timelineOption, identifier, table, and fields properties.
       * If a replacement object is not found for a given identifier, it is excluded from the resulting array.
       * 
       * @param replacementIdsInHtml - An array of replacement identifiers in HTML.
       * @returns An array of replacement objects.
       */
      // const replacementObjects = replacementIdsInHtml.map((id) => {
      //   const replacement = this.replacementsVariables.find((r) => r.identifier === id);
      //   if (replacement) {
      //     return {
      //       filter: null,
      //       sort: null,
      //       timelineOption: 'CURRENT',
      //       identifier: replacement.identifier,
      //       table: replacement.table.id,
      //       fields: replacement.fields.map((field) => field.id),
      //     };
      //   }
      //   return null;
      // }).filter((replacement) => replacement !== null);

      let replacementObjects = this.replacementsForm.value.replacements;

      // replacementObjects.forEach(replacement => {
      //   replacement.timelineOption = "CURRENT";
      // });

      replacementObjects.forEach(replacement => {
        let sortString = '';
        let filterString = '';
        replacement.fields.forEach(field => {
          let fieldDetails = this.tables.find(table => table.id === replacement.table).fields.find(tableField => tableField.id === field.id);

          if(field.sortDirection !== null){
            sortString += `${fieldDetails.propertyName}-${field.sortDirection}~`;
          }

          if(field.filterOperator !== null && field.filterValue !== null){
            filterString += `(${fieldDetails.propertyName} ${field.filterOperator} "${field.filterValue}") AND `;
          }
        });

        replacement.sort = sortString.slice(0, -1);
        replacement.filter = filterString.slice(0, -5);
      });

      replacementObjects.map(replacement => {
        replacement.fields = replacement.fields.map(field => field.id);
      });


      const submitData: LetterTemplateSubmit = {
        id: this.letterTemplate?.id,
        changeReason: changeReasonFormData.changeReason,
        changeReasonComments: changeReasonFormData.changeReasonComments,
        name: this.step1FormData.name,
        description: this.step1FormData.description,
        html: this.step1HtmlFormData.html,
        status: this.step2FormData.status,
        sendToActiveUsersOnly: this.step2FormData.sendToActiveUsersOnly,
        abilityToSetTags: this.step2FormData.abilityToSetTags,
        footer: this.step1FormData.footer,
        letterhead: this.step1FormData.letterhead,
        owners: this.step2FormData.owners?.map(x => x.id),
        organizations: this.step2FormData.organizations?.map(x => x.id),
        publishers: this.step2FormData.publishers?.map(x => x.id),
        topMargin: this.step1FormData.topMargin,
        bottomMargin: this.step1FormData.bottomMargin,
        pageMargin: this.step1FormData.pageMargin,
        templateReplacements: replacementObjects
      }

      this.overlayService.show('Saving...');

      if(this.letterTemplate) {
        this.lettersService.updateLetterTemplate(this.letterTemplate.id, submitData)
        .subscribe(
          {
            next: (v) => {
              this.overlayService.hide();
              this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
              this.router.navigate(['/Letters/LettersUserSection/Templates']);
            },
            error: (e) => {
              this.overlayService.hide();
            }
          }
        );
      }
      else {
        this.lettersService.createLetterTemplate(submitData)
        .subscribe(
          {
            next: (v) => {
              this.overlayService.hide();
              this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
              this.router.navigate([`${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.EDITOR}/${v.id}`]);
            },
            error: (e) => {
              this.overlayService.hide();
            }
          }
        );
      }
      
    }
  }
}
