import { Component, Input, OnInit } from '@angular/core';
import { GoalPlanService } from '../goal-plans/services/goal-plan.service';
import { finalize, forkJoin } from 'rxjs';
import { EmployeeAssignedGoalPlan, EmployeeGoal, EmployeeGoalSubmit, EmployeeGoalsWithEmployeeId } from '../goal-plans/models/goal-plan.model';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PersonalGoalDialogComponent } from '../personal-goal-dialog/personal-goal-dialog.component';
import { db_tables } from '@app/consts';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { EmployeeTableFieldSecurity, EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { GoalTypeService } from '../goal-types/services/goal-type.service';

@Component({
  selector: 'app-employee-published-goal-parent-data-grid',
  templateUrl: './employee-published-goal-parent-data-grid.component.html',
  styleUrls: ['./employee-published-goal-parent-data-grid.component.scss']
})
export class EmployeePublishedGoalParentDataGridComponent implements OnInit {
  @Input() employeeId: string;
  @Input() goalPlan: {
    id: string
    name: string
    description: string
    lockGoals: boolean
    permitEmployeeChildGoalEntry: boolean
    permitEmployeeGoalEntry: boolean
  }
  isLoading: boolean;
  searchFilterString: any;
  filterString: any;
  getGoalsRequest: any;
  pageSize: number = 20;
  skip: number = 0;
  gridDataResult: { data: EmployeeGoal[]; total: number; };
  sortString: string;
  dialogRef: any;

  public db_tables = db_tables;
  tableId = db_tables.EmployeeGoals;
  public columns: any[] = [
    { field: "employee", tableId: "", title: "Employee", type: "employee" },
    { field: "objective", tableId: "tfi_PEgObjective", title: this.translate.instant("EmployeeGoals-Objective") },
    { field: "description", tableId: "tfi_PEgDescription", title: "Description", type: "longText", longTextLimit: 200 },
    { field: "expectedCompletionDate", tableId: "tfi_PEgExpectedCompletionDate", title: "Expected Completion Date", type: "date", dataType: 'Date'},
    { field: "actualCompletionDate", tableId: "tfi_PEgActualCompletionDate", title: "Actual Completion Date", type: "date", dataType: 'Date'},
    { field: "goalType", subField: "name", tableId: "tfi_PEgGoalType", title: "Goal Type", dataType: "SpecialLookup", lookupCode: 'GOAL_TYPE'},
    { field: "goalPlan", subField: "name", tableId: "", title: this.translate.instant("EmployeeGoals-GoalPlan"), dataType: "SpecialLookup", lookupCode: 'GOAL_PLAN'},
    { field: "createdBy", tableId: "", title: this.translate.instant("EmployeeGoals-CreatedBy"), type: "employee" },
    { field: "parent", subField: "objective", tableId: "tfi_PEgParentGoal", title: this.translate.instant("EmployeeGoals-Parent") },
    { field: "percentageComplete", tableId: "tfi_PEgPercentageComplete", title: "Percentage Complete", type: "percentage", percentageMaxValue: 1 },
    { field: "weight", tableId: "tfi_PEgWeight", title: "Weight", type: "percentage", percentageMaxValue: 1 },
    { field: "managers", tableId: "", title: "Managers", type: "employees" },
  ];
  sortingColumns: boolean;
  fieldList: TableField[] = [];
  isLoadingPermissions: boolean;
  user$: any;
  targetEmployeeTablePermissions: EmployeeTableSecurity[] = [];
  goalTypes: any[];
  // goalTypes: [any];
  
  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    private goalTypeService: GoalTypeService,
    private goalService: GoalPlanService
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getTargetEmployeeTablePermissions();
    this.getFields(this.tableId);
    this.getTableFieldPermissions();
    this.getGoals();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.user$.userId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.user$.userId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize( () => {
        this.sortingColumns = false;
        this.getTableFieldPermissions();
      })
    )
    .subscribe(
        pagedFieldList => {
          this.fieldList = pagedFieldList.data;

          this.fieldList.forEach(
            field => {
              let index = this.columns.findIndex( column => column.tableId === field.id )
              // let filterCategoriesIndex = this.filterCategories.findIndex( column => column.tableId === field.id )

              if(index !== -1){
                if(field.enabled === false){
                  this.columns.splice(index, 1);
                }
                else if(field.name !== null){
                  this.columns[index].title = field.name;
                }
              }

              // if(filterCategoriesIndex !== -1){
              //   if(field.enabled === false){
              //     this.filterCategories.splice(filterCategoriesIndex, 1);
              //   }
              //   else if(field.name !== null){
              //     this.filterCategories[filterCategoriesIndex].title = field.name;
              //   }
              // }
            }
          );
        }
    );
  }

  getTableFieldPermissions() {
    let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(this.tableId, this.targetEmployeeTablePermissions);

    if(tableFieldPermissions !== null) {
      tableFieldPermissions.forEach(
        tableFieldPermission => {
          let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )
  
          if(index !== -1){
            if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
              this.columns.splice(index, 1);
            }
          }
        }
      )
    }
  }

  getGoals() {
    this.isLoading = true;

    let filter = `(GoalPlanId = "${this.goalPlan.id}") AND (parent.id = null)`;
    if(this.searchFilterString) {
      filter = `${filter} AND ${this.searchFilterString}`;
    }
    if(this.filterString) {
      filter = `${filter} AND ${this.filterString}`;
    }

    this.getGoalsRequest = this.goalService.getEmployeeGoals(this.employeeId, String(this.pageSize), this.skip, this.sortString, filter)
      .pipe(
        finalize( () => {
          this.isLoading = false;
          this.getGoalType();
        })
      )
      .subscribe(
        (res) => {
          res.employeeGoals.data.map( employeeGoal => employeeGoal.employee.id = res.employeeId )
          res.employeeGoals.data.filter( employeeGoal => employeeGoal.parent !== null )

          this.gridDataResult = {
            data: res.employeeGoals.data,
            total: res.employeeGoals.total,
          }
        }
      );
  }

  getGoalType() {
    let requests = [];

    this.gridDataResult.data.forEach(
      goalPlan => {
        requests.push(this.goalTypeService.getGoalType(goalPlan?.goalType?.id));
      }
    );

    forkJoin(requests)
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (res) => {
        this.goalTypes = res;
      }
    );
  }

  isGoalEntryAllowed(goal: any): boolean {
    let goalType = this.goalTypes.find( goalType => goalType.id === goal.goalType.id);

    return goalType.allowEmployeeSubGoalCreation && this.goalPlan.permitEmployeeChildGoalEntry
  }

  getColumnTitle(tableId: string): string | undefined {
    const column = this.columns.find(col => col.tableId === tableId);
    return column ? column.title : undefined;
  }

  calculateSliderPercentage(value: string, max: number): number {
    return Number( ((parseFloat(value)/max) * (100/1)).toFixed() );
  }

  openConfirmDeleteDialog(goalId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('AreYouSureYouWantToDeleteThis')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteGoal(goalId);
        }
      }
    );
  }

  deleteGoal(goalId: string) {
    this.overlayService.show();

    this.goalService.deleteEmployeeGoal(this.employeeId, goalId)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.getGoals();
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  getGoalVerbose(goal?: EmployeeGoal) {
    if(goal === undefined){
      this.openGoalDialog();
    }
    else {
      let employeeGoal: EmployeeGoal;

      this.isLoading = true;

      this.goalService.getEmployeeGoal(this.employeeId, goal.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          employeeGoal = res;
          this.openGoalDialog(employeeGoal);
        }
      );
    }
  }

  openGoalDialog(goal?: EmployeeGoal) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      goal: goal,
      goalPlan: this.goalPlan,
      readOnly: goal?.goalPlan?.lockGoals,
      employeeId: goal?.employee?.id || this.employeeId,
    };

    this.dialogRef = this.dialog.open(PersonalGoalDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getGoals();
        }
      }
    );
  }
}


