import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TalentTrackProfilesComponent } from './modules/talent-track/talent-track-profiles/talent-track-profiles.component';
import { AuthPageComponent } from '@app/modules/auth/components/auth-page/auth-page.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { AuthCallbackComponent } from './core/auth-callback/auth-callback.component';
import { FormsComponent } from './modules/form-generator/forms/forms.component';
import { CreateFormComponent } from './modules/form-generator/create-form/create-form.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { HeadcountReportComponent } from './modules/reports/components/headcount-report/headcount-report.component';
import { PacketComponent } from './modules/packets/components/packet/packet.component';
import {
    SettingsOnboardingTaskChecklistComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/onboarding-demo/settings-onboarding-task-checklist/settings-onboarding-task-checklist.component';
import { LookupComponent } from './modules/lookup/lookup.component';
import { PositionsComponent } from './modules/positions/positions.component';
import { EmployementRecordsV2Component } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employement-records-v2/employement-records-v2.component';
import { EmployeeDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/employee-details.component';
import { EmploymentRecordDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/employment-record-details.component';
import { SecurityMenuComponent } from './modules/security-menu/security-menu.component';
import { OrgChartComponent } from './modules/org-chart/org-chart.component';
import { HeadcountByMonthReportComponent } from './modules/reports/components/headcount-by-month-report/headcount-by-month-report.component';
import { WorkforcePlanningReportComponent } from './modules/reports/components/workforce-planning-report/workforce-planning-report.component';
import { DiversityBenchmarkReportComponent } from './modules/reports/components/diversity-benchmark-report/diversity-benchmark-report.component';
import { WorkRotationsComponent } from './modules/work-rotations/work-rotations.component';
import { CreatedLettersListComponent } from './modules/letter-generator/components/created-letters-list/created-letters-list.component';
import { CreateLetterComponent } from './modules/letter-generator/components/create-letter/create-letter.component';
import { SendLetterComponent } from './modules/letter-generator/components/send-letter/send-letter.component';
import { CompensationReportComponent } from '@app/modules/reports/components/compensation-report/compensation-report.component';
import { TimeOffMenuComponent } from '@app/modules/time-off/time-off-menu.component';
import { MonthlyAbsenceReportComponent } from './modules/reports/components/monthly-absence-report/monthly-absence-report.component';
import { MonthlyHolidayUtilisationReportComponent } from './modules/reports/components/monthly-holiday-utilisation-report/monthly-holiday-utilisation-report.component';
import { NewsFeedComponent } from './modules/news-feed/news-feed.component';
import { InboxComponent } from './modules/inbox/inbox.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { ReportsRectangleDisplayListComponent } from './modules/reports/components/reports-rectangle-display-list/reports-rectangle-display-list.component';
import { CompensationSectionDemoComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/compensation-section-demo/compensation-section-demo.component';
import { SiteSettingsConfigureComponent } from '@app/modules/site-settings-configure/site-settings-configure.component';
import { SiteSettingsCurrencyComponent } from '@app/modules/site-settings-currency/site-settings-currency.component';
import { TranslationsComponent } from './modules/translations/translations.component';
import { CostCentersComponent } from '@app/modules/cost-centers/cost-centers.component';
import { WorkRotationEditorComponent } from '@app/modules/work-rotations/components/work-rotation-editor/work-rotation-editor.component';
import { BenefitsComponent } from './modules/benefits/benefits.component';
import { BenefitsCoveragesComponent } from './modules/benefits/components/benefits-coverages/benefits-coverages.component';
import { BenefitsPlanTypesComponent } from './modules/benefits/components/benefits-plan-types/benefits-plan-types.component';
import { BenefitsPlanTypeDetailsComponent } from './modules/benefits/components/benefits-plan-types/components/benefits-plan-type-details/benefits-plan-type-details.component';
import { SiteSettingsMenuComponent } from './modules/site-settings-menu/site-settings-menu.component';
import { BenefitsPlansComponent } from './modules/benefits/components/benefits-plans/benefits-plans.component';
import { BenefitsPlanEditorComponent } from './modules/benefits/components/benefits-plans/components/benefits-plan-editor/benefits-plan-editor.component';
import { BenefitsGroupsComponent } from './modules/benefits/components/benefits-groups/benefits-groups.component';
import { BenefitGroupEditorComponent } from './modules/benefits/components/benefits-groups/components/benefit-group-editor/benefit-group-editor.component';
import { SecurityRoleEditorComponent } from '@app/modules/security-roles/components/security-role-editor/security-role-editor.component';
import { IntegrationConfigurationComponent } from '@app/modules/integration-configuration/integration-configuration.component';
import { SecurityRolesComponent } from '@app/modules/security-roles/security-roles.component';
import { ApiSetupComponent } from '@app/modules/api-setup/api-setup.component';
import { SecuritySetupComponent } from '@app/modules/security-setup/security-setup.component';
import { EditFormV3Component } from './modules/form-generator/edit-form-v3/edit-form-v3.component';
import { StatutoryHolidaysComponent } from '@app/modules/statutory-holidays/statutory-holidays.component';
import { HolidayGroupsEditorComponent } from '@app/modules/statutory-holidays/components/holiday-groups-editor/holiday-groups-editor.component';
import { OrganizationStructureComponent } from '@app/modules/organization-structure/organization-structure.component';
import { OrganizationEditorComponent } from '@app/modules/organization-structure/components/organization-editor/organization-editor.component';
import { EmployeeSecurityComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/employee-security.component';
import { CountryConfigurationComponent } from './modules/country-configuration/country-configuration.component';
import { EmployeeBankDetailsReportComponent } from './modules/reports/components/employee-bank-details-report/employee-bank-details-report.component';
import { BasicEmployeeReportComponent } from './modules/reports/components/basic-employee-report/basic-employee-report.component';
import { BasicHeadcountReportComponent } from './modules/reports/components/basic-headcount-report/basic-headcount-report.component';
import { TableFieldEditorComponent } from './modules/table-field-editor/table-field-editor.component';
import { AdminControlsComponent } from './modules/admin-controls/admin-controls.component';
import { AddDummyPositionsComponent } from './modules/admin-controls/components/add-dummy-positions/add-dummy-positions.component';
import { AddDummyOrganizationsComponent } from './modules/admin-controls/components/add-dummy-organizations/add-dummy-organizations.component';
import { AddDummyEmployeesComponent } from './modules/admin-controls/components/add-dummy-employees/add-dummy-employees.component';
import { AssignPositionsToAllComponent } from './modules/admin-controls/components/assign-positions-to-all/assign-positions-to-all.component';
import { RunJobFormComponent } from './modules/generate-schedules/components/run-job-form/run-job-form.component';
import { PositionEditorPageComponent } from './modules/positions/components/position-editor-page/position-editor-page.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { EmployeePersonalDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-personal-details/employee-personal-details.component';
import { EmployeeLeaveComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/employee-leave.component';
import { EmployeeCompensationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/employee-compensation.component';
import { EmployeeOtherCompensationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-other-compensation/employee-other-compensation.component';
import { EmployeeEmploymentDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-employment-details/employee-employment-details.component';
import { EmergencyContactsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/emergency-contacts.component';
import { EmployeeFamilyDependantsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-family-dependants/employee-family-dependants.component';
import { EmployeeMedicalTestsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/employee-medical-tests.component';
import { EmployeeVisasAndPermitsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/employee-visas-and-permits.component';
import { EmployeeLanguagesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-languages/employee-languages.component';
import { TrainingAndCertificationsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/training-and-certifications/training-and-certifications.component';
import { EmployeeEducationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-education/employee-education.component';
import { EmployeeWorkExperienceComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-work-experience/employee-work-experience.component';
import { EmployeeAssociationsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-associations/employee-associations.component';
import { EmployeeDocumentsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/employee-documents.component';
import { UploadProfileImageComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/upload-profile-image/upload-profile-image.component';
import { EmployeeCompanyAssetComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-company-asset/employee-company-asset.component';
import { EmployeeBankDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-bank-details/employee-bank-details.component';
import { EmployeePayrollDetailsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-payroll-details/employee-payroll-details.component';
import { api_routes, routes } from './consts';
import { EmployeeGrievancesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-grievances/employee-grievances.component';
import { EmployeeInjuryIllnessComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-injury-illness/employee-injury-illness.component';
import {
    EmploymentRecordPositionsComponent
} from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/employment-record-positions.component';
import { ReportRendererComponent } from '@app/modules/custom-reports/components/report-renderer/report-renderer.component';
import { ReportsListComponent } from '@app/modules/custom-reports/components/reports-list/reports-list.component';
import { ReportBuilderComponent } from '@app/modules/custom-reports/components/report-builder/report-builder.component';
import { WorkLocationsListComponent } from './modules/work-locations/components/work-locations-list/work-locations-list.component';
import { PerformanceComponent } from './modules/performance/performance.component';
import { GoalTypesComponent } from './modules/performance/components/goal-types/goal-types.component';
import { EmployeeRelocationComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-relocation/employee-relocation.component';
import { GoalPlansComponent } from './modules/performance/components/goal-plans/goal-plans.component';
import { GoalPlanEditorComponent } from './modules/performance/components/goal-plans/components/goal-plan-editor/goal-plan-editor.component';
import { EmployeeProfessionalExpertisesComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-professional-expertises/employee-professional-expertises.component';
import { EmployeeProjectsComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-projects/employee-projects.component';
import { AllGoalsListComponent } from './modules/performance/components/all-goals-list/all-goals-list.component';
import { AbsenceReportComponent } from '@app/modules/reports/components/absence-report/absence-report.component';
import { AbsenceFinanceReportComponent } from '@app/modules/reports/components/absence-finance-report/absence-finance-report.component';
import { OutstandingAbsenceReportComponent } from '@app/modules/reports/components/outstanding-absence-report/outstanding-absence-report.component';
import { EmployeeToilHistoryComponent } from './modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-toil-history/employee-toil-history.component';
import { ToilPoliciesTypesComponent } from './modules/time-off-in-lieu/toil-policies-types/toil-policies-types.component';
import { ToilEmailsComponent } from './modules/time-off-in-lieu/toil-emails/toil-emails.component';
import { EmployeeLoginReportComponent } from './modules/reports/components/employee-login-report/employee-login-report.component';
import { TimeOffPoliciesTypesComponent } from './modules/time-off/time-off-policies-types/time-off-policies-types.component';
import { TimeOffEmailsComponent } from './modules/time-off/time-off-emails/time-off-emails.component';
import { AddDummyWorkRotationsComponent } from './modules/admin-controls/components/add-dummy-work-rotations/add-dummy-work-rotations.component';
import { AddDummyWorkLocationsComponent } from './modules/admin-controls/components/add-dummy-work-locations/add-dummy-work-locations.component';
import { AddDummyCostCentersComponent } from './modules/admin-controls/components/add-dummy-cost-centers/add-dummy-cost-centers.component';
import { SplashPageComponent } from './core/splash-page/splash-page.component';
import { UnsavedGuard } from './core/guards/unsaved.guard';
import { ReviewPlansComponent } from './modules/performance/components/review-plans/review-plans.component';
import { ReviewPlanEditorComponent } from './modules/performance/components/review-plans/components/review-plan-editor/review-plan-editor.component';
import { ReviewRatingsComponent } from './modules/performance/components/review-ratings/review-ratings.component';
import { EmployeeIntegrationDetailsComponent } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-integration-details/employee-integration-details.component";
import { ReviewsComponent } from './modules/performance/components/reviews/reviews.component';
import { ReviewPageComponent } from './modules/performance/components/review-page/review-page.component';
import { BusinessRuleAutomationComponent } from "@app/modules/business-rule-automation/business-rule-automation.component";
import { BusinessRuleAutomationFormComponent } from "@app/modules/business-rule-automation/components/business-rule-automation-form/business-rule-automation-form.component";
import { EmployeeGoalsPageComponent } from './modules/performance/components/employee-goals-page/employee-goals-page.component';
import { ModuleConfigurationComponent } from './modules/admin-controls/components/module-configuration/module-configuration.component';
import { GoalsReportComponent } from "@app/modules/reports/components/performance-goals-report/goals-report.component";
import { StylingColoursComponent } from './modules/site-settings-styling/components/styling-colours/styling-colours.component';
import { StylingColourEditorComponent } from './modules/site-settings-styling/components/styling-colours/components/styling-colour-editor/styling-colour-editor.component';
import { SiteSettingsStylingComponent } from './modules/site-settings-styling/site-settings-styling.component';
import { CompanyLogosComponent } from './modules/site-settings-styling/components/company-logos/company-logos.component';
import { CompanyLogoEditorComponent } from './modules/site-settings-styling/components/company-logos/components/company-logo-editor/company-logo-editor.component';
import { CreateCompanyLogoComponent } from './modules/site-settings-styling/components/company-logos/components/create-company-logo/create-company-logo.component';
import { EditCompanyLogoFileComponent } from './modules/site-settings-styling/components/company-logos/components/edit-company-logo-file/edit-company-logo-file.component';
import { CultureConfigurationComponent } from './modules/admin-controls/components/culture-configuration/culture-configuration.component';
import { PerformanceSectionMyReviewsComponent } from './modules/performance/components/reviews/components/performance-section-my-reviews/performance-section-my-reviews.component';
import { GlobalReviewsComponent } from './modules/performance/components/global-reviews/global-reviews.component';
import { GlobalReviewComponent } from './modules/performance/components/global-reviews/components/global-review/global-review.component';
import { LettersComponent } from './modules/letters/letters.component';
import { LetterTemplateEditorComponent } from './modules/letters/components/letter-templates/components/letter-template-editor/letter-template-editor.component';
import { ReviewStatusReportComponent } from './modules/reports/components/performance-review-status/review-status-report.component';
import { ToilBalanceReportComponent } from '@app/modules/reports/components/toil-balance-report/toil-balance-report.component';
import { ToilOccurrenceReportComponent } from '@app/modules/reports/components/toil-occurrence-report/toil-occurrence-report.component';
import { IntegrationsComponent } from "@app/modules/site-settings-integrations/integrations.component";
import { IntegrationEditorComponent } from "@app/modules/site-settings-integrations/components/integration-editor/integration-editor.component";
import { LetterGenerationEditorComponent } from './modules/letters/components/letter-generations/components/letter-generation-editor/letter-generation-editor.component';
import { LettersUserSectionComponent } from './modules/letters/components/letters-user-section/letters-user-section.component';
import { LettersAdminSectionComponent } from './modules/letters/components/letters-admin-section/letters-admin-section.component';

const route_declarations: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: TalentTrackProfilesComponent
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent
    },
    {
        path: 'SplashPage',
        component: SplashPageComponent
    },
    {
        path: 'login',
        canActivate: [AuthGuard],
        component: AuthPageComponent
    },
    {
        path: 'TalentTrack',
        canActivate: [AuthGuard],
        component: TalentTrackProfilesComponent
    },
    {
        path: 'TalentTrack/Edit/:id',
        canActivate: [AuthGuard],
        component: EmployeeDetailsComponent
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeeDetailsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/PersonalDetails',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeePersonalDetailsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/LeaveHistory',
        canActivate: [AuthGuard],
        component: EmployeeLeaveComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/ToilHistory',
        canActivate: [AuthGuard],
        component: EmployeeToilHistoryComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Compensation',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeeCompensationComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/OtherCompensation',
        canActivate: [AuthGuard],
        component: EmployeeOtherCompensationComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/EmploymentDetails',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeeEmploymentDetailsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/EmergencyContacts',
        canActivate: [AuthGuard],
        component: EmergencyContactsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/FamilyDependants',
        canActivate: [AuthGuard],
        component: EmployeeFamilyDependantsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Grievances',
        canActivate: [AuthGuard],
        component: EmployeeGrievancesComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/InjuryIllness',
        canActivate: [AuthGuard],
        component: EmployeeInjuryIllnessComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/ProfessionalExpertises',
        canActivate: [AuthGuard],
        component: EmployeeProfessionalExpertisesComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/MedicalTests',
        canActivate: [AuthGuard],
        component: EmployeeMedicalTestsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/VisaPermits',
        canActivate: [AuthGuard],
        component: EmployeeVisasAndPermitsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Languages',
        canActivate: [AuthGuard],
        component: EmployeeLanguagesComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/TrainingAndCertifications',
        canActivate: [AuthGuard],
        component: TrainingAndCertificationsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Education',
        canActivate: [AuthGuard],
        component: EmployeeEducationComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Associations',
        canActivate: [AuthGuard],
        component: EmployeeAssociationsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/WorkExperiences',
        canActivate: [AuthGuard],
        component: EmployeeWorkExperienceComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Documents',
        canActivate: [AuthGuard],
        component: EmployeeDocumentsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/ProfileImage',
        canActivate: [AuthGuard],
        component: UploadProfileImageComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Projects',
        canActivate: [AuthGuard],
        component: EmployeeProjectsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/CompanyAssets',
        canActivate: [AuthGuard],
        component: EmployeeCompanyAssetComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/BankDetails',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeeBankDetailsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/PayrollDetails',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeePayrollDetailsComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/Relocations',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeeRelocationComponent,
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeDetails/IntegrationDetails',
        canActivate: [AuthGuard],
        component: EmployeeIntegrationDetailsComponent
    },
    {
        path: 'TalentTrack/Edit/:id/EmploymentRecords',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployementRecordsV2Component
    },
    {
        path: 'TalentTrack/Edit/:id/Performance',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmployeeGoalsPageComponent
    },
    {
        path: 'TalentTrack/Edit/:id/Positions',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: EmploymentRecordPositionsComponent
    },
    {
        path: 'TalentTrack/Edit/:id/EmployeeSecurity/:section',
        canActivate: [AuthGuard],
        component: EmployeeSecurityComponent
    },
    {
        path: 'TalentTrack/Edit/:id/EmploymentRecords/:employmentRecordId',
        canActivate: [AuthGuard],
        component: EmploymentRecordDetailsComponent
    },
    {
        path: 'TalentTrack/Edit/:id/EmploymentCompensation',
        canActivate: [AuthGuard],
        component: CompensationSectionDemoComponent
    },
    {
        path: 'Form/List',
        canActivate: [AuthGuard],
        component: FormsComponent
    },
    {
        path: 'Form/Create',
        canActivate: [AuthGuard],
        component: CreateFormComponent
    },
    {
        path: 'Form/Edit/:id',
        canActivate: [AuthGuard],
        component: EditFormV3Component
    },
    {
        path: 'Dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent
    },
    {
        path: 'ReportsList',
        canActivate: [AuthGuard],
        component: ReportsComponent
    },
    {
        path: 'Reports',
        canActivate: [AuthGuard],
        component: ReportsRectangleDisplayListComponent
    },
    {
        path: 'Reports/HeadCountReport',
        canActivate: [AuthGuard],
        component: HeadcountReportComponent
    },
    {
        path: 'Reports/OrgChart',
        canActivate: [AuthGuard],
        component: OrgChartComponent
    },
    {
        path: 'Reports/OrgChart/:type/:isEditable',
        canActivate: [AuthGuard],
        component: OrgChartComponent
    },
    {
        path: 'Reports/HeadcountByMonthReport',
        canActivate: [AuthGuard],
        component: HeadcountByMonthReportComponent
    },
    {
        path: 'Reports/WorkforcePlanningReport',
        canActivate: [AuthGuard],
        component: WorkforcePlanningReportComponent
    },
    {
        path: 'Reports/DiversityBenchmarkReport',
        canActivate: [AuthGuard],
        component: DiversityBenchmarkReportComponent
    },
    {
        path: 'Reports/CompensationReport',
        canActivate: [AuthGuard],
        component: CompensationReportComponent
    },
    {
        path: 'Reports/MonthlyAbsenceReport',
        canActivate: [AuthGuard],
        component: MonthlyAbsenceReportComponent
    },
    {
        path: 'Reports/MonthlyHolidayUtilisationReport',
        canActivate: [AuthGuard],
        component: MonthlyHolidayUtilisationReportComponent
    },
    {
        path: 'Reports/EmployeeBankDetailsReport',
        canActivate: [AuthGuard],
        component: EmployeeBankDetailsReportComponent
    },
    {
        path: 'Reports/BasicHeadcountReport',
        canActivate: [AuthGuard],
        component: BasicHeadcountReportComponent
    },
    {
        path: 'Reports/BasicEmployeeReport',
        canActivate: [AuthGuard],
        component: BasicEmployeeReportComponent
    },
    {
        path: 'Reports/Predefined/ToilBalanceReport',
        canActivate: [AuthGuard],
        component: ToilBalanceReportComponent
    },
    {
        path: 'Reports/Predefined/ToilOccurrenceReport',
        canActivate: [AuthGuard],
        component: ToilOccurrenceReportComponent
    },
    {
        path: 'Reports/Security/EmployeeLoginReport',
        canActivate: [AuthGuard],
        component: EmployeeLoginReportComponent
    },
    {
        path: 'Packet/:id',
        canActivate: [AuthGuard],
        component: PacketComponent
    },
    {
        path: 'Checklist/Settings/:id',
        canActivate: [AuthGuard],
        component: SettingsOnboardingTaskChecklistComponent
    },
    {
        path: 'AdminControls',
        canActivate: [AuthGuard],
        component: AdminControlsComponent
    },
    {
        path: 'AdminControls/ModuleConfiguration',
        canActivate: [AuthGuard],
        component: ModuleConfigurationComponent
    },
    {
        path: `AdminControls${routes.CULTURE_CONFIGURATION}`,
        canActivate: [AuthGuard],
        component: CultureConfigurationComponent
    },
    {
        path: 'AdminControls/Positions',
        canActivate: [AuthGuard],
        component: AddDummyPositionsComponent
    },
    {
        path: 'AdminControls/TalentTrack',
        canActivate: [AuthGuard],
        component: AddDummyEmployeesComponent
    },
    {
        path: 'AdminControls/AssignPositions',
        canActivate: [AuthGuard],
        component: AssignPositionsToAllComponent
    },
    {
        path: 'AdminControls/Organization',
        canActivate: [AuthGuard],
        component: AddDummyOrganizationsComponent
    },
    {
        path: 'AdminControls/WorkRotations',
        canActivate: [AuthGuard],
        component: AddDummyWorkRotationsComponent
    },
    {
        path: 'AdminControls/WorkLocations',
        canActivate: [AuthGuard],
        component: AddDummyWorkLocationsComponent
    },
    {
        path: 'AdminControls/CostCenters',
        canActivate: [AuthGuard],
        component: AddDummyCostCentersComponent
    },
    {
        path: 'SiteSettings',
        canActivate: [AuthGuard],
        component: SiteSettingsMenuComponent
    },
    {
        path: 'SiteSettings/Lists',
        canActivate: [AuthGuard],
        component: LookupComponent
    },
    {
        path: 'SiteSettings/Positions',
        canActivate: [AuthGuard],
        component: PositionsComponent
    },
    {
        path: `SiteSettings${routes.STYLING}`,
        canActivate: [AuthGuard],
        component: SiteSettingsStylingComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.STYLING_COLOURS}`,
        canActivate: [AuthGuard],
        component: StylingColoursComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.STYLING_COLOURS}${routes.EDITOR}`,
        canActivate: [AuthGuard],
        component: StylingColourEditorComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.STYLING_COLOURS}${routes.EDITOR}/:id`,
        canActivate: [AuthGuard],
        component: StylingColourEditorComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.COMPANY_LOGOS}`,
        canActivate: [AuthGuard],
        component: CompanyLogosComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.COMPANY_LOGOS}${routes.EDITOR}`,
        canActivate: [AuthGuard],
        component: CreateCompanyLogoComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.COMPANY_LOGOS}${routes.EDITOR}/:id`,
        canActivate: [AuthGuard],
        component: CompanyLogoEditorComponent
    },
    {
        path: `SiteSettings${routes.STYLING}${routes.COMPANY_LOGOS}/LogoEdit/:id`,
        canActivate: [AuthGuard],
        component: EditCompanyLogoFileComponent
    },
    {
        path: 'SiteSettings/Positions/Editor/:positionId',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: PositionEditorPageComponent
    },
    {
        path: 'SiteSettings/Positions/Editor',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: PositionEditorPageComponent
    },
    {
        path: 'SiteSettings/WorkLocations',
        canActivate: [AuthGuard],
        component: WorkLocationsListComponent
    },
    {
        path: 'SiteSettings/Forms',
        canActivate: [AuthGuard],
        component: FormsComponent
    },
    {
        path: 'SiteSettings/WorkRotations',
        canActivate: [AuthGuard],
        component: WorkRotationsComponent
    },
    {
        path: 'SiteSettings/WorkRotations/Editor',
        canActivate: [AuthGuard],
        component: WorkRotationEditorComponent
    },
    {
        path: 'SiteSettings/WorkRotations/Editor/:workRotationId',
        canActivate: [AuthGuard],
        component: WorkRotationEditorComponent
    },
    {
        path: `SiteSettings${routes.TIME_OFF}`,
        canActivate: [AuthGuard],
        component: TimeOffMenuComponent
    },
    {
        path: `SiteSettings${routes.TIME_OFF}${routes.TIME_OFF_POLICIES_TYPES}`,
        canActivate: [AuthGuard],
        component: TimeOffPoliciesTypesComponent
    },
    {
        path: `SiteSettings${routes.TIME_OFF}${routes.TIME_OFF_EMAILS}`,
        canActivate: [AuthGuard],
        component: TimeOffEmailsComponent
    },
    {
        path: 'SiteSettings/Toil/Policies',
        canActivate: [AuthGuard],
        component: ToilPoliciesTypesComponent
    },
    {
        path: 'SiteSettings/Toil/Emails',
        canActivate: [AuthGuard],
        component: ToilEmailsComponent
    },
    {
        path: 'SiteSettings/StatutoryHolidays',
        canActivate: [AuthGuard],
        component: StatutoryHolidaysComponent
    },
    {
        path: 'SiteSettings/StatutoryHolidays/Editor',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: HolidayGroupsEditorComponent
    },
    {
        path: 'SiteSettings/StatutoryHolidays/Editor/:holidayGroupId',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: HolidayGroupsEditorComponent
    },
    {
        path: 'SiteSettings/Organization',
        canActivate: [AuthGuard],
        component: OrganizationStructureComponent
    },
    {
        path: 'SiteSettings/Organization/Editor',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: OrganizationEditorComponent
    },
    {
        path: 'SiteSettings/Organization/Editor/:organizationId',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: OrganizationEditorComponent
    },
    {
        path: 'SiteSettings/Configure',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: SiteSettingsConfigureComponent
    },
    {
        path: 'SiteSettings/Currency',
        canActivate: [AuthGuard],
        component: SiteSettingsCurrencyComponent
    },
    {
        path: 'SiteSettings/Translations',
        canActivate: [AuthGuard],
        component: TranslationsComponent
    },
    {
        path: 'SiteSettings/CostCenters',
        canActivate: [AuthGuard],
        component: CostCentersComponent
    },
    {
        path: 'SiteSettings/CountryConfig',
        canActivate: [AuthGuard],
        component: CountryConfigurationComponent
    },
    {
        path: 'SiteSettings/TableFieldEditor',
        canActivate: [AuthGuard],
        component: TableFieldEditorComponent
    },
    {
        path: 'SiteSettings/TableFieldEditor/:tableId/:fieldId',
        canActivate: [AuthGuard],
        component: TableFieldEditorComponent
    },
    {
        path: 'SiteSettings/GenerateSchedule',
        canActivate: [AuthGuard],
        component: RunJobFormComponent
    },
    {
        path: 'SiteSettings/BusinessRuleAutomation',
        canActivate: [AuthGuard],
        component: BusinessRuleAutomationComponent
    },
    {
        path: 'SiteSettings/BusinessRuleAutomation/Form/:id',
        canActivate: [AuthGuard],
        component: BusinessRuleAutomationFormComponent
    },
    {
        path: 'SiteSettings/BusinessRuleAutomation/Form',
        canActivate: [AuthGuard],
        component: BusinessRuleAutomationFormComponent
    },
    {
        path: 'SiteSettings/Integrations',
        canActivate: [AuthGuard],
        component: IntegrationsComponent
    },
    {
        path: 'SiteSettings/Integrations/Editor',
        canActivate: [AuthGuard],
        component: IntegrationEditorComponent
    },
    {
        path: 'SiteSettings/Integrations/Editor/:id',
        canActivate: [AuthGuard],
        component: IntegrationEditorComponent
    },
    {
        path: 'Security',
        canActivate: [AuthGuard],
        component: SecurityMenuComponent
    },
    {
        path: 'Security/Integration',
        canActivate: [AuthGuard],
        component: IntegrationConfigurationComponent
    },
    {
        path: 'Security/Api',
        canActivate: [AuthGuard],
        component: ApiSetupComponent
    },
    {
        path: 'Security/Setup',
        canActivate: [AuthGuard],
        component: SecuritySetupComponent
    },
    {
        path: 'Security/Roles',
        canActivate: [AuthGuard],
        component: SecurityRolesComponent
    },
    {
        path: 'Security/Roles/Editor',
        canActivate: [AuthGuard],
        component: SecurityRoleEditorComponent
    },
    {
        path: 'Security/Roles/Editor/:roleId',
        canActivate: [AuthGuard],
        component: SecurityRoleEditorComponent
    },
    {
        path: 'Letters/List',
        canActivate: [AuthGuard],
        component: CreatedLettersListComponent
    },
    {
        path: 'Letters/Editor',
        canActivate: [AuthGuard],
        component: CreateLetterComponent
    },
    {
        path: 'Letters/Editor/:letterId',
        canActivate: [AuthGuard],
        component: CreateLetterComponent
    },
    {
        path: 'Letters/Send',
        canActivate: [AuthGuard],
        component: SendLetterComponent
    },
    {
        path: 'Letters/Send/:letterId',
        canActivate: [AuthGuard],
        component: SendLetterComponent
    },
    {
        path: 'OrgChart',
        canActivate: [AuthGuard],
        component: OrgChartComponent
    },
    {
        path: 'Newsfeed',
        canActivate: [AuthGuard],
        component: NewsFeedComponent
    },
    {
        path: 'Inbox',
        canActivate: [AuthGuard],
        component: InboxComponent
    },
    {
        path: 'Homepage',
        canActivate: [AuthGuard],
        component: HomepageComponent
    },
    {
        path: 'Benefits',
        canActivate: [AuthGuard],
        component: BenefitsComponent
    },
    {
        path: 'Benefits/Coverages',
        canActivate: [AuthGuard],
        component: BenefitsCoveragesComponent
    },
    {
        path: 'Benefits/PlanTypes',
        canActivate: [AuthGuard],
        component: BenefitsPlanTypesComponent
    },
    {
        path: 'Benefits/PlanTypes/:planTypeId',
        canActivate: [AuthGuard],
        component: BenefitsPlanTypeDetailsComponent
    },
    {
        path: 'Benefits/Plans',
        canActivate: [AuthGuard],
        component: BenefitsPlansComponent
    },
    {
        path: 'Benefits/Plans/Editor',
        canActivate: [AuthGuard],
        component: BenefitsPlanEditorComponent
    },
    {
        path: 'Benefits/Plans/Editor/:planId',
        canActivate: [AuthGuard],
        component: BenefitsPlanEditorComponent
    },
    {
        path: 'Benefits/Groups',
        canActivate: [AuthGuard],
        component: BenefitsGroupsComponent
    },
    {
        path: 'Benefits/Groups/Editor',
        canActivate: [AuthGuard],
        component: BenefitGroupEditorComponent
    },
    {
        path: 'Benefits/Groups/Editor/:groupId',
        canActivate: [AuthGuard],
        component: BenefitGroupEditorComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}`,
        canActivate: [AuthGuard],
        component: PerformanceComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.GOAL_TYPES}`,
        canActivate: [AuthGuard],
        component: GoalTypesComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.GOAL_PLANS}`,
        canActivate: [AuthGuard],
        component: GoalPlansComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.GOAL_PLANS}/Editor/:goalPlanId`,
        canActivate: [AuthGuard],
        component: GoalPlanEditorComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.GOALS}`,
        canActivate: [AuthGuard],
        component: AllGoalsListComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.REVIEW_PLANS}`,
        canActivate: [AuthGuard],
        component: ReviewPlansComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.REVIEW_PLANS}/Editor/:reviewPlanId`,
        canActivate: [AuthGuard],
        component: ReviewPlanEditorComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.REVIEW_RATINGS}`,
        canActivate: [AuthGuard],
        component: ReviewRatingsComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.REVIEWS}`,
        canActivate: [AuthGuard],
        component: ReviewsComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.REVIEWS}/Editor/:reviewId`,
        canActivate: [AuthGuard],
        component: ReviewPageComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.MY_REVIEWS}`,
        canActivate: [AuthGuard],
        component: PerformanceSectionMyReviewsComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.GLOBAL_REVIEWS}`,
        canActivate: [AuthGuard],
        component: GlobalReviewsComponent
    },
    {
        path: `${routes.PERFORMANCE_NO_SLASH}${routes.GLOBAL_REVIEWS}/:reviewPlanId`,
        canActivate: [AuthGuard],
        component: GlobalReviewComponent
    },
    {
        path: 'Reports/Builder',
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedGuard],
        component: ReportBuilderComponent
    },
    {
        path: 'Reports/Builder/Edit/:id',
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedGuard],
        component: ReportBuilderComponent
    },
    {
        path: 'Reports/Builder/:type',
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedGuard],
        component: ReportBuilderComponent
    },
    {
        path: 'Reports/Custom/Type/:type',
        canActivate: [AuthGuard],
        component: ReportsListComponent
    },
    {
        path: 'Reports/Custom/:id',
        canActivate: [AuthGuard],
        component: ReportRendererComponent
    },
    {
        path: 'Reports/Predefined/AbsenceReport',
        canActivate: [AuthGuard],
        component: AbsenceReportComponent
    },
    {
        path: 'Reports/Predefined/AbsenceFinanceReport',
        canActivate: [AuthGuard],
        component: AbsenceFinanceReportComponent
    },
    {
        path: 'Reports/Predefined/OutstandingAbsenceReport',
        canActivate: [AuthGuard],
        component: OutstandingAbsenceReportComponent
    },
    {
        path: 'Reports/Predefined/EmployeePerformanceReport',
        canActivate: [AuthGuard],
        component: GoalsReportComponent
    },
    {
        path: `${routes.LETTERS}`,
        canActivate: [AuthGuard],
        component: LettersComponent
    },
    {
        path: `${routes.LETTERS}/${routes.LETTERS_USER_SECTION}/:section`,
        canActivate: [AuthGuard],
        component: LettersUserSectionComponent
    },
    {
        path: `${routes.LETTERS}/${routes.LETTERS_ADMIN_SECTION}/:section`,
        canActivate: [AuthGuard],
        component: LettersAdminSectionComponent
    },
    {
        path: `${routes.LETTERS}/${routes.TEMPLATES}${routes.EDITOR}`,
        canActivate: [AuthGuard],
        component: LetterTemplateEditorComponent
    },
    {
        path: `${routes.LETTERS}/${routes.TEMPLATES}${routes.EDITOR}/:letterId`,
        canActivate: [AuthGuard],
        component: LetterTemplateEditorComponent
    },
    {
        path: `${routes.LETTERS}/${routes.LETTER_GENERATIONS}${routes.EDITOR}`,
        canActivate: [AuthGuard],
        component: LetterGenerationEditorComponent
    },
    {
        path: `${routes.LETTERS}/${routes.LETTER_GENERATIONS}${routes.EDITOR}/:letterGenerationId`,
        canActivate: [AuthGuard],
        component: LetterGenerationEditorComponent
    },
    {
        path: 'Reports/Predefined/ReviewStatusReport',
        canActivate: [AuthGuard],
        component: ReviewStatusReportComponent
    },

];

@NgModule({
    imports: [RouterModule.forRoot(route_declarations)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
