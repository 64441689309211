<div class="page-description-section">
    <div appLocalizationPopupDirective localizationCode="PayworksApiDocumentation" >
        <a href="https://payworks.my.site.com/" target="_blank">
            {{ 'PayworksApiDocumentation' | translate: {Default: "Payworks API Documentation"} }}
        </a>
    </div>

    <br>

    <div appLocalizationPopupDirective localizationCode="PayworksMappingsPageDescription">
        {{ 'PayworksMappingsPageDescription' | translate: {Default: "This page allows you to manage the mappings for Payworks"} }}
    </div>
</div>

<app-data-grid-comp
    [columns]="columns"
    [gridDataResult]="gridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Mappings"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [searchable]="false"
    [filter]="null"
    [filterable]="null"
    [filterCategories]="null"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="navigateToMapping($event)"
    (emitDeleteEvent)="deleteAllSelected($event)"
></app-data-grid-comp>