import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-letter-generation-employee-dialog',
  templateUrl: './letter-generation-employee-dialog.component.html',
  styleUrls: ['./letter-generation-employee-dialog.component.scss']
})
export class LetterGenerationEmployeeDialogComponent implements OnInit {
  includeInactiveEmployees: boolean = false;

  constructor(
    public translate: TranslateService,
    private dialogRef: MatDialogRef<LetterGenerationEmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {}

  ngOnInit(): void {
  }

  selectEmployees() {
    this.dialogRef.close({action:'openEmployeeDialog'});
  }

  addAllEmployees() {
    this.dialogRef.close({action: 'addAllEmployees', includeInactiveEmployees: this.includeInactiveEmployees});
  }

  close() {
    this.dialogRef.close();
  }

}
