import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { routes } from '@app/consts';
import { fieldTypes } from '@app/consts/field-types';
import { tableid_to_name } from '@app/consts/tableid-to-name';
import { EmployeeService } from '@app/core/services/employee.service';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { CostCenter } from '@app/modules/cost-centers/models/cost-center.model';
import { Form, FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { EmployeeTableFieldSecurity, EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { finalize } from 'rxjs/operators';
import { formats } from '@app/consts/formats';
import { SettingsService } from "@app/core/services/settings.service";
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { Observable } from 'rxjs';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { CultureService } from '@app/core/services/system-language/culture.service';

@Component({
  selector: 'app-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.scss']
})
export class FormGeneratorComponent implements OnInit {
  public routes: typeof routes = routes;
  public fieldTypes: typeof fieldTypes = fieldTypes;
  public tableid_to_name: typeof tableid_to_name = tableid_to_name
  @Input() formId: string;
  @Input() formData: any;
  @Input() emitFormDataTrigger: boolean;
  @Input() readOnly: boolean;
  @Input() targetEmployeeTablePermissions: EmployeeTableSecurity[];
  @Input() skeletonLoadingRows: number = 5;


  @Output() emitFormData: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitFormStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitFormPristine: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitFormDataChange: EventEmitter<any> = new EventEmitter<any>();

  loadingFormElements: boolean;
  formElements: FormElement[];
  // formElementsCopy: FormElement[]; // used to find date components without traversing a tree
  form: UntypedFormGroup;
  mySubscription: any;
  user$: any;
  tableSecurity: EmployeeTableSecurity;
  formDetails: Form;
  fieldPermissions: EmployeeTableFieldSecurity[];
  currentCulture: Culture;
  dbTableFields: TableField[];
  loadingDbTableFields: boolean;

  isDirty$: Observable<boolean>;
  public formPristine: boolean;
  cultures: Culture[];
  loadingCultures: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private metadataFormService: MetadataFormService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private oidcAuthService: OidcAuthService,
    private employeeService: EmployeeService,
    private tablePermissionsService: TablePermissionsService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private employeeSecurityService: EmployeeSecurityService,
    private securitySetupService: SecuritySetupService,
    private cultureService: CultureService,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }


  ngOnInit(): void {
    this.getCultures();
    this.getFormDetails();
    this.getCurrentCulture();
    // this.getTableSecurity();
    // this.getFormElements();
    // this.formData?.name === undefined ? this.formData.name = [] : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.emitFormDataTrigger && changes.emitFormDataTrigger.previousValue !== undefined && (changes.emitFormDataTrigger.currentValue !== changes.emitFormDataTrigger.previousValue)) {
      // this.sanitizeFormDateValues();
      this.emitFormData.emit(this.form?.getRawValue());
      this.form.markAsPristine();
    }
  }
  // sanitizeFormDateValues() {
  //   this.formElementsCopy.forEach(formElement => {
  //     if(formElement.formElementType.id === fieldTypes.DATE_INPUT) {
  //       if (this.form && this.form.value[formElement.formControl] !== null && this.form.value[formElement.formControl] !== undefined) {
  //         if (moment(this.form.value[formElement.formControl], "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
  //           console.log("current", this.form.value[formElement.formControl]);
  //           // let value = moment(this.form.value[formElement.formControl]).format().slice(0, 10);
  //           let value = moment(this.form.value[formElement.formControl], "YYYY-MM-DDTHH:mm:ss").utc().toISOString().slice(0, 10);
  //           console.log("new", value);
  //           // this.form.controls[formElement.formControl].setValue(value);
  //         }
  //       }
  //     }
  //   });
  // }

  // ngOnDestroy() {
    // this.mySubscription = this.form.valueChanges.subscribe();
    // this.mySubscription.unsubscribe()
  // }


  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  isFormPristine(): boolean {
    return this.form?.pristine;
  }

  getCultures() {
    this.cultureService.getCultures()
    .subscribe(
      res => {
        this.cultures = res;
      }
    )
  }

  getCurrentCulture() {
    this.loadingCultures = true;

    this.employeeSecurityService.getCurrentEmployeeCulture()
    .pipe(
      finalize(() => this.loadingCultures = false)
    )
    .subscribe(
      res => {
        this.currentCulture = res;
      }
    )
  }

  // Get the form details to find the table assigned to the form
  getFormDetails() {
    this.metadataFormService.getForm(this.formId)
      .subscribe(
        res => {
          this.formDetails = res;
          this.getTableFieldPermissions();
        }
      );
  }

  getTableFieldPermissions() {
    if(this.formDetails.table){
      this.fieldPermissions = this.tablePermissionsService.getTableFieldsPermissions(this.formDetails.table.id, this.targetEmployeeTablePermissions);
    }
    this.getFormElements();
  }

  getFormElements() {
    this.loadingFormElements = true;

    this.metadataFormService.getFormElements(this.formId, 0, '1000')
      .pipe(
        finalize(() => this.loadingFormElements = false)
      )
      .subscribe(
        res => {
          this.formElements = res.data;
          this.extractFormElementProperties(this.formElements);
          this.formElements.sort((firstItem, secondItem) => parseInt(firstItem.order) - parseInt(secondItem.order));

          // If the form has a table assocciated with it then fetch the table field data to override form element data
          if(this.formDetails.table !== null){
            this.getDbTableFields();
          }
          else {
            this.buildForm();
          }
        }
      );
  }

  buildForm() {
    this.form = this.createForm(this.formElements);
    this.sortIntoParentsAndChildren();

    //Subscribe to form values, if it changes emit the validity of the form
    this.form.valueChanges.subscribe(value => {
      this.emitFormPristine.emit(this.form?.pristine);
      this.emitFormStatus.emit(this.form.valid);
      this.emitFormDataChange.emit(this.form.value);
    });
    this.emitFormStatus.emit(this.form.valid);
    this.emitFormDataChange.emit(this.form.value);
  }

  extractFormElementProperties(formElements: FormElement[]){
    formElements.forEach(formElement => {
      formElement.properties?.forEach(prop => {
        formElement[prop.property] = prop.value;
      })

      if(this.readOnly){
        formElement.disabled = 'true';
      }

    });
  }

  sortIntoParentsAndChildren() {
    let childrenToBeRemovedFromFormElementsArray: FormElement[] = [];

    this.formElements.forEach( formElement => {
      if(formElement.parentFormElement !== null) {
        let parentId = formElement.parentFormElement.id;
        let parent = this.formElements.filter(formElement => formElement.id === parentId);

        //If the form element is a child
        if(parent.length === 1){

          if (formElement.tableField) {
            // TODO fix the hardcoding
            if (formElement.tableField.id == 'tfi_CompensationPayRate') {
              formElement.tableField.format = formats.COMMA_SEPARATED;
            }
          }

          //Get the form elements parent index
          let index = this.formElements.indexOf(parent[0]);

          if(index !== -1){
            // create a children array in the parent element
            if(this.formElements[index].children === undefined) {
              this.formElements[index].children = [];
            }

            childrenToBeRemovedFromFormElementsArray.push(formElement)

            // add current form element to the children array of its parent
            this.formElements[index].children.push(formElement);
          }
        }
      }
    });

    // remove any child elements that have been copied to children arrays
    childrenToBeRemovedFromFormElementsArray.forEach( childFormElement => {
      let index = this.formElements.indexOf(childFormElement);

      if(index !== -1) {
        this.formElements.splice(index, 1);
      }
    });
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(this.formDetails.table?.id, 0, '1000')
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
        this.buildForm();
      })
    )
    .subscribe(
      res => {
        this.dbTableFields = res.data;
        this.addDBTableValues();
      }
    );
  }

  // getDbTableField() {
  //   this.loadingDbTableFields = true;

  //   let observables: Observable<TableFieldVerbose>[] = [];

  //   this.formElements.forEach( formElement => {
  //     if(formElement?.tableField?.id !== undefined){
  //       observables.push(this.securitySetupService.getField(this.formDetails.table?.id, formElement?.tableField?.id))
  //     }
  //   });

  //   forkJoin(observables)
  //   .pipe(
  //     finalize(()=>{
  //       this.loadingDbTableFields = false;
  //       this.buildForm();
  //     })
  //   )
  //   .subscribe(
  //     res => {
  //       this.dbTableFields = res;
  //       this.addDBTableValues();
  //     }
  //   );
  // }

  addDBTableValues() {
    this.dbTableFields.forEach(
      dbTableField => {
        let formElIndex = this.formElements.findIndex( el => el.tableField?.id === dbTableField?.id)

        if(formElIndex !== -1){
          this.formElements[formElIndex] = this.useDbTableFieldValues(this.formElements[formElIndex], dbTableField)
        }
      }
    )

  }

  useDbTableFieldValues(formElement: FormElement, dbTableField: TableField) {
    // set field name
    // let currentCultureFieldName: Localization[] = dbTableField.name.filter( n => n.culture === this.currentCulture.id );
    // formElement.text = currentCultureFieldName[0].text;
    formElement.text = dbTableField.name;

    // set field hidden value
    formElement.hidden = dbTableField.enabled ? 'false' : 'true';

    // set required value
    formElement.requiredField = dbTableField.requiredField ? 'true' : 'false';

    // set min length value
    // formElement.minLength = dbTableField.minimumValue;

    // set max length value
    // formElement.maxLength = dbTableField.maximumValue;

    // set the placeholder
    formElement.placeholder = dbTableField.defaultValue;

    return formElement
  }

  createForm(formElements: FormElement[]): UntypedFormGroup {
    let form: UntypedFormGroup = this.fb.group({});

    formElements.forEach(formElement => {
      if(formElement.formElementType.id === fieldTypes.TEXT_LOCALIZATION || formElement.formElementType.id === fieldTypes.TEXT_AREA_LOCALIZATION) {
        form.addControl(formElement.formControl, this.toFormArray(formElement));
      }
      else if(formElement.formElementType.id === fieldTypes.COST_CENTERS_SELECTION) {
        form.addControl(formElement.formControl, this.toCostCenterFormArray(formElement));
      }
      else {
        form.addControl(formElement.formControl, this.toFormControl(formElement))
      }
    });

    return form;
  }

  addToFormArray(formControl: string, formElement: FormElement) {
    let formArray = this.form.controls[formControl] as UntypedFormArray

    formArray.push(this.fb.group({
      culture: ['', Validators.required],
      [formElement.localizationValueParam]: ['', Validators.required]
    }));
  }

  addToCostCenterFormArray(formControl: string) {
    let formArray = this.form.controls[formControl] as UntypedFormArray

    formArray.push(this.fb.group({
      costCenter: ['', Validators.required],
      percentAllocated: ['']
    }));
  }

  deleteFromFormArray(event: {index: number, formControl: string}) {
    let formArray = this.form.controls[event.formControl] as UntypedFormArray

    formArray.removeAt(event.index);
  }

  reorderArrayByCulture(array, cultureValue) {
    // Find the object with the matching culture
    const matchedObject = array.find(item => item.culture === cultureValue);
    
    // Filter out the matched object from the array
    const remainingArray = array.filter(item => item.culture !== cultureValue);
    
    // Return the new array with the matched object at the start
    return matchedObject ? [matchedObject, ...remainingArray] : array;
  }

  toFormArray(formElement: FormElement): UntypedFormArray {
    let formArray = this.fb.array([]);

    if(this.formData && this.formData[formElement.formControl]?.length > 0) {

      this.formData[formElement.formControl] = this.reorderArrayByCulture(this.formData[formElement.formControl], this.currentCulture?.id);

      this.formData[formElement.formControl].forEach(
        item => {
          if(Object.values(item)[1] !== ''){
            let obj = {};

            for (const [key, value] of Object.entries(item)) {
              obj[key] = [{value, disabled: (formElement.disabled === 'true')}, Validators.required];
            }

            formArray.push(this.fb.group(obj));
          }
        }
      )
    }
    else {
      let cultures = [...this.cultures];

      let currentCultureIndex = this.cultures.findIndex(  culture => culture.id === this.currentCulture?.id);

      cultures.splice(1, currentCultureIndex);

      // add a formgroup without data.
      formArray.push(this.fb.group(
          {
            culture: [this.currentCulture?.id, Validators.required],
            [formElement.localizationValueParam]: ['', Validators.required]
          }
        )
      )

      // cultures.forEach(
      //   culture => {
      //     formArray.push(this.fb.group(
      //         {
      //           culture: [culture?.id, Validators.required],
      //           [formElement.localizationValueParam]: ['', Validators.required]
      //         }
      //       )
      //     )
      //   }
      // )
    }

    return formArray;
  }

  toCostCenterFormArray(formElement: FormElement): UntypedFormArray {
    let formArray = this.fb.array([]);

    if(this.formData && this.formData[formElement.formControl].length > 0) {
      this.formData[formElement.formControl].forEach(
        (item: {
          costCenter: CostCenter
          percentAllocated: number
        }) => {
          let obj = {};
          let costCenter = item.costCenter.id;
          let percentAllocated = item.percentAllocated;

          obj['costCenter'] = [
            {
              value: costCenter,
              disabled: (formElement.disabled === 'true')
            },
            Validators.required
          ];

          obj['percentAllocated'] = [
            {
              value: percentAllocated,
              disabled: (formElement.disabled === 'true')
            }
          ];

          formArray.push(this.fb.group(obj));
        }
      )
    }
    else {
      // add a formgroup without data.
      // formArray.push(this.fb.group({
      //   costCenter: ['', Validators.required],
      //   percentAllocated: ['']
      // }))
    }

    return formArray;
  }

  toFormControl(formElement: FormElement): UntypedFormControl {
    let validators = [];

    let fieldPermission: EmployeeTableFieldSecurity = this.fieldPermissions?.find( filedPermission => filedPermission.field.id === formElement.tableField?.id );

    if(fieldPermission){
      if(fieldPermission.update) {
        //If there is no global disabled or hidden values from the form generator set them to false
        formElement.disabled !== 'true' ? formElement.disabled = 'false' : null
        formElement.hidden !== 'true' ? formElement.hidden = 'false' : null
      }
      else if(fieldPermission.read) {
        formElement.disabled = 'true';
      }
      else if(fieldPermission.deny) {
        formElement.disabled = 'true';
        formElement.hidden = 'true';
      }
    }

    if (formElement.requiredField === 'true') { validators.push(Validators.required) }
    // if (formElement.validation == 'email') { validators.push(Validators.email) }
    // if (formElement.minimumValue) { validators.push(Validators.minLength(field.minimumValue)) }
    // if (formElement.maximumValue) { validators.push(Validators.maxLength(field.maximumValue)) }

    if(formElement.formElementType.id === fieldTypes.TOGGLE) {
      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null ? this.formData[formElement.formControl] : false),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.DROPDOWN) {
      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null && this.formData[formElement.formControl] !== undefined ? this.formData[formElement.formControl] : null),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.DATE_INPUT) {
      var value = null
      if (this.formData && this.formData?.id !== null && this.formData[formElement.formControl] !== null && this.formData[formElement.formControl] !== undefined) {
          value = this.formData[formElement.formControl];
      }
      return new UntypedFormControl(
        {
          value: value,
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.TIME_INPUT) {
      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null && this.formData[formElement.formControl] !== null ? this.formData[formElement.formControl].substring(0, 5) : null), //Using substring to remove seconds from time value
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.TIMEZONE_DROPDOWN) {
      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null ? this.formData[formElement.formControl] : (this.settingsService.siteSettings?.baseTimezone?.id || null)),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.CULTURE_DROPDOWN) {
      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null ? this.formData[formElement.formControl] : (this.settingsService.siteSettings?.defaultCulture?.id || null)),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.CURRENCY_DROPDOWN) {
      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null ? this.formData[formElement.formControl] : (this.settingsService.siteSettings?.baseCurrency?.id || null)),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else if(formElement.formElementType.id === fieldTypes.NUMBER_INPUT) {
      if(formElement.minLength !== null) {
        validators.push(Validators.min(parseInt(formElement.minLength)))
      }

      if(formElement.maxLength !== null) {
        validators.push(Validators.max(parseInt(formElement.maxLength)))
      }

      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null ? this.formData[formElement.formControl] : null),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
    else {
      if(formElement.minLength !== null || formElement.minLength !== '') {
        validators.push(Validators.minLength(parseInt(formElement.minLength)))
      }

      if(formElement.maxLength !== null || formElement.maxLength !== '') {
        validators.push(Validators.maxLength(parseInt(formElement.maxLength)))
      }

      return new UntypedFormControl(
        {
          value: (this.formData && this.formData?.id !== null ? this.formData[formElement.formControl] : null),
          disabled: (formElement.disabled === 'true') //converting string to boolean
        }, validators);
    }
  }

  openConfirmCloseDialog(): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    return dialogRef.afterClosed();
  }

  save() {
    console.log(JSON.stringify(this.form.getRawValue()))
  }

}
