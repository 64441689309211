<ng-container *ngIf="!loadingFormDetails; loading">
    <div class="form">
        <h5 mat-dialog-title>{{ formDetails?.name[0]?.text }}: {{ formData?.name }}</h5>
        
        <mat-dialog-content>
            <app-form-generator 
                [formId]="formId"
                [formData]="formData"
                [emitFormDataTrigger]="getFormData"
                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                (emitFormData)="formDataEmitted($event)"
                (emitFormPristine)="formPristineEmitted($event)"
                (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>

            <div class="form-section">
                <div class="form-section-title">
                    <div>
                        <h6>{{ 'Parameters' | translate: {Default: "Parameters"} }}</h6>
                        <span class="help-text">{{ 'Parameters-HelpText' | translate: {Default: "Parameters HelpText"} }}</span>
                    </div>

                    <button mat-stroked-button setColor="primaryColour" color="primary" (click)="addKeyValuePair()">
                        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                        {{ 'Add-Parameter' | translate: {Default: "Add Parameter"} }}
                    </button>
                </div>
                <div class="form-section-contents">
                    <div class="section-row">
                        <form [formGroup]="form">
                            <div formArrayName="parameters">
                                <div *ngFor="let parameter of parameters.controls; let i=index" [formGroupName]="i">
            
                                    <mat-form-field  appearance="fill">
                                        <mat-label>
                                            {{ 'Key' | translate: {Default: "Key"} }}
                                        </mat-label>
            
                                        <input matInput formControlName="key">
                                    </mat-form-field>
            
                                    <mat-form-field  appearance="fill">
                                        <mat-label>
                                            {{ 'Value' | translate: {Default: "Value"} }}
                                        </mat-label>
            
                                        <input matInput formControlName="value">
                                    </mat-form-field>
            
                                    <mat-icon 
                                        setColor 
                                        [setColorRules]="[
                                            {
                                                color: 'primaryColour',
                                                events: ['hover']
                                            }
                                        ]"
                                        class="material-icons-outlined clickable-icon" 
                                        (click)="removeKeyValuePair(i)" 
                                        matTooltip="Delete"
                                    >delete</mat-icon>
            
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



        </mat-dialog-content>
        
        <mat-dialog-actions>
            <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
            
            <button 
                mat-raised-button 
                setColor="primaryColour" 
                color="primary" 
                [disabled]="formValid === false" 
                appPreventDoubleClick 
                (throttledClick)="getFormData = !getFormData">
                {{ 'Save' | translate: {Default: "Save"} }}
            </button> 
        </mat-dialog-actions>
    </div>
</ng-container>

<ng-template #loading>
    <mat-dialog-content class="loading">
        <ngx-skeleton-loader
        count="5"
        [theme]="{ 
            'height.px': 50
        }"
        ></ngx-skeleton-loader>
    </mat-dialog-content>
</ng-template>
