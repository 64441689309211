<app-data-grid-comp
    [tableId]="db_tables.LettersLetterGenerations"
    [columns]="columns"
    [filterCategories]="filterCategories"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Letters-GenerateLetters"
    [rowSelectionSettings]="{
        canSelectRows: true,
        selectableMode: 'multiple',
        selectRowBy: 'id'
    }"
    clickableRows="true"
    [clearSelectedItems]="clearSelectedItems"
    [accessAndHistoryURL]="accessAndHistoryURL"
    [historyDialogSettings]="{
        showEffectivDateColumn: false
    }"
    [showAccessIcon]="false"
    searchable="true"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    [filterToggleDetails]="filterToggleDetails"
    (emitToggleFilter)="updateFilterToggleDetails($event)"
    (emitPageChangeEvent)="pageChange($event)"
    (emitSearchEvent)="search($event)"
    (emitOpenDialogEvent)="openEditor($event)"
    (emitDeleteEvent)="deleteItems($event)"
    (emitFilterEvent)="filterCallback($event)"
    (emitSortEvent)="sortChange($event)"
></app-data-grid-comp>