import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TranslationService } from '@app/core/services/translation.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from "@app/modules/security-setup/models/table.model";
import { TableField } from "@app/modules/security-setup/models/table-field.model";
import { SecuritySetupService } from "@app/modules/security-setup/services/security-setup.service";
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";
import { IntegrationMapping } from "@app/modules/site-settings-integrations/models/integrations.model";
import { fieldTypes } from "@app/consts";

@Component({
    selector: 'app-integration-mappings-dialog',
    templateUrl: './integration-mappings-dialog.component.html',
    styleUrls: ['./integration-mappings-dialog.component.scss']
})
export class IntegrationMappingsDialogComponent implements OnInit {

    public integrationId: string;
    public integrationMapping?: IntegrationMapping;
    public form: any;
    public tableGroups: Array<any>;
    public selectedTable: Table;
    public fieldList: Array<TableField>;
    public selectedField: TableField;

    constructor(
        private cultureService: CultureService,
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private translationService: TranslationService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private securitySetupService: SecuritySetupService,
        private employeeSecurityService: EmployeeSecurityService,
        private integrationService: IntegrationsService,
        private dialogRef: MatDialogRef<IntegrationMappingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.integrationId = data.integrationId;
        this.integrationMapping = data.integrationMapping;
        this.createForm();
    }

    ngOnInit(): void {
        this.getTables();
    }

    createForm() {
        this.form = this.fb.group({
            tableId: [this.integrationMapping ? this.integrationMapping.table?.id : null, Validators.required],
            fieldId: [this.integrationMapping ? this.integrationMapping.field?.id : null, Validators.required],
            status: [this.integrationMapping ? this.integrationMapping.status : true],
            mandatory: [this.integrationMapping ? this.integrationMapping.mandatory : false],
            externalField: [this.integrationMapping ? this.integrationMapping.externalField : null, Validators.required],
        });
    }

    getTables() {
        this.securitySetupService.getTables(0, '200').subscribe(
            pagedTableList => {
                let tableList = pagedTableList.data;
                console.log("tableList", tableList)
                let tableMap = [];
                tableList.map(table => {
                    if (table.parentTable != null) {
                        let groupIndex = tableMap.findIndex(t => t.groupId === table.parentTable.id);

                        if (groupIndex >= 0) {
                            tableMap[groupIndex].tables.push(table);
                        } else {
                            tableMap.push({groupName: table.parentTable.name, groupId: table.parentTable.id, tables: [table]});
                        }
                    } else {
                        tableMap.push({groupName: table.name, groupId: table.id, tables: [table]});
                    }
                });
                this.tableGroups = tableMap;

                if (this.integrationMapping) {
                    this.form.patchValue({
                        tableId: this.integrationMapping.table?.id,
                        fieldId: this.integrationMapping.field?.id,
                    });
                    this.selectedTable = tableList.find(table => table.id === this.integrationMapping.table?.id);
                    this.getFields(this.integrationMapping.table?.id);
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    getFields(tableId) {
        this.securitySetupService.getFields(tableId, 0, '200').subscribe(
            pagedFieldList => {
                this.fieldList = pagedFieldList.data;

                if (this.integrationMapping) {
                    this.selectedField = this.fieldList.find(field => field.id === this.integrationMapping.field?.id);
                }

            }, err => console.log(err)
        );
    }

    handleSelectTableClick(table) {
        this.form.get('tableId').setValue(table.id);
        this.selectedTable = table;
        this.selectedField = null;
        this.getFields(table.id);
    }

    handleSelectFieldClick(tableField) {
        this.selectedField = tableField;
        this.form.get('fieldId').setValue(tableField.id);
    }

    save() {
        this.overlayService.show();

        let mappingPayload = {
            tableId: this.selectedTable.id,
            fieldId: this.selectedField.id,
            status: this.form.value.status,
            mandatory: this.form.value.mandatory,
            externalField: this.form.value.externalField
        }

        if (this.integrationMapping) {
            this.integrationService.updateIntegrationMapping(this.integrationId, this.integrationMapping.id, mappingPayload).subscribe(res => {
                console.log("res", res);
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');

                this.dialogRef.close(true);

            }, err => {
                console.log("err", err);
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdateError'), 'clear', 'warn');
            });
        } else {
            this.integrationService.createIntegrationMapping(this.integrationId, mappingPayload).subscribe(res => {
                console.log("res", res);
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');

                this.dialogRef.close(true);

            }, err => {
                console.log("err", err);
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdateError'), 'clear', 'warn');
            });
        }
    }

    close() {
        this.dialogRef.close();
    }

    protected readonly fieldTypes = fieldTypes;
}
