import { Version } from "@app/shared/models/version.model"

export interface IntegrationSettings {
    id: string,
    description: any,
    integration: IntegrationType,
    status: boolean,
    version: Version
}


export interface IntegrationType {
    id: string,
    name: string
}

export interface IntegrationMapping {
    id: string,
    integration: IntegrationType,
    table: {
        id: string,
        name: string,
    },
    field: {
        id: string,
        name: string,
        format: string
    },
    status: boolean,
    mandatory: boolean,
    externalField: string,
}

export interface PayworksIntegrationDetails {
    clientId: string,
    clientSecret: string,
    customerNumber: string,
    username: string,
    password: string,
    culture: {
        id: string,
        text: string
    }
}

export interface PayworksIntegrationDetailsSubmit {
    clientId: string,
    clientSecret: string,
    customerNumber: string,
    username: string,
    password: string,
    culture: string
}

export interface PayworksIntegrationMapping {
    id: string,
    table: {
        id: string,
        name: string,
    },
    tableField: {
        id: string,
        name: string,
        format: string
    },
    transformations: {
      hubbub: string,
      payworks: string
    }[],
    payworksFieldId: string,
    version: Version
}

export interface PayworksIntegrationMappingSubmit {
    integrationSetting: string,
    tableField: string,
    payworksFieldId: string,
    transformations: {
        hubbub: string,
        payworks: string
    }[],
}

export interface IntegrationEvent {
    id: string,
    timestamp: string,
    processed: boolean,
    integration: {
        id: string,
        name: string
    },
    affectedUser: {
        id: string,
        firstName: string
        lastName: string
    },
    version: Version
}

export interface IntegrationEventVerbose {
    id: string,
    timestamp: string,
    processed: boolean,
    processedTimestamp: string,
    payload: string,
    integration: {
        id: string,
        name: string
    },
    affectedUser: {
        id: string,
        firstName: string
        lastName: string
    },
    logs: {
        createdOn: string,
        message: string
    }[]
    version: Version
}

export interface PayworksTestConnectionResponse {
    success: boolean,
    message: string
}

export interface PayworksMappingHubbubTable {
    id: string,
    name: string
    parentTable: {
        id: string,
        name: string
    }
}