import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RecipientType } from '@app/modules/letters/models/letters.model';
import { LettersService } from '@app/modules/letters/services/letters.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-letter-generation-recipient-dialog',
  templateUrl: './letter-generation-recipient-dialog.component.html',
  styleUrls: ['./letter-generation-recipient-dialog.component.scss']
})
export class LetterGenerationRecipientDialogComponent implements OnInit {
  recipientTypes: RecipientType[];
  recipientType: RecipientType;
  error: boolean;
  formId: string = 'frm_gOZbiyZJghumQN';
  formData: any;
  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean;
  selectedRecipients: any;

  constructor(
    private lettersService: LettersService,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<LetterGenerationRecipientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.recipientTypes = data.recipientTypes;
    this.selectedRecipients = data.selectedRecipients;
  }

  ngOnInit(): void {
  }

  isRecipientTypeDisabled(recipientTypeId: string) {
    if(recipientTypeId === 'SpecifiedUser') {
      return false;
    }
    else {
      let index = this.selectedRecipients.findIndex((recipient) => recipient.recipientType === recipientTypeId && recipient.status?.id !== 'remove');
  
      if(index >= 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  recipientAlreadySelected() {
    if(!this.formData?.recipient) {
      return false;
    }
    
    let index = this.selectedRecipients.findIndex((recipient) => recipient.user?.id === this.formData?.recipient && recipient.status?.id !== 'remove');

    if(index >= 0) {
      return true;
    }
    else {
      return false;
    }
  }

  isSaveButtonDisabled() {
    if(this.recipientType?.id === 'SpecifiedUser') {
      return !this.formValid || this.recipientAlreadySelected();
    }
    else {
      return !this.recipientType;
    }
  }

  getRecipientTypes() {
    this.lettersService.getTemplateRecipientTypes(100, 0)
    .subscribe(
      {
        next: (v) => {
          this.recipientTypes = v.data;
        },
        error: (e) => {
          this.error = true
        }
      }
    );
  }

  formDataEmitted(formData) {
    this.formData = formData;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save() {
    const data = {
      recipientType: this.recipientType,
      recipient: this.formData?.recipient
    }

    this.dialogRef.close(data);

    // // Convert formdata to submit format, reverse boolean value because the backend returned "disabled" but the frontend wants to reflect "enabled"
    // const convertedData = Object.keys(formData).map(name => ({
    //   name,
    //   disabled: !formData[name]
    // }));

    // this.overlayService.show();

    // this.cultureConfigurationService.saveCultureConfiguration(convertedData)
    // .pipe(
    //   finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //   (res) => {
    //     this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    //   }
    // );
  }

  close() {
    this.dialogRef.close();
  }

}
