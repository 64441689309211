import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateTimelineOption } from '@app/modules/letters/models/letters.model';
import { LettersService } from '@app/modules/letters/services/letters.service';

@Component({
  selector: 'app-replacements-dialog',
  templateUrl: './replacements-dialog.component.html',
  styleUrls: ['./replacements-dialog.component.scss']
})
export class ReplacementsDialogComponent implements OnInit {
  //These tables cannot have filters, sorts or multiple fields
  flatTables = [
    'tbl_Employees',
    'tbl_Compensations',
    'tbl_BankDetails',
    'tbl_PayrollDetails',
    'tbl_EmploymentDetails',
    'tbl_EmploymentRecords'
  ];
  //Field filter variables
  filterOperators = {
    "TEXT": [
      {
        label: 'Like',
        value: 'like'
      },
      {
        label: 'Starts With',
        value: 'startswith'
      },
      {
        label: 'Ends With',
        value: 'endswith'
      },
    ],
    "Number": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "Percentage": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "DATETIME": [
      {
        label: '=',
        value: '='
      },
      {
        label: '>=',
        value: '>='
      },
      {
        label: '<',
        value: '<'
      },
      {
        label: '>',
        value: '>'
      },
    ],
    "DROPDOWN": [
      {
        label: '=',
        value: '='
      },
    ],
    "ProvidedList": [
      {
        label: '=',
        value: '='
      },
    ],
    "SpecialLookup": [
      {
        label: '=',
        value: '='
      },
    ],
  }
  tables: any;
  replacement: any;
  originalData: any;
  timelineOptions: TemplateTimelineOption[] = [];

  get isTableFlat() {
    return this.flatTables.includes(this.replacement.get('table').value);
  }

  constructor(
    private fb: FormBuilder,
    public lettersService: LettersService,
    private dialogRef: MatDialogRef<ReplacementsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.originalData = data.replacement.value;
    this.replacement = data.replacement;
    this.tables = data.tables;

    this.replacement.get('identifier').setValidators([Validators.required]);
    this.replacement.get('table').setValidators([Validators.required]);

    this.replacement.get('table').valueChanges.subscribe(() => {
      const fields = this.replacement.get('fields') as FormArray;
      while (fields.length) {
        fields.removeAt(0);
      }
      this.addField();
    });
  }

  ngOnInit(): void {
    this.getTimelineOptions();
  }

  getTimelineOptions(): void {
    this.lettersService.getTemplateTimelineOptions(100, 0).subscribe((response) => {
      this.timelineOptions = response.data;
    });
  }

  /**
   * Retrieves the correct filter operators based on the replacement and field indices.
   *
   * @param {number} replacementIndex - The index of the replacement in the replacements array.
   * @param {number} fieldIndex - The index of the field in the fields array.
   * @returns {Array} An array of filter operators corresponding to the field type.
   */
  getCorrectFilterOperators(fieldIndex) {
    let tableId = this.replacement.get('table').value;
    let fields = this.getFields();
    let fieldId = fields.at(fieldIndex).value?.id;

    if(tableId === '' || fieldId === '' || fieldId === null) return [];

    let fieldType = this.tables.find(table => table.id === tableId).fields.find(field => field.id === fieldId).fieldType.id;

    return this.filterOperators[fieldType];
  }
  
  /**
   * Retrieves the fields of the selected table based on the replacement index.
   *
   * @param {number} replacementIndex - The index of the replacement to get the table fields for.
   * @returns {Array<any>} An array of fields for the selected table. Returns an empty array if no table is selected.
   */
  getSelectedTableFields() {
    let tableId = this.replacement.get('table').value;

    if(tableId === '') return [];

    return this.tables.find(table => table.id === tableId).fields;
  }

  /**
   * Retrieves the form array of fields for a given replacement index.
   *
   * @param {number} replacementIndex - The index of the replacement to get the fields from.
   * @returns {FormArray} The form array of fields associated with the specified replacement index.
   */
  getFields(): FormArray {
    return this.replacement?.get('fields') as FormArray;
  }
  
  addField(): void {
    this.getFields().push(this.createField());
  }
  
  removeField(fieldIndex: number): void {
    this.getFields().removeAt(fieldIndex);
  }

  createReplacement(): FormGroup {
    return this.fb.group({
      identifier: [null, Validators.required],
      table: [null, Validators.required],
      timelineOption: ['CURRENT', Validators.required],
      fields: this.fb.array([this.createField()]),
      filter: [null],
      sort: [null]
    });
  }
  
  createField(): FormGroup {
    return this.fb.group({
      id: [null, Validators.required],
      sortDirection: [null],
      filterOperator: [null],
      filterValue: [null]
    });
  }

  close(): void {
    this.replacement.reset(this.originalData);
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close();
  }

}
