import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fieldTypes } from '@app/consts';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { CustomReportTable, FieldType, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import { ReportsMapping } from "@app/modules/custom-reports/util/reports-mapping";


@Component({
    selector: 'app-report-element-datagrid',
    templateUrl: './report-element-datagrid.component.html',
    styleUrls: ['./report-element-datagrid.component.scss']
})
export class ReportElementDatagridComponent implements OnInit {

    private reportsMapping: ReportsMapping = new ReportsMapping();

    public fieldTypes: typeof fieldTypes = fieldTypes;

    formId: string;
    cultures: Culture[];
    loading: boolean;
    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;
    isDebug = false;

    @Input() element: ServerReportElement;
    @Input() form: UntypedFormGroup;

    @Output() outputSave = new EventEmitter<ServerReportElement>();
    @Output() outputClose = new EventEmitter();

    tables = [
        {
            name: 'Employee',
            subTables: [
                {tableName: 'Employee', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employment Record', fields: []},
            ]
        },
        {
            name: 'Position',
            subTables: [
                {tableName: 'Position', fields: []},
            ]
        },
        {
            name: 'Organization Structure',
            subTables: [
                {tableName: 'Organization Structure', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee', fields: []},
            ]
        },
        {
            name: 'Cost Centre',
            subTables: [
                {tableName: 'Cost Centre', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee', fields: []},
            ]
        },
        {
            name: 'Work Location',
            subTables: [
                {tableName: 'Work Location', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee', fields: []},
            ]
        },
        {
            name: 'Work Rotation',
            subTables: [
                {tableName: 'Work Rotation', fields: []},
                {tableName: 'Position', fields: []},
                {tableName: 'Employee', fields: []},
            ]
        },
        {
            name: 'Time Off Type',
            subTables: [
                {tableName: 'Time Off Type', fields: []},
                {tableName: 'Time Off Policy', fields: []},
                // {tableName: 'Employee', fields: []},
            ]
        }
    ]

    constructor(
        private fb: UntypedFormBuilder,
        private metadataTableService: MetadataTableService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.element = data.element
    }

    ngOnInit(): void {
        this.isDebug = false;
        this.createForm();
    }

    createForm() {
        this.form.addControl('parentTable', this.fb.control('', Validators.required));
        this.form.addControl('separateLines', this.fb.control(false, Validators.required));
        this.form.addControl('columns', this.fb.array([]));

        if (this.element) {

            let parentTable = this.reportsMapping.getParentTableFromProperties(this.element);
            this.form.controls.parentTable.setValue(parentTable);

            let separateLines = this.reportsMapping.getSeparateLinesFromProperties(this.element);
            this.form.controls.separateLines.setValue(separateLines);

            let columns = this.reportsMapping.getColumnsFromProperties(this.element)
            // console.log("columns", columns)
            columns.forEach(
                property => {
                    this.addNewColumn(property.title, property.table, property.field, property.fieldType, property.filter);
                    this.fetchTables(property.table);
                }
            );
        } else {
            this.addNewColumn()
        }

        this.form.controls.parentTable.valueChanges.subscribe(value => {
            this.columns.clear();
            this.addNewColumn();
        })
    }


    get localizations() {
        return this.form.controls["name"] as UntypedFormArray;
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    get columns() {
        return this.form.controls["columns"] as UntypedFormArray;
    }

    addNewColumn(property?: string, value?: string, field?: string, fieldType?: FieldType, filter?: string) {
        // console.log('addNewProperty', property, value, field);
        let val;

        // if the property value is supposed to be a boolean convert it from string to boolean
        (value === "true" || value === "false")
            ? val = (value === 'true')
            : val = value;

        const propertyForm = this.fb.group({
            title: [property || '', Validators.required],
            table: [val || '', Validators.required],
            field: [field || '', Validators.required],
            fieldType: [fieldType || null],
            filter: [filter || ''],
        });

        propertyForm.controls.table.valueChanges.subscribe(value => {
            console.log('property table value changed', value);
            this.fetchTables(value);
        })

        this.columns.push(propertyForm);
    }

    deleteColumn(index: number) {
        this.columns.removeAt(index);
    }

    drop(event: CdkDragDrop<any>) {
        const currentItem = this.columns.at(event.previousIndex)
        this.columns.removeAt(event.previousIndex)
        this.columns.insert(event.currentIndex, currentItem)
    }

    debugDataTimeOff() {
        // Time Off Type
        this.form.controls['parentTable'].setValue('Time Off Type');
        this.deleteColumn(0);
        this.addNewColumn('TOT Name', CustomReportTable.TimeOffType, 'Name');
        this.addNewColumn('TOP Name', CustomReportTable.TimeOffPolicy, 'Name');
        // this.addNewProperty('Emp Name', CustomReportTable.Employee, 'tfi_FirstName');
        this.fetchTables(CustomReportTable.TimeOffType);
        this.fetchTables(CustomReportTable.TimeOffPolicy);
        // this.fetchTables(CustomReportTable.Employee);
    }

    debugDataWorkRotation() {
        // Work Rotation
        this.form.controls['parentTable'].setValue('Work Rotation');
        this.deleteColumn(0);
        this.addNewColumn('WR Name', CustomReportTable.WorkRotation, 'Name');
        this.addNewColumn('Positions', CustomReportTable.Position, 'tfi_PosName');
        this.addNewColumn('Emp Names', CustomReportTable.Employee, 'firstName');
        this.addNewColumn('Emp Last names', CustomReportTable.Employee, 'lastName');
        this.fetchTables(CustomReportTable.WorkRotation);
        this.fetchTables(CustomReportTable.Position);
        this.fetchTables(CustomReportTable.Employee);

    }

    debugDataWorkLocation() {
        // Work Location
        this.form.controls['parentTable'].setValue('Work Location');
        this.deleteColumn(0);
        this.addNewColumn('WL Name', CustomReportTable.WorkLocation, 'tfi_WlName');
        this.addNewColumn('Positions', CustomReportTable.Position, 'tfi_PosName');
        this.addNewColumn('Emp Names', CustomReportTable.Employee, 'firstName');
        this.fetchTables(CustomReportTable.WorkLocation);
        this.fetchTables(CustomReportTable.Position);
        this.fetchTables(CustomReportTable.Employee);
    }


    debugDataCostCentre() {
        // Cost Centre
        this.form.controls['parentTable'].setValue('Cost Centre');
        this.deleteColumn(0);
        this.addNewColumn('CC Id', CustomReportTable.CostCentre, 'Id');
        this.addNewColumn('CC Name', CustomReportTable.CostCentre, 'Name');
        this.addNewColumn('Pos Names', CustomReportTable.Position, 'tfi_PosName');
        this.addNewColumn('Emp Names', CustomReportTable.Employee, 'firstName');
        this.fetchTables(CustomReportTable.CostCentre);
        this.fetchTables(CustomReportTable.Position);
        this.fetchTables(CustomReportTable.Employee);
    }

    debugDataOrg() {
        // Organization Structure
        this.form.controls['parentTable'].setValue('Organization Structure');
        this.deleteColumn(0);
        this.addNewColumn('Org name', CustomReportTable.OrganizationStructure, 'Name');
        // this.addNewProperty('Emp name', CustomReportTable.Employee, 'tfi_FirstName');
        this.addNewColumn('Pos', CustomReportTable.Position, 'tfi_PosName');
        this.addNewColumn('Dept', CustomReportTable.Position, 'tfi_PosDepartment');
        this.fetchTables(CustomReportTable.Employee);
        this.fetchTables(CustomReportTable.Position);
        this.fetchTables(CustomReportTable.OrganizationStructure);
    }

    debugDataEmp() {
        // Employee
        this.form.controls['parentTable'].setValue(CustomReportTable.Employee);
        this.deleteColumn(0);
        this.addNewColumn('EE ID', CustomReportTable.Employee, 'tfi_EmpUserId');
        this.addNewColumn('Client Employee Number', CustomReportTable.Employee, 'tfi_EmployeeId');
        this.addNewColumn('Name', CustomReportTable.Employee, 'tfi_FirstName');
        this.addNewColumn('Last Name', CustomReportTable.Employee, 'tfi_LastName');
        this.addNewColumn('Last Name', CustomReportTable.Employee, 'tfi_EmpCountryOfBirth');
        this.addNewColumn('Cultural Aff', CustomReportTable.Employee, 'tfi_CulturalAffiliation');
        this.addNewColumn('Region of Birth', CustomReportTable.Employee, 'tfi_EmpRegionOfBirth');
        this.addNewColumn('Equal Employment Opp', CustomReportTable.Employee, 'tfi_EmpEqualEmploymentOpportunity');
        this.addNewColumn('Veteran Status', CustomReportTable.Employee, 'tfi_EmpVeteranStatus');
        this.addNewColumn('Disability', CustomReportTable.Employee, 'tfi_EmpDisability');
        this.addNewColumn('Nationality', CustomReportTable.Employee, 'tfi_EmpNationality');
        this.fetchTables(CustomReportTable.Employee);
        this.fetchTables(CustomReportTable.Position);
        this.fetchTables(CustomReportTable.EmploymentRecord);

    }

    // TODO: move to service
    fetchTables(tableName: string) {
        let cachedTable = this.reportsMapping.parentChildTables.find(t => t.name == this.form.value.parentTable).subTables.find(s => s.tableName == tableName);

        if (cachedTable && cachedTable.fields.length == 0) {

            console.log('fetching table: ' + tableName);

            let tableId;

            switch (tableName) {
                case CustomReportTable.Employee:
                        tableId = 'tbl_Employees'
                    break
                case CustomReportTable.EmployeePosition:
                    tableId = 'tbl_PositionsEmployees'
                    // cachedTable.fields = this.reportsMapping.positionDummyMetadata
                    break
                case CustomReportTable.Position:
                    tableId = 'tbl_Positions'
                    break
                case CustomReportTable.WorkLocation:
                    tableId = 'tbl_WorkLocations'
                    break
                case CustomReportTable.EmploymentRecord:
                    tableId = 'tbl_EmploymentRecords'
                    break
                case CustomReportTable.TimeOffType:
                    cachedTable.fields = this.reportsMapping.timeOffTypeDummyMetadata
                    break
                case CustomReportTable.Manager:
                    cachedTable.fields = this.reportsMapping.managerDummyMetadata
                    break
                case CustomReportTable.TimeOffPolicy:
                    cachedTable.fields = this.reportsMapping.timeOffPolicyDummyMetadata
                    break
                case CustomReportTable.OrganizationStructure:
                    cachedTable.fields = this.reportsMapping.organizationDummyMetadata
                    break
                case CustomReportTable.CostCentre:
                    cachedTable.fields = this.reportsMapping.costCenterDummyMetadata
                    break
                case CustomReportTable.WorkRotation:
                    cachedTable.fields = this.reportsMapping.workRotationsDummyMetadata
                    break
                case CustomReportTable.TimeOffHistory:
                    tableId = 'tbl_Absences'
                    break
                case CustomReportTable.Compensation:
                    tableId = 'tbl_Compensations'
                    break
                case CustomReportTable.OtherCompensations:
                    tableId = 'tbl_OtherCompensations'
                    break
                case CustomReportTable.EmployeeDetails:
                    tableId = 'tbl_Employees'
                    break
                case CustomReportTable.EmergencyContacts:
                    tableId = 'tbl_EmergencyContacts'
                    break
                case CustomReportTable.FamilyDependants:
                    tableId = 'tbl_Family'
                    break
                case CustomReportTable.MedicalTests:
                    tableId = 'tbl_MedicalTestings'
                    break
                case CustomReportTable.VisasAndPermits:
                    tableId = 'tbl_VisaPermits'
                    break
                case CustomReportTable.Languages:
                    tableId = 'tbl_Languages'
                    break
                case CustomReportTable.TrainingAndCertification:
                    tableId = 'tbl_TrainingAndCertifications'
                    break
                case CustomReportTable.Education:
                    tableId = 'tbl_Education'
                    break
                case CustomReportTable.WorkHistory:
                    tableId = 'tbl_WorkExperiences'
                    break
                case CustomReportTable.ProfessionalExpertise:
                    tableId = 'tbl_ProfessionalExpertises'
                    break
                case CustomReportTable.Associations:
                    tableId = 'tbl_Associations'
                    break
                case CustomReportTable.CompanyAssets:
                    tableId = 'tbl_CompanyAssets'
                    break
                case CustomReportTable.Relocations:
                    tableId = 'tbl_Relocations'
                    break
                case CustomReportTable.Grievances:
                    tableId = 'tbl_Grievances'
                    break
                case CustomReportTable.InjuryIllness:
                    tableId = 'tbl_InjuryIllness'
                    break
                case CustomReportTable.BankDetails:
                    tableId = 'tbl_BankDetails'
                    break
                case CustomReportTable.PayrollDetails:
                    tableId = 'tbl_PayrollDetails'
                    break
                case CustomReportTable.Goals:
                    tableId = 'tbl_PerformanceEmployeeGoals'
                    break

                default:
                    console.error("Table not found: " + tableName);
                    break
            }

            if (tableId) {
                this.metadataTableService.getTableFields(tableId, 0, '1000')
                .subscribe(res => {

                    // Filter the specific field for "Time Off History" table: Fix to AH4-101
                    if (tableName === CustomReportTable.TimeOffHistory) {
                        res.data = res.data.filter(field => field.id !== 'tfi_AbsenceNumber');
                    }

                    cachedTable.fields = res.data
                })
            }
        }
    }

    getSubTables(parentTable: any) {
        let subTables = this.reportsMapping.parentChildTables.find(t => t.name == parentTable).subTables;
        return subTables;
    }

    copyColumn(i: number) {
        let column = this.columns.controls[i];
        this.addNewColumn(column.value.name, column.value.table, column.value.field);
    }
}
