import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable } from 'rxjs';
import { DeliveryType, DocumentType, LetterDeliveryMethod, LetterDeliveryMethodVerbose, LetterFooter, LetterFooterSubmit, LetterFooterVerbose, LetterGeneration, LetterGenerationEmployee, LetterGenerationEmployeeFromFilter, LetterGenerationEmployeeSubmit, LetterGenerationRecipient, LetterGenerationRecipientSubmit, LetterGenerationSubmit, LetterGenerationVerbose, Letterhead, LetterheadSubmit, LetterheadVerbose, LetterTemplate, LetterTemplateSubmit, LetterTemplateVerbose, PageOrientation, PaperSize, RecipientType, TemplateOrganization, TemplateOwner, TemplatePublisher, TemplateStatus, TemplateTimelineOption } from '../models/letters.model';
import { api_routes } from '@app/consts';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class LettersService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  /*
  .##.......########.########.########.########.########.....##.....##.########....###....########..########.########...######.
  .##.......##..........##.......##....##.......##.....##....##.....##.##.........##.##...##.....##.##.......##.....##.##....##
  .##.......##..........##.......##....##.......##.....##....##.....##.##........##...##..##.....##.##.......##.....##.##......
  .##.......######......##.......##....######...########.....#########.######...##.....##.##.....##.######...########...######.
  .##.......##..........##.......##....##.......##...##......##.....##.##.......#########.##.....##.##.......##...##.........##
  .##.......##..........##.......##....##.......##....##.....##.....##.##.......##.....##.##.....##.##.......##....##..##....##
  .########.########....##.......##....########.##.....##....##.....##.########.##.....##.########..########.##.....##..######.
  */

  getLetterheads(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<Letterhead>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTERHEADS}`, {
      headers: headers,
      params: params
    });
  }

  async getAllLetterheads(sort?: string, filter?: string): Promise<PagedData<Letterhead>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getLetterheads(take, skip, sort, filter).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getLetterhead(letterheadId: string ): Observable<LetterheadVerbose> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTERHEADS}/${letterheadId}`, {
      headers: headers,
      params: params
    });
  }

  createLetterhead(letterheadSubmit: LetterheadSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTERHEADS}`, letterheadSubmit, {
      headers: headers
    });
  }

  updateLetterhead(letterheadId: string, letterheadSubmit: LetterheadSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTERHEADS}/${letterheadId}`, letterheadSubmit, {
      headers: headers
    });
  }

  deleteLetterhead(letterheadId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTERHEADS}/${letterheadId}`, {
      headers: headers
    });
  }

  getLetterheadStatuses(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplateStatus>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.STATUSES}${api_routes.LETTERS}/${api_routes.LETTERHEADS}/${api_routes.STATUSES}`, {
      headers: headers,
      params: params
    });
  }

  copyLetterhead(letterheadId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTERHEADS}/${letterheadId}/Copy`, {
      headers: headers
    });
  }

  /*
  .##.......########.########.########.########.########.....########..#######...#######..########.########.########...######.
  .##.......##..........##.......##....##.......##.....##....##.......##.....##.##.....##....##....##.......##.....##.##....##
  .##.......##..........##.......##....##.......##.....##....##.......##.....##.##.....##....##....##.......##.....##.##......
  .##.......######......##.......##....######...########.....######...##.....##.##.....##....##....######...########...######.
  .##.......##..........##.......##....##.......##...##......##.......##.....##.##.....##....##....##.......##...##.........##
  .##.......##..........##.......##....##.......##....##.....##.......##.....##.##.....##....##....##.......##....##..##....##
  .########.########....##.......##....########.##.....##....##........#######...#######.....##....########.##.....##..######.
  */

  getLetterFooters(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterFooter>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}`, {
      headers: headers,
      params: params
    });
  }

  async getAllLetterFooters(sort?: string, filter?: string): Promise<PagedData<Letterhead>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getLetterFooters(take, skip, sort, filter).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getLetterFooter(letterFooterId: string ): Observable<LetterFooterVerbose> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}/${letterFooterId}`, {
      headers: headers,
      params: params
    });
  }

  createLetterFooter(letterFooterSubmit: LetterFooterSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}`, letterFooterSubmit, {
      headers: headers
    });
  }

  updateLetterFooter(letterFooterId: string, letterFooterSubmit: LetterFooterSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}/${letterFooterId}`, letterFooterSubmit, {
      headers: headers
    });
  }

  deleteLetterFooter(letterFooterId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}/${letterFooterId}`, {
      headers: headers
    });
  }

  copyLetterFooter(letterFooterId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}/${letterFooterId}/Copy`, {
      headers: headers
    });
  }

  getLetterFooterStatuses(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplateStatus>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}/${api_routes.STATUSES}`, {
      headers: headers,
      params: params
    });
  }

  /*
  .########.########.##.....##.########..##..........###....########.########
  ....##....##.......###...###.##.....##.##.........##.##......##....##......
  ....##....##.......####.####.##.....##.##........##...##.....##....##......
  ....##....######...##.###.##.########..##.......##.....##....##....######..
  ....##....##.......##.....##.##........##.......#########....##....##......
  ....##....##.......##.....##.##........##.......##.....##....##....##......
  ....##....########.##.....##.##........########.##.....##....##....########
  */

  getLetterTemplates(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterTemplate>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}`, {
      headers: headers,
      params: params
    });
  }

  getLetterTemplate(letterTemplateId: string ): Observable<LetterTemplateVerbose> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${letterTemplateId}`, {
      headers: headers,
      params: params
    });
  }

  createLetterTemplate(letterTemplateSubmit: LetterTemplateSubmit): Observable<{ id: string }> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}`, letterTemplateSubmit, {
      headers: headers
    });
  }

  updateLetterTemplate(letterTemplateId: string, letterTemplateSubmit: LetterTemplateSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${letterTemplateId}`, letterTemplateSubmit, {
      headers: headers
    });
  }

  deleteLetterTemplate(letterTemplateId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${letterTemplateId}`, {
      headers: headers
    });
  }

  copyLetterTemplate(letterTemplateId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${letterTemplateId}/Copy`, {
      headers: headers
    });
  }

  getTemplatePageOrientations(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<PageOrientation>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.PAGE_ORIENTATIONS}`, {
      headers: headers,
      params: params
    });
  }

  getTemplatePaperSizes(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<PaperSize>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.PAPER_SIZES}`, {
      headers: headers,
      params: params
    });
  }

  getTemplateRecipientTypes(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<RecipientType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.RECIPIENT_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getTemplateDocumentTypes(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<DocumentType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.DOCUMENT_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getTemplateDeliveryTypes(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<DeliveryType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.DELIVERY_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getTemplateOwners(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplateOwner>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.OWNERS}`, {
      headers: headers,
      params: params
    });
  }

  getTemplatePublishers(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplatePublisher>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.PUBLISHERS}`, {
      headers: headers,
      params: params
    });
  }

  getTemplateOrganizations(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplateOrganization>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.ORGANIZATIONS}`, {
      headers: headers,
      params: params
    });
  }

  async getAllTemplateOrganizations(sort?: string, filter?: string): Promise<PagedData<TemplateOrganization>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getTemplateOrganizations(take, skip, sort, filter).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getTemplateStatuses(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplateStatus>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.STATUSES}`, {
      headers: headers,
      params: params
    });
  }

  getTemplateTimelineOptions(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<TemplateTimelineOption>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.TIMELINE_OPTIONS}`, {
      headers: headers,
      params: params
    });
  }

  getUserPermissibleLetterTemplates(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterTemplate>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.USER_PERMISSIBLE}`, {
      headers: headers,
      params: params
    });
  }

  async getAllTemplateAvailableEmployees(templateId: string, sort?: string, filter?: string, filterInactive?: boolean): Promise<PagedData<EmployeeContactCard>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getTemplateAvailableEmployees(templateId, take, skip, sort, filter, filterInactive).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getTemplateAvailableEmployees(templateId: string, take?: number, skip?: number, sort?: string, filter?: string, filterInactive: boolean = true, asOf?: string): Observable<PagedData<EmployeeContactCard>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    params = params.append('FilterInactive', filterInactive ? 'true' : 'false');


    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${templateId}/${api_routes.AVAILABLE_EMPLOYEES}`, {
      headers: headers,
      params: params
    });
  }

  /*
  .##.......########.########.########.########.########......######...########.##....##.########.########.....###....########.####..#######..##....##..######.
  .##.......##..........##.......##....##.......##.....##....##....##..##.......###...##.##.......##.....##...##.##......##.....##..##.....##.###...##.##....##
  .##.......##..........##.......##....##.......##.....##....##........##.......####..##.##.......##.....##..##...##.....##.....##..##.....##.####..##.##......
  .##.......######......##.......##....######...########.....##...####.######...##.##.##.######...########..##.....##....##.....##..##.....##.##.##.##..######.
  .##.......##..........##.......##....##.......##...##......##....##..##.......##..####.##.......##...##...#########....##.....##..##.....##.##..####.......##
  .##.......##..........##.......##....##.......##....##.....##....##..##.......##...###.##.......##....##..##.....##....##.....##..##.....##.##...###.##....##
  .########.########....##.......##....########.##.....##.....######...########.##....##.########.##.....##.##.....##....##....####..#######..##....##..######.
  */

  getLetterGenerations(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterGeneration>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}`, {
      headers: headers,
      params: params
    });
  }

  getLetterGeneration(letterGenerationId: string ): Observable<LetterGenerationVerbose> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}`, {
      headers: headers,
      params: params
    });
  }

  createLetterGeneration(letterGenerationSubmit: LetterGenerationSubmit): Observable<{id: string}> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}`, letterGenerationSubmit, {
      headers: headers
    });
  }

  updateLetterGeneration(letterGenerationId: string, letterGenerationSubmit: LetterGenerationSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}`, letterGenerationSubmit, {
      headers: headers
    });
  }

  deleteLetterGeneration(letterGenerationId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}`, {
      headers: headers
    });
  }

  publishLetterGeneration(letterGenerationId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.PUBLISH}`, null, {
      headers: headers
    });
  }

  copyLetterGeneration(letterGenerationId: string): Observable<{ id: string }> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.COPY}`, null, {
      headers: headers
    });
  }

  getLetterGenerationEmployees(letterGenerationId: string, take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterGenerationEmployee>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.EMPLOYEES}`, {
      headers: headers,
      params: params
    });
  }

  async getAllLetterGenerationEmployees(letterGenerationId: string, sort?: string, filter?: string): Promise<PagedData<LetterGenerationEmployee>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getLetterGenerationEmployees(letterGenerationId, take, skip, sort, filter).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getLetterGenerationEmployee(letterGenerationId: string, letterGenerationEmployeeId: string ): Observable<LetterGenerationEmployee> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.EMPLOYEES}/${letterGenerationEmployeeId}`, {
      headers: headers,
      params: params
    });
  }

  createLetterGenerationEmployee(letterGenerationId: string, letterGenerationEmployeeSubmit: LetterGenerationEmployeeSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.EMPLOYEES}`, letterGenerationEmployeeSubmit, {
      headers: headers
    });
  }

  deleteLetterGenerationEmployee(letterGenerationId: string, letterGenerationEmployeeId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.EMPLOYEES}/${letterGenerationEmployeeId}`, {
      headers: headers
    });
  }

  getAllLetterGenerationEmployeeDownload(letterGenerationId: string): Observable<any> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.DOWNLOAD}`, {
      headers: headers,
      params: params,
      responseType: 'blob' as 'json'
    });
  }

  getLetterGenerationEmployeeDownload(letterGenerationId: string, letterGenerationEmployeeId: string ): Observable<any> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.EMPLOYEES}/${letterGenerationEmployeeId}/${api_routes.DOWNLOAD}`, {
      headers: headers,
      params: params,
      responseType: 'blob' as 'json'
    });
  }

  createLetterGenerationEmployeeFromFilter(letterGenerationId: string, filter: LetterGenerationEmployeeFromFilter): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.EMPLOYEES}/${api_routes.CREATE_FROM_FILTER}`, filter, {
      headers: headers
    });
  }

  getLetterGenerationRecipients(letterGenerationId: string, take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterGenerationRecipient>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.RECIPIENTS}`, {
      headers: headers,
      params: params
    });
  }

  getLetterGenerationRecipient(letterGenerationId: string ): Observable<LetterGenerationRecipient> {
    let params = new HttpParams();

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.RECIPIENTS}`, {
      headers: headers,
      params: params
    });
  }

  createLetterGenerationRecipient(letterGenerationId: string, letterGenerationRecipientSubmit: LetterGenerationRecipientSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.RECIPIENTS}`, letterGenerationRecipientSubmit, {
      headers: headers
    });
  }

  updateLetterGenerationRecipient(letterGenerationId: string, letterGenerationRecipientId: string, letterGenerationRecipientSubmit: LetterGenerationRecipientSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.RECIPIENTS}/${letterGenerationRecipientId}`, letterGenerationRecipientSubmit, {
      headers: headers
    });
  }

  deleteLetterGenerationRecipient(letterGenerationId: string, letterGenerationRecipientId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/${letterGenerationId}/${api_routes.RECIPIENTS}/${letterGenerationRecipientId}`, {
      headers: headers
    });
  }

  /*
  .##.......########.########.########.########.########.....########..########.##.......####.##.....##.########.########..##....##....##.....##.########.########.##.....##..#######..########...######.
  .##.......##..........##.......##....##.......##.....##....##.....##.##.......##........##..##.....##.##.......##.....##..##..##.....###...###.##..........##....##.....##.##.....##.##.....##.##....##
  .##.......##..........##.......##....##.......##.....##....##.....##.##.......##........##..##.....##.##.......##.....##...####......####.####.##..........##....##.....##.##.....##.##.....##.##......
  .##.......######......##.......##....######...########.....##.....##.######...##........##..##.....##.######...########.....##.......##.###.##.######......##....#########.##.....##.##.....##..######.
  .##.......##..........##.......##....##.......##...##......##.....##.##.......##........##...##...##..##.......##...##......##.......##.....##.##..........##....##.....##.##.....##.##.....##.......##
  .##.......##..........##.......##....##.......##....##.....##.....##.##.......##........##....##.##...##.......##....##.....##.......##.....##.##..........##....##.....##.##.....##.##.....##.##....##
  .########.########....##.......##....########.##.....##....########..########.########.####....###....########.##.....##....##.......##.....##.########....##....##.....##..#######..########...######.
  */

  getLetterDeliveryMethods(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<LetterDeliveryMethod>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.DELIVERY_METHODS}`, {
      headers: headers,
      params: params
    });
  }

  getLetterDeliveryMethod(letterDeliveryMethodId: string ): Observable<LetterDeliveryMethodVerbose> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.DELIVERY_METHODS}/${letterDeliveryMethodId}`, {
      headers: headers
    });
  }

  updateLetterDeliveryMethod(letterDeliveryMethodId: string, letterDeliveryMethodSubmit: LetterDeliveryMethod): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.LETTERS}/${api_routes.TEMPLATES}/${api_routes.DELIVERY_METHODS}/${letterDeliveryMethodId}`, letterDeliveryMethodSubmit, {
      headers: headers
    });
  }

}
