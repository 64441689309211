import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { defer, forkJoin } from 'rxjs';
import { LettersService } from '../../services/letters.service';
import { LetterDeliveryMethod, LetterDeliveryMethodVerbose } from '../../models/letters.model';
import { LetterDeliveryMethodDialogComponent } from './components/letter-delivery-method-dialog/letter-delivery-method-dialog.component';

@Component({
  selector: 'app-letter-delivery-methods',
  templateUrl: './letter-delivery-methods.component.html',
  styleUrls: ['./letter-delivery-methods.component.scss']
})
export class LetterDeliveryMethodsComponent implements OnInit {
  public columns: any[] = [
    { field: "name", title: "Name", tableId: "tfi_LtrHeaderName" },
    { field: "sortOrder", title: "Sort Order", tableId: "tfi_LtrHeaderDescription" },
    { field: "enabled", tableId: "tfi_LtrHeaderStatus", title: "Enabled", type: 'booleanToggle' },
  ];

  filterCategories: any[] = [
    { field: "name", title: "Name", tableId: "tfi_LtrHeaderName", type: "String", dataType: 'String' },
    { field: "sortOrder", title: "Sort Order", tableId: "tfi_LtrHeaderDescription", type: "String", dataType: 'String' },
    { field: "enabled", title: "Enabled", tableId: "tfi_RpFinalReview", dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "sortOrder", sortValue: "sortOrder"},
    { field: "enabled", sortValue: "enabled"},
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_n1AAycAETtK5ZM'
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string = 'sortOrder-asc';
  filterString: string;
  error: boolean;
  getLetterDeliveryMethodsRequest: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    public lettersService: LettersService
  ) { }

  ngOnInit(): void {
    this.getLetterDeliveryMethods();
  }

  getLetterDeliveryMethods() {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoading = true;

    this.getLetterDeliveryMethodsRequest = this.lettersService.getLetterDeliveryMethods(this.pageSize, this.skip, this.sortString, filter)
    .subscribe(
      {
        next: (v) => {
          this.gridDataResult = {
            data: v.data,
            total: v.total,
          }
        },
        error: (e) => {
          this.error = true;
          this.isLoading = false;
        },
        complete: () => this.isLoading = false
      }
    );
  }

  getLetterDeliveryMethod(id: string) {
    this.overlayService.show('Loading...');

    this.getLetterDeliveryMethodsRequest = this.lettersService.getLetterDeliveryMethod(id)
    .subscribe(
      {
        next: (v) => {
          this.openDialog(v);
        },
        error: (e) => this.overlayService.hide(),
        complete: () => this.overlayService.hide()
      }
    );
  }

  updateOrCreateLetterDeliveryMethod(letterDeliveryMethod: LetterDeliveryMethod){
    if(letterDeliveryMethod) {
      this.getLetterDeliveryMethod(letterDeliveryMethod.id);
    }
    else {
      this.openDialog();
    }
  }

  openDialog(letterDeliveryMethod?: LetterDeliveryMethodVerbose) {
    let formData = {
      id: letterDeliveryMethod?.id || null,
      name: letterDeliveryMethod?.name || null,
      sortOrder: letterDeliveryMethod?.sortOrder || null,
      enabled: letterDeliveryMethod?.enabled || null,
      parameters: letterDeliveryMethod?.parameters || null,
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.formId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(LetterDeliveryMethodDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    
    this.lettersService.updateLetterDeliveryMethod(formData.id, formData)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getLetterDeliveryMethods();
        },
        error: (e) => this.overlayService.hide(),
        complete: () => this.overlayService.hide()
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getLetterDeliveryMethods();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getLetterDeliveryMethodsRequest.unsubscribe();
    this.getLetterDeliveryMethods();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getLetterDeliveryMethods();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getLetterDeliveryMethods();
  }

}

