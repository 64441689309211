
<div *ngIf="loadingFormElements || loadingDbTableFields" class="loading-container">
    <ngx-skeleton-loader
    [count]="skeletonLoadingRows"
    [theme]="{
        'height.px': 50
    }">
    </ngx-skeleton-loader>
</div>


<form [formGroup]="form" *ngIf="form">
    <!-- <button (click)="save()">console log form data</button> -->
    <div class="form-configurator-buttons">
        <mat-icon
            setColor
            [setColorRules]="[
                {
                    color: 'primaryColour',
                    events: ['hover']
                }
            ]"
            class="material-icons-outlined clickable-icon"
            matTooltip="Configure Form"
            routerLink="{{routes.FORM}}{{routes.EDIT}}/{{formId}}"
            *appHasFeature="'Forms'"
        >settings</mat-icon>
    </div>
    <div *ngFor="let formElement of formElements">
        <ng-container *ngIf="formElement.formElementType.id === fieldTypes.CONTAINER; else showFormElement">
            <div class="form-section" *ngIf="formElement.hidden !== 'true'">
                <div class="form-section-title">
                    <h6>{{ formElement.text }}</h6>
                    <span class="help-text">{{ formElement.helpText }}</span>
                </div>
                <div class="form-section-contents">
                    <div class="section-row" [ngClass]="{'vertical-align': formElement.alignElements === 'vertical' }">
                        <ng-container [formArrayName]="formElement.formControl">
                            <ng-container *ngFor="let childFormElement of formElement.children">
                                <app-form-generator-form-field
                                    [ngClass]="{'full-width': childFormElement.formElementType.id === fieldTypes.HELP_TEXT}"
                                    [parentGroup]="form"
                                    [formElement]="childFormElement"
                                    [formTableId]="formDetails.table?.id"
                                    [currentCulture]="currentCulture"
                                    [cultures]="cultures"
                                    [readOnly]="readOnly"
                                    (emitAddtoFormArray)="addToFormArray($event, formElement)"
                                    (emitAddtoCostCenter)="addToCostCenterFormArray($event)"
                                    (emitDeleteFromFormArray)="deleteFromFormArray($event)"
                                ></app-form-generator-form-field>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #showFormElement>
            <app-form-generator-form-field
                [parentGroup]="form"
                [formElement]="formElement"
                [formTableId]="formDetails.table?.id"
                [currentCulture]="currentCulture"
                [cultures]="cultures"
                [readOnly]="readOnly"
                (emitAddtoFormArray)="addToFormArray($event, formElement)"
                (emitAddtoCostCenter)="addToCostCenterFormArray($event)"
                (emitDeleteFromFormArray)="deleteFromFormArray($event)"
            ></app-form-generator-form-field>
        </ng-template>
    </div>
</form>
