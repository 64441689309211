import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { StylingColoursService } from '@app/modules/site-settings-styling/components/styling-colours/services/styling-colours.service';

@Directive({
  selector: '[setColor]'
})
export class SetColorDirective implements OnInit, AfterViewInit {
  @Input('setColor') color: string;

  @Input('setColorRules') setColorRules: {
    color?: string
    style?: string
    setIfClass?: string[]
    events?: string[]
    target?: string
  }[] = [];

  originalStyle: any;

  constructor(
    private stylingColoursService: StylingColoursService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if(this.stylingColoursService.activeStylingColour !== undefined) {

      if(this.color !== ""){
        if(this.el.nativeElement.classList.contains('mat-stroked-button')){
          this.setMatStrokedButtonStyling();
        }
        else if(this.el.nativeElement.classList.contains('mat-raised-button')){
          this.setMatRaisedButtonStyling();
        }
        else if(this.el.nativeElement.classList.contains('mat-flat-button')){
          this.setMatRaisedButtonStyling();
        }
        else if(this.el.nativeElement.classList.contains('mat-slide-toggle')) {
          this.observeMatSlideToggleCheckedClass();
        }
        else if(this.el.nativeElement.classList.contains('mat-progress-bar')) {
          this.setMatProgressBarStyling();
        }
        else if(this.el.nativeElement.classList.contains('mat-checkbox')) {
          this.observeMatCheckboxClass();
        }
        else if(this.el.nativeElement.classList.contains('mat-list-option')) {
          this.observeMatListOptionClass();
        }
        else if(this.el.nativeElement.classList.contains('mat-radio-button')) {
          this.observeMatRadioButtonClass();
        }
        else if(this.el.nativeElement.classList.contains('mat-chip')) {
          this.setMatChipStyling();
        }
        else {
          this.setColorRegardlessOfClass();
        }
      }
      else {
        this.setColorRules.forEach(
          colorRule => {
            if(colorRule?.target === "mat-badge") {
              this.setMatBadgeStyling(colorRule);
            }
            else if(colorRule?.target === "kendo-treeview") {
              // this.setMatBadgeStyling(colorRule);
            }
            else {
              if(colorRule?.events?.includes('hover')){
                this.addHoverListeners(colorRule);
              }
              else {
                this.setColor(colorRule);
              }
            }
          }
        )
      }
    }
  }

  ngAfterViewInit() {
    if(this.stylingColoursService.activeStylingColour !== undefined) {
      this.setColorRules.forEach(
        colorRule => {
          if(colorRule?.events?.includes('routerLinkActive')){
            this.observeRouterLinkActiveClass(colorRule);
          }
          else if(colorRule.target === 'kendo-treeview') {
            this.setInitialKendoTreeviewStyling(colorRule)
            this.observeKendoTreeviewClass(colorRule)
          }
        }
      )
    }
  }

  private observeRouterLinkActiveClass(
    colorRule: {
      color?: string
      style?: string
      setIfClass?: string[]
      events?: string[]
      target?: string
    },
  ) {
    const linkElement = this.el.nativeElement;

    // Using MutationObserver to observe changes in class attribute
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          const target = mutation.target as HTMLElement;
          if (this.haveSharedItems(target.classList, colorRule?.setIfClass)) {
            // Do something when the 'this.setColorSettings?.setIfClass' class is added
            this.setColor(colorRule);
            // Call your function or execute your code here
          }
        }
      }
    });

    // Start observing the class attribute
    observer.observe(linkElement, { attributes: true });
  }

  haveSharedItems(array1: any[] | DOMTokenList, array2: any[]): boolean {
    const list1 = Array.from(array1 instanceof DOMTokenList ? array1 : array1);
    const set2 = new Set(array2);
    for (const item of list1) {
        if (set2.has(item)) {
            return true;
        }
    }
    return false;
  }

  /*
  .##.....##..#######..##.....##.########.########......######..########.##.......##....##.####.##....##..######.......######..########....###....########..########
  .##.....##.##.....##.##.....##.##.......##.....##....##....##....##....##........##..##...##..###...##.##....##.....##....##....##......##.##...##.....##....##...
  .##.....##.##.....##.##.....##.##.......##.....##....##..........##....##.........####....##..####..##.##...........##..........##.....##...##..##.....##....##...
  .#########.##.....##.##.....##.######...########......######.....##....##..........##.....##..##.##.##.##...####.....######.....##....##.....##.########.....##...
  .##.....##.##.....##..##...##..##.......##...##............##....##....##..........##.....##..##..####.##....##...........##....##....#########.##...##......##...
  .##.....##.##.....##...##.##...##.......##....##.....##....##....##....##..........##.....##..##...###.##....##.....##....##....##....##.....##.##....##.....##...
  .##.....##..#######.....###....########.##.....##.....######.....##....########....##....####.##....##..######.......######.....##....##.....##.##.....##....##...
  */

  private addHoverListeners(colorRule: {
    color?: string
    style?: string
    setIfClass?: string[]
    events?: string[]
    target?: string
  }) {
    this.renderer.listen(this.el.nativeElement, 'mouseenter', () => {
      this.setHoverStyle(colorRule);
      // this.setHoverStyle();
    });

    this.renderer.listen(this.el.nativeElement, 'mouseleave', () => {
      this.removeHoverStyle(colorRule);
      // this.removeHoverStyle();
    });
  }

  private setHoverStyle(colorRule?: {
    color?: string
    style?: string
    setIfClass?: string[]
    events?: string[]
    target?: string
  }) {
    this.setColorRegardlessOfClass(colorRule);
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
  }

  private removeHoverStyle(colorRule?: {
    color?: string
    style?: string
    setIfClass?: string[]
    events?: string[]
    target?: string
  }) {
    this.removeColorRegardlessOfClass(colorRule);
    this.renderer.removeStyle(this.el.nativeElement, 'cursor');
  }

  /*
  .##.....##..#######..##.....##.########.########......######..########.##....##.##.......####.##....##..######......########.##....##.########.
  .##.....##.##.....##.##.....##.##.......##.....##....##....##....##.....##..##..##........##..###...##.##....##.....##.......###...##.##.....##
  .##.....##.##.....##.##.....##.##.......##.....##....##..........##......####...##........##..####..##.##...........##.......####..##.##.....##
  .#########.##.....##.##.....##.######...########......######.....##.......##....##........##..##.##.##.##...####....######...##.##.##.##.....##
  .##.....##.##.....##..##...##..##.......##...##............##....##.......##....##........##..##..####.##....##.....##.......##..####.##.....##
  .##.....##.##.....##...##.##...##.......##....##.....##....##....##.......##....##........##..##...###.##....##.....##.......##...###.##.....##
  .##.....##..#######.....###....########.##.....##.....######.....##.......##....########.####.##....##..######......########.##....##.########.
  */

  /*
  .##.....##....###....########.....######..##.......####.########..########....########..#######...######....######...##.......########.....######..########.##....##.##.......####.##....##..######.......######..########....###....########..########
  .###...###...##.##......##.......##....##.##........##..##.....##.##.............##....##.....##.##....##..##....##..##.......##..........##....##....##.....##..##..##........##..###...##.##....##.....##....##....##......##.##...##.....##....##...
  .####.####..##...##.....##.......##.......##........##..##.....##.##.............##....##.....##.##........##........##.......##..........##..........##......####...##........##..####..##.##...........##..........##.....##...##..##.....##....##...
  .##.###.##.##.....##....##........######..##........##..##.....##.######.........##....##.....##.##...####.##...####.##.......######.......######.....##.......##....##........##..##.##.##.##...####.....######.....##....##.....##.########.....##...
  .##.....##.#########....##.............##.##........##..##.....##.##.............##....##.....##.##....##..##....##..##.......##................##....##.......##....##........##..##..####.##....##...........##....##....#########.##...##......##...
  .##.....##.##.....##....##.......##....##.##........##..##.....##.##.............##....##.....##.##....##..##....##..##.......##..........##....##....##.......##....##........##..##...###.##....##.....##....##....##....##.....##.##....##.....##...
  .##.....##.##.....##....##........######..########.####.########..########.......##.....#######...######....######...########.########.....######.....##.......##....########.####.##....##..######.......######.....##....##.....##.##.....##....##...
  */

  private observeMatSlideToggleCheckedClass() {
    const linkElement = this.el.nativeElement;

    // Using MutationObserver to observe changes in class attribute
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains('mat-checked')) {
            // Do something when the 'this.setColorSettings?.setIfClass' class is added
            this.setMatSlideToggleStyling();
            // Call your function or execute your code here
          }
          else {
            this.removeMatSlideToggleStyling();
          }
        }
      }
    });

    // Start observing the class attribute
    observer.observe(linkElement, { attributes: true });
  }

  setMatSlideToggleStyling() {
    const circle = this.el.nativeElement.querySelector('.mat-slide-toggle-thumb');
    const bar = this.el.nativeElement.querySelector('.mat-slide-toggle-bar');

    this.renderer.setStyle(bar, 'background-color', `${this.stylingColoursService.activeStylingColour[this.color]}8f`);
    this.renderer.setStyle(circle, 'background-color', this.stylingColoursService.activeStylingColour[this.color]);
  }

  removeMatSlideToggleStyling() {
    const circle = this.el.nativeElement.querySelector('.mat-slide-toggle-thumb');
    const bar = this.el.nativeElement.querySelector('.mat-slide-toggle-bar');

    this.renderer.setStyle(bar, 'background-color', '');
    this.renderer.setStyle(circle, 'background-color', '');
  }

  /*
  .##.....##....###....########.....######..##.......####.########..########....########..#######...######....######...##.......########.....######..########.##....##.##.......####.##....##..######......########.##....##.########.
  .###...###...##.##......##.......##....##.##........##..##.....##.##.............##....##.....##.##....##..##....##..##.......##..........##....##....##.....##..##..##........##..###...##.##....##.....##.......###...##.##.....##
  .####.####..##...##.....##.......##.......##........##..##.....##.##.............##....##.....##.##........##........##.......##..........##..........##......####...##........##..####..##.##...........##.......####..##.##.....##
  .##.###.##.##.....##....##........######..##........##..##.....##.######.........##....##.....##.##...####.##...####.##.......######.......######.....##.......##....##........##..##.##.##.##...####....######...##.##.##.##.....##
  .##.....##.#########....##.............##.##........##..##.....##.##.............##....##.....##.##....##..##....##..##.......##................##....##.......##....##........##..##..####.##....##.....##.......##..####.##.....##
  .##.....##.##.....##....##.......##....##.##........##..##.....##.##.............##....##.....##.##....##..##....##..##.......##..........##....##....##.......##....##........##..##...###.##....##.....##.......##...###.##.....##
  .##.....##.##.....##....##........######..########.####.########..########.......##.....#######...######....######...########.########.....######.....##.......##....########.####.##....##..######......########.##....##.########.
  */

  /*
  .##.....##....###....########.....######..##.....##.########..######..##....##.########...#######..##.....##.....######..########.##....##.##.......####.##....##..######.......######..########....###....########..########
  .###...###...##.##......##.......##....##.##.....##.##.......##....##.##...##..##.....##.##.....##..##...##.....##....##....##.....##..##..##........##..###...##.##....##.....##....##....##......##.##...##.....##....##...
  .####.####..##...##.....##.......##.......##.....##.##.......##.......##..##...##.....##.##.....##...##.##......##..........##......####...##........##..####..##.##...........##..........##.....##...##..##.....##....##...
  .##.###.##.##.....##....##.......##.......#########.######...##.......#####....########..##.....##....###........######.....##.......##....##........##..##.##.##.##...####.....######.....##....##.....##.########.....##...
  .##.....##.#########....##.......##.......##.....##.##.......##.......##..##...##.....##.##.....##...##.##............##....##.......##....##........##..##..####.##....##...........##....##....#########.##...##......##...
  .##.....##.##.....##....##.......##....##.##.....##.##.......##....##.##...##..##.....##.##.....##..##...##.....##....##....##.......##....##........##..##...###.##....##.....##....##....##....##.....##.##....##.....##...
  .##.....##.##.....##....##........######..##.....##.########..######..##....##.########...#######..##.....##.....######.....##.......##....########.####.##....##..######.......######.....##....##.....##.##.....##....##...
  */

  private observeMatCheckboxClass() {
    const linkElement = this.el.nativeElement;

    // Using MutationObserver to observe changes in class attribute
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains('mat-checkbox-checked')) {
            // Do something when the 'this.setColorSettings?.setIfClass' class is added
            this.setMatCheckboxStyling();
            // Call your function or execute your code here
          }
          else {
            this.removeMatCheckboxStyling();
          }
        }
      }
    });

    // Start observing the class attribute
    observer.observe(linkElement, { attributes: true });
  }

  setMatCheckboxStyling() {
    const background = this.el.nativeElement.querySelector('.mat-checkbox-background');

    this.renderer.setStyle(background, 'background-color', this.stylingColoursService.activeStylingColour[this.color]);
  }

  removeMatCheckboxStyling() {
    const background = this.el.nativeElement.querySelector('.mat-checkbox-background');

    this.renderer.setStyle(background, 'background-color', '');
  }

  /*
  .##.....##....###....########.....######..##.....##.########..######..##....##.########...#######..##.....##.....######..########.##....##.##.......####.##....##..######......########.##....##.########.
  .###...###...##.##......##.......##....##.##.....##.##.......##....##.##...##..##.....##.##.....##..##...##.....##....##....##.....##..##..##........##..###...##.##....##.....##.......###...##.##.....##
  .####.####..##...##.....##.......##.......##.....##.##.......##.......##..##...##.....##.##.....##...##.##......##..........##......####...##........##..####..##.##...........##.......####..##.##.....##
  .##.###.##.##.....##....##.......##.......#########.######...##.......#####....########..##.....##....###........######.....##.......##....##........##..##.##.##.##...####....######...##.##.##.##.....##
  .##.....##.#########....##.......##.......##.....##.##.......##.......##..##...##.....##.##.....##...##.##............##....##.......##....##........##..##..####.##....##.....##.......##..####.##.....##
  .##.....##.##.....##....##.......##....##.##.....##.##.......##....##.##...##..##.....##.##.....##..##...##.....##....##....##.......##....##........##..##...###.##....##.....##.......##...###.##.....##
  .##.....##.##.....##....##........######..##.....##.########..######..##....##.########...#######..##.....##.....######.....##.......##....########.####.##....##..######......########.##....##.########.
  */

  /*
  .......##.....##....###....########....##.......####..######..########.....#######..########..########.####..#######..##....##.....######..########.##....##.##.......####.##....##..######.......######..########....###....########..########
  .......###...###...##.##......##.......##........##..##....##....##.......##.....##.##.....##....##.....##..##.....##.###...##....##....##....##.....##..##..##........##..###...##.##....##.....##....##....##......##.##...##.....##....##...
  .......####.####..##...##.....##.......##........##..##..........##.......##.....##.##.....##....##.....##..##.....##.####..##....##..........##......####...##........##..####..##.##...........##..........##.....##...##..##.....##....##...
  .......##.###.##.##.....##....##.......##........##...######.....##.......##.....##.########.....##.....##..##.....##.##.##.##.....######.....##.......##....##........##..##.##.##.##...####.....######.....##....##.....##.########.....##...
  .......##.....##.#########....##.......##........##........##....##.......##.....##.##...........##.....##..##.....##.##..####..........##....##.......##....##........##..##..####.##....##...........##....##....#########.##...##......##...
  .......##.....##.##.....##....##.......##........##..##....##....##.......##.....##.##...........##.....##..##.....##.##...###....##....##....##.......##....##........##..##...###.##....##.....##....##....##....##.....##.##....##.....##...
  .......##.....##.##.....##....##.......########.####..######.....##........#######..##...........##....####..#######..##....##.....######.....##.......##....########.####.##....##..######.......######.....##....##.....##.##.....##....##...
  */

  private observeMatListOptionClass() {
    const linkElement = this.el.nativeElement;

    // Using MutationObserver to observe changes in class attribute
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'aria-selected') {
          const target = mutation.target as HTMLElement;
          if(target.getAttribute("aria-selected") === "true") {
            // Do something when the 'this.setColorSettings?.setIfClass' class is added
            this.setMatListOptionStyling();
            // Call your function or execute your code here
          }
          else {
            this.removeMatListOptionStyling();
          }
        }
      }
    });

    // Start observing the class attribute
    observer.observe(linkElement, { attributes: true });
  }

  setMatListOptionStyling() {
    const background = this.el.nativeElement.querySelector('.mat-pseudo-checkbox');

    this.renderer.setStyle(background, 'background', this.stylingColoursService.activeStylingColour[this.color]);
  }

  removeMatListOptionStyling() {
    const background = this.el.nativeElement.querySelector('.mat-pseudo-checkbox');

    this.renderer.setStyle(background, 'background', '');
  }

  /*
  .##.....##....###....########....##.......####..######..########.....#######..########..########.####..#######..##....##.....######..########.##....##.##.......####.##....##..######......########.##....##.########.
  .###...###...##.##......##.......##........##..##....##....##.......##.....##.##.....##....##.....##..##.....##.###...##....##....##....##.....##..##..##........##..###...##.##....##.....##.......###...##.##.....##
  .####.####..##...##.....##.......##........##..##..........##.......##.....##.##.....##....##.....##..##.....##.####..##....##..........##......####...##........##..####..##.##...........##.......####..##.##.....##
  .##.###.##.##.....##....##.......##........##...######.....##.......##.....##.########.....##.....##..##.....##.##.##.##.....######.....##.......##....##........##..##.##.##.##...####....######...##.##.##.##.....##
  .##.....##.#########....##.......##........##........##....##.......##.....##.##...........##.....##..##.....##.##..####..........##....##.......##....##........##..##..####.##....##.....##.......##..####.##.....##
  .##.....##.##.....##....##.......##........##..##....##....##.......##.....##.##...........##.....##..##.....##.##...###....##....##....##.......##....##........##..##...###.##....##.....##.......##...###.##.....##
  .##.....##.##.....##....##.......########.####..######.....##........#######..##...........##....####..#######..##....##.....######.....##.......##....########.####.##....##..######......########.##....##.########.
  */

  /*
  .##.....##....###....########....########.....###....########..####..#######.....########..##.....##.########.########..#######..##....##.....######..########....###....########..########
  .###...###...##.##......##.......##.....##...##.##...##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.###...##....##....##....##......##.##...##.....##....##...
  .####.####..##...##.....##.......##.....##..##...##..##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.####..##....##..........##.....##...##..##.....##....##...
  .##.###.##.##.....##....##.......########..##.....##.##.....##..##..##.....##....########..##.....##....##.......##....##.....##.##.##.##.....######.....##....##.....##.########.....##...
  .##.....##.#########....##.......##...##...#########.##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.##..####..........##....##....#########.##...##......##...
  .##.....##.##.....##....##.......##....##..##.....##.##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.##...###....##....##....##....##.....##.##....##.....##...
  .##.....##.##.....##....##.......##.....##.##.....##.########..####..#######.....########...#######.....##.......##.....#######..##....##.....######.....##....##.....##.##.....##....##...
  */

  private observeMatRadioButtonClass() {
    const linkElement = this.el.nativeElement;

    // Using MutationObserver to observe changes in class attribute
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'class') {
          const target = mutation.target as HTMLElement;
          if(target.classList.contains('mat-radio-checked')) {
            // Do something when the 'this.setColorSettings?.setIfClass' class is added
            this.setMatRadioButtonStyling();
            // Call your function or execute your code here
          }
          else {
            this.removeMatRadioButtonStyling();
          }
        }
      }
    });

    // Start observing the class attribute
    observer.observe(linkElement, { attributes: true });
  }

  setMatRadioButtonStyling() {
    const outerCircle = this.el.nativeElement.querySelector('.mat-radio-outer-circle');
    const innerCircle = this.el.nativeElement.querySelector('.mat-radio-inner-circle');

    this.renderer.setStyle(outerCircle, 'border-color', this.stylingColoursService.activeStylingColour[this.color]);
    this.renderer.setStyle(innerCircle, 'background-color', this.stylingColoursService.activeStylingColour[this.color]);
  }

  removeMatRadioButtonStyling() {
    const outerCircle = this.el.nativeElement.querySelector('.mat-radio-outer-circle');
    const innerCircle = this.el.nativeElement.querySelector('.mat-radio-inner-circle');

    this.renderer.setStyle(outerCircle, 'border-color', 'rgba(0, 0, 0, 0.54)');
    this.renderer.setStyle(innerCircle, 'background-color', '');
  }

  /*
  ....##.....##....###....########....########.....###....########..####..#######.....########..##.....##.########.########..#######..##....##....########.##....##.########.
  ....###...###...##.##......##.......##.....##...##.##...##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.###...##....##.......###...##.##.....##
  ....####.####..##...##.....##.......##.....##..##...##..##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.####..##....##.......####..##.##.....##
  ....##.###.##.##.....##....##.......########..##.....##.##.....##..##..##.....##....########..##.....##....##.......##....##.....##.##.##.##....######...##.##.##.##.....##
  ....##.....##.#########....##.......##...##...#########.##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.##..####....##.......##..####.##.....##
  ....##.....##.##.....##....##.......##....##..##.....##.##.....##..##..##.....##....##.....##.##.....##....##.......##....##.....##.##...###....##.......##...###.##.....##
  ....##.....##.##.....##....##.......##.....##.##.....##.########..####..#######.....########...#######.....##.......##.....#######..##....##....########.##....##.########.
  */

  /*
  .##....##.########.##....##.########...#######.....########.########..########.########.##.....##.####.########.##......##.....######..########....###....########..########
  .##...##..##.......###...##.##.....##.##.....##.......##....##.....##.##.......##.......##.....##..##..##.......##..##..##....##....##....##......##.##...##.....##....##...
  .##..##...##.......####..##.##.....##.##.....##.......##....##.....##.##.......##.......##.....##..##..##.......##..##..##....##..........##.....##...##..##.....##....##...
  .#####....######...##.##.##.##.....##.##.....##.......##....########..######...######...##.....##..##..######...##..##..##.....######.....##....##.....##.########.....##...
  .##..##...##.......##..####.##.....##.##.....##.......##....##...##...##.......##........##...##...##..##.......##..##..##..........##....##....#########.##...##......##...
  .##...##..##.......##...###.##.....##.##.....##.......##....##....##..##.......##.........##.##....##..##.......##..##..##....##....##....##....##.....##.##....##.....##...
  .##....##.########.##....##.########...#######........##....##.....##.########.########....###....####.########..###..###......######.....##....##.....##.##.....##....##...
  */

  private observeKendoTreeviewClass(colorRule?: {
    color?: string
    style?: string
    setIfClass?: string[]
    events?: string[]
    target?: string
  }) {
    const treeViewElement = this.el.nativeElement;

    // Using MutationObserver to observe changes in attributeName aria-checked
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'aria-checked') {
          const target = mutation.target as HTMLElement;
          if(target.getAttribute("aria-checked") === "true") {
            this.setKendoTreeviewStyling(target, colorRule);
          }
          else {
            this.removeKendoTreeviewStyling(target);
          }
        }
      }
    });

    // Get all li elements
    const liElements = treeViewElement.querySelectorAll('li');

    // Loop through li elements and change checkbox color
    liElements.forEach(li => {
      const checkbox = li.querySelector('input');
      if (checkbox) {
        observer.observe(li, { attributes: true });
      }
    });
  }

  private setInitialKendoTreeviewStyling(colorRule?: {
    color?: string
    style?: string
    setIfClass?: string[]
    events?: string[]
    target?: string
  }) {
    const treeViewElement = this.el.nativeElement;

    // Get all li elements
    const liElements = treeViewElement.querySelectorAll('li');

    // Loop through li elements and change checkbox color
    liElements.forEach(li => {
      const checkbox = li.querySelector('input');
      if (checkbox && li.getAttribute('aria-checked')==='true') {
        this.setKendoTreeviewStyling(li, colorRule);
      }
    });
  }

  setKendoTreeviewStyling(li, colorRule) {
    const checkbox = li.querySelector('input');

    checkbox.setAttribute(
      'style',
      `
        background-color:${this.stylingColoursService.activeStylingColour[colorRule.color]} !important;
        border-color:${this.stylingColoursService.activeStylingColour[colorRule.color]} !important;
      `
    );
  }

  removeKendoTreeviewStyling(li) {
    const checkbox = li.querySelector('input');

    checkbox.setAttribute(
      'style',
      `
        background-color: '' !important;
        border-color: rgba(0, 0, 0, 0.08); !important;
      `
    );
  }

  /*
  .##....##.########.##....##.########...#######.....########.########..########.########.##.....##.####.########.##......##....########.##....##.########.
  .##...##..##.......###...##.##.....##.##.....##.......##....##.....##.##.......##.......##.....##..##..##.......##..##..##....##.......###...##.##.....##
  .##..##...##.......####..##.##.....##.##.....##.......##....##.....##.##.......##.......##.....##..##..##.......##..##..##....##.......####..##.##.....##
  .#####....######...##.##.##.##.....##.##.....##.......##....########..######...######...##.....##..##..######...##..##..##....######...##.##.##.##.....##
  .##..##...##.......##..####.##.....##.##.....##.......##....##...##...##.......##........##...##...##..##.......##..##..##....##.......##..####.##.....##
  .##...##..##.......##...###.##.....##.##.....##.......##....##....##..##.......##.........##.##....##..##.......##..##..##....##.......##...###.##.....##
  .##....##.########.##....##.########...#######........##....##.....##.########.########....###....####.########..###..###.....########.##....##.########.
  */


  setColor(colorRule?: {
    color?: string;        // Color to set
    style?: string;        // Style property to apply the color to
    setIfClass?: string[]; // Classes to check before applying the color
    events?: string[];     // Events (not used in this function)
    target?: string;       // Target (not used in this function)
  }) {
    const nativeElement = this.el.nativeElement;

    // Check if colorRule is provided, if not, return early
    if (!colorRule) return;

    // Determine the style property to use, defaulting to 'color' if not provided
    let styleProperty = colorRule.style || 'color';

    // Check if setIfClass is provided and if the condition is not met, then return early
    if (colorRule.setIfClass && !this.haveSharedItems(nativeElement.classList, colorRule.setIfClass)) {
      return;
    }

    // If both style and color are provided, set the style property directly
    if (colorRule.style && colorRule.color) {
      nativeElement.style[styleProperty] = this.stylingColoursService.activeStylingColour[colorRule.color];
    }
    // If only color is provided, set the style attribute using setAttribute
    else if (colorRule.color) {
      nativeElement.setAttribute('style', `${styleProperty}:${this.stylingColoursService.activeStylingColour[colorRule.color]} !important`);
    }

    // Update the originalStyle property with the updated style attribute of the element
    this.originalStyle = this.el.nativeElement.getAttribute('style');
  }

  removeColor(colorRule?: {
    color?: string;
    style?: string;
    setIfClass?: string[];
    events?: string[];
    target?: string;
  }) {
    const nativeElement = this.el.nativeElement;

    if(colorRule?.style) {
      if (colorRule?.setIfClass) {
        if(this.haveSharedItems(nativeElement.classList, colorRule?.setIfClass)){
          nativeElement.style[colorRule.style] = this.originalStyle || '';
        }
      }
      else {
        nativeElement.style[colorRule.style] = this.originalStyle || '';
      }
    }
    else {
      if (colorRule?.setIfClass) {
        if(this.haveSharedItems(nativeElement.classList, colorRule?.setIfClass)){
          nativeElement.setAttribute('style', this.originalStyle || `color: ''`);
        }
      }
      else {
        nativeElement.style.color = this.originalStyle || '';
      }
    }
  }

  setColorRegardlessOfClass(colorRule?: {
    color?: string;
    style?: string;
    setIfClass?: string[];
    events?: string[];
    target?: string;
  }) {
    const nativeElement = this.el.nativeElement;

    // Check if colorRule is provided and has a style property
    if (colorRule?.style) {
      nativeElement.style[colorRule.style] = this.stylingColoursService.activeStylingColour[colorRule.color];
    }
    else {
      // If neither colorRule nor setColorSettings.style is provided, fallback to setting color directly
      nativeElement.setAttribute('style', `color:${this.stylingColoursService.activeStylingColour[colorRule?.color || this.color]} !important`);
    }
  }

  removeColorRegardlessOfClass(colorRule?: {
    color?: string;
    style?: string;
    setIfClass?: string[];
    events?: string[];
    target?: string;
  }){
    const nativeElement = this.el.nativeElement;

    if(colorRule?.style) {
      nativeElement.setAttribute('style', this.originalStyle || `${colorRule.style}: ''`);
    }
    else {
      nativeElement.setAttribute('style', this.originalStyle || `color: ''`);
    }
  }

  setMatStrokedButtonStyling() {
    const nativeElement = this.el.nativeElement;
    nativeElement.setAttribute('style',
    `color:${this.stylingColoursService.activeStylingColour[this.color]} !important;
    border-color:${this.stylingColoursService.activeStylingColour[this.color]} !important;`
    );
  }

  setMatRaisedButtonStyling() {
    const nativeElement = this.el.nativeElement;
    nativeElement.setAttribute('style',
    `background:${this.stylingColoursService.activeStylingColour[this.color]} !important;`
    );
  }

  setMatChipStyling() {
    const nativeElement = this.el.nativeElement;
    nativeElement.setAttribute('style',
    `background:${this.stylingColoursService.activeStylingColour[this.color]} !important;`
    );
  }

  setMatProgressBarStyling() {
    const nativeElement = this.el.nativeElement;
    let styleEl = document.createElement('style');
    styleEl.innerText = `
      .mat-progress-bar-fill::after {
        background-color: ${this.stylingColoursService.activeStylingColour[this.color]} !important;
      }

      .mat-progress-bar-buffer {
        background: #e3e3e3;
      }
    `;

    nativeElement.appendChild(styleEl);
  }

  setMatBadgeStyling(colorRule: {
    color?: string;
    style?: string;
    setIfClass?: string[];
    events?: string[];
    target?: string;
  }) {
    const badge = this.el.nativeElement.querySelector('.mat-badge-content');

    this.renderer.setStyle(badge, 'background', `${this.stylingColoursService.activeStylingColour[colorRule.color]}`);
  }
}
