import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-toil-balance-report',
    templateUrl: './toil-balance-report.component.html',
    styleUrls: ['./toil-balance-report.component.scss']
})
export class ToilBalanceReportComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
