<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'AddEmployees'">{{ 'AddEmployees' | translate: {Default: "Add Employees"} }}</h5>

<mat-dialog-content>
    <div class="options-container">
        <mat-card class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title appLocalizationPopupDirective [localizationCode]="'SelectEmployees'">{{ 'SelectEmployees' | translate: {Default: "SelectEmployees"} }}</mat-card-title>
                <mat-card-subtitle appLocalizationPopupDirective [localizationCode]="'SelectEmployeesDesc'">{{ 'SelectEmployeesDesc' | translate: {Default: "Select employees to add"} }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <button 
                    appLocalizationPopupDirective [localizationCode]="'SelectEmployees'"
                    mat-raised-button 
                    setColor="primaryColour" 
                    color="primary" 
                    appPreventDoubleClick 
                    (throttledClick)="selectEmployees()"
                >{{ 'SelectEmployees' | translate: {Default: "Select Employees"} }}</button>
            </mat-card-content>
        </mat-card>

        <h3>Or</h3>

        <mat-card class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title appLocalizationPopupDirective [localizationCode]="'AddAllEmployees'">{{ 'AddAllEmployees' | translate: {Default: "Add All Employees"} }}</mat-card-title>
                <mat-card-subtitle appLocalizationPopupDirective [localizationCode]="'AddAllEmployeesDesc'">{{ 'AddAllEmployeesDesc' | translate: {Default: "Add all the employees available to this letter"} }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div appLocalizationPopupDirective [localizationCode]="'IncludeInactiveEmployees'">
                    <mat-slide-toggle setColor="primaryColour" [(ngModel)]="includeInactiveEmployees">
                        {{ 'IncludeInactiveEmployees' | translate: {Default: "Include Inactive Employees"} }}
                    </mat-slide-toggle>
                </div>

                <button 
                    appLocalizationPopupDirective [localizationCode]="'AddAllEmployees'"
                    mat-raised-button 
                    setColor="primaryColour" 
                    color="primary" 
                    appPreventDoubleClick 
                    (throttledClick)="addAllEmployees()"
                >{{ 'AddAllEmployees' | translate: {Default: "Add All Employees"} }}</button>
            </mat-card-content>
        </mat-card>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>
</mat-dialog-actions>