import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PayworksIntegrationMapping } from '@app/modules/site-settings-integrations/models/integrations.model';
import { IntegrationsService } from '@app/modules/site-settings-integrations/services/integrations.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { PayworksMappingsDialogComponent } from '../payworks-mappings-dialog/payworks-mappings-dialog.component';
import { defer, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-payworks-mappings',
  templateUrl: './payworks-mappings.component.html',
  styleUrls: ['./payworks-mappings.component.scss']
})
export class PayworksMappingsComponent implements OnInit {
  @Input() public integrationId: string;

  // GRID
  public columns: any[] = [
      {
        field: 'payworksFieldId',
        title: this.translate.instant("Payworks-Field"),
        localizationCode: 'Payworks-Field',
        dataType: "String"
      },
      {
        field: 'table',
        subField: 'name',
        title: this.translate.instant("Integrations-HubbubHRTable"),
        localizationCode: 'Integrations-HubbubHRTable',
        dataType: "String"
      },
      {
        field: 'tableField',
        subField: 'name',
        title: this.translate.instant("Integrations-HubbubHRField"),
        localizationCode: 'Integrations-HubbubHRField',
        dataType: "String"
      }
  ];
  gridDataResult: GridDataResult;
  isLoading: boolean = false;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  searchFilterString: string;
  getRequest: any;
  clearSelectedItems: boolean = false;
  searchValue: string;
  sortString: string = "";

  constructor(
      private translate: TranslateService,
      private snackbarService: SnackbarService,
      private overlayService: OverlayService,
      private integrationService: IntegrationsService,
      private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
      this.getData();
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take
      this.getData();
  }

  navigateToMapping(mapping?: PayworksIntegrationMapping) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
          integrationId: this.integrationId,
          integrationMapping: mapping
      };

      const dialogRef = this.dialog.open(PayworksMappingsDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
              if (data === true) {
                  this.getData();
              }
          }
      );
  }


  deleteAllSelected(itemsToDelete: string[]) {
      const observables = itemsToDelete.map(selectedItem => defer(() => this.integrationService.deletePayworksMapping(this.integrationId, selectedItem)));

      this.overlayService.show();

      forkJoin(observables)
      .pipe(
          finalize(() => this.overlayService.hide())
      )
      .subscribe(
          (res) => {
              this.clearSelectedItems = !this.clearSelectedItems;
              this.getData();
              this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
          }
      );
  }

  getData() {
      this.isLoading = true;

      this.integrationService.getPayworksMappings(this.integrationId, this.skip, this.pageSize)
      .pipe(
          finalize(() => {
              this.isLoading = false;
          })
      )
      .subscribe(res => {
          this.gridDataResult = {
              data: res.data,
              total: res.total,
          };
      })
  }

}
