import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { LetterFooterVerbose, LetterGenerationVerbose, LetterheadVerbose, LetterTemplateVerbose, RecipientType, TemplateOrganization } from '@app/modules/letters/models/letters.model';
import { LettersService } from '@app/modules/letters/services/letters.service';
import { RowActionButtonInput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { PreviewTemplateDialogComponent } from '../../../letter-templates/components/preview-template-dialog/preview-template-dialog.component';
import * as moment from 'moment';
import { finalize, forkJoin, from, Observable } from 'rxjs';
import { Version } from '@app/shared/models/version.model';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import * as fileSaver from 'file-saver';
import { LetterGenerationRecipientDialogComponent } from './components/letter-generation-recipient-dialog/letter-generation-recipient-dialog.component';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { LetterGenerationEmployeeDialogComponent } from './components/letter-generation-employee-dialog/letter-generation-employee-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-letter-generation-editor',
  templateUrl: './letter-generation-editor.component.html',
  styleUrls: ['./letter-generation-editor.component.scss']
})
export class LetterGenerationEditorComponent implements OnInit {
  public api_routes = api_routes;
  public db_tables = db_tables;
  
  loadingGeneration: boolean;
  letterGeneration: LetterGenerationVerbose = null;
  error: boolean;
  step1Valid: boolean = true;

  // Letter templates
  public letterTemplatesColumns: any[] = [
    { field: "name", title: "Name", tableId: "tfi_LtrHeaderName" },
    { field: "description", title: "description", tableId: "tfi_LtrHeaderDescription", type: "longText", longTextLimit: 200 },
    { field: "status", subField: "name", tableId: "tfi_LtrHeaderStatus", title: "Status", type: 'chip', chipValue: ['Active', 'Archived', 'Draft'] },
  ];

  letterTemplatesFilterCategories: any[] = [
    { field: "name", title: "Name", tableId: "tfi_LtrHeaderName", type: "String", dataType: 'String' },
    { field: "description", title: "Description", tableId: "tfi_LtrHeaderDescription", type: "String", dataType: 'String' },
  ];

  letterTemplatesSortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description"},
    { field: "status", sortValue: "status.name"},
  ];

  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'preview',
      name: this.translate.instant('Preview'),
      icon: 'pageview'
    }
  ];

  public bindingType: String = "array";
  public letterTemplatesGridDataResult: GridDataResult;
  selectedTemplates: any[] = [];
  pageSize: number = 10;
  skip: number = 0;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string;
  filterString: string;
  isLoadingLetterTemplates: boolean = true;
  getLetterTemplatesRequest: any;
  headerFooterFormData: { header: any; footer: any; } = {
    header: null,
    footer: null
  };
  headerFooterFormPristine: boolean;
  employeeListSearchValue: string = '';
  recipientsListSearchValue: string = '';


  loadingEmployees: boolean;
  deliveryDetailsFormData: { fileName: string; paperSize: string; orientation: string; documentType: string; deliveryMethod: string; } = null;
  headerFooterFormValid: any;
  employeeFormValid: any;
  deliveryDetailsFormValid: any;
  employeeFormPristine: boolean;
  deliveryDetailsFormPristine: boolean;
  loadingLetterTemplate: boolean = false;
  letterTemplate: LetterTemplateVerbose;
  letterHead: LetterheadVerbose;
  letterFooter: LetterFooterVerbose;
  employees: { 
    status: {
      id: string,
      text: string
    }; 
    employee: any; 
    letterGeneration: { id: string; };
    letterGenerated: boolean; 
    version: Version; 
  }[] = [];
  recipients: {
    id: string
    status: {
      id: string,
      text: string
    };  
    letterGeneration: {
        id: string
    }
    user: any
    recipientType: {
        id: string
        name: string
    }
    version: Version
  }[] = [];
  loadingRecipients: boolean;
  selectEmployeesDialogRef: MatDialogRef<SelectEmployeesDialogComponent>;
  recipientTypes: RecipientType[] = [];
  emailDetailsFormData: { emailSubject: string; emailBody: string; } = null;
  emailDetailsFormValid: any;
  emailDetailsFormPristine: boolean;
  templateOrganizations: TemplateOrganization[];

  get recipientsNotBeingRemoved() {
    return this.recipients.filter(recipient => recipient.status?.id !== 'remove');
  }

  get isStep3Valid() {
    if(this.deliveryDetailsFormData?.deliveryMethod === 'Email') {
      return this.deliveryDetailsFormValid && this.emailDetailsFormValid;
    }
    else {
      return this.deliveryDetailsFormValid;
    }
  }

  constructor(
    private ngZone: NgZone,
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    public lettersService: LettersService,
    private employeeContactCardService: EmployeeContactCardService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if(params['letterGenerationId']) {
        this.getLetterGeneration(params['letterGenerationId']);
      }
      else {
        this.loadingGeneration = false;

        this.buildDeliveryDetailsFormData();
        this.getLetterTemplates();
      }
    });

    this.getRecipientTypes();
  }

  getLetterGeneration(id: string) {
    this.loadingGeneration = true;

    this.lettersService.getLetterGeneration(id)
    .subscribe(
      {
        next: (v) => {
          this.letterGeneration = v;
          this.buildDeliveryDetailsFormData();
          this.buildStep2FormData();
          this.getLetterTemplates();
          this.getEmployees();
          this.getRecipients();
          this.getTemplateOrganizations();
        },
        error: (e) => {
          this.loadingGeneration = false
          this.error = true
        },
        complete: () => this.loadingGeneration = false
      }
    );
  }

  backToLetterGenerations() {
    this.router.navigate([`/Letters/LettersUserSection/Generations`]);
  }

  stepChange(event: StepperSelectionEvent) {
    if(event.selectedIndex === 3 && this.selectedTemplates.length > 0) {
      this.getLetterTemplate(this.selectedTemplates[0].id);
    }
  }

  /*
  .##.......########.########.########.########.########.....########.########.##.....##.########..##..........###....########.########..######.
  .##.......##..........##.......##....##.......##.....##.......##....##.......###...###.##.....##.##.........##.##......##....##.......##....##
  .##.......##..........##.......##....##.......##.....##.......##....##.......####.####.##.....##.##........##...##.....##....##.......##......
  .##.......######......##.......##....######...########........##....######...##.###.##.########..##.......##.....##....##....######....######.
  .##.......##..........##.......##....##.......##...##.........##....##.......##.....##.##........##.......#########....##....##.............##
  .##.......##..........##.......##....##.......##....##........##....##.......##.....##.##........##.......##.....##....##....##.......##....##
  .########.########....##.......##....########.##.....##.......##....########.##.....##.##........########.##.....##....##....########..######.
  */

  getLetterTemplates() {
    let filter;

    // If we are creating a new letter generation, we need to filter the templates by active status
    if(this.letterGeneration === null || this.letterGeneration?.template === null) {
      filter = `(Status.Id = "Active")`;
    }
    // If we are editing an existing letter generation, we need to filter the templates by the template of the letter generation
    else {
      filter = `(id = "${this.letterGeneration.template.id}")`;
    }

    if(this.searchFilterString) {
      if(filter){
        filter = `${filter} AND ${this.searchFilterString}`;
      }
      else {
        filter = this.searchFilterString;
      }
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.isLoadingLetterTemplates = true;

    this.getLetterTemplatesRequest = this.lettersService.getUserPermissibleLetterTemplates(this.pageSize, this.skip, this.sortString, filter)
    .subscribe(
      {
        next: (v) => {
          this.letterTemplatesGridDataResult = {
            data: v.data,
            total: v.total,
          }

          if(this.letterGeneration?.template !== null) {
            this.selectedTemplates = this.letterTemplatesGridDataResult.data.filter(template => template.id === this.letterGeneration?.template?.id);
          }

          if(this.letterGeneration?.publishedOn !== null && this.selectedTemplates.length > 0){
            this.getLetterTemplate(this.selectedTemplates[0]?.id);
          }
        },
        error: (e) => {
          this.isLoadingLetterTemplates = false
          this.error = true
        },
        complete: () => this.isLoadingLetterTemplates = false
      }
    );
  }

  getLetterTemplate(id: string) {
    this.loadingLetterTemplate = true;

    this.lettersService.getLetterTemplate(id)
    .subscribe(
      {
        next: (v) => {
          this.letterTemplate = v;
        },
        error: (e) => {
          this.loadingLetterTemplate = false
        },
        complete: () => {
          this.loadingLetterTemplate = false;

          if(this.headerFooterFormData.header !== null && this.headerFooterFormData.header !== undefined) {
            this.getLetterHeader(this.headerFooterFormData.header);
          }
          else if(this.letterTemplate.letterHead !== null) {
            this.getLetterHeader(this.letterTemplate.letterHead?.id);
          }

          if(this.headerFooterFormData.footer !== null && this.headerFooterFormData.footer !== undefined) {
            this.getLetterFooter(this.headerFooterFormData.footer);
          }
          else if(this.letterTemplate.footer !== null){
            this.getLetterFooter(this.letterTemplate.footer.id);
          }

        }
      }
    );
  }

  getLetterHeader(id: string) {
    this.lettersService.getLetterhead(id)
    .subscribe(
      {
        next: (v) => {
          this.letterHead = v;
        },
        error: (e) => {
          this.error = true
        }
      }
    );
  }

  getLetterFooter(id: string) {
    this.lettersService.getLetterFooter(id)
    .subscribe(
      {
        next: (v) => {
          this.letterFooter = v;
        },
        error: (e) => {
          this.error = true
        }
      }
    );
  }

  selectedRows(event: any) {
    this.selectedTemplates = event.selectedRows.map(row => row.dataItem);
    this.employees = [];
    this.getTemplateOrganizations();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getLetterTemplates();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getLetterTemplatesRequest.unsubscribe();
    this.getLetterTemplates();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getLetterTemplates();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getLetterTemplates();
  }

  previewTemplate(event) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: "Preview-Template",
      templateId: event.rowData?.id
    };

    const dialogRef = this.dialog.open(PreviewTemplateDialogComponent, dialogConfig);
  }

  buildStep2FormData() {
    this.headerFooterFormData = {
      header: this.letterGeneration?.header?.id,
      footer: this.letterGeneration?.footer?.id,
    }
  }

  formDataEmitted(formData, step: string) {
    if(step === 'headerFooter') this.headerFooterFormData = formData;
    else if(step === 'deliveryDetails') {
      this.deliveryDetailsFormData = formData;
    }
    else if(step === 'emailDetails') this.emailDetailsFormData = formData;
  }

  formStatusUpdated(formValid, step: string) {
    if(step === 'headerFooter') this.headerFooterFormValid = formValid;
    else if(step === 'employee') this.employeeFormValid = formValid;
    else if(step === 'deliveryDetails') this.deliveryDetailsFormValid = formValid;
    else if(step === 'emailDetails') this.emailDetailsFormValid = formValid;
  }

  formPristineEmitted(formPristine: boolean, step: string) {
    if(step === 'headerFooter') this.headerFooterFormPristine = formPristine;
    else if(step === 'employee') this.employeeFormPristine = formPristine;
    else if(step === 'deliveryDetails') this.deliveryDetailsFormPristine = formPristine;
    else if(step === 'emailDetails') this.emailDetailsFormPristine = formPristine;
  }

  getTemplateOrganizations() {
    let filter;
    if(this.selectedTemplates.length === 0){
      filter = `(template.id = "${this.letterGeneration?.template?.id}")`
    }
    else {
      filter = `(template.id = "${this.selectedTemplates[0].id}")`
    }

    from(this.lettersService.getAllTemplateOrganizations(null, filter))
    .subscribe(
      {
        next: (v) => {
          this.templateOrganizations = v.data;
        },
        error: (e) => {
          this.error = true
        }
      }
    );
  }

/*
....########..########.##.......####.##.....##.########.########..##....##....########..########.########....###....####.##........######.
....##.....##.##.......##........##..##.....##.##.......##.....##..##..##.....##.....##.##..........##......##.##....##..##.......##....##
....##.....##.##.......##........##..##.....##.##.......##.....##...####......##.....##.##..........##.....##...##...##..##.......##......
....##.....##.######...##........##..##.....##.######...########.....##.......##.....##.######......##....##.....##..##..##........######.
....##.....##.##.......##........##...##...##..##.......##...##......##.......##.....##.##..........##....#########..##..##.............##
....##.....##.##.......##........##....##.##...##.......##....##.....##.......##.....##.##..........##....##.....##..##..##.......##....##
....########..########.########.####....###....########.##.....##....##.......########..########....##....##.....##.####.########..######.
*/

  buildDeliveryDetailsFormData() {
    this.deliveryDetailsFormData = {
      fileName: this.letterGeneration?.fileName,
      paperSize: this.letterGeneration?.paperSize?.id,
      orientation: this.letterGeneration?.orientation?.id,
      documentType: this.letterGeneration?.documentType?.id,
      deliveryMethod: this.letterGeneration?.deliveryMethod?.id
    }
    
    this.emailDetailsFormData = {
      emailSubject: this.letterGeneration?.emailSubject,
      emailBody: this.letterGeneration?.emailBody,
    }
  }

  /*
  ..######.....###....##.....##.########
  .##....##...##.##...##.....##.##......
  .##........##...##..##.....##.##......
  ..######..##.....##.##.....##.######..
  .......##.#########..##...##..##......
  .##....##.##.....##...##.##...##......
  ..######..##.....##....###....########
  */
  
  save(type?: string) {
    if(this.letterGeneration) {
      if(type === 'publish') {
        this.openConfirmPublishDialog();
      }
      else {
        this.updateLetterGeneration(type);
      }
    }
    else {
      this.createLetterGeneration(type);
    }
  }

  openConfirmPublishDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      localizationCode: 'ConfirmPublishLetterGeneration',
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.updateLetterGeneration('publish');
        }
      }
    );
  }

  updateLetterGeneration(type?: string) {
    this.overlayService.show();

    this.lettersService.updateLetterGeneration(this.letterGeneration.id, {
      id: this.letterGeneration.id,
      template: this.letterTemplate.id,
      header: this.letterHead?.id,
      footer: this.letterFooter?.id,
      fileName: this.deliveryDetailsFormData.fileName,
      emailSubject: this.emailDetailsFormData.emailSubject,
      emailBody: this.emailDetailsFormData.emailBody,
      paperSize: this.deliveryDetailsFormData.paperSize,
      orientation: this.deliveryDetailsFormData.orientation,
      documentType: this.deliveryDetailsFormData.documentType,
      deliveryMethod: this.deliveryDetailsFormData.deliveryMethod,
      employees: this.employees.map(employee => employee.employee.id),
      changeReason: null,
      changeReasonComments: null,
      originalCreatedOn: this.letterGeneration.version.createdOn,
      asOf: moment().format('YYYY-MM-DD')
    })
    .subscribe(
      {
        next: (v) => {

          if(this.recipients.length > 0){
            // Once the letter generation is updated, we need to update the employees and recipients
            forkJoin([...this.crudRecipients(this.letterGeneration.id)])
            .subscribe(
              {
                next: (v) => {
  
                },
                error: (e) => {
                  this.overlayService.hide();
                },
                complete: () => {
                  this.overlayService.hide();
                  this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
  
                  if(type === 'publish') {
                    this.publish();
                  }
                  else {
                    this.backToLetterGenerations();
                  }
                }
              }
            );
          }
          else {
            this.overlayService.hide();
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
  
            if(type === 'publish') {
              this.publish();
            }
            else {
              this.backToLetterGenerations();
            }
          }

        },
        error: (e) => {
          this.overlayService.hide();
        }
      }
    );
  }

  createLetterGeneration(type?: string) {
    this.overlayService.show();

    this.lettersService.createLetterGeneration({
      template: this.letterTemplate.id,
      header: this.letterHead?.id,
      footer: this.letterFooter?.id,
      fileName: this.deliveryDetailsFormData.fileName,
      emailSubject: this.emailDetailsFormData.emailSubject,
      emailBody: this.emailDetailsFormData.emailBody,
      paperSize: this.deliveryDetailsFormData.paperSize,
      orientation: this.deliveryDetailsFormData.orientation,
      documentType: this.deliveryDetailsFormData.documentType,
      deliveryMethod: this.deliveryDetailsFormData.deliveryMethod,
      employees: this.employees.map(employee => employee.employee.id),
      changeReason: null,
      changeReasonComments: null,
      originalCreatedOn: null,
      asOf: moment().format('YYYY-MM-DD')
    })
    .subscribe(
      {
        next: (v) => {
          let letterGenerationId = v.id;

          if(this.recipients.length > 0) {
            // Once the letter generation is created, we need to create the employees and recipients
            forkJoin([...this.crudRecipients(letterGenerationId)])
            .subscribe(
              {
                next: (v) => {
                  if(type === 'publish') {
                    this.overlayService.hide();
                    this.publish(letterGenerationId);
                  }
                },
                error: (e) => {
                  this.overlayService.hide();
                },
                complete: () => {
                  this.overlayService.hide();
                  this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                  if(type !== 'publish') {
                    this.backToLetterGenerations();
                  }
                }
              }
            );
          }
          else {
            this.overlayService.hide();
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            if(type !== 'publish') {
              this.backToLetterGenerations();
            }
            else {
              this.publish(letterGenerationId);
            }
          }
        },
        error: (e) => {
          this.overlayService.hide();
        }
      }
    );
  }

  /*
  .########.##.....##.########..##........#######..##....##.########.########..######......######..########..##.....##.########.
  .##.......###...###.##.....##.##.......##.....##..##..##..##.......##.......##....##....##....##.##.....##.##.....##.##.....##
  .##.......####.####.##.....##.##.......##.....##...####...##.......##.......##..........##.......##.....##.##.....##.##.....##
  .######...##.###.##.########..##.......##.....##....##....######...######....######.....##.......########..##.....##.##.....##
  .##.......##.....##.##........##.......##.....##....##....##.......##.............##....##.......##...##...##.....##.##.....##
  .##.......##.....##.##........##.......##.....##....##....##.......##.......##....##....##....##.##....##..##.....##.##.....##
  .########.##.....##.##........########..#######.....##....########.########..######......######..##.....##..#######..########.
  */
  openLetterGenerationEmployeeDialogComponent() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(LetterGenerationEmployeeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if(result.action === 'openEmployeeDialog') {
        this.showAddUserModal();
      }
      else if(result.action === 'addAllEmployees') {
        this.addAllLetterTemplateAllowedEmployees(
          this.selectedTemplates.length === 0 ? this.letterGeneration?.template?.id : this.selectedTemplates[0].id, 
          !result.includeInactiveEmployees
        );
        // this.addAllEmployees(result.includeInactiveEmployees);
      }
    });
  }

  addAllLetterTemplateAllowedEmployees(templateId: string, filterInactive: boolean) {
    this.overlayService.show("Fetching Employees...");
    
    from(this.lettersService.getAllTemplateAvailableEmployees(templateId, null, null, filterInactive))
    .pipe(
        finalize(()=>{ this.overlayService.hide(); })
    )
    .subscribe(
      res => {
        this.employees = res.data.map(employee => {
          return {
            status: {
              id: 'draft',
              text: this.translate.instant('Draft')
            }, 
            employee: employee,
            letterGeneration: { id: this.letterGeneration?.id },
            letterGenerated: false, 
            version: null 
          }
        });
      }
    )
  }

  showAddUserModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableEmployees: this.employees.map( employee => employee?.employee?.id ),
      employeeValueToSelect: 'employee',
      templateId: this.selectedTemplates.length === 0 ? this.letterGeneration?.template?.id : this.selectedTemplates[0].id,
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
    });

    const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((selectedEmployees) => {

      selectedEmployees.forEach(employee => {
        this.employees.push(
          {
            status: {
              id: 'draft',
              text: this.translate.instant('Draft')
            }, 
            employee: employee,
            letterGeneration: { id: this.letterGeneration?.id },
            letterGenerated: false, 
            version: null 
          }
        );
        this.employees = [...this.employees]; // Trigger change detection
      })
      this.selectEmployeesDialogRef.close();
    });

    this.selectEmployeesDialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  getEmployees() {
    this.loadingEmployees = true;

    from(this.lettersService.getAllLetterGenerationEmployees(this.letterGeneration.id, `Employee.Lastname-asc~Employee.Firstname-asc`))
    .subscribe(
      {
        next: (v) => {
          this.employees = v.data.map(employee => {
            return {
              ...employee,
              status: {
                id: 'current',
                text: this.translate.instant('Current')
              }
            };
          });
        },
        error: (e) => {
          this.loadingEmployees = false
          this.error = true
        },
        complete: () => {
          this.loadingEmployees = false
        }
      }
    );
  }

  removeEmployee(employee: any) {
    this.employees = this.employees.filter(e => e.employee.id !== employee.employee.id);
  }

  /*
  .......########..########..######..####.########..####.########.##....##.########..######......######..########..##.....##.########.
  .......##.....##.##.......##....##..##..##.....##..##..##.......###...##....##....##....##....##....##.##.....##.##.....##.##.....##
  .......##.....##.##.......##........##..##.....##..##..##.......####..##....##....##..........##.......##.....##.##.....##.##.....##
  .......########..######...##........##..########...##..######...##.##.##....##.....######.....##.......########..##.....##.##.....##
  .......##...##...##.......##........##..##.........##..##.......##..####....##..........##....##.......##...##...##.....##.##.....##
  .......##....##..##.......##....##..##..##.........##..##.......##...###....##....##....##....##....##.##....##..##.....##.##.....##
  .......##.....##.########..######..####.##........####.########.##....##....##.....######......######..##.....##..#######..########.
  */
  getRecipientTypes() {
    this.lettersService.getTemplateRecipientTypes(100, 0)
    .subscribe(
      {
        next: (v) => {
          this.recipientTypes = v.data;
        },
        error: (e) => {
          this.error = true
        }
      }
    );
  }

  getRecipientTypeName(recipientTypeId: string) {
    return this.recipientTypes.find(recipientType => recipientType.id === recipientTypeId)?.name;
  } 

  recipientTypeChange(event, recipient) {
    recipient.recipientType = this.recipientTypes.find(recipientType => recipientType.id === event.value);
    recipient.status = {
      id: 'draft',
      text: this.translate.instant('Draft')
    };
  }

  getRecipients() {
    this.loadingRecipients = true;

    this.lettersService.getLetterGenerationRecipients(this.letterGeneration.id)
    .subscribe(
      {
        next: (v) => {
          this.recipients = v.data.map(recipient => {
            return {
              ...recipient,
              status: {
                id: 'current',
                text: this.translate.instant('Current')
              }
            };
          });
        },
        error: (e) => {
          this.loadingRecipients = false
          this.error = true
        },
        complete: () => {
          this.loadingRecipients = false
        }
      }
    );
  }

  removeRecipient(recipient: any) {
    recipient.status = {
      id: 'remove',
      text: this.translate.instant('Remove')
    };
  }

  openRecipientDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: "Select-Recipient",
      recipientTypes: this.recipientTypes,
      selectedRecipients: this.recipients
    };

    const dialogRef = this.dialog.open(LetterGenerationRecipientDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if(data){
          console.log(data);

          if(data.recipientType?.id === 'SpecifiedUser' && data.recipient !== undefined){
            this.employeeContactCardService.getEmployeeContactCard(data.recipient)
            .subscribe(
              {
                next: (v) => {
                  this.recipients.push(
                    {
                      id: null,
                      status: {
                        id: 'draft',
                        text: this.translate.instant('Draft')
                      },
                      letterGeneration: {
                          id: this.letterGeneration?.id
                      },
                      user: {
                        id: v.id,
                        firstName: v.firstname,
                        lastName: v.lastname
                      },
                      recipientType: data.recipientType,
                      version: null
                    }
                  )
                },
                error: (e) => {
                  this.loadingRecipients = false
                  this.error = true
                },
                complete: () => {
                  this.loadingRecipients = false
                }
              }
            )
          }
          else {
            this.recipients.push(
              {
                id: null,
                status: {
                  id: 'draft',
                  text: this.translate.instant('Draft')
                },
                letterGeneration: {
                    id: this.letterGeneration?.id
                },
                user: null,
                recipientType: data.recipientType,
                version: null
              }
            )
          }
        }
      }
    ); 

  }

  crudRecipients(letterGenerationId: string): Observable<any>[] {
    let observables: Observable<any>[] = [];
    
    this.recipients.forEach(
      recipient => {
        if(recipient.status?.id === 'draft' && recipient.id === null){
          observables.push(this.lettersService.createLetterGenerationRecipient(
            letterGenerationId, 
            { 
              specifiedUser: recipient?.user?.id, 
              letterGeneration: letterGenerationId,
              recipientType: recipient.recipientType?.id,
              changeReason: null,
              changeReasonComments: null
            }
          ));
        }
        else if(recipient.status?.id === 'draft' && recipient.id !== null){
          observables.push(this.lettersService.updateLetterGenerationRecipient(
            letterGenerationId,
            recipient.id,
            { 
              specifiedUser: recipient?.user?.id, 
              letterGeneration: letterGenerationId,
              recipientType: recipient.recipientType?.id,
              changeReason: null,
              changeReasonComments: null
            }
          ));
        }
        else if(recipient.status?.id === 'remove' && recipient.id !== null){
          observables.push(this.lettersService.deleteLetterGenerationRecipient(letterGenerationId, recipient.id));
        }
      }
    )

    return observables;
  }

  /*
  .########..##.....##.########..##.......####..######..##.....##.####.##....##..######..
  .##.....##.##.....##.##.....##.##........##..##....##.##.....##..##..###...##.##....##.
  .##.....##.##.....##.##.....##.##........##..##.......##.....##..##..####..##.##.......
  .########..##.....##.########..##........##...######..#########..##..##.##.##.##...####
  .##........##.....##.##.....##.##........##........##.##.....##..##..##..####.##....##.
  .##........##.....##.##.....##.##........##..##....##.##.....##..##..##...###.##....##.
  .##.........#######..########..########.####..######..##.....##.####.##....##..######..
  */

  publish(letterGenerationId?: string) {
    this.overlayService.show('Publishing');

    this.lettersService.publishLetterGeneration(letterGenerationId ? letterGenerationId : this.letterGeneration.id)
    .subscribe(
      {
        next: (v) => {
          this.backToLetterGenerations();
            this.snackbarService.openSnackBar(`${this.translate.instant('PublishRequestSentSuccessfully')}`, 'clear', 'success');
        },
        error: (e) => {
          this.overlayService.hide();
        },
        complete: () => {
          this.overlayService.hide();
        }
      }
    );
  }

  /*
  .########...#######..##......##.##....##.##........#######.....###....########.....##.......########.########.########.########.########.
  .##.....##.##.....##.##..##..##.###...##.##.......##.....##...##.##...##.....##....##.......##..........##.......##....##.......##.....##
  .##.....##.##.....##.##..##..##.####..##.##.......##.....##..##...##..##.....##....##.......##..........##.......##....##.......##.....##
  .##.....##.##.....##.##..##..##.##.##.##.##.......##.....##.##.....##.##.....##....##.......######......##.......##....######...########.
  .##.....##.##.....##.##..##..##.##..####.##.......##.....##.#########.##.....##....##.......##..........##.......##....##.......##...##..
  .##.....##.##.....##.##..##..##.##...###.##.......##.....##.##.....##.##.....##....##.......##..........##.......##....##.......##....##.
  .########...#######...###..###..##....##.########..#######..##.....##.########.....########.########....##.......##....########.##.....##
  */
  downloadAllLetters() {
    this.overlayService.show('Downloading');

    this.lettersService.getAllLetterGenerationEmployeeDownload(this.letterGeneration.id)
    .subscribe(
      {
        next: (v) => {
          this.overlayService.hide();
          let blob:any = new Blob([v], { type: 'application/zip' });
          fileSaver.saveAs(blob, this.letterGeneration.fileName);
          this.snackbarService.openSnackBar(`${this.translate.instant('DownloadedSuccessfully')}`, 'clear', 'success');
        },
        error: (e) => {
          this.overlayService.hide();
        }
      }
    );
  }

  downloadLetter(employeeId: string) {
    let fileTypes = {
      Pdf: 'application/pdf',
      Word: 'application/msword'
    }
    this.overlayService.show('Downloading');

    this.lettersService.getLetterGenerationEmployeeDownload(this.letterGeneration.id, employeeId)
    .subscribe(
      {
        next: (v) => {
          this.overlayService.hide();
          let blob:any = new Blob([v], { type: fileTypes[this.letterGeneration.documentType.id] });
          fileSaver.saveAs(blob, this.letterGeneration.fileName);
          this.snackbarService.openSnackBar(`${this.translate.instant('DownloadedSuccessfully')}`, 'clear', 'success');
        },
        error: (e) => {
          this.overlayService.hide();
        }
      }
    );
  }
}
