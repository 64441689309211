import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";
import { IntegrationSettings } from "@app/modules/site-settings-integrations/models/integrations.model";

@Component({
    selector: 'app-integration-editor',
    templateUrl: './integration-editor.component.html',
    styleUrls: ['./integration-editor.component.scss']
})
export class IntegrationEditorComponent implements OnInit {

    public integration: string;
    public allCultures: Culture[] = [];
    public integrationSettings: IntegrationSettings = null;
    public gridDataResult: GridDataResult = {
        data: [],
        total: 0,
    };
    public pageSize: number = 20;
    public skip: number = 0;

    // FORM
    formId: string = 'frm_kOhKZciaDnFl5r';
    formData: any;
    getFormData: boolean = false;
    formValid: boolean = false;
    formPristine: boolean = true;
    formLoaded: boolean = false;

    constructor(
        private translate: TranslateService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private integrationService: IntegrationsService,
    ) {
    }

    ngOnInit(): void {
        this.cultureService.getCultures().subscribe(res => {
            this.allCultures = res;
        });

        this.integration = this.getIdInURL();

        this.integration !== null
            ? this.getIntegration()
            : this.buildFormData();
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('id')
        );

        return IdInURL;
    }

    getIntegration() {
        // this.isLoading = true;
        this.integrationService.getIntegration(this.integration)
        .subscribe(
            res => {
                this.integrationSettings = res;
                this.buildFormData();
            },
            err => {
                this.snackbarService.openSnackBar(this.translate.instant('Integrations-ErrorLoading'), 'clear', 'warn');
                // this.isLoading = false;
            }
        );
    }

    buildFormData() {
        this.formData = {
            description: this.integrationSettings ? this.integrationSettings.description : null,
            status: this.integrationSettings ? this.integrationSettings.status : false,
            integration: this.integrationSettings ? this.integrationSettings.integration.id : null,
        }

        // this.isLoading = false;
    }


    save(formDataEmitted) {
        this.overlayService.show();

        let integrationPayload = {
            integration: formDataEmitted.integration,
            description: formDataEmitted.description,
            status: formDataEmitted.status,
        }
        if (this.integrationSettings) {
            this.updateIntegration(integrationPayload);
        } else {
            this.createIntegration(integrationPayload);
        }
    }


    formDataEmitted(formDataEmitted) {
        this.save(formDataEmitted);
    }

    formStatusUpdated(formValid) {
        if (!this.formLoaded) {
            this.formLoaded = true;
        }

        this.formValid = formValid;
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    navigateBack() {
        this.router.navigate([`${routes.SITE_SETTINGS}/${routes.INTEGRATIONS}`]);
    }

    private createIntegration(integrationPayload) {
        this.integrationService.createIntegration(integrationPayload)
        .subscribe(
            res => {
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('Integrations-Created'), 'clear', 'success');
                this.navigateBack();
            },
            err => {
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('Integrations-ErrorCreating'), 'clear', 'warn');
            }
        );
    }

    private updateIntegration(integrationPayload) {
        this.integrationService.updateIntegration(this.integrationSettings.id, integrationPayload)
        .subscribe(
            res => {
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('Integrations-Updated'), 'clear', 'success');
                this.navigateBack();
            },
            err => {
                this.overlayService.hide();
                this.snackbarService.openSnackBar(this.translate.instant('Integrations-ErrorUpdating'), 'clear', 'warn');
            }
        );
    }

    get shouldShowForm(): boolean {
        let res =  this.formData && (this.integration === null || this.integrationSettings !== null);
        return res;
    }
}
