<app-layout>
    <mat-card class="mat-elevation-z0">
        <mat-card-header>
            <mat-card-title>
                <h4 appLocalizationPopupDirective [localizationCode]="'Template-Editor'">
                    {{ 'Template-Editor' | translate: {Default: 'Letter Template Editor'} }}
                </h4>
            </mat-card-title>

            <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToTemplates()">
                {{ 'Template-Back' | translate: {Default: 'Back To Templates'} }}
            </button>
        </mat-card-header>
        <mat-card-content>
            
            <ng-container *ngIf="loadingTemplate || loadingOrganizations || loadingTables || loadingFields; else loaded">
                <div class="loading">
                    <ngx-skeleton-loader count="10" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
                </div>
            </ng-container>
            <ng-template #loaded>
                <mat-stepper linear #stepper (selectionChange)="stepChange($event)">
                    <ng-template matStepperIcon="edit"><mat-icon style="margin-top: 3px; margin-left: 4px;" class="material-icons-outlined">create</mat-icon></ng-template>
            
                    <mat-step>
                        <ng-template matStepLabel appLocalizationPopupDirective [localizationCode]="'Template-Step1'">
                            {{ 'Template-Step1' | translate: {Default: 'Letter Content'} }}
                        </ng-template>
                        
                        <app-form-generator 
                            formId="frm_k6zSoWtEfWyMwg"
                            [formData]="step1FormData"
                            [emitFormDataTrigger]="getStep1FormData"
                            (emitFormData)="formDataEmitted($event, 'step1')"
                            (emitFormDataChange)="formDataEmitted($event, 'step1')"
                            (emitFormPristine)="formPristineEmitted($event, 'step1')"
                            (emitFormStatus)="formStatusUpdated($event, 'step1')"
                        ></app-form-generator>
            
                        <div class="html-editor-container" *ngIf="loadingFields === false; else loadingTables">
                            <mat-card class="mat-elevation-z0 system-variables-card">
                                <mat-card-title class="header">
                                    <h5 appLocalizationPopupDirective [localizationCode]="'Template-Replacements'">
                                        {{ 'Template-Replacements' | translate: {Default: 'Replacements'} }}
                                    </h5>
                                    <button mat-stroked-button class="add-field-btn" setColor="primaryColour" color="primary" (click)="openReplacementDialog()">
                                        <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'AddReplacement' | translate: {Default: "Add Replacement"} }}
                                    </button>
                                </mat-card-title>
                                <mat-card-subtitle>
                                    <span appLocalizationPopupDirective [localizationCode]="'Template-ReplacementsDragAndDrop'">
                                        {{ 'Template-ReplacementsDragAndDrop' | translate: {Default: 'Drag & Drop values into letter'} }}
                                    </span>
                                </mat-card-subtitle>
                                <mat-card-content>
                                    <mat-list>
                                        <ng-container *ngIf="replacements.controls.length === 0">
                                            <mat-list-item class="field-list-item">
                                                {{ 'Template-NoReplacements' | translate: {Default: 'No Replacements Yet'} }}
                                            </mat-list-item>
                                        </ng-container>
                                        <ng-container *ngFor="let replacement of replacements.controls; let i = index">
                                            <mat-list-item
                                                class="field-list-item" 
                                                draggable="true" 
                                                (dragstart)="drag($event, replacement)"
                                            > 
                                                {{replacement.get('identifier').value}} 

                                                <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn">
                                                    <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item appPreventDoubleClick (throttledClick)="openReplacementDialog(replacement)">
                                                        <mat-icon class="material-icons-outlined">edit</mat-icon> 
                                                        <span>{{ 'Edit' | translate: {Default: 'Edit'} }}</span>
                                                    </button>
                                                    <button mat-menu-item appPreventDoubleClick (throttledClick)="removeReplacement(i)">
                                                        <mat-icon class="material-icons-outlined">close</mat-icon> 
                                                        <span>{{ 'Delete' | translate: {Default: 'Delete'} }}</span>
                                                    </button>
                                                </mat-menu>
                                            </mat-list-item>

                                            <mat-divider></mat-divider>
                                        </ng-container>
                                    </mat-list>
                                </mat-card-content>
                            </mat-card>
                            
                            <app-form-generator 
                                formId="frm_2xhbBKhguXDyea"
                                [formData]="step1HtmlFormData"
                                [emitFormDataTrigger]="getStep1HtmlFormData"
                                (emitFormData)="formDataEmitted($event, 'step1Html')"
                                (emitFormDataChange)="formDataEmitted($event, 'step1Html')"
                                (emitFormPristine)="formPristineEmitted($event, 'step1Html')"
                                (emitFormStatus)="formStatusUpdated($event, 'step1Html')"
                            ></app-form-generator>
                        </div>
                        
                        <ng-template #loadingTables>
                            <ngx-skeleton-loader count="10" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
                        </ng-template>
                        
                        <div class="buttons-container">
                          <button mat-raised-button matStepperNext setColor="primaryColour" color="primary" [disabled]="!step1FormValid">{{ 'Next' | translate: {Default: 'Next'} }}</button>
                        </div>
                    </mat-step>
            
                    <mat-step>
                        <ng-template matStepLabel appLocalizationPopupDirective [localizationCode]="'Template-Step2'">{{ 'Template-Step2' | translate: {Default: 'Letter Permissions'} }}</ng-template>
                
                        <app-form-generator 
                            formId="frm_NhvYieGeFXph9f"
                            [formData]="step2FormData"
                            [emitFormDataTrigger]="getStep2FormData"
                            (emitFormData)="formDataEmitted($event, 'step2')"
                            (emitFormDataChange)="formDataEmitted($event, 'step2')"
                            (emitFormPristine)="formPristineEmitted($event, 'step2')"
                            (emitFormStatus)="formStatusUpdated($event, 'step2')"
                        ></app-form-generator>
            
                        <div class="buttons-container">
                            <button mat-raised-button matStepperNext setColor="primaryColour" color="primary" [disabled]="!step2FormValid">{{ 'Next' | translate: {Default: 'Next'} }}</button>
                            <button mat-raised-button matStepperPrevious>{{ 'Back' | translate: {Default: 'Back'} }}</button>
                        </div>
                    </mat-step>
                    
                    <mat-step>
                        <ng-template matStepLabel appLocalizationPopupDirective [localizationCode]="'Template-Step3'">{{ 'Template-Step3' | translate: {Default: 'Letter Review'} }}</ng-template>
                        
                        <ng-template matStepContent>
                            <div class="preview-container">
                                <div class="preview-details">
    
                                    <div class="section">
                                        <h5 appLocalizationPopupDirective [localizationCode]="'Template-LetterDetails'">{{ 'Template-LetterDetails' | translate: {Default: 'Letter Details'} }}</h5>
                                        <div>{{step1FormData.name[0]?.text}}</div>
                                        <div>{{step1FormData.description[0]?.text}}</div>
                                    </div>
    
                                    <div class="section">
                                        <h5  appLocalizationPopupDirective [localizationCode]="'Template-LetterDetails'">{{ 'Template-LetterPermissions' | translate: {Default: 'Letter Permissions'} }}</h5>
                                        
                                        <mat-chip-list *ngIf="step2FormData.status">
                                            <mat-chip 
                                                [ngClass]="{
                                                    'red-chip': step2FormData.status === 'Archived',
                                                    'green-chip': step2FormData.status === 'Active',
                                                    'orange-chip': step2FormData.status === 'Draft'
                                                }"
                                            >{{ step2FormData.status }}</mat-chip>
                                        </mat-chip-list>

                                        
                                        <div *ngIf="showSendToActiveUsersOnlyToggle">
                                            <mat-slide-toggle setColor="primaryColour" disabled [ngModel]="step2FormData.sendToActiveUsersOnly">{{ 'Template-SendToActiveUsersOnly' | translate: {Default: 'Send To Active Users Only'} }}</mat-slide-toggle>
                                        </div>

                                        <div *ngIf="showTemplateAbilityToSetTagsToggle">
                                            <mat-slide-toggle setColor="primaryColour" disabled [ngModel]="step2FormData.abilityToSetTags">{{ 'Template-AbilityToSetTags' | translate: {Default: 'Ability To Set Tags'} }}</mat-slide-toggle>
                                        </div>
                                    </div>
    
                                    <div class="section">
                                        <h5 appLocalizationPopupDirective [localizationCode]="'Template-Owners'">{{ 'Template-Owners' | translate: {Default: 'Owners'} }}</h5>
    
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let item of step2FormData.owners">
                                                <app-employee-image matChipAvatar [employeeId]="item.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                                
                                                <span class="employee">
                                                    {{item.firstName}} {{item.lastName}}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
    
                                    <div class="section">
                                        <h5 appLocalizationPopupDirective [localizationCode]="'Template-Publishers'">{{ 'Template-Publishers' | translate: {Default: 'Publishers'} }}</h5>
    
    
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let item of step2FormData.publishers">
                                                <app-employee-image matChipAvatar [employeeId]="item.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
                                
                                                <span class="employee">
                                                    {{item.firstName}} {{item.lastName}}
                                                </span>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
    
                                    <div class="section">
                                        <h5 appLocalizationPopupDirective [localizationCode]="'Template-Organizations'">{{ 'Template-Organizations' | translate: {Default: 'Organizations'} }}</h5>
    
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let item of step2FormData.organizations">
                                                {{item.name}}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
    
                                </div>
                                <div class="preview-letter">
    
                                    <div class="letter" [ngStyle]="{
                                        'margin-top': step1FormData.topMargin ? step1FormData.topMargin + 'px' : '0px', 
                                        'margin-bottom': step1FormData.bottomMargin ? step1FormData.bottomMargin + 'px' : '0px',
                                        'margin-left': step1FormData.pageMargin ? step1FormData.pageMargin + 'px' : '0px',
                                        'margin-right': step1FormData.pageMargin ? step1FormData.pageMargin + 'px' : '0px'
                                    }" >
                                        <div class="letterhead" [innerHTML]="letterheadDetails?.html"></div>
                                        <div class="letter-content" [innerHTML]="step1HtmlFormData.html"></div>
                                        <div class="letter-footer" [innerHTML]="letterFooterDetails?.html"></div>
                                    </div>
    
                                </div>
                            </div>
                            
                            <div class="buttons-container">
                                <button mat-raised-button setColor="primaryColour" color="primary" matStepperNext (click)="openChangeReasonDialog()">{{ 'Save' | translate: {Default: 'Save'} }}</button>
                                <button mat-raised-button matStepperPrevious>{{ 'Back' | translate: {Default: 'Back'} }}</button>
                            </div>
                        </ng-template>
    
                    </mat-step>
            
                </mat-stepper>
            </ng-template>

        </mat-card-content>
    </mat-card>

</app-layout>