<app-form-generator
    [formId]="formId"
    [formData]="formData"
    [emitFormDataTrigger]="getFormData"
    (emitFormData)="formDataEmitted($event)"
    (emitFormStatus)="formStatusUpdated($event)"
    (emitFormPristine)="formPristineEmitted($event)"
></app-form-generator>

<button mat-raised-button setColor="primaryColour" color="primary" (click)="getFormData = !getFormData" [disabled]="!formValid">{{ 'Save' | translate: {Default: "Save"} }}</button>
