import { Injectable } from '@angular/core';
import { api_routes, features } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { CustomReport, CustomReportVerbose, HardcodedReport, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import { EnvironmentService } from "@app/core/services/environment.service";
import { PagedData } from "@app/modules/lookup/models/paged-data.model";

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class CustomReportsService {

    constructor(
        private translate: TranslateService,
        private http: HttpClient,
        private envService: EnvironmentService
    ) {
    }


    postReport(reportData: any): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}`, reportData, {
            headers: headers,
            observe: 'response',
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    updateReport(reportId: string, reportData: any): Observable<CustomReportVerbose> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}`, reportData, {
            headers: headers,
            observe: 'response',
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    postReportElement(reportId, reportElement: ServerReportElement): Observable<any> {
        return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}/Elements`, reportElement, {
            headers: headers,
            observe: 'response',
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }

    putReportElement(reportId, reportElementId, reportElement: ServerReportElement): Observable<any> {
        return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}/Elements/${reportElementId}`, reportElement, {
            headers: headers,
            observe: 'response',
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                return res.body;
            })
        );
    }


    getReportById(reportId: string): Observable<CustomReportVerbose> {

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}`, {
            headers: headers,
            observe: 'response',
        })
        .pipe(
            catchError(err => throwError(err)),
            map(res => {
                let report: CustomReportVerbose = res.body;
                return report;
            })
        );
    }

    getReportElements(reportId: string): Observable<any> {
        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}/Elements`, {
            headers: headers
        });
    }

    // getReportsByType(reportType: string, take?: string, skip?: string): Observable<CustomReport[]> {
    //     // console.log('getReportsByType', reportType);

    //     // TODO: comment out when API working
    //     return Observable.create(observer => {
    //         const obs: CustomReport[] = []
    //         let reportTypeId = '';

    //         if (reportType == 'Terminations') {
    //             reportTypeId = 'terminations_report';

    //         } else if (reportType == 'Employees') {
    //             reportTypeId = 'employees_report';
    //             obs.push(
    //                 {
    //                     type: "",
    //                     name: this.translate.instant('BasicHeadcountReport'),
    //                     id: "1",
    //                     createdBy: 'Lukasz M',
    //                     createdDate: '2022-01-01',
    //                     lastGenerated: '2022-07-22',
    //                     url: "/Reports/BasicHeadcountReport"
    //                 },
    //                 {
    //                     type: "",
    //                     name: this.translate.instant('BasicEmployeeReport'),
    //                     id: "2",
    //                     createdBy: 'Lukasz M',
    //                     createdDate: '2022-01-01',
    //                     lastGenerated: '2022-07-22',
    //                     url: "/Reports/BasicEmployeeReport"
    //                 },
    //                 {
    //                     type: "",
    //                     name: "Employee Bank Details",
    //                     id: "2",
    //                     createdBy: 'Lukasz M',
    //                     createdDate: '2022-01-01',
    //                     lastGenerated: '2022-07-22',
    //                     url: "/Reports/EmployeeBankDetailsReport"
    //                 },
    //             );
    //         } else if (reportType == 'TimeOff') {
    //             reportTypeId = 'timeoff_report';
    //         } else if (reportType == 'Configuration') {
    //             reportTypeId = 'configuration_report';
    //         } else if (reportType == 'Security') {
    //             reportTypeId = 'security_report';
    //         }

    //         let params = new HttpParams();
    //         params = params.append('Filter', `Type = "${reportTypeId}"`);
    //         skip ? params = params.append('skip', skip) : null;
    //         take ? params = params.append('take', take) : null;


    //         this.http.get<PagedData<CustomReport>>(`${this.envService.env.apiUrl}${api_routes.REPORTS}`, {
    //             headers: headers,
    //             params: params,
    //             observe: 'response',
    //         })
    //         .pipe(
    //             catchError(err => throwError(err)),
    //             map(res => {

    //                 return res.body;
    //             })
    //         )
    //         .subscribe(
    //             res => {
    //                 // console.log('getReportsByType', res.data);

    //                 res.data.forEach((report: CustomReport) => {
    //                     obs.push({
    //                         type: "",
    //                         name: report.name,
    //                         id: report.id,
    //                         createdBy: 'Lukasz M',
    //                         createdDate: '2022-01-01',
    //                         lastGenerated: '2022-07-22',
    //                         url: null
    //                     })
    //                 });
    //                 observer.next(obs);
    //                 observer.complete();
    //             }
    //         );
    //     });
    // }

    getHardcodedReports(reportType: string): Observable<HardcodedReport[]> {
        let reports = [
            {
                "id": "p",
                "name": "Employee Bank Details",
                "icon": "account_balance",
                "detail": "",
                "type": "Employees",
                "href": ["/Reports/EmployeeBankDetailsReport"],
                "featureCode": features.BANK_FEATURES_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('BasicEmployeeReport'),
                "icon": "account_circle",
                "detail": "",
                "type": "Employees",
                "href": ["/Reports/BasicEmployeeReport"],
                "featureCode": features.BASIC_EMPLOYEE_DETAILS_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('BasicHeadcountReport'),
                "icon": "people",
                "detail": "",
                "type": "Employees",
                "href": ["/Reports/BasicHeadcountReport"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('AbsenceReport'),
                "icon": "people",
                "detail": "",
                "type": "TimeOff",
                "href": ["/Reports/Predefined/AbsenceReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('AbsenceFinanceReport'),
                "icon": "people",
                "detail": "",
                "type": "TimeOff",
                "href": ["/Reports/Predefined/AbsenceFinanceReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('OutstandingAbsenceReport'),
                "icon": "people",
                "detail": "",
                "type": "TimeOff",
                "href": ["/Reports/Predefined/OutstandingAbsenceReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('EmployeeLoginReport'),
                "icon": "login",
                "detail": "",
                "type": "Security",
                "href": ["/Reports/Security/EmployeeLoginReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('GoalsReport'),
                "icon": "leaderboard",
                "detail": "",
                "type": "Performance",
                "href": ["/Reports/Predefined/EmployeePerformanceReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('ToilBalanceReport'),
                "icon": "people",
                "detail": "",
                "type": "Toil",
                "href": ["/Reports/Predefined/ToilBalanceReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('ToilOccurrenceReport'),
                "icon": "people",
                "detail": "",
                "type": "Toil",
                "href": ["/Reports/Predefined/ToilOccurrenceReport"],
                "featureCode": "allow"
            },
            {
                "id": "p",
                "name": this.translate.instant('ReviewStatusReport'),
                "icon": "people",
                "detail": "",
                "type": "Performance",
                "href": ["/Reports/Predefined/ReviewStatusReport"],
                "featureCode": "allow"
            },
        ]

        return of( reports.filter(report => report.type === reportType) )
    }

    getNewReports(take?: string, skip?: string, filter?: string, sort?: string): Observable<PagedData<CustomReport>> {
        let params = new HttpParams();
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;
        filter ? params = params.append('filter', filter) : null;
        sort ? params = params.append('sort', sort) : null;

        return this.http.get<PagedData<CustomReport>>(`${this.envService.env.apiUrl}${api_routes.REPORTS}`, {
            headers: headers,
            params: params,
        })
    }


    public getCustomReports(): Observable<any> {
        return of([
            {
                "id": "p",
                "name": this.translate.instant('Employees'),
                "icon": "people",
                "detail": "",
                "href": ["/Reports/Custom/Type/Employees"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('Terminations'),
                "icon": "people",
                "detail": "",
                "href": ["/Reports/Custom/Type/Terminations"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('Time Off'),
                "icon": "alarm",
                "detail": "",
                "href": ["/Reports/Custom/Type/TimeOff"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('Toil'),
                "icon": "alarm",
                "detail": "",
                "href": ["/Reports/Custom/Type/Toil"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('Configuration'),
                "icon": "settings",
                "detail": "",
                "href": ["/Reports/Custom/Type/Configuration"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
            {
                "id": "p",
                "name": this.translate.instant('Security'),
                "icon": "security",
                "detail": "",
                "href": ["/Reports/Custom/Type/Security"],
                "featureCode": features.HEAD_COUNT_REPORT
            },
        ]);
    }


    deleteReport(reportId: string): Observable<any> {
        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}`, {
            headers: headers
        });
    }


    deleteReportElement(reportId: string, elementId: string) {
        return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${reportId}/Elements/${elementId}`, {
            headers: headers
        });
    }
}
