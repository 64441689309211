import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ExcelExportEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EmploymentRecordsService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { finalize } from 'rxjs/operators';
import { EmployeeTableFieldSecurity } from '@app/shared/models/employee.model';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { Observable, from } from 'rxjs';
import { HistoryDialogService } from './services/history-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss']
})
export class HistoryDialogComponent implements OnInit {

  private url: string;
  private employeeId: string;
  private entityId: string;

  public columns: any[];
  public gridDataResult: GridDataResult;
  public isLoading: boolean;
  public pageSize: number = 20;
  public skip: number = 0;
  sortingColumns: boolean;
  fieldList: TableField[];
  tableId: string;
  isNotEmployeeDetails: boolean = false;
  showEffectivDateColumn: any;

  constructor(
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<HistoryDialogComponent>,
    private employmentRecordService: EmploymentRecordsService,
    private securitySetupService: SecuritySetupService,
    private tablePermissionsService: TablePermissionsService,
    public translate: TranslateService,
    private historyDialogService: HistoryDialogService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.url = data.url;
    this.employeeId = data.employeeId;
    this.entityId = data.entityId;
    this.columns = data.columns;
    this.tableId = data.tableId;
    this.isNotEmployeeDetails = data.isNotEmployeeDetails;
    data.showEffectivDateColumn ? this.showEffectivDateColumn = data.showEffectivDateColumn : this.showEffectivDateColumn = false;
  }

  ngOnInit(): void {
    this.getHistory();

    if(this.tableId) {
      this.getFields(this.tableId);
    }
  }

  getFields(tableId) {
    this.sortingColumns = true;

    this.securitySetupService.getFields(tableId, 0, '200')
    .pipe(
      finalize( () => {
        this.sortingColumns = false;
        this.getTableFieldPermissions();
      })
    )
    .subscribe(
        pagedFieldList => {
          this.fieldList = pagedFieldList.data;

          this.fieldList.forEach(
            field => {
              let index = this.columns.findIndex( column => column.tableId === field.id )

              if(index !== -1){
                if(field.enabled === false){
                  this.columns.splice(index, 1);
                }

                if(field.name !== null){
                  this.columns[index].title = field.name;
                }
              }
            }
          );
        }
    );
  }

  getTableFieldPermissions() {
    let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(this.tableId);

    tableFieldPermissions.forEach(
      tableFieldPermission => {
        let index = this.columns.findIndex( column => column.tableId === tableFieldPermission.field.id )

        if(index !== -1){
          if(tableFieldPermission.read === false || tableFieldPermission.deny === true){
            this.columns.splice(index, 1);
          }
        }
      }
    )
  }

  public getHistory() {
    this.isLoading = true;

    if(this.isNotEmployeeDetails) {
      this.historyDialogService.getHistory(this.url)
      .subscribe(pagedAccessLogs => {
        this.gridDataResult = {
          data: pagedAccessLogs.data,
          total: pagedAccessLogs.total,
        };
        this.isLoading = false;
      });
    }
    else {
      this.employmentRecordService.getHistory(this.url, this.skip, String(this.pageSize))
          .subscribe(pagedAccessLogs => {
            this.gridDataResult = {
              data: pagedAccessLogs.data,
              total: pagedAccessLogs.total,
            };
            this.isLoading = false;
          });
    }

  }

  close(){
    this.dialogRef.close();
  }


  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getHistory();
  }

  wasModified(column: string, record: any) {
    var res = false;
    if (record?.version?.modifiedList && Array.isArray(record?.version?.modifiedList)) {
      record?.version?.modifiedList.forEach(changedItem => {
        if (changedItem.toLowerCase() == column.toLowerCase()) {
          res = true
        }
      })
    }
    return res;
  }

  public allData = (): Observable<GridDataResult> => {
    if(this.isNotEmployeeDetails) {
      return this.historyDialogService.getHistory(this.url, 0, String(100))
    }
    else {
      return from(this.employmentRecordService.queryAllHistory(this.url, 0, String(100)))
    }
  };

  public onExcelExport(e: ExcelExportEvent): void {
    const rows = e.workbook.sheets[0].rows;


    // if the row data is an object create a string of all the values
    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells.forEach((cell) => {
          if(Array.isArray(cell.value)){
            let val: string = '';

            cell.value.forEach( (item) => {
              if(typeof item === 'object' && item !== null) {
                if(item.hasOwnProperty('name')){
                  if(val !== ''){
                    val = val.concat(', ', val)
                  }
                  if(item.name !== null){
                    val = val.concat(item.name, val)
                  }
                }
              }
            })

            if(val !== ''){
              cell.value = val;
            }
          }
        });
      }
    });
  }
}
