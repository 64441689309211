import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-organizations-dialog',
  templateUrl: './select-organizations-dialog.component.html',
  styleUrls: ['./select-organizations-dialog.component.scss']
})
export class SelectOrganizationsDialogComponent implements OnInit {
  @Output() emitSelectedOrganizations: EventEmitter<string[]> = new EventEmitter<string[]>();

  refreshView: boolean = true;
  public columns: any[] = [
      {field: 'name', title: 'Name'},
      {field: 'organizationType', subField: 'name', title: 'Organization Type'},
      {field: 'parentOrganization', subField: 'name', title: 'Parent Organization'},
      {field: 'startDate', title: 'Start Date', type:'date'},
      {field: 'endDate', title: 'End Date', type:'date'},
  ];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  public searchFilterString: string;
  public searchValue: string;
  private getRequest: any;
  filterString: string;
  sortString: string = "name-asc";
  clearSelectedItems: boolean = false;

  selectedOrganizations: any[] = [];

  filterToggleDetails: {
      text: string,
      checked: boolean
  } = {
      text: 'FilterInactive',
      checked: true
  }
  
  constructor(
    private translate: TranslateService,
    private organizationService: OrganizationStructureService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<SelectOrganizationsDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.getOrganizations();
  }

  getOrganizations(): void {
    this.isLoading = true;

    this.getRequest = this.organizationService.getOrganizations(this.skip, String(this.pageSize), this.searchFilterString, this.sortString, this.filterToggleDetails.checked.toString())
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getOrganizations();
  }

  deleteAllSelected(itemsToDelete: string[]) {
  //     const observables = itemsToDelete.map(item => defer(() => this.organizationService.deleteOrganization(item)));
  //     this.overlayService.show();
  //     forkJoin(observables)
  //         .pipe(
  //             finalize(() => this.overlayService.hide())
  //         )
  //         .subscribe(
  //             (res) => {
  //                 this.clearSelectedItems = !this.clearSelectedItems;
  //                 this.getOrganizations();
  //                 this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
  //             },
  //             err => {
  //                 this.snackbarService.openSnackBar(err, 'clear', 'warn');
  //             }
  //         );
  }

  filterCallback(filterString: string) {
      this.filterString = filterString;
      this.skip = 0;;
      this.getOrganizations();
  }

  search(searchValue) {
      this.searchValue = searchValue;
      this.searchFilterString = `(Name like "${this.searchValue}")`;
      this.getRequest.unsubscribe();
      this.getOrganizations();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
      this.filterToggleDetails.checked = toggleChange.checked;
      this.getOrganizations();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.emitSelectedOrganizations.emit(this.selectedOrganizations);
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    selectionEvent.selectedRows.forEach(row => {
      this.selectedOrganizations.push(row.dataItem)
    });

    selectionEvent.deselectedRows.forEach(row => {
      this.selectedOrganizations = this.selectedOrganizations.filter(org => org.id !== row.dataItem.id)
    });
  }

}
