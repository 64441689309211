import { EmployeeService } from "@app/core/services/employee.service";
import { CustomReportColumn, CustomReportTable, ServerReportElement } from "@app/modules/custom-reports/models/custom-report.model";
import { defer, forkJoin, Observable } from "rxjs";
import {
    EmploymentRecordPositionsService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service";
import { PositionsService } from "@app/modules/positions/services/positions.service";
import { CostCentersService } from "@app/modules/cost-centers/services/cost-centers.service";
import { OrganizationStructureService } from "@app/modules/organization-structure/services/organization-structure.service";
import { WorkLocationsService } from "@app/modules/work-locations/services/work-locations.service";
import { WorkRotationService } from "@app/modules/work-rotations/services/work-rotation.service";
import { TimeOffService } from "@app/modules/time-off/services/time-off.service";
import { EmploymentRecordsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service";
import { EmployeeLeaveService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service";
import { EmployeeCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/services/employee-compensation.service";
import { EmployeeOtherCompensationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-other-compensation/services/employee-other-compensation.service";
import { ReportsMapping } from "@app/modules/custom-reports/util/reports-mapping";
import { EmergencyContactService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/services/emergency-contact.service";
import { EmployeeMedicalTestService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-medical-tests/services/employee-medical-test.service";
import { ReportGeneratorListener } from "@app/modules/custom-reports/util/report-generator-listener";
import { defaultIfEmpty } from "rxjs/operators";
import { VisasAndPermitsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-visas-and-permits/services/visas-and-permits.service";
import { EmployeeLanguageService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-languages/services/employee-language.service";
import { TrainingAndCertificationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/training-and-certifications/services/training-and-certification.service";
import { EducationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-education/services/education.service";
import { EmployeeWorkExperienceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-work-experience/services/employee-work-experience.service";
import {
    EmployeeProfessionalExpertiseService
} from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-professional-expertises/services/employee-professional-expertise.service";
import { EmployeeAssociationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-associations/services/employee-association.service";
import { EmployeeCompanyAssetsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-company-asset/services/employee-company-assets.service";
import { EmployeeRelocationService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-relocation/services/employee-relocation.service";
import { EmployeeGrievancesService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-grievances/services/employee-grievances.service";
import { EmployeeInjuryIllnessService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-injury-illness/services/employee-injury-illness.service";
import { EmployeeBankDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-bank-details/services/employee-bank-details.service";
import { EmployeePayrollDetailsService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-payroll-details/services/employee-payroll-details.service";
import { EmployeeAbsenceService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service";
import { FamilyDependantService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-family-dependants/services/family-dependant.service";
import { ReportsService } from "@app/modules/custom-reports/util/reports-service";
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";

export class ReportsUtil {

    private reportsMapping: ReportsMapping = new ReportsMapping();
    private reportsService: ReportsService;

    constructor(private employeeService: EmployeeService,
                private employmentRecordPositionsService: EmploymentRecordPositionsService,
                private positionsService: PositionsService,
                private costCentersService: CostCentersService,
                private organizationStructureService: OrganizationStructureService,
                private workLocationsService: WorkLocationsService,
                private workRotationService: WorkRotationService,
                private timeOffService: TimeOffService,
                private employeeAbsenceService: EmployeeAbsenceService,
                private employmentRecordsService: EmploymentRecordsService,
                private employeeCompensationService: EmployeeCompensationService,
                private employeeOtherCompensationService: EmployeeOtherCompensationService,
                private employeeEmergencyContactService: EmergencyContactService,
                private employeeFamilyDependantsService: FamilyDependantService,
                private employeeLeaveService: EmployeeLeaveService,
                private employeeMedicalTestService: EmployeeMedicalTestService,
                private visasAndPermitsService: VisasAndPermitsService,
                private employeeLanguageService: EmployeeLanguageService,
                private trainingAndCertificationService: TrainingAndCertificationService,
                private educationService: EducationService,
                private employeeWorkExperienceService: EmployeeWorkExperienceService,
                private employeeProfessionalExpertiseService: EmployeeProfessionalExpertiseService,
                private employeeAssociationService: EmployeeAssociationService,
                private companyAssetsService: EmployeeCompanyAssetsService,
                private employeeRelocationService: EmployeeRelocationService,
                private employeeGrievanceService: EmployeeGrievancesService,
                private employeeInjuryIllnesService: EmployeeInjuryIllnessService,
                private employeeBankDetailsService: EmployeeBankDetailsService,
                private employeePayrollDetailsService: EmployeePayrollDetailsService,
                private employeeGoalPlanService: GoalPlanService,
    ) {
        this.reportsService = new ReportsService(
            employeeService,
            employmentRecordPositionsService,
            positionsService,
            costCentersService,
            organizationStructureService,
            workLocationsService,
            workRotationService,
            timeOffService,
            employeeAbsenceService,
            employmentRecordsService,
            employeeCompensationService,
            employeeOtherCompensationService,
            employeeEmergencyContactService,
            employeeFamilyDependantsService,
            employeeLeaveService,
            employeeMedicalTestService,
            visasAndPermitsService,
            employeeLanguageService,
            trainingAndCertificationService,
            educationService,
            employeeWorkExperienceService,
            employeeProfessionalExpertiseService,
            employeeAssociationService,
            companyAssetsService,
            employeeRelocationService,
            employeeGrievanceService,
            employeeInjuryIllnesService,
            employeeBankDetailsService,
            employeePayrollDetailsService,
            employeeGoalPlanService
        );
    }

    generateReport(reportElement: ServerReportElement, listener: ReportGeneratorListener, preview: boolean, elementIndex: number = -1) {
        listener.dataLoading();

        let elementType = reportElement.name

        let columns = this.reportsMapping.getColumnsFromProperties(reportElement);
        let tableColumns = this.reportsMapping.getTableColumnsFromProperties(columns);
        let parentTable = this.reportsMapping.getParentTableFromProperties(reportElement);

        // console.log("columns", columns);
        if (elementType === "datagrid_element" && tableColumns?.length > 0) {

            if (parentTable === 'Employee') {
                this.renderEmployeeReportData(listener, reportElement, preview, elementIndex)
            } else if (parentTable === 'Position') {
                this.renderPositionReportData(listener, reportElement, preview, elementIndex)
            } else if (parentTable === 'Cost Centre') {
                this.renderCostCentreReportData(listener, reportElement, preview, elementIndex)
            } else if (parentTable === 'Organization Structure') {
                this.renderOrganizationUnitReportData(listener, reportElement, preview, elementIndex)
            } else if (parentTable === 'Work Location') {
                this.renderWorkLocationReportData(listener, reportElement, preview, elementIndex)
            } else if (parentTable === 'Work Rotation') {
                this.renderWorkRotationReportData(listener, reportElement, preview, elementIndex)
            } else if (parentTable === 'Time Off Type') {
                this.renderTimeOffTypeReportData(listener, reportElement, preview, elementIndex)
            }
        } else if (elementType === "datagrid_element" && tableColumns?.length < 1) {
            listener.setData({data: [], total: 0,}, elementIndex);
        } else if (elementType === "piechart_element") {

            if (parentTable === 'Employee') {
                this.renderPieChartEmployeeData(listener, reportElement, columns, preview, elementIndex)
            } else if (parentTable === 'Position') {
                this.renderPieChartPositionData(listener, reportElement, columns, preview, elementIndex)
            } else if (parentTable === 'Compensation') {
                this.renderPieChartCompensationData(listener, reportElement, columns, preview, elementIndex)
            } else if (parentTable === 'Employment Record') {
                this.renderPieChartEmploymentRecordData(listener, reportElement, columns, preview, elementIndex)
            } else if (parentTable === 'Other Compensations') {
                this.renderPieChartOtherCompensationsData(listener, reportElement, columns, preview, elementIndex)
            }
        }
    }

    private prepareDataToDisplayInTable(reportElement: ServerReportElement, parentData: any[], childData: any[] | any, table: string) {
        const zipData = (parentObj, childObjArray, isSeparateLines) => {
            if (isSeparateLines) {
                return childObjArray.map(childObj => ({...parentObj, [table]: childObj}));
            }

            const relevantFields = this.getAllFieldsForTable(reportElement, table);
            const mergedData = relevantFields.reduce((result, field) => {
                const fieldMapping = this.getFieldMapping(reportElement, table, field, false);
                if (!fieldMapping) return result;

                if (fieldMapping.includes('.')) {
                    return this.transformData(childObjArray, fieldMapping, table, result);
                }

                const fieldValues = childObjArray
                .map(childObj => childObj[fieldMapping])
                .filter(value => value !== null && value !== undefined)
                .join(', ');
                return {...result, [table]: {...(result[table] || {}), [fieldMapping]: fieldValues}};
            }, parentObj);

            return [mergedData];
        };

        return parentData.flatMap((parentObj, i) => {
            let childObjArray = Array.isArray(childData) ? childData[i] : childData;
            if (!childObjArray || childObjArray.length === 0) {
                return [parentObj];
            }

            // Convert single child object to an array to handle it in the same way as an array of objects
            if (!Array.isArray(childObjArray)) {
                childObjArray = [childObjArray];
            }

            const separateLines = this.reportsMapping.getSeparateLinesFromProperties(reportElement);
            return zipData(parentObj, childObjArray, separateLines);
        });
    }

    getNestedFieldValue(obj, path) {
        const [firstKey, ...remainingKeys] = path.split('.');
        let value = obj[firstKey];

        if (value === null || value === undefined) {
            return null;
        }

        if (Array.isArray(value)) {
            return value.map(item => this.getNestedFieldValue(item, remainingKeys.join('.'))).filter(val => val !== null && val !== '').join(', ');
        }

        if (remainingKeys.length === 0) {
            return value;
        }

        return this.getNestedFieldValue(value, remainingKeys.join('.'));
    }


    updateNestedStructure(structure, pathArray, value) {
        if (pathArray.length === 1) {
            structure[pathArray[0]] = value;
        } else {
            if (!structure[pathArray[0]]) {
                structure[pathArray[0]] = {};
            }
            this.updateNestedStructure(structure[pathArray[0]], pathArray.slice(1), value);
        }
    }

    transformData(childObjArray, fieldMapping, table, result = {}) {
        const fieldValues = childObjArray
        .map(childObj => this.getNestedFieldValue(childObj, fieldMapping))
        .filter(value => value !== null && value !== undefined && value !== '')
        .join(', ');

        let currentTableData = result[table] || {};

        const pathArray = fieldMapping.split(".");
        let parentField = pathArray.slice(0, -1).join('.');
        let childField = pathArray.slice(-1)[0];

        if (parentField.includes('.')) {
            if (!currentTableData[parentField.split('.')[0]]) {
                currentTableData[parentField.split('.')[0]] = {};
            }
            this.updateNestedStructure(currentTableData[parentField.split('.')[0]], parentField.split('.').slice(1).concat(childField), fieldValues);
        } else {
            const updatedParentField = {...(currentTableData[parentField] || {}), [childField]: fieldValues};
            currentTableData = {...currentTableData, [parentField]: updatedParentField};
        }

        return {...result, [table]: currentTableData};
    }


    async renderEmployeeReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        const shouldFetch = {
            employeeData: {fetch: true, completed: false},
            positionData: {fetch: this.tablesContainFieldFrom(reportElement, 'Employee Position'), completed: false},
            employmentRecordData: {fetch: this.tablesContainFieldFrom(reportElement, 'Employment Record'), completed: false},
            timeOffHistoryData: {fetch: this.tablesContainFieldFrom(reportElement, 'Time Off History'), completed: false},
            compensationData: {fetch: this.tablesContainFieldFrom(reportElement, 'Compensation'), completed: false},
            otherCompensationsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Other Compensations'), completed: false},
            emergencyContactData: {fetch: this.tablesContainFieldFrom(reportElement, 'Emergency Contacts'), completed: false},
            familyDependantsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Family Dependants'), completed: false},
            medicalTestsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Medical Tests'), completed: false},
            visasAndPermitsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Visas and Permits'), completed: false},
            languageData: {fetch: this.tablesContainFieldFrom(reportElement, 'Languages'), completed: false},
            costCentreData: {fetch: this.tablesContainFieldFrom(reportElement, 'Cost Centre'), completed: false},
            trainingAndCertificationData: {fetch: this.tablesContainFieldFrom(reportElement, 'Training and Certification'), completed: false},
            educationData: {fetch: this.tablesContainFieldFrom(reportElement, 'Education'), completed: false},
            workHistoryData: {fetch: this.tablesContainFieldFrom(reportElement, 'Work History'), completed: false},
            professionalExpertiseData: {fetch: this.tablesContainFieldFrom(reportElement, 'Professional Expertise'), completed: false},
            associationsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Associations'), completed: false},
            companyAssetsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Company Assets'), completed: false},
            relocationsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Relocations'), completed: false},
            grievancesData: {fetch: this.tablesContainFieldFrom(reportElement, 'Grievances'), completed: false},
            injuryIllnessData: {fetch: this.tablesContainFieldFrom(reportElement, 'Injury Illness'), completed: false},
            bankDetailsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Bank Details'), completed: false},
            payrollDetailsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Payroll Details'), completed: false},
            goalsData: {fetch: this.tablesContainFieldFrom(reportElement, 'Goals'), completed: false},
            managerData: {fetch: this.tablesContainFieldFrom(reportElement, 'Manager'), completed: false},
        };

        try {
            let zipped = await this.reportsService.getAllEmployees(preview, shouldFetch, listener);
            // console.log('zipped', zipped);

            if (shouldFetch.positionData.fetch ) {
                const posData = await this.reportsService.fetchPositionData(zipped, shouldFetch, listener, 'Positions Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, posData, CustomReportTable.EmployeePosition);
            }

            if (shouldFetch.managerData.fetch) {
                const managerData = await this.getManagerData(zipped);
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, managerData, CustomReportTable.Manager);
            }

            if (shouldFetch.employmentRecordData.fetch) {
                const empRecData = await this.reportsService.fetchEmploymentRecordData(zipped, shouldFetch, listener, 'Employment Records Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, empRecData, CustomReportTable.EmploymentRecord);
            }

            if (shouldFetch.timeOffHistoryData.fetch) {
                const timeOffHistoryData = await this.reportsService.fetchTimeOffHistoryData(zipped, shouldFetch, listener, 'Time Off History Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, timeOffHistoryData, CustomReportTable.TimeOffHistory);
            }

            if (shouldFetch.compensationData.fetch) {
                const compensationData = await this.reportsService.fetchCompensationData(zipped, shouldFetch, listener, 'Compensations Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, compensationData, CustomReportTable.Compensation);
            }

            if (shouldFetch.otherCompensationsData.fetch) {
                const otherCompensationsData = await this.reportsService.fetchOtherCompensationsData(zipped, shouldFetch, listener, 'Other Compensations Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, otherCompensationsData, CustomReportTable.OtherCompensations);
            }

            if (shouldFetch.emergencyContactData.fetch) {
                const emergencyContactData = await this.reportsService.fetchEmergencyContactData(zipped, shouldFetch, listener, 'Emergency Contacts Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, emergencyContactData, CustomReportTable.EmergencyContacts);
            }

            if (shouldFetch.familyDependantsData.fetch) {
                const familyDependantsData = await this.reportsService.fetchFamilyDependantsData(zipped, shouldFetch, listener, 'Family Dependants Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, familyDependantsData, CustomReportTable.FamilyDependants);
            }

            if (shouldFetch.medicalTestsData.fetch) {
                const medicalTestsData = await this.reportsService.fetchMedicalTestsData(zipped, shouldFetch, listener, 'Medical Tests Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, medicalTestsData, CustomReportTable.MedicalTests);
            }

            if (shouldFetch.visasAndPermitsData.fetch) {
                const visasAndPermitsData = await this.reportsService.fetchVisasAndPermitsData(zipped, shouldFetch, listener, 'Visas and Permits Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, visasAndPermitsData, CustomReportTable.VisasAndPermits);
            }

            if (shouldFetch.languageData.fetch) {
                const languageData = await this.reportsService.fetchLanguageData(zipped, shouldFetch, listener, 'Languages Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, languageData, CustomReportTable.Languages);
            }

            if (shouldFetch.trainingAndCertificationData.fetch) {
                const trainingAndCertificationData = await this.reportsService.fetchTrainingAndCertificationData(zipped, shouldFetch, listener, 'Training and Certifications Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, trainingAndCertificationData, CustomReportTable.TrainingAndCertification);
            }

            if (shouldFetch.educationData.fetch) {
                const educationData = await this.reportsService.fetchEducationData(zipped, shouldFetch, listener, 'Education Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, educationData, CustomReportTable.Education);
            }

            if (shouldFetch.workHistoryData.fetch) {
                const workHistoryData = await this.reportsService.fetchWorkHistoryData(zipped, shouldFetch, listener, 'Work History Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, workHistoryData, CustomReportTable.WorkHistory);
            }

            if (shouldFetch.professionalExpertiseData.fetch) {
                const professionalExpertiseData = await this.reportsService.fetchProfessionalExpertiseData(zipped, shouldFetch, listener, 'Professional Expertise Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, professionalExpertiseData, CustomReportTable.ProfessionalExpertise);
            }

            if (shouldFetch.associationsData.fetch) {
                const associationsData = await this.reportsService.fetchAssociationsData(zipped, shouldFetch, listener, 'Associations Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, associationsData, CustomReportTable.Associations);
            }

            if (shouldFetch.companyAssetsData.fetch) {
                const companyAssetsData = await this.reportsService.fetchCompanyAssetsData(zipped, shouldFetch, listener, 'Company Assets Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, companyAssetsData, CustomReportTable.CompanyAssets);
            }

            if (shouldFetch.relocationsData.fetch) {
                const relocationsData = await this.reportsService.fetchRelocationsData(zipped, shouldFetch, listener, 'Relocations Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, relocationsData, CustomReportTable.Relocations);
            }

            if (shouldFetch.grievancesData.fetch) {
                const grievancesData = await this.reportsService.fetchGrievancesData(zipped, shouldFetch, listener, 'Grievances Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, grievancesData, CustomReportTable.Grievances);
            }

            if (shouldFetch.injuryIllnessData.fetch) {
                const injuryIllnessData = await this.reportsService.fetchInjuryIllnessData(zipped, shouldFetch, listener, 'Injury Illness Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, injuryIllnessData, CustomReportTable.InjuryIllness);
            }

            if (shouldFetch.bankDetailsData.fetch) {
                const bankDetailsData = await this.reportsService.fetchBankDetailsData(zipped, shouldFetch, listener, 'Bank Details Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, bankDetailsData, CustomReportTable.BankDetails);
            }

            if (shouldFetch.payrollDetailsData.fetch) {
                const payrollDetailsData = await this.reportsService.fetchPayrollDetailsData(zipped, shouldFetch, listener, 'Payroll Details Table');
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, payrollDetailsData, CustomReportTable.PayrollDetails);
            }

            if (shouldFetch.goalsData.fetch) {
                const goalsData = await this.reportsService.fetchGoalsData(zipped, shouldFetch, listener, 'Goals Table');
                console.log('goalsData', goalsData);
                zipped = this.prepareDataToDisplayInTable(reportElement, zipped, goalsData, CustomReportTable.Goals);
                console.log('zipped', zipped);
            }

            this.returnData(reportElement, zipped, listener, elementIndex);
        } catch (error) {
            console.error("error: ", error);
        }
    }

    async getAllEmployees(preview, shouldFetch, listener) {
        // this.reportsService.updateProgressAndCompletion(shouldFetch, 'employeeData', 'Querying the Employees Table...', listener);

        let take = preview ? 10 : 100;
        let skip = 0;
        let employees = [];
        let total = null;

        while (total === null || skip < total) {
            const res = await this.employeeService.getEmployeesNew(skip, take).toPromise();

            if (res && res.data && res.data.length > 0) {
                employees = employees.concat(res.data);
                skip += take;
                total = res.total;

                this.reportsService.updateProgressAndCompletion(
                    shouldFetch,
                    '',
                    `Fetched ${skip} out of ${total} employees.`,
                    listener,
                    total,
                    skip
                );

                if (preview) {
                    break; // Exit the loop if we only want a preview
                }
            } else {
                break;
            }
        }

        return employees;
    }

    async getAllPositions(preview) {
        let take = preview ? 10 : 100;
        let skip = 0;
        let positions = [];
        let total = null;

        while (total === null || skip < total) {
            const res = await this.positionsService.getPositions(skip, take.toString()).toPromise();

            if (res && res.data && res.data.length > 0) {
                positions = positions.concat(res.data);
                skip += take;
                total = res.total;

                if (preview) {
                    break; // Exit the loop if we only want a preview
                }
            } else {
                break;
            }
        }

        return positions;
    }

    async getAllCostCentres(preview) {
        let take = preview ? 10 : 100;
        let skip = 0;
        let costCentres = [];
        let total = null;

        while (total === null || skip < total) {
            const res = await this.costCentersService.getCostCenters(skip, take.toString()).toPromise();

            if (res && res.data && res.data.length > 0) {
                costCentres = costCentres.concat(res.data);
                skip += take;
                total = res.total;

                if (preview) {
                    break; // Exit the loop if we only want a preview
                }
            } else {
                break;
            }
        }

        return costCentres;
    }

    async getAllOrganizations(preview) {
        let take = preview ? 100 : 100;
        let skip = 0;
        let organizations = [];
        let total = null;

        while (total === null || skip < total) {
            const res = await this.organizationStructureService.getOrganizations(skip, take.toString()).toPromise();

            if (res && res.data && res.data.length > 0) {
                organizations = organizations.concat(res.data);
                skip += take;
                total = res.total;

                if (preview) {
                    break; // Exit the loop if we only want a preview
                }
            } else {
                break;
            }
        }

        return organizations;
    }

    async getAllWorkLocations(preview) {
        let take = preview ? 10 : 100;
        let skip = 0;
        let workLocations = [];
        let total = null;

        while (total === null || skip < total) {
            const res = await this.workLocationsService.getWorkLocations(skip, take.toString()).toPromise();

            if (res && res.data && res.data.length > 0) {
                workLocations = workLocations.concat(res.data);
                skip += take;
                total = res.total;

                if (preview) {
                    break; // Exit the loop if we only want a preview
                }
            } else {
                break;
            }
        }

        return workLocations;
    }


    async getAllWorkRotations(preview) {
        let take = preview ? 100 : 100;
        let skip = 0;
        let workRotations = [];
        let total = null;

        while (total === null || skip < total) {
            const res = await this.workRotationService.getWorkRotations(skip, take.toString()).toPromise();

            if (res && res.data && res.data.length > 0) {
                workRotations = workRotations.concat(res.data);
                skip += take;
                total = res.total;

                if (preview) {
                    break; // Exit the loop if we only want a preview
                }
            } else {
                break;
            }
        }

        return workRotations;
    }

    async getManagerData(zipped) {
        const managerData = [];

        zipped.forEach(employee => {
            if (employee.managers && employee.managers.length > 0) {
                const employeeManagerData = employee.managers.map(manager => ({
                    name: `${manager.firstName} ${manager.lastName}`
                }));
                managerData.push(employeeManagerData);
            } else {
                managerData.push([]);
            }
        });

        return managerData;
    }


    private async renderPositionReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        listener.setProgress(25, 'Querying the Positions...');
        const allPositions = await this.getAllPositions(preview);
        this.returnData(reportElement, allPositions, listener, elementIndex);
    }


    private async renderCostCentreReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        listener.setProgress(25, 'Querying the Cost Centers...');
        let zipped = await this.getAllCostCentres(preview);

        const shouldFetch = {
            positionData: {fetch: this.tablesContainFieldFrom(reportElement, 'Position'), completed: false},
            employeeData: {fetch: this.tablesContainFieldFrom(reportElement, 'Employee Position'), completed: false},
        }

        if (shouldFetch.positionData.fetch || shouldFetch.employeeData.fetch) {
            listener.setProgress(75, 'Querying the Position table...');

            let posData = await this.getAllPositions(preview);
            let posDataOrdered = [];
            zipped.forEach((costCenter, i) => {
                posDataOrdered[i] = posData.filter(pos => pos.costCenters.filter(ccAloc => ccAloc.costCenter.id === costCenter.id).length > 0);
            });
            zipped = this.prepareDataToDisplayInTable(reportElement, zipped, posDataOrdered, CustomReportTable.Position);

            if (shouldFetch.employeeData.fetch) {
                listener.setProgress(75, 'Querying the Employee Position table...');
                zipped = await this.appendEmployeeDataToPositionData(reportElement, posData, posDataOrdered, zipped);
            }
        }

        listener.setProgress(95, 'Finalizing...');
        this.returnData(reportElement, zipped, listener, elementIndex);
    }


    private async renderOrganizationUnitReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        listener.setProgress(25, 'Querying the Organization Units...');
        let zipped = await this.getAllOrganizations(preview);

        const shouldFetch = {
            positionData: {fetch: this.tablesContainFieldFrom(reportElement, 'Position'), completed: false},
            employeeData: {fetch: this.tablesContainFieldFrom(reportElement, 'Employee'), completed: false},
        }

        if (shouldFetch.employeeData.fetch) {
            let empData = await this.getAllEmployees(preview, shouldFetch, listener);
            let empDataOrdered = []
            zipped.forEach((org, i) => {
                empDataOrdered[i] = empData.filter(emp => emp.organization?.id === org.id)
            })
            zipped = this.prepareDataToDisplayInTable(reportElement, zipped, empDataOrdered, CustomReportTable.Employee)
        }

        if (shouldFetch.positionData.fetch) {
            let posData = await this.getAllPositions(preview);
            let posDataOrdered = []
            zipped.forEach((org, i) => {
                posDataOrdered[i] = posData.filter(pos => pos.organization?.id === org.id)
            });
            zipped = this.prepareDataToDisplayInTable(reportElement, zipped, posDataOrdered, CustomReportTable.Position)
            if (preview) {
                zipped = this.beautifyPreviewData('Position', zipped)
            }
        }


        this.returnData(reportElement, zipped, listener, elementIndex);
    }

    private async renderWorkLocationReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        listener.setProgress(25, 'Querying the Work Locations...');
        let zipped = await this.getAllWorkLocations(preview);

        const shouldFetch = {
            positionData: {fetch: this.tablesContainFieldFrom(reportElement, 'Position'), completed: false},
            employeeData: {fetch: this.tablesContainFieldFrom(reportElement, 'Employee Position'), completed: false},
        }

        if (shouldFetch.positionData.fetch || shouldFetch.employeeData.fetch) {
            listener.setProgress(50, 'Querying the Position table...');
            let posData = await this.getAllPositions(preview);
            let posDataOrdered = [];
            zipped.forEach((wl, i) => {
                posDataOrdered[i] = posData.filter(pos => pos.workLocation?.id === wl.id);
            });

            zipped = this.prepareDataToDisplayInTable(reportElement, zipped, posDataOrdered, CustomReportTable.Position);

            if (shouldFetch.employeeData.fetch) {
                listener.setProgress(75, 'Querying the Employee Position table...');
                zipped = await this.appendEmployeeDataToPositionData(reportElement, posData, posDataOrdered, zipped);
            }
        }
        listener.setProgress(95, 'Finalizing...');
        this.returnData(reportElement, zipped, listener, elementIndex);
    }

    private async renderWorkRotationReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        listener.setProgress(25, 'Querying the Work Rotations...');
        let zipped = await this.getAllWorkRotations(preview);

        let shouldFetch = {
            positionData: {fetch: this.tablesContainFieldFrom(reportElement, 'Position'), completed: false},
            employeeData: {fetch: this.tablesContainFieldFrom(reportElement, 'Employee Position'), completed: false},
        }

        if (shouldFetch.positionData.fetch || shouldFetch.employeeData.fetch) {
            listener.setProgress(50, 'Querying the Position table...');
            let posData = await this.getAllPositions(preview);

            let posDataOrdered = [];
            zipped.forEach((wl, i) => {
                posDataOrdered[i] = posData.filter(pos => pos.workRotation?.id === wl.id);
            });

            zipped = this.prepareDataToDisplayInTable(reportElement, zipped, posDataOrdered, CustomReportTable.Position);

            if (shouldFetch.employeeData.fetch) {
                listener.setProgress(75, 'Querying the Employee Position table...');
                zipped = await this.appendEmployeeDataToPositionData(reportElement, posData, posDataOrdered, zipped);
            }
        }
        listener.setProgress(95, 'Finalizing...');
        this.returnData(reportElement, zipped, listener, elementIndex);
    }

    private renderTimeOffTypeReportData(listener: ReportGeneratorListener, reportElement: ServerReportElement, preview: boolean, elementIndex: number) {
        let take = preview ? '10' : '1500';

        let observables = [];
        observables.push(defer(() => this.timeOffService.getTimeOffTypes(0, take)));

        const shouldFetchEmployeeData = this.tablesContainFieldFrom(reportElement, 'Employee');
        if (shouldFetchEmployeeData) {
            observables.push(defer(() => this.employeeService.getEmployeesNew(0, parseInt(take))))
        }

        forkJoin(observables)
        .subscribe(async (res: any[]) => {

                let totData = res[0].data ? res[0].data : [];
                let empData = shouldFetchEmployeeData ? res[1].data : [];

                let zipped = [];
                let topDataOrdered = []
                totData.forEach((tot, i) => {
                    topDataOrdered[i] = tot.policies
                })
                zipped = this.prepareDataToDisplayInTable(reportElement, totData, topDataOrdered, CustomReportTable.TimeOffPolicy)

                this.returnData(reportElement, zipped, listener, elementIndex);
            }
        );
    }

    private appendEmployeeDataToPositionData(reportElement, posData, posDataOrdered, zipped): Promise<any[]> {
        return new Promise((resolve, reject) => {
            let empObservables = [];
            posData.forEach(pos => empObservables.push(defer(() => this.positionsService.getPositionEmployees(pos.id))));

            return forkJoin(empObservables).pipe(
                defaultIfEmpty(null),
            ).subscribe((empData: any[]) => {
                let empDataOrdered = [];
                posDataOrdered.forEach((posArr, i) => {
                    let emps = [];
                    posArr.forEach((pos, j) => {
                        let employeeIndex = posData.findIndex(p => p.id === pos.id);
                        if (employeeIndex > -1) {

                            emps = emps.concat(empData[employeeIndex])
                        }
                    });
                    empDataOrdered[i] = emps;
                });
                let zipped2 = this.prepareDataToDisplayInTable(reportElement, zipped, empDataOrdered, CustomReportTable.EmployeePosition)
                resolve(zipped2);
            });
        });
    }


    getValueFromFieldMapping(element, fieldMapping: string): string {
        let value = element;
        let path = fieldMapping.split('.').map(part => part);
        for (let i = 0; i < path.length; i++) {
            // console.log("value: ", value, "path[i]: ", path[i])
            if (value[path[i]]) {
                value = value[path[i]];
            } else {
                return null;
            }
        }
        return value;
    }

    setValueFromFieldMapping(element, fieldMapping: string, value: string) {
        let path = fieldMapping.split('.').map(part => part);
        let currentObject = element;
        for (let i = 0; i < path.length; i++) {
            if (!currentObject[path[i]]) {
                currentObject[path[i]] = {};
            }
            if (i === path.length - 1) {
                currentObject[path[i]] = value;
            }
            currentObject = currentObject[path[i]];
        }
        return element;
    }


    private beautifyPreviewData(property: String, data: any): any {
        let prettyData = data.filter((item) => item[`${property}`] !== undefined)
        if (prettyData.length > 0) {
            return prettyData.slice(0, 10);
        } else {
            return data.slice(0, 10);
        }
    }


    getAllFieldsForTable(reportElement: ServerReportElement, table: string) {
        let columns = this.reportsMapping.getColumnsFromProperties(reportElement);
        let tableColumns = this.reportsMapping.getTableColumnsFromProperties(columns);
        return tableColumns.filter(column => column.table === table).map(column => column.field);
    }

    getColumnsWithFilters(reportElement: ServerReportElement) {
        let columns = this.reportsMapping.getColumnsFromProperties(reportElement);
        return columns.filter(column => column.filter);
    }

    getColumnsWithFieldType(reportElement: ServerReportElement) {
        let columns = this.reportsMapping.getColumnsFromProperties(reportElement);
        return columns.filter(column => column.fieldType);
    }

    tablesContainFieldFrom(reportElement: ServerReportElement, table: string) {
        let columns = this.reportsMapping.getColumnsFromProperties(reportElement);
        let tableColumns = this.reportsMapping.getTableColumnsFromProperties(columns);
        // console.log(this.tableColumns)
        let res = false;
        tableColumns.map(tableColumns => {
            if (tableColumns.table == table) {
                res = true
            }
        })
        return res
    }

    returnData(reportElement, zipped, listener, elementIndex) {
        zipped = this.formatData(reportElement, zipped);
        zipped = this.filterData(reportElement, zipped);
        let data = {
            data: zipped,
            total: zipped.length,
        }
        listener.setData(data, elementIndex);
    }


    getFieldMapping(reportElement: ServerReportElement, table: string, field: string, dotNotation: boolean) {
        let fieldValue
        let parentTable = this.reportsMapping.getParentTableFromProperties(reportElement)
        if (this.metadataTableIdToTablePropertyNameMap[parentTable][table] && this.metadataTableIdToTablePropertyNameMap[parentTable][table][field]) {

            if (dotNotation && parentTable != table) {
                fieldValue = `${table}.${this.metadataTableIdToTablePropertyNameMap[parentTable][table][field]}`;
            } else {
                fieldValue = this.metadataTableIdToTablePropertyNameMap[parentTable][table][field];
            }

        } else {
            console.error("Field mapping not found for", parentTable, "report, table:", table, ",field:", field);
        }

        return fieldValue;
    }

    toJsIdentifier(str: string): string {
        return str.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_$.]/g, '');
    }

    getColumns(reportElement: ServerReportElement) {
        let columns = this.reportsMapping.getColumnsFromProperties(reportElement);
        return columns;
    }


    formatData(reportElement, data) {
        let parentTable = this.reportsMapping.getParentTableFromProperties(reportElement);
        let columnsWithFieldType = this.getColumnsWithFieldType(reportElement);
        data.forEach((element) => {
            columnsWithFieldType.forEach((column) => {
                try {
                    let fieldMapping = this.getFieldMapping(reportElement, column.table, column.field, false);
                    if (parentTable !== column.table) {
                        fieldMapping = column.table + "." + fieldMapping;
                    }

                    if (column.fieldType?.id === 'DATETIME') {
                        let value = this.getValueFromFieldMapping(element, fieldMapping);

                        if (typeof value === 'string') {
                            if (value.includes(',')) {
                                value = value.split(',')
                                .map(val => val.trim().split('T')[0])
                                .join(', ');
                            } else {
                                value = value.trim().split('T')[0];
                            }
                        } else {
                            value = '';
                        }

                        element[fieldMapping] = value;
                        this.setValueFromFieldMapping(element, fieldMapping, value);
                    }

                    if (column.fieldType?.id === 'BOOLEAN') {
                        let value = this.getValueFromFieldMapping(element, fieldMapping);
                        this.setValueFromFieldMapping(element, fieldMapping, (value === 'true' || value) ? 'Yes' : 'No');
                    }

                    // potentially might have to move to model if more cases like this come up
                    // if (column.table === 'Position' && column.field === 'tfi_CostCentre') {
                    //     let value = "";
                    //     element.costCenters.forEach((costCenter) => {
                    //             value = value.concat(this.getValueFromFieldMapping(costCenter, fieldMapping), element.costCenters.indexOf(costCenter) === element.costCenters.length - 1 ? "" : ", ");
                    //         }
                    //     );
                    //     this.setValueFromFieldMapping(element, fieldMapping, value);
                    // }
                } catch (e) {
                    console.error("Error formatting data for field", column.table, column.field, e);
                }
            });
        });
        return data;
    }


    filterData(reportElement: ServerReportElement, data) {
        let filters = this.getColumnsWithFilters(reportElement);
        let parentTable = this.reportsMapping.getParentTableFromProperties(reportElement);

        if (!filters || filters.length === 0) {
            return data;
        }

        return data.filter((element) => {
            return filters.every((filter) => {

                let fieldMapping = this.getFieldMapping(reportElement, filter.table, filter.field, false);

                if (parentTable !== filter.table) {
                    fieldMapping = filter.table + "." + fieldMapping;
                }

                let value = this.getValueFromFieldMapping(element, fieldMapping);
                if (filter.filter === 'not-empty') {
                    return value !== null && value !== undefined && value !== '';
                } else if (filter.filter.startsWith('like:')) {
                    const pattern = filter.filter.slice(5);
                    return value !== null && value !== undefined && value.toLowerCase().includes(pattern.toLowerCase());
                } else if (filter.filter.startsWith('not-like:')) {
                    const pattern = filter.filter.slice(9);
                    return value === null || value === undefined || !value.toLowerCase().includes(pattern.toLowerCase());
                } else if (filter.filter === 'empty') {
                    return value === null || value === undefined || value === '';
                }
                return true;
            });
        });
    }


    private renderPieChartEmployeeData(listener: ReportGeneratorListener, reportElement: ServerReportElement, columns: CustomReportColumn[], preview: boolean, elementIndex: number) {
        let take = preview ? '150' : '1500';
        listener.setProgress(25, 'Querying the Employees Table...');
        this.employeeService.getEmployeesNew(0, parseInt(take))
        .subscribe(
            res => {
                const empData = res.data;

                console.log("columns", columns);

                let chartType = this.reportsMapping.getChartTypeFromProperties(reportElement);
                console.log("chartType", chartType);

                let pieChartData = this.dataToPieChartCategories(reportElement, columns, empData);
                console.log("pieChartData", pieChartData);

                listener.setData(pieChartData, elementIndex);
            })
    }

    private renderPieChartPositionData(listener: ReportGeneratorListener, reportElement: ServerReportElement, columns: CustomReportColumn[], preview: boolean, elementIndex: number) {
        let take = preview ? '150' : '1500';

        this.positionsService.getPositions(0, take)
        .subscribe(
            res => {
                let posData = res.data
                // console.log("posData", posData)
                let pieChartData = this.dataToPieChartCategories(reportElement, columns, posData);
                // console.log("pieChartData", pieChartData)
                listener.setData(pieChartData, elementIndex);
            }
        );
    }

    private renderPieChartEmploymentRecordData(listener: ReportGeneratorListener, reportElement: ServerReportElement, columns: CustomReportColumn[], preview: boolean, elementIndex: number) {
        let take = preview ? '10' : '100';
        // listener.setProgress(25, 'Querying the Employees Table...');
        this.employeeService.getEmployeesNew(0, parseInt(take))
        .subscribe(
            res => {
                const empData = res.data;

                const observables: Observable<any>[] = empData.map(employee => defer(() => this.employmentRecordsService.getEmploymentRecords(employee.id)));

                forkJoin(observables)
                .subscribe(
                    (empRecData) => {
                        let zipped = this.prepareDataToDisplayInTable(reportElement, empData, empRecData, CustomReportTable.EmploymentRecord)
                        console.log("zipped", zipped)

                        let pieChartData = this.dataToPieChartCategories(reportElement, columns, zipped);
                        console.log("pieChartData", pieChartData)
                        listener.setData(pieChartData, elementIndex);
                    }, error => {
                        console.log("error: ", error)
                    })

            }, error => {
                console.log("error: ", error)
            }
        )
    }

    private renderPieChartCompensationData(listener: ReportGeneratorListener, reportElement: ServerReportElement, columns: CustomReportColumn[], preview: boolean, elementIndex: number) {
        let take = preview ? '10' : '1500';
        // listener.setProgress(25, 'Querying the Employees Table...');
        this.employeeService.getEmployeesNew(0, parseInt(take))
        .subscribe(
            res => {
                const empData = res.data;

                const observables: Observable<any>[] = empData.map(employee => defer(() => this.employeeCompensationService.getCompensation(employee.id)));

                forkJoin(observables)
                .subscribe(
                    (compData) => {
                        let zipped = this.prepareDataToDisplayInTable(reportElement, empData, compData, CustomReportTable.EmploymentRecord)
                        console.log("zipped", zipped)

                        let pieChartData = this.dataToPieChartCategories(reportElement, columns, zipped);
                        console.log("pieChartData", pieChartData)
                        listener.setData(pieChartData, elementIndex);
                    }, error => {
                        console.log("error: ", error)
                    })

            }, error => {
                console.log("error: ", error)
            }
        )
    }

    private renderPieChartOtherCompensationsData(listener: ReportGeneratorListener, reportElement: ServerReportElement, columns: CustomReportColumn[], preview: boolean, elementIndex: number) {
        let take = preview ? '10' : '1500';
        // listener.setProgress(25, 'Querying the Employees Table...');
        this.employeeService.getEmployeesNew(0, parseInt(take))
        .subscribe(
            res => {
                const empData = res.data;

                const observables: Observable<any>[] = empData.map(employee => defer(() => this.employeeOtherCompensationService.getOtherCompensations(employee.id)));

                forkJoin(observables)
                .subscribe(
                    (otherCompData) => {

                        // let zipped = this.prepareDataToDisplayInTable(reportElement, empData, empRecData, CustomReportTable.EmploymentRecord)
                        // console.log("zipped", zipped)
                        //
                        // let pieChartData = this.dataToPieChartCategories(reportElement, columns, zipped);
                        // console.log("pieChartData", pieChartData)
                        // listener.setData(pieChartData, elementIndex);
                    }, error => {
                        console.log("error: ", error)
                    });

            }, error => {
                console.log("error: ", error)
            }
        )
    }


    dataToPieChartCategories(reportElement: ServerReportElement, columns: CustomReportColumn[], data: any[]) {
        let pieChartCategories: any = [];

        // let column = columns[0];
        columns.forEach(column => {
            data.forEach(emp => {
                let value = "Not specified";
                // let fieldMapping = this.getFieldMapping2(parentTable, column.table, column.field);
                let fieldMapping = this.getFieldMapping(reportElement, column.table, column.field, false);
                if (fieldMapping.includes('.')) {
                    let mappingArr = fieldMapping.split('.');

                    if (emp[mappingArr[0]] && emp[mappingArr[0]][mappingArr[1]]) {
                        value = emp[mappingArr[0]][mappingArr[1]];
                    }
                } else {
                    value = emp[fieldMapping]
                }
                if (value) {
                    let index = pieChartCategories.findIndex(item => item.category === value);
                    if (index > -1) {
                        pieChartCategories[index].value++;
                    } else {
                        pieChartCategories.push({category: value, value: 1, colorField: ''});
                    }
                }
            });
        });
        console.log("pieChartCategories", pieChartCategories)
        if (pieChartCategories.length > 0) {
            let groupThreshold = this.reportsMapping.getGroupingThresholdTableFromProperties(reportElement);

            const totalValue = pieChartCategories.reduce((sum, item) => sum + item.value, 0);

            const belowThreshold = pieChartCategories.filter(item => (item.value / totalValue) * 100 < groupThreshold);
            const aboveThreshold = pieChartCategories.filter(item => (item.value / totalValue) * 100 >= groupThreshold);

            const otherValue = belowThreshold.reduce((sum, item) => sum + item.value, 0);

            let pieChartData = aboveThreshold;
            if (belowThreshold.length > 0) {
                pieChartData = aboveThreshold.concat({
                    category: 'Other',
                    value: otherValue,
                    colorField: ''
                });
            }

            let availableColors = this.reportsMapping.getColorScheme(reportElement).hex;
            pieChartData.forEach((item, index) => {
                item.colorField = availableColors[index % availableColors.length];
            });

            return pieChartData;
        } else {
            return [{
                category: 'No data',
                value: 1,
            }];
        }
    }

    metadataEmployeeTable = {
        tfi_Birthday: "birthday",
        tfi_EmpCellPhone: "cellPhone",
        tfi_ChangeReason: "version.changeReason.text",
        tfi_ChangeReasonComments: "version.comments",
        tfi_EmpCity: "city",
        tfi_EmpCountry: "country.name",
        tfi_EmpCountryOfBirth: "countryOfBirth.name",
        tfi_CulturalAffiliation: "culturalAffiliation.text",
        tfi_EmployeeId: "customClientId",
        tfi_EmpDisability: "disability.text",
        tfi_EmpEmail: "email",
        tfi_EmpEmail2: "email2",
        tfi_EmpEqualEmploymentOpportunity: "equalEmploymentOpportunity.text",
        tfi_EmpFacebookUrl: "facebookUrl",
        tfi_FirstName: "firstName",
        tfi_Gender: "gender.text",
        tfi_EmpHomePhone: "homePhone",
        tfi_EmpIdentityNumber: "identityNumber",
        tfi_EmpInternetAddress: "internetAddress",
        tfi_LastName: "lastName",
        tfi_EmpLinkedInUrl: "linkedInUrl",
        tfi_MaidenName: "maidenName",
        tfi_MaritalStatus: "maritalStatus.text",
        tfi_MiddleName: "middleName",
        tfi_EmpMunicipality: "municipality",
        tfi_EmpNationality: "nationality.text",
        tfi_EmpNeighbourhood: "neighbourhood",
        tfi_EmpOrganization: "organization.name",
        tfi_EmpPoBox: "poBox",
        tfi_PreferredName: "preferredName",
        tfi_EmpPreferredPronoun: "preferredPronoun",
        tfi_EmpProvince: "province",
        tfi_EmpRegion1: "region1",
        tfi_EmpRegion2: "region2",
        tfi_EmpRegion3: "region3",
        tfi_EmpRegionOfBirth: "regionOfBirth",
        tfi_EmpRegisteredDisabled: "registeredDisabled.text",
        tfi_Religious: "religiousAffiliation.text",
        tfi_EmpSexualOrientation: "sexualOrientation.text",
        tfi_EmpSocialSecurityNumber: "socialSecurityNumber",
        tfi_EmpStreetAddress: "streetAddress",
        tfi_EmpStreetAddress2: "streetAddress2",
        tfi_EmpTimezone: "timezone.text",
        tfi_Title: "title.text",
        tfi_EmpTownOfBirth: "townOfBirth",
        tfi_EmpTwitterHandle: "twitterHandle",
        tfi_EmpUserId: "userId",
        tfi_Username: "username",
        tfi_EmpVeteranStatus: "veteranStatus.text",
        tfi_EmpWorkPhone: "workPhone",
        tfi_EmpZip: "zip",
    }

    metadataPositionTable = {
        tfi_PosAssistant: "assistant",
        tfi_PositionChangeReason: "version.changeReason.text",
        tfi_PositionChangeReasonComments: "version.comments",
        tfi_PosClientPositionId: "clientPositionId",
        tfi_CostCentre: "costCenters.costCenter.name",
        tfi_PosDepartment: "department.text",
        tfi_PosDivision: "division.text",
        tfi_PosEmployeeCategory: "employeeCategory.text",
        tfi_PosEmploymentGroup: "employmentGroup.text",
        tfi_PosEmploymentType: "employmentType.text",
        tfi_PosEnforceSlotLimit: "enforceSlotLimit",
        tfi_PosFte: "fte",
        tfi_PosFunctionalJobTitle: "functionalJobTitle.text",
        // tfi_PostHiredAt: "",
        tfi_PosJobBand: "jobBand.text",
        tfi_PosJobFamily: "jobFamily.text",
        tfi_PosJobGroup: "jobGroup.text",
        tfi_PosJobStep: "jobStep.text",
        tfi_PosJobType: "jobType.text",
        tfi_PosMarketPosition: "marketPosition.text",
        tfi_PosMarketPositionTitle: "marketPositionTitle.text",
        tfi_PosMarketView: "marketView.text",
        tfi_PosNumberOfSlots: "numberOfSlots",
        tfi_PosOrganization: "organization.name",
        tfi_PositionParent: "parentPosition.name",
        tfi_PosName: "name",
        tfi_PosProjectTeam: "projectTeam.text",
        tfi_PosRegion: "region.text",
        tfi_PosShiftCode: "shiftCode.text",
        tfi_PosSubDivision: "subDivision.text",
        tfi_PosUnionCode: "unionCode.text",
        tfi_PosUdd1: "udd1",
        tfi_PosUdd2: "udd2",
        tfi_PosUdl1: "udl1.text",
        tfi_PosUdl2: "udl2.text",
        tfi_PosUdt1: "udt1",
        tfi_PosUdt2: "udt2",
        tfi_PosWorkLocation: "workLocation.name",
        tfi_PosWorkRotation: "workRotation.name",
        tfi_PosWorkRotationStartDate: "workRotationStartDate",
        tfi_StartDate: "startDate",
        tfi_EndDate: "endDate",
    }

    employeeLinkTable = {
        startDate: "startDate",
        endDate: "endDate",
        startReason: "startReason",
        endReason: "endReason",
        firstName: "employee.firstName",
        lastName: "employee.lastName",
        id: "employee.id",
    }

    employeePositionTable = {
        tfi_PeEndDate: "endDate",
        tfi_PeEndReason: "endReason.text",
        tfi_PeStartDate: "startDate",
        tfi_PeStartReason: "startReason.text",
        tfi_PeFirstName: "employee.firstName",
        tfi_PeLastName: "employee.lastName",
        tfi_PePositionName: "position.name",
    }

    metadataTableIdToTablePropertyNameMap = {
        'Employee': {
            'Employee': this.metadataEmployeeTable,
            'Employee Position': this.employeePositionTable,
            'Employment Record': {
                tfi_ApprenticeshipProgramEndDate: "apprenticeshipProgramEndDate",
                tfi_ApprenticeshipProgramStartDate: "apprenticeshipProgramStartDate",
                tfi_ErBudgetAuthority: "budgetAuthority",
                tfi_ErChangeReason: "version.changeReason.text",
                tfi_ErChangeReasonComments: "version.comments",
                tfi_ErCodeOfConductSigned: "codeOfConductSigned.text",
                tfi_ErCodeOfConductSignedOn: "codeOfConductSignedOn",
                tfi_GraduateProgramEndDate: "graduateProgramEndDate",
                tfi_GraduateProgramMember: "graduateProgramMember.text",
                tfi_GraduateProgramStartDate: "graduateProgramStartDate",
                tfi_ErHireCategory: "hireCategory.text",
                tfi_ErHireDate: "hireDate",
                tfi_ErHireDetailComments: "hireDetailComments",
                tfi_ErHireDetailsUdd1: "hireDetailsUdd1",
                tfi_ErHireDetailsUdd2: "hireDetailsUdd2",
                tfi_ErHireDetailsUdf1: "hireDetailsUdf1.text",
                tfi_ErInductionCompletedOn: "inductionCompletedOn",
                tfi_ErMedicalCompleted: "medicalCompleted.text",
                tfi_ErMedicalCompletedDate: "medicalCompletedDate",
                tfi_ErNoticePeriodLength: "noticePeriodLength",
                tfi_ErNoticePeriodUnits: "noticePeriodUnits.text",
                tfi_ErOffice: "office",
                tfi_ErOffice2: "office2",
                tfi_ErPayrollCode: "payrollCode.text",
                tfi_ErPayrollNumber: "payrollNumber",
                tfi_ErProbationPeriodEnd: "probationPeriodEnd",
                tfi_ErProbationPeriodLength: "probationPeriodLength",
                tfi_ErProbationPeriodUnits: "probationPeriodUnit.name",
                tfi_ErRecruitmentAgency: "recruitmentAgency.text",
                tfi_ErReHireEligibility: "reHireEligibility.text",
                tfi_ErRehireEligibilityComments: "rehireEligibilityComments",
                tfi_ErReHireReason: "reHireReason.text",
                tfi_ErReplacementFor: "",
                tfi_ErSearchFee: "searchFee",
                tfi_ErSecurityClearance: "securityClearance.text",
                tfi_ErStartReason: "startReason.text",
                tfi_SubStatus: "subStatus.text",
                tfi_SupervisorLegacyId: "supervisorLegacyId",
                tfi_ErTerminationAcceptedByDate: "terminationAcceptedByDate",
                tfi_ErTerminationAcceptedByName: "terminationAcceptedByName",
                tfi_ErTerminationBenefitsEnd: "terminationBenefitsEnd",
                tfi_ErTerminationBenefitsEndOfNotice: "terminationBenefitsEndOfNotice",
                tfi_ErTerminationComments: "terminationComments",
                tfi_ErTerminationDateActual: "terminationDateActual",
                tfi_ErTerminationDateProjected: "terminationDateProjected",
                tfi_ErTerminationDateNotified: "terminationDateNotified",
                tfi_ErTerminationItConfirmation: "terminationItConfirmation",
                tfi_ErTerminationLastDateOnSite: "terminationLastDateOnSite",
                tfi_ErTerminationOtherPayment: "terminationOtherPayment",
                tfi_ErTerminationPayInLieu: "terminationPayInLieu",
                tfi_ErTerminationReason: "terminationReason.text",
                tfi_ErTerminationRecordOfEmploymentReason: "terminationRecordOfEmploymentReason.text",
                tfi_ErTerminationRecordOfEmploymentComment: "terminationRecordOfEmploymentComment",
                tfi_ErTerminationRehireReason: "terminationRehireReason.text",
                tfi_ErTerminationRehireRecommend: "terminationRehireRecommend.text",
                tfi_ErTerminationSeveranceAmount: "terminationSeveranceAmount",
                tfi_ErTerminationSpecialCircumstances: "terminationSpecialCircumstances.text",
                tfi_ErTerminationVacationPayout: "terminationVacationPayout",
                tfi_ErTerminationVoluntary: "terminationVoluntary",
                tfi_ErHireDetailsUdf2: "hireDetailsUdf2.text",
                tfi_ErHireDetailsUdf3: "hireDetailsUdf3.text",
                tfi_ErWorkCellNumber: "cellNumber",
                tfi_ErEmail: "email",
                tfi_ErWorkPhoneNumber: "phoneNumber",

            },
            'Compensation': {
                tfi_CompensationAdjustedFte: "adjustedFte",
                tfi_CompensationBaseSalaryFteAdjusted: "baseSalaryFteAdjusted",
                tfi_CoChangeReason: "version.changeReason.text",
                tfi_CohangeReasonComments: "version.comments",
                tfi_CompensationComments: "comments",
                tfi_CompensationRatio: "compensationRatio",
                tfi_CompensationUddf1: "compensationUddf1",
                tfi_CompensationUddf2: "compensationUddf2",
                tfi_CompensationUdf1: "compensationUdf1.text",
                tfi_CompensationUdf2: "compensationUdf2.text",
                tfi_CompensationUdtf1: "compensationUdtf1",
                tfi_CompensationUdtf2: "compensationUdtf2",
                tfi_CompensationCountry: "country.name",
                tfi_CompensationHoursPerWeek: "hoursPerWeek",
                tfi_CompensationLtiPsu: "ltiPsu.text",
                tfi_CompensationltiRsu: "ltiRsu.text",
                tfi_CompensationLtiStockOption: "ltiStockOption.text",
                tfi_CompensationMarketView: "marketView.text",
                tfi_CompensationOvertimeEligible: "overtimeEligible.text",
                tfi_CompensationPayFrequency: "payFrequency.text",
                tfi_CompensationPayGrade: "payGrade.text",
                tfi_CompensationPayLevel: "payLevel.text",
                tfi_CompensationPayRate: "payRate",
                tfi_CompensationPayRateCurrency: "payRateCurrency.id",
                tfi_CompensationPayRateInterval: "payRateInterval.text",
                tfi_CompensationPayScale: "payScale.text",
                tfi_CompensationPayType: "payType.text",
                tfi_PayrollId: "payrollId.text",
                tfi_CompensationRoleCategory: "roleCategory.text",
                tfi_CompensationRoleGroup: "roleGroup.text",
                tfi_CompensationSalaryNonGmg: "salaryNonGmg",
                tfi_CompensationSalaryNonGmgCurrency: "salaryNonGmgCurrency.id",
                tfi_CompensationSalaryNonGmgFteAdjusted: "salaryNonGmgFteAdjusted",
                tfi_CompensationSalaryOther: "salaryOther",
                tfi_CompensationSalaryOtherCurrency: "salaryOtherCurrency.id",
                tfi_CompensationSalaryOtherFteAdjusted: "salaryOtherFteAdjusted",
                tfi_CompensationSalarySystem: "salarySystem",
                tfi_CompensationSalarySystemCurrency: "salarySystemCurrency.id",
                tfi_CompensationSalarySystemFteAdjusted: "salarySystemFteAdjusted",
                tfi_CompensationSterlineEquivalent: "sterlineEquivalent",
                tfi_CompensationStiActualAmount: "stiActualAmount",
                tfi_CompensationStiActualPercentage: "stiActualPercentage",
                tfi_CompensationStiCurrency: "stiCurrency.id",
                tfi_CompensationStiIncentivePlan: "stiIncentivePlan.text",
                tfi_CompensationStiTargetAmount: "stiTargetAmount",
                tfi_CompensationStiTargetPercentage: "stiTargetPercentage",
                tfi_CompensationTerm: "term.text",
                tfl_CompensationFte: "fte",
            },
            'Other Compensations': {
                tfi_OtherCompensationAmount: "amount",
                tfi_OtherCompensationPercentage: "percentage",
                tfi_OtherCompensationCurrency: "currency.id",
                tfi_OtherCompensationEndDate: "endDate",
                tfi_OtherCompensationPaymentCycle: "paymentCycle.text",
                tfi_OtherCompensationStartDate: "startDate",
                tfi_OtherCompensationType: "compensationType.text",
                tfi_OCompChangeReason: "version.changeReason.text",
                tfi_OCompReasonComments: "version.comments",
                tfi_OtherCompensationComments: "comments",
            },
            'Time Off History': {
                tfi_AbsenceNumber: "absenceNumber",
                tfi_AbsenceChangeReason: "version.changeReason.text",
                tfi_AbsenceChangeReasonComments: "version.comments",
                tfi_AbsenceComments: "comments",
                tfi_AbsenceEndDate: "endDate",
                tfi_AbsenceEndTime: "endTime",
                tfi_AbsenceHours: "hours",
                tfi_ReturnToWork: "returnToWork",
                tfi_AbsenceStartDate: "startDate",
                tfi_AbsenceStartTime: "startTime",
                tfi_AbsenceUdl1: "udl1.text",
                tfi_TimeOffType: "timeOffType.name"
            },
            'Emergency Contacts': {
                tfi_EcValidTo: "validTo",
                tfi_EcBirthday: "birthday",
                tfi_EcCellPhone: "cellPhone",
                tfi_EcChangeReason: 'version.changeReason.text',
                tfi_EcChangeReasonComments: 'version.comments',
                tfi_EcCity: "city",
                tfi_EcCountry: "country.name",
                tfi_EcStreetAddress: "streetAddress",
                tfi_EcFirstName: "firstName",
                tfi_EcGender: "gender.text",
                tfi_EcZip: "zip",
                tfi_EcIsPrimary: "isPrimary",
                tfi_EcLastName: "lastName",
                tfi_EcMiddleName: "middleName",
                tfi_EcPreferredName: "preferredName",
                tfi_EcEmail: "email",
                tfi_EcRelationshipToEmployee: "relationshipToEmployee.text",
                tfi_EcHomePhone: "homePhone",
                tfi_EcTitle: "title.text",
                tfi_EcValidFrom: "validFrom",
                tfi_EcWorkPhone: "workPhone",
                tfi_EcProvince: "province",
            },
            'Family Dependants': {
                tfi_FBirthday: "birthday",
                tfi_FmlyCellPhone: "cellPhone",
                tfi_FaChangeReason: "version.changeReason.text",
                tfi_FaChangeReasonComments: "version.comments",
                tfi_FmlyCity: "city",
                tfi_FComments: "comments",
                tfi_FmlyCountry: "country.name",
                tfi_FmlyEmail: "email",
                tfi_FFirstName: "firstName",
                tfi_FGender: "gender.text",
                tfi_FmlyHomePhone: "homePhone",
                tfi_FIsDependant: "isDependent",
                tfi_FLastName: "lastName",
                tfi_FMiddleName: "middleName",
                tfi_FPreferredName: "preferredName",
                tfi_FmlyProvince: "province",
                tfi_FRelationShipToEmployee: "relationshipToEmployee.text",
                tfi_FmlyStreetAddress: "streetAddress",
                tfi_FTitle: "title.text",
                tfi_FmlyWorkPhone: "workPhone",
                tfi_FmlyZip: "zip"
            },
            'Medical Tests': {
                tfi_MtChangeReason: 'version.changeReason.text',
                tfi_MtChangeReasonComments: 'version.comments',
                tfi_ConductedBy: "conductedBy.text",
                tfi_Frequency: "frequency.text",
                tfi_MedComments: "comments",
                tfi_NextTesetDate: "nextTestDate",
                tfi_Results: "results.text",
                tfi_MedicalStatus: "status.text",
                tfi_TestDate: "testDate",
                tfi_TestName: "testName.text",
            },
            'Visas and Permits': {
                tfi_VpChangeReason: 'version.changeReason.text',
                tfi_VpChangeReasonComments: 'version.comments',
                tfi_VpCurrentStatus: "currentStatus.text",
                tfi_VpDateExpired: "dateExpired",
                tfi_VpDn: "documentNumber",
                tfi_VpHRR: "humanResourceRep",
                tfi_VpIssuedate: "issueDate",
                tfi_IC: "issuingCountry.name",
                tfi_VpNameOfHolder: "nameOfHolder",
                tfi_VpPoI: "placeOfIssue",
                tfi_VpRtE: "relationshipToEmployee.text",
                tfi_VpVisaDocumentType: "visaDocumentType.text",
            },
            'Languages': {
                tfi_LaChangeReason: 'version.changeReason.text',
                tfi_LaChangeReasonComments: 'version.comments',
                tfi_LangComments: "comments",
                tfi_ConvLevel: "conversationalLevel.text",
                tfi_LanDateUpdated: "dateUpdated",
                tfi_LanguagesLanguage: "dialect.text",
                tfi_ReadWriteLevel: "readingWritingLevel.text",
            },
            'Training and Certification': {
                tfi_TcCertificateno: "certificateNo",
                tfi_TcCPoI: "certificatePlaceOfIssue",
                tfi_TcChangeReason: 'version.changeReason.text',
                tfi_TcChangeReasonComments: 'version.comments',
                tfi_TcComments: "comments",
                tfi_TcCountryOfIssue: "countryOfIssue.name",
                tfi_TcDateCompleted: "dateCompleted",
                tfi_TcExpiryDate: "expiryDate",
                tfi_TcInstitution: "institution.text",
                tfi_TcIssueDate: "issueDate",
                tfi_TcTrainingDescription: "trainingDescription.text",
                tfi_TcTrainingFunction: "trainingFunction.text",
                tfi_TcTrainingName: "trainingName",
                tfi_TcTrainingProvider: "trainingProvider.text",
                tfi_tcTrainingType: "trainingType.text",
                tfi_TcVehicleType: "vehicleType.text",
            },
            'Education': {
                tfi_EdChangeReasonComments: 'version.comments',
                tfi_EducationChangeReason: 'version.changeReason.text',
                tfi_EducationComments: 'comments',
                tfi_EducationDateCompleted: "dateCompleted",
                tfi_EducationDesignation: "designation.text",
                tfi_EducationDiscipline: "discipline.text",
                tfi_EducationInsititution: "institution",
                tfi_EductionQualification: "qualificationType.text",
                tfi_EducationQualificationName: "qualificationName",
            },
            'Work History': {
                tfi_WeChangeReason: 'version.changeReason.text',
                tfi_WeChangeReasonComments: 'version.comments',
                tfi_WeCompany: "company",
                tfi_WeDepartment: "department",
                tfi_WeEndDate: "endDate",
                tfi_WeJobTitle: "jobTitle",
                tfi_WeManager: "manager",
                tfi_WeStartDate: "startDate",
                tfi_WeUdt1: "udt1",
                tfi_WeUdt2: "udt2",
                tfi_WeUdt3: "udt3",
                tfi_WeUdt4: "udt4",
                tfi_WeWorkLocation: "workLocation",
            },
            'Professional Expertise': {
                tfi_AreadOfExpertise: "areaOfExpertise.text",
                tfi_PeChangeReason: 'version.changeReason.text',
                tfi_PeChangeReasonComments: 'version.comments',
                tfi_ProfComments: "comments",
                tfi_Insitution: "institution",
                tfi_ProfLevel: "proficiencyLevel.text",
            },
            'Associations': {
                tfi_AssociationOrIns: "associationOrInstitution",
                tfi_AsChangeReason: 'version.changeReason.text',
                tfi_AsChangeReasonComments: 'version.comments',
                tfi_AssociationComments: "comments",
                tfi_AssociationDate: "date",
                tfi_DesignationOrAward: "designationOrAward",
            },
            'Company Assets': {
                tfi_AssetType: "assetType.text",
                tfi_CaChangeReason: 'version.changeReason.text',
                tfi_CahangeReasonComments: 'version.comments',
                tfi_AssetComments: "comments",
                tfi_AssetDateAssigned: "dateAssigned",
                tfi_AssetDateReturned: "dateReturned",
            },
            'Relocations': {
                tfi_ReChangeReason: 'version.changeReason.text',
                tfi_ReReasonComments: 'version.comments',
                tfi_Relocation: 'comments',
                tfi_RelocationDateInitiated: "dateInitiated",
                tfi_RelocationPointOfOrigin: "pointOfOrigin.text",
                tfi_RelocationReceivingLocation: "receivingLocation.text",
                tfi_RelocationEndDate: "relocationEndDate",
                tfi_RelocationStartDate: "relocationStartDate",
                tfi_RelocationSendingLocation: "sendingLocation.text",
                tfi_RelocationTotalCosts: "totalCosts",
            },
            'Grievances': {
                tfi_GrChangeReason: 'version.changeReason.text',
                tfi_GrhangeReasonComments: 'version.comments',
                tfi_GrievanceDate: "date",
                tfi_GrievanceDescription: "description",
                tfi_GrievanceFiledBy: "filedBy.text",
                tfi_GrievanceFinalResolution: "finalResolution.text",
                tfi_GrievanceShortDescription: "shortDescription",
                tfi_GrievanceStatus: "status.text",
                tfi_GrievanceType: "type.text",
            },
            'Injury Illness': {
                tfi_IiCaseDescription: "caseDescription.text",
                tfi_IiChangeReason: 'version.changeReason.text',
                tfi_IiReasonComments: 'version.comments',
                tfi_IiComments: 'comments',
                tfi_IiDateReported: "dateReported",
                tfi_IiDayOfWeek: "dayOfWeek.text",
                tfi_IiDaysOffWork: "daysOffWork",
                tfi_IiGovernmentReportable: "governmentReportable.text",
                tfi_IiInjuryIllnessDate: "injuryIllnessDate",
                tfi_IiLeaveEnd: "leaveEnd",
                tfi_IiLeaveStart: "leaveStart",
                tfi_IiOccupationalIllness: "occupationalIllness.text",
                tfi_IiOccuredOnEmployeePremise: "occuredOnEmployeePremise.text",
                tfi_IiOccurenceCountry: "occurenceCountry.name",
                tfi_IiOccurenceLocation: "occurenceLocation.text",
                tfi_IiOccurenceType: "occurenceType.text",
                tfi_IiPartOfBody: "partOfBody.text",
                tfi_IiStatus: "status.text",
                tfi_IiTimeOfIncident: "timeOfIncident",
            },
            'Bank Details': {
                tfi_BankAccountName: "bankAccountName",
                tfi_BankAccountNumber: "bankAccountNumber",
                tfi_BankName: "bankName",
                tfi_BankSortCode: "bankSortCode",
                tfi_BIG: "big",
                tfi_BuildingSocietyRollNo: "buildingSocietyRollNo",
                tfi_BdChangeReason: "version.changeReason.text",
                tfi_BdChangeReasonComments: "version.comments",
                tfi_IBAN: "iban",
                tfi_SWIFT: "swift",
            },
            'Payroll Details': {
                tfi_PayRollChangeReason: "version.changeReason.text",
                tfi_PayChangeReasonComments: "version.comments",
                tfi_FixedEndDate: "fixedEndDate",
                tfi_LegacyId: "legacyId",
                tfi_PayChangeReason: "payChangeReason.text",
                tfi_PayElementType: "payElementType.text",
                tfi_PayFromDate: "payFromDate",
                tfi_PayrollCode: "payrollCode.text",
                tfi_PayrollNextNumber: "payrollNextNumber",
                tfi_PayrollNumber: "payrollNumber",
                tfi_TaxId: "taxId",
                tfi_TaxIdSecondary: "taxIdSecondary",
                tfi_PayrollUddf1: "payrollUddf1",
                tfi_PayrollUddf2: "payrollUddf2",
                tfi_PayrollUdf1: "payrollUdf1.text",
                tfi_PayrollUdf2: "payrollUdf2.text",
                tfi_PayrollUdf3: "payrollUdf3.text",
                tfi_PayrollUdf4: "payrollUdf4.text",
                tfi_PayrollUdf5: "payrollUdf5.text",
                tfi_PayrollUdtf1: "payrollUdtf1",
                tfi_PayrollUdtf2: "payrollUdtf2",
            },
            'Goals': {
                tfi_PEgActualCompletionDate: "actualCompletionDate",
                tfi_PEgDescription: "description",
                tfi_PEgExpectedCompletionDate: "expectedCompletionDate",
                tfi_PEgObjective: "objective",
                tfi_PEgGoalType: "goalType.name",
                tfi_PEgParentGoal: "parent.name",
                tfi_PEgPercentageComplete: "percentageComplete",
                tfi_PEgWeight: "weight",
                tfi_PEgGoal: "goalPlan.name",
            },
            'Manager': {
                name: "name",
            }
        },
        'Position': {
            Employee: {
                tfi_Birthday: "birthday",
                tfi_EmpCellPhone: "cellPhone",
                tfi_ChangeReason: "",
                tfi_ChangeReasonComments: "",
                tfi_EmpCity: "city",
                tfi_EmpCountry: "country",
                tfi_EmpCountryOfBirth: "countryOfBirth",
                tfi_CulturalAffiliation: "",
                tfi_EmployeeId: "",
                tfi_EmpDisability: "",
                tfi_EmpEmail: "email",
                tfi_EmpEmail2: "email2",
                tfi_EmpEqualEmploymentOpportunity: "",
                tfi_EmpFacebookUrl: "",
                tfi_FirstName: "firstName",
                tfi_Gender: "gender.text",
                tfi_EmpHomePhone: "homePhone",
                tfi_EmpIdentityNumber: "identityNumber",
                tfi_EmpInternetAddress: "",
                tfi_LastName: "lastName",
                tfi_EmpLinkedInUrl: "",
                tfi_MaidenName: "maidenName",
                tfi_MaritalStatus: "",
                tfi_MiddleName: "middleName",
                tfi_EmpMunicipality: "",
                tfi_EmpNationality: "nationality",
                tfi_EmpNeighbourhood: "",
                tfi_EmpOrganization: "",
                tfi_EmpPoBox: "",
                tfi_PreferredName: "preferredName",
                tfi_EmpPreferredPronoun: "preferredPronoun",
                tfi_EmpProvince: "",
                tfi_EmpRegion1: "",
                tfi_EmpRegion2: "",
                tfi_EmpRegion3: "",
                tfi_EmpRegionOfBirth: "",
                tfi_EmpRegisteredDisabled: "",
                tfi_Religious: "",
                tfi_EmpSexualOrientation: "",
                tfi_EmpSocialSecurityNumber: "",
            },
            Position: this.metadataPositionTable,
            EmploymentRecord: {},
        },
        'Cost Centre': {
            'Cost Centre': {
                // Id: "id",
                Name: "name",
                Code: "code"
            },
            Position: this.metadataPositionTable,
            'Employee Position': this.employeePositionTable,
        },
        'Organization Structure': {
            'Organization Structure': {
                Id: "id",
                Name: "name",
                organizationType: "organizationType.name",
                parentOrganization: "parentOrganization.name",
                startDate: "startDate",
                endDate: "endDate",
            },
            'Employee': this.metadataEmployeeTable,
            'Position': this.metadataPositionTable,
        },
        'Work Location': {
            'Work Location': {
                tfi_WlChangeReason: "",
                tfi_WlChangeReasonComments: "",
                tfi_WlCity: "city",
                tfi_WlCountry: "country.name",
                tfi_WlName: "name",
                tfi_WlProvince: "province",
                tfi_WlStreetAddress: "streetAddress",
                tfi_WlZip: "zip",
            },
            Position: this.metadataPositionTable,
            'Employee Position': this.employeePositionTable,
        },
        'Work Rotation': {
            'Work Rotation': {
                Id: "id",
                Name: "name",
                positionsUsingRotation: "positionsUsingRotation",
            },
            Position: this.metadataPositionTable,
            'Employee Position': this.employeePositionTable,
        },
        'Time Off Type': {
            'Time Off Type': {
                Id: "id",
                Name: "name",
                positionsUsingRotation: "positionsUsingRotation",
            },
            'Time Off Policy': {
                Id: "id",
                Name: "name",
                disabled: "disabled",
                accruedAt: "accruedAt.name",
                fteProrated: "fteProrated",
                carryOverMonth: "carryOverMonth",
                carryOverDay: "carryOverDay",
                carryOverUseHireDate: "carryOverUseHireDate",
            },
            Employee: this.metadataEmployeeTable,
        },
    }
}
