import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { Form } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-letter-delivery-method-dialog',
  templateUrl: './letter-delivery-method-dialog.component.html',
  styleUrls: ['./letter-delivery-method-dialog.component.scss']
})
export class LetterDeliveryMethodDialogComponent implements OnInit {
  @Input() formTitle: string;
  @Input() formId: string;
  @Input() changeReasonFormId: string;
  @Input() formData: any;
  @Output() emitFormData: EventEmitter<void> = new EventEmitter<void>();
  
  getFormData: boolean = false;
  formValid: boolean = false;
  loadingFormDetails: boolean = true;
  formPristine: boolean = true;
  formDetails: Form;

  form: FormGroup;

  get parameters(): FormArray {
    return this.form.get('parameters') as FormArray;
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private metadataFormService: MetadataFormService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<LetterDeliveryMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.formTitle = data.formTitle;
    this.formId = data.formId;
    this.changeReasonFormId = data.changeReasonFormId;
    this.formData = data.formData;

    // Initialize the form
    this.form = this.formBuilder.group({
      parameters: this.formBuilder.array([])
    });

    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.dialogRef.close();
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  } 

  ngOnInit(): void {
    this.getFormDetails();
  }

  addKeyValuePair(): void {
    const keyValuePair = this.formBuilder.group({
      key: '',
      value: ''
    });
    this.parameters.push(keyValuePair);
  }

  removeKeyValuePair(index: number): void {
    this.parameters.removeAt(index);
  }

  // Get the form details to find the table assigned to the form 
  getFormDetails() {
    this.loadingFormDetails = true;
    
    this.metadataFormService.getForm(this.formId)
    .pipe(
      finalize(() => this.loadingFormDetails = false)
    )
    .subscribe( 
      res => {
        this.formDetails = res; 
      }
    );
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.addChangeReasonsToFormDataAndEmit(formData, data);
        }
      }
    );
  }

  openConfirmCloseDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      text: this.translate.instant('UnsavedChangesMessage')
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.dialogRef.close();
        }
      }
    ); 
  }

  addChangeReasonsToFormDataAndEmit(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.emitFormData.emit(merged);
  }

  formDataEmitted(formData) {
    //if there is no change reason form id emit form data, if there is open the change reason dialog
    if(this.changeReasonFormId === undefined){
      let merged = {...formData, ...this.form.getRawValue()};
      this.emitFormData.emit(merged);
    }
    else {
      this.openChangeReasonDialog(formData);
    }
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  close(){
    this.formPristine ? this.dialogRef.close() : this.openConfirmCloseDialog();
  }

}
