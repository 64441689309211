<mat-card class="mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <h2 appLocalizationPopupDirective [localizationCode]="'Error-Card-Title'">{{ 'Error-Card-Title' | translate: {Default: "Error"} }}</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-icon 
            setColor="primaryColour"
            class="material-icons-outlined"
        >error_outline</mat-icon>

        <h6 appLocalizationPopupDirective [localizationCode]="'Error-Card-Text'">
            {{ 'Error-Card-Text' | translate: {Default: "Something went wrong"} }}
        </h6>      
    </mat-card-content>
</mat-card>