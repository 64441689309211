<mat-card class="mat-elevation-z0">
    <mat-card-content>
        <div class="form-section">
            <div class="response" *ngIf="testConnectionResponse">
                <h6 appLocalizationPopupDirective localizationCode="Integrations-TestConnection">{{ 'Integrations-TestConnection-Response' | translate: {Default: 'Response'} }}</h6>
                <mat-chip-list *ngIf="testConnectionResponse?.success !== null">
                    <mat-chip 
                        [ngClass]="
                            testConnectionResponse?.success === true ? 'green-chip' : 
                            testConnectionResponse?.success === false ? 'red-chip' :
                            ''
                        "
                    >
                        <div appLocalizationPopupDirective [localizationCode]="testConnectionResponse?.success === true ? 'Successful' : 'Unsuccessful'">
                            {{ testConnectionResponse?.success === true ? 'Successful' : 'Unsuccessful' }}
                        </div>
                    </mat-chip>
                </mat-chip-list>
                
                
                <div class="response-message" *ngIf="testConnectionResponse?.message">
                    {{ testConnectionResponse?.message }}
                </div>
            </div>

            <div class="testing" *ngIf="isLoading">
                <mat-spinner></mat-spinner>
                <h6 appLocalizationPopupDirective localizationCode="Testing-Connection">
                    {{ 'Testing-Connection' | translate: {Default: 'Testing Connection'} }}
                </h6>
            </div>


            <section>
                <button 
                    mat-raised-button 
                    setColor="primaryColour" 
                    color="primary" 
                    (click)="testConnection()" 
                    [disabled]="isLoading"
                >
                    {{ isLoading ? 'Testing...' : 'Test Connection' }}
                </button>
            </section>
        </div>
    </mat-card-content>
</mat-card>
