import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-letters',
  templateUrl: './letters.component.html',
  styleUrls: ['./letters.component.scss']
})
export class LettersComponent implements OnInit {
  public routes: typeof routes = routes;
  public features: typeof features = features;

  navList = [
    {
      text: 'LettersUserSection',
      link: `/${routes.LETTERS}/${routes.LETTERS_USER_SECTION}/Headers`,
      icon: 'email',
      featureCode: 'allow'
    },
    {
      text: 'LettersAdministation',
      link: `/${routes.LETTERS}/${routes.LETTERS_ADMIN_SECTION}/DeliveryMethods`,
      icon: 'admin_panel_settings',
      featureCode: features.LETTERS_ADMINISTRATION
    }
  ];

  constructor(
      private translate: TranslateService,
      public router: Router,
      private featureServicePrivate: FeatureService,
      featureService: FeatureService,
  ) {}

  ngOnInit(): void {
    this.navigateToFirstAllowedSection();
  }

  navigateToFirstAllowedSection() {
    let urlToNavigateTo = '';

    if(this.router.url === `/${routes.LETTERS}`) {
      this.navList.every(element => {
        let hasAccess = this.featureServicePrivate.hasFeatureAccess(element.featureCode)
  
        if(hasAccess){
          urlToNavigateTo = element.link;
          return false;
        }

        return true;
      });
    }

    if(urlToNavigateTo !== ''){
      this.router.navigate([urlToNavigateTo]);
    }
  }
}
