import { PagedData } from "@app/modules/lookup/models/paged-data.model"
import { Localization } from "@app/shared/models/localization.model"
import { Version } from "@app/shared/models/version.model"

export class GoalPlan {
    id: string
    name: string
    description: string
    permitEmployeeGoalEntry: boolean
    permitEmployeeChildGoalEntry: boolean
    lockGoals: boolean
    status: {   
        id: string
        name: string
    }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    version: Version
}

export class GoalPlanStatus {
    id: string
    name: string
}

export class GoalPlanVerbose {
    id: string
    name: Localization[]
    description: Localization[]
    permitEmployeeGoalEntry: boolean
    permitEmployeeChildGoalEntry: boolean
    lockGoals: boolean
    status: {   
        id: string
        name: string
    }
    version: Version
}

export class GoalTypeStatus {
    id: string
    name: string
}

export class GoalPlanSubmit {
    id?: string
    name: Localization[]
    description: Localization[]
    lockGoals: boolean
    permitEmployeeGoalEntry: boolean
    permitEmployeeChildGoalEntry: boolean
    originalCreatedOn: string
    asOf: string
    changeReason: string
    changeReasonComments: string
}

export class GoalPlanEmployee {
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    goalPlan: {
        id: string
        name: string
    }
    createdOn: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
}

export class Goal {
    id: string
    description: string
    percentageComplete: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalPlan: { id: string, name: string }
    goalType: { id: string, name: string }
    parent: { id: string, objective: string, description: string } 
}

export class GoalVerbose {
    id: string
    objective: Localization[]
    description: Localization[]
    percentageComplete: number
    weight: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalPlan: { id: string, name: string }
    goalType: { id: string, name: string }
    parent: { id: string, objective: string, description: string } 
    goalTypeId?: string
}

export class GoalSubmit {
    id?: string
    objective: Localization[]
    description: Localization[]
    percentageComplete: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalTypeId: string
    parentId: string
}

export class GoalComment {
    id?: string
    comments: string
    commentedOn: string
    version: Version
}

export class GoalCommentSubmit {
    comments: string
    originalCreatedOn: string
    asOf: string
    changeReason: string
    changeReasonComments: string
}

export class EmployeeGoal {
    id: string
    objective: string
    description: string
    percentageComplete: number
    weight: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalType: { 
        id: string, 
        name: string 
        allowEmployeeGoalEntry: boolean
        allowEmployeeSubGoalCreation: boolean
    }
    goalPlan: {
        id: string
        name: string
        lockGoals: boolean
    }
    employee: {
        id: string,
        firstName: string
        lastName: string
    }
    createdBy: {
        id: string,
        firstName: string
        lastName: string
    }
    parent: {
        id: string
        objective: string
        description: string
    }
    personalGoal: boolean
}

export class EmployeeGoalSubmit {
    id?: string
    objective: string
    description: string
    percentageComplete: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalTypeId: string
    goalPlan: string
}

export class EmployeeGoalsWithEmployeeId {
    employeeId: string
    employeeGoals: PagedData<EmployeeGoal>
}

export class DirectReporteeGoal {
    id: string
    description: string
    percentageComplete: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalType: {
        id: string
        name: string
    }
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    goalPlan: {
        id: string
        name: string
    }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
}

export class AllGoalsListItem {
    id: string
    objective: string
    description: string
    percentageComplete: number
    expectedCompletionDate: string
    actualCompletionDate: string
    goalType: {
        id: string
        name: string
    }
    employee: {
        id: string
        firstName: string
        lastName: string
    }
    goalPlan: {
        id: string
        name: string
    }
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    managers: {
        id: string
        firstName: string
        lastName: string
    }[]
}

export class GoalPlanPublishHistory {
    id: string
    goalPlan: {
        id: string
        name: string
    }
    publishedBy: {
        id: string
        firstName: string
        lastName: string
    }
    publishedOn: string
    startedAt: string
    completedAt: string
}

export class GoalPlanOwner {
    id: string
    owner: {
        id: string
        firstName: string
        lastName: string
    }
    goalPlan: {
        id: string
        name: string
    }
    version: Version
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
}

export class SubmitGoalPlanOwner {
    userId: string
    goalPlanId: string
}

export class EmployeeAssignedGoalPlan {
    goalPlan: {
        id: string
        name: string
        description: string
        lockGoals: boolean
        permitEmployeeChildGoalEntry: boolean
        permitEmployeeGoalEntry: boolean
    }
    lastPublished: string
    employeeGoalEntryAllowed: boolean
    status: {
        id: string,
        name: string
    }
    employee: {
        id: string
        firstName: string
        lastName: string
    }
}