<app-letters>
    <mat-card class="mat-elevation-z0">
        <mat-card-content>
            <mat-tab-group [selectedIndex]="selectedTab">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Letters-LetterDeliveryMethods">
                            {{ 'Letters-LetterDeliveryMethods' | translate: {Default: 'Letter Delivery Methods'} }}
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <app-letter-delivery-methods></app-letter-delivery-methods>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</app-letters>
