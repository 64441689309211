import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fieldTypes } from '@app/consts';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TableList } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { TableFieldVerbose } from '@app/modules/security-setup/models/table-field.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormElement, FormElementType, FormElementVerbose, TableField } from '../../models/form.model';

@Component({
  selector: 'app-form-element-dialog-v2',
  templateUrl: './form-element-dialog-v2.component.html',
  styleUrls: ['./form-element-dialog-v2.component.scss']
})
export class FormElementDialogV2Component implements OnInit {
  public fieldTypes: typeof fieldTypes = fieldTypes;
  form: UntypedFormGroup;
  formId: string;
  cultures: Culture[];
  loading: boolean;
  formElementTypes: FormElementType[] = [];
  tableLists: Observable<TableList[]>;
  formElementVerbose: FormElementVerbose;
  formElementId: string;
  parentId: string;
  formTableId: string;
  tableFields: Observable<TableField>;
  parentElements: FormElement[];
  order: number;

  allowedFieldTypes = fieldTypes;
  loadingTableField: boolean;
  selectedFieldVerboseFormId: string = 'frm_O734S5cU26vwCY';
  selectedFieldVerbose: TableFieldVerbose;
  selectedFieldVerboseFormData: any;

  constructor(
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private metadataFormService: MetadataFormService,
    private fb: UntypedFormBuilder,
    private cultureService: CultureService,
    private lookupService: LookupService,
    private metadataTableService: MetadataTableService,
    private securitySetupService: SecuritySetupService,
    private router: Router,
    private dialogRef: MatDialogRef<FormElementDialogV2Component>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.formElementId = data.formElementId;
      this.parentId = data.parentId;
      this.formId = data.formId;
      this.formTableId = data.formTableId;
      this.order = data.order;
    }

  ngOnInit(): void {
    if(this.formElementId !== null) {
      this.getFormElement();
    }
    else {
      this.createForm();
    }

    this.getTableLookups();
    this.getTableFields();
    this.getFormElementTypes();
    this.getParentElements();
    this.cultureService.getCultures().subscribe( res => { this.cultures = res } );
  }

  getTableLookups() {
    this.tableLists = this.lookupService.getTableLists();
  }

  getTableFields() {
    this.tableFields = this.metadataTableService.getTableFields(this.formTableId, 0, '1000');
  }

  get formFieldId() {
    return this.form.controls["tableField"].value;
  }

  getFormElementTypes() {
    this.metadataFormService.getFormElementTypes(0, '1000')
    .subscribe(
      res => {
        this.formElementTypes = res.data;
      });
  }

  getFormElement() {
    this.metadataFormService.getFormElement(this.formId, this.formElementId)
    .subscribe(
      res => {
        this.formElementVerbose = res;
        this.createForm();
      }
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.formElementVerbose ? this.formElementVerbose.id : null],
      asOf: [moment().format()],
      name: this.fb.array([]),
        tooltipText: this.fb.array([]),
      properties: this.fb.array([]),
      formElementType: [this.formElementVerbose ? this.formElementVerbose.formElementType?.id : null, Validators.required],
      parentFormElement: [this.formElementVerbose ? this.formElementVerbose.parentFormElement?.id : this.parentId],
      tableField: [this.formElementVerbose ? this.formElementVerbose.tableField?.id : null],
      formId: [this.formId],
      changeReason: [''],
      changeReasonComments: [''],
    });

    if(this.formElementVerbose){
      this.addMinAndMaxProperties();

      this.getTableFieldDetails();

      this.formElementVerbose.text?.forEach(
        localization => {
          this.addNewLocalization(localization.culture, localization.text);
        }
      );

        this.formElementVerbose.tooltipText?.forEach(
            localization => {
            this.addNewTooltipLocalization(localization.culture, localization.text);
            }
        );

      this.formElementVerbose.properties?.forEach(
        property => {
          this.addNewProperty(property.property, property.value);
        }
        );

      this.addMissingDefaultValues()
    }
    else {
      this.addNewLocalization();
      this.setDeafultProperties();
    }
  }

  // If there are missing default properties when editing an existing form element then add them
  addMissingDefaultValues() {
    let requiredField = this.properties.value.findIndex( element => element.property === 'requiredField');
    let hidden = this.properties.value.findIndex( element => element.property === 'hidden');
    let disabled = this.properties.value.findIndex( element => element.property === 'disabled');
    let formControl = this.properties.value.findIndex( element => element.property === 'formControl');
    let order = this.properties.value.findIndex( element => element.property === 'order');

    requiredField === -1 ? this.addNewProperty('requiredField', 'false') : null;
    hidden === -1 ? this.addNewProperty('hidden', 'false') : null;
    disabled === -1 ? this.addNewProperty('disabled', 'false') : null;
    formControl === -1 ? this.addNewProperty('formControl', '') : null;
    order === -1 ? this.addNewProperty('order', this.order.toString()) : null;
  }

  getTableFieldDetails() {
    if(this.formTableId !== null && (this.formFieldId !== null && this.formFieldId !== undefined)){
      this.loadingTableField = true;

      this.securitySetupService.getField(this.formTableId, this.formFieldId)
      .pipe(
        finalize(()=>{
          this.loadingTableField = false;
        })
      )
      .subscribe(
        res => {
          this.selectedFieldVerbose = res;

          this.selectedFieldVerboseFormData = {
            id: this.selectedFieldVerbose ? this.selectedFieldVerbose.id : null,
            changeReason: '',
            changeReasonComments: '',
            name: this.selectedFieldVerbose ? this.selectedFieldVerbose.name : null,
            enabled: this.selectedFieldVerbose ? this.selectedFieldVerbose.enabled : null,
            requiredField: this.selectedFieldVerbose ? this.selectedFieldVerbose.requiredField : null,
            minimumValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.minimumValue : null,
            maximumValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.maximumValue : null,
            defaultValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.defaultValue : null,
            format: this.selectedFieldVerbose ? this.selectedFieldVerbose.format : null,
            originalCreatedOn: this.selectedFieldVerbose ? this.selectedFieldVerbose.version?.createdOn : null,
            asOf: moment().format(),
          };
        }
      );
    }
  }

  // If the form element doesnt have minLength and maxLength value in the properties array add them
  addMinAndMaxProperties() {
    let hasMin = this.formElementVerbose.properties?.findIndex( property =>
      property.property === 'minLength'
    )

    let hasMax = this.formElementVerbose.properties?.findIndex( property =>
      property.property === 'maxLength'
    )

    if(hasMin === -1){
      this.addNewProperty('minLength', null);
    }

    if(hasMax === -1){
      this.addNewProperty('maxLength', null);
    }
  }

  setDeafultProperties() {
    this.addNewProperty('requiredField', 'false');
    this.addNewProperty('hidden', 'false');
    this.addNewProperty('disabled', 'false');
    this.addNewProperty('formControl', '');
    this.addNewProperty('minLength', null);
    this.addNewProperty('maxLength', null);

    if(this.order !== null){
      this.addNewProperty('order', this.order.toString());
    }

  }

  get localizations() {
    return this.form.controls["name"] as UntypedFormArray;
  }

  //Create a textLocalization form group object to add to the localizations form array
  addNewLocalization(culture?: string, text?: string) {
    const localizationForm = this.fb.group({
      culture: [culture || '', Validators.required],
      text: [text || '', Validators.required]
    });

    this.localizations.push(localizationForm);
  }

  deleteLocalization(index: number) {
    this.localizations.removeAt(index);
  }

    get tooltipLocalizations() {
        return this.form.controls["tooltipText"] as UntypedFormArray;
    }

    addNewTooltipLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.tooltipLocalizations.push(localizationForm);
    }

    deleteTooltipLocalization(index: number) {
    this.tooltipLocalizations.removeAt(index);
  }

  get properties() {
    return this.form.controls["properties"] as UntypedFormArray;
  }

  addNewProperty(property?: string, value?: string) {
    let val;

    // if the property value is supposed to be a boolean convert it from string to boolean
    (value === "true" || value === "false")
      ? val = (value === 'true')
      : val = value;

    const propertyForm = this.fb.group({
      property: [property || '', Validators.required],
      value: [val || '']
    });

    this.properties.push(propertyForm);
  }

  deleteProperty(index: number) {
    this.properties.removeAt(index);
  }

  deletePropertyByName(name: string) {
    let index = this.properties.value.findIndex(prop => prop.property === name);

    if(index !== -1) {
      this.properties.removeAt(index);
    }
  }

  onFormElementTypeSelection(event) {
    if(event.value === fieldTypes.DROPDOWN) {
      this.addNewProperty('lookupGroup', '');
    }
    else {
      this.deletePropertyByName('lookupGroup')
    }
  }

  getParentElements() {
    this.metadataFormService.getFormElements(this.formId, 0, '1000')
    .subscribe(
      res => {
        this.parentElements = res.data.filter( (formElement: FormElement) => formElement.formElementType.id === fieldTypes.CONTAINER )
      }
    )
  }

  convertFormPropertyValuesToStrings() {
    this.form.value.properties.forEach( property => {
      property.value !== null
        ? property.value = property.value.toString()
        : null
    })
  }

  save() {
    this.overlayService.show();
    this.convertFormPropertyValuesToStrings();

    if(this.formElementId !== null) {
      this.metadataFormService.updateFormElement(this.formId, this.formElementVerbose.id, this.form.value)
      .pipe(
        finalize( () => this.overlayService.hide() )
      )
      .subscribe(
        res => {
          this.snackbarService.openSnackBar('Form Element updated successfully', 'clear', 'success');
          this.dialogRef.close(true);
        }
      )
    }
    else {
      this.metadataFormService.postFormElement(this.formId, this.form.value)
      .pipe(
        finalize( () => this.overlayService.hide() )
      )
      .subscribe(
        res => {
          this.snackbarService.openSnackBar('Form Element created successfully', 'clear', 'success');
          this.dialogRef.close(true);
        }
      )
    }
  }

  navigateToTableFieldEditor() {
    this.close();
    this.router.navigateByUrl(`SiteSettings/TableFieldEditor/${this.formTableId}/${this.formFieldId}`);
  }

  close() {
    this.dialogRef.close();
  }

    deleteAllTooltipLocalizations(): void {
        this.tooltipLocalizations.clear();
    }

}
