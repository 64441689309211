import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeRelocation, EmployeeRelocationVerbose } from './models/employee-relocation.model';
import { EmployeeRelocationService } from './services/employee-relocation.service';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-relocation',
  templateUrl: './employee-relocation.component.html',
  styleUrls: ['./employee-relocation.component.scss']
})
export class EmployeeRelocationComponent implements OnInit {
  @Input() formIds: string[];
  @Input() employeeID: string;

  public columns: any[] = [
    { field: "receivingLocation", subField: "text", title: "Receiving Location", type: "navigation", tableId: "tfi_RelocationReceivingLocation" },
    { field: "sendingLocation", subField: "text", title: "Sending Location", tableId: "tfi_RelocationSendingLocation" },
    { field: "dateInitiated", title: "Date Initiated", type: "date", tableId: "tfi_RelocationDateInitiated" },
    { field: "relocationStartDate", title: "Relocation Start Date", type: "date", tableId: "tfi_RelocationStartDate" },
    { field: "relocationEndDate", title: "Relocation End Date", type: "date", tableId: "tfi_RelocationEndDate" },
    { field: "pointOfOrigin", subField: "text", title: "Point Of Origin", tableId: "tfi_RelocationPointOfOrigin" },
    { field: "totalCosts", title: "Total Costs", tableId: "tfi_RelocationTotalCosts" },
    { field: "comments", title: "Comments", tableId: "tfi_ReReasonComments" },
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_XzbUJWNEMlZ1zD'
  changeReasonFormId: string = 'frm_hnsODCH9r2QBtg';
  formValid: boolean;
  private sortString: string = 'dateInitiated-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.RELOCATIONS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private employeeRelocationService: EmployeeRelocationService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.view = this.getRelocations();
    this.getTargetEmployeeTablePermissions();
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getRelocations() {
    this.isLoading = true;

    this.employeeRelocationService.getRelocations(this.employeeID, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getRelocation(relocation?: EmployeeRelocation) {
    if(relocation === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.employeeRelocationService.getRelocation(this.employeeID, relocation.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(relocation?: EmployeeRelocationVerbose) {
    let formData = {
      id: relocation ? relocation.id : null,
      asOf: moment().format(),
      dateInitiated: relocation ? relocation.dateInitiated : null,
      relocationStartDate: relocation ? relocation.relocationStartDate : null,
      relocationEndDate: relocation ? relocation.relocationEndDate : null,
      totalCosts: relocation ? relocation.totalCosts : null,
      sendingLocation: relocation && relocation.sendingLocation ? relocation.sendingLocation.id : null,
      receivingLocation: relocation && relocation.receivingLocation ? relocation.receivingLocation.id : null,
      pointOfOrigin: relocation && relocation.pointOfOrigin ? relocation.pointOfOrigin.id : null,
      comments: relocation ? relocation.comments : null,
      changeReason: '',
      changeReasonComments: ''
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('Relocation')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(!formData.id){
      this.employeeRelocationService.createRelocation(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getRelocations();
        }
      );
    }
    else {
      this.employeeRelocationService.updateRelocation(this.employeeID, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getRelocations();
        }
      );
    }
  }

  deleteRelocations(RelocationIds: string[]) {
    const observables = RelocationIds.map(selectedItem => defer(() => this.employeeRelocationService.deleteRelocation(this.employeeID, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getRelocations();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.view = this.getRelocations();
  }

}




