
<app-layout>
    <div class="main-container">
        <div class="menu" *appHasFeature="features.LETTERS_ADMINISTRATION">
            <mat-list class="navigation-menu-list">
                <ng-container *ngFor="let item of navList">
                    <mat-list-item 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            },
                            {
                                color: 'primaryColour',
                                setIfClass: ['primaryColor'],
                                events: ['routerLinkActive']
                            },
                        ]"
                        [routerLink]="item.link" 
                        routerLinkActive="primaryColor" 
                        class="primaryHoverColor" 
                        *appHasFeature="item.featureCode"
                    >
                        <mat-icon 
                            setColor
                            [setColorRules]="[
                                {
                                    color: 'primaryColour',
                                    events: ['hover']
                                },
                                {
                                    color: 'primaryColour',
                                    setIfClass: ['primaryColor'],
                                    events: ['routerLinkActive']
                                },
                            ]"
                            class="material-icons-outlined"
                            routerLinkActive="primaryColor" 
                        >{{item.icon}}</mat-icon>
                        <div
                            class="text"
                            appLocalizationPopupDirective 
                            [localizationCode]="item.text"
                        >{{ translate.instant(item.text) }}</div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
        <div class="content">
            <div class="main-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</app-layout>
