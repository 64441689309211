import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";
import { IntegrationMapping, IntegrationSettings } from "@app/modules/site-settings-integrations/models/integrations.model";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { finalize } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IntegrationMappingsDialogComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/integration-mappings-dialog/integration-mappings-dialog.component";
import { defer, forkJoin } from "rxjs";

@Component({
    selector: 'app-integration-mappings-tab',
    templateUrl: './integration-mappings-tab.component.html',
    styleUrls: ['./integration-mappings-tab.component.scss']
})
export class IntegrationMappingsTabComponent implements OnInit {

    @Input() public integrationId: string;
    public integration: IntegrationSettings = null;

    // GRID
    public columns: any[] = [
        {
            field: 'externalField',
            title: this.translate.instant("Integrations-FieldName"),
            localizationCode: 'Integrations-FieldName',
            dataType: "String"
        },
        {
            field: 'table',
            subField: 'name',
            title: this.translate.instant("Integrations-HubbubHRTable"),
            localizationCode: 'Integrations-HubbubHRTable',
            dataType: "String"
        },
        {
            field: 'field',
            subField: 'name',
            title: this.translate.instant("Integrations-HubbubHRField"),
            localizationCode: 'Integrations-HubbubHRField',
            dataType: "String"
        },
        {
            field: 'status',
            title: this.translate.instant("Integrations-Status"),
            localizationCode: 'Integrations-Status',
            type: 'booleanToggle'
        },
        {
            field: 'mandatory',
            title: this.translate.instant("Integrations-Mandatory"),
            localizationCode: 'Integrations-Mandatory',
            type: 'booleanToggle'
        },
    ];
    gridDataResult: GridDataResult;
    isLoading: boolean = false;
    pageSize: number = 20;
    skip: number = 0;
    filterString: string;
    searchFilterString: string;
    getRequest: any;
    clearSelectedItems: boolean = false;
    searchValue: string;
    sortString: string = "";

    constructor(
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private integrationService: IntegrationsService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.getData();
    }

    navigateToMapping(mapping?: IntegrationMapping) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            integrationId: this.integrationId,
            integrationMapping: mapping
        };

        const dialogRef = this.dialog.open(IntegrationMappingsDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.getData();
                }
            }
        );
    }


    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(selectedItem => defer(() => this.integrationService.deleteIntegrationMapping(this.integrationId, selectedItem)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.clearSelectedItems = !this.clearSelectedItems;
                this.getData();
                this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
            }
        );
    }

    getData() {
        this.isLoading = true;

        this.integrationService.getIntegrationMappings(this.integrationId, this.skip, this.pageSize)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(res => {
            this.gridDataResult = {
                data: res.data,
                total: res.total,
            };
        })
    }
}
