<ng-container *ngIf="employeeId === undefined; else loadImage">
    <div class="image-container" [ngStyle]="{'width.px': width, 'height.px': height}">
        <img class="profiler" [src]="miscellaneous.STOCK_EMPLOYEE_IMAGE" [ngClass]="{'round': round}">
    </div>
</ng-container>
<ng-template #loadImage>
    <div class="image-container" [ngStyle]="{'width.px': width, 'height.px': height}">
        <div *ngIf="employeeImage$ | withLoading | async as employeeImage">
            <ng-template [ngIf]="employeeImage.value">
                <img class="profiler" [src]="employeeImage.value" [ngClass]="{'round': round}">
            </ng-template>
            <ng-template [ngIf]="employeeImage.error">
                <img class="profiler" [src]="miscellaneous.STOCK_EMPLOYEE_IMAGE" [ngClass]="{'round': round}">
            </ng-template>
            <ng-template [ngIf]="employeeImage.loading">
                <ngx-skeleton-loader
                    class="profiler skeleton-loader"
                    count="1"
                    appearance="circle"
                    [theme]="{
                        'height.px': height,
                        'width.px': width
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </div>
</ng-template>
