<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="title">
    {{ translate.instant(title) }}
</h5>

<mat-dialog-content>
    <ng-container *ngIf="loadingTemplate || loadingOrganizations || loadingLetterFooter || loadingLetterhead; else loaded">
        <div class="loading">
            <ngx-skeleton-loader count="10" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </div>
    </ng-container>
    <ng-template #loaded>
        <div class="preview-container">
            <div class="preview-details">
    
                <div class="section">
                    <h5 appLocalizationPopupDirective [localizationCode]="'Template-LetterDetails'">{{ 'Template-LetterDetails' | translate: {Default: 'Letter Details'} }}</h5>
                    <div>{{step1FormData.name[0]?.text}}</div>
                    <div>{{step1FormData.description[0]?.text}}</div>
                </div>
    
                <div class="section">
                    <h5  appLocalizationPopupDirective [localizationCode]="'Template-LetterDetails'">{{ 'Template-LetterPermissions' | translate: {Default: 'Letter Permissions'} }}</h5>
                    
                    <mat-chip-list *ngIf="step2FormData.status">
                        <mat-chip 
                            [ngClass]="{
                                'red-chip': step2FormData.status === 'Archived',
                                'green-chip': step2FormData.status === 'Active',
                                'orange-chip': step2FormData.status === 'Draft'
                            }"
                        >{{ step2FormData.status }}</mat-chip>
                    </mat-chip-list>
    
                    <div>
                        <mat-slide-toggle setColor="primaryColour" disabled [ngModel]="step2FormData.sendToActiveUsersOnly">{{ 'Template-SendToActiveUsersOnly' | translate: {Default: 'Send To Active Users Only'} }}</mat-slide-toggle>
                    </div>
    
                    <div>
                        <mat-slide-toggle setColor="primaryColour" disabled [ngModel]="step2FormData.abilityToSetTags">{{ 'Template-AbilityToSetTags' | translate: {Default: 'Ability To Set Tags'} }}</mat-slide-toggle>
                    </div>
                </div>
    
                <div class="section">
                    <h5 appLocalizationPopupDirective [localizationCode]="'Template-Owners'">{{ 'Template-Owners' | translate: {Default: 'Owners'} }}</h5>
    
                    <mat-chip-list>
                        <mat-chip *ngFor="let item of step2FormData.owners">
                            <app-employee-image matChipAvatar [employeeId]="item.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
            
                            <span class="employee">
                                {{item.firstName}} {{item.lastName}}
                            </span>
                        </mat-chip>
                    </mat-chip-list>
                </div>
    
                <div class="section">
                    <h5 appLocalizationPopupDirective [localizationCode]="'Template-Publishers'">{{ 'Template-Publishers' | translate: {Default: 'Publishers'} }}</h5>
    
    
                    <mat-chip-list>
                        <mat-chip *ngFor="let item of step2FormData.publishers">
                            <app-employee-image matChipAvatar [employeeId]="item.id" [width]="35" [height]="35" [round]="true" *ngIf="!isLoading"></app-employee-image>
            
                            <span class="employee">
                                {{item.firstName}} {{item.lastName}}
                            </span>
                        </mat-chip>
                    </mat-chip-list>
                </div>
    
                <div class="section">
                    <h5 appLocalizationPopupDirective [localizationCode]="'Template-Organizations'">{{ 'Template-Organizations' | translate: {Default: 'Organizations'} }}</h5>
    
                    <mat-chip-list>
                        <mat-chip *ngFor="let item of step2FormData.organizations">
                            {{item.name}}
                        </mat-chip>
                    </mat-chip-list>
                </div>
    
            </div>
            <div class="preview-letter">
    
                <div class="letter" [ngStyle]="{
                    'margin-top': step1FormData.topMargin ? step1FormData.topMargin + 'px' : '0px', 
                    'margin-bottom': step1FormData.bottomMargin ? step1FormData.bottomMargin + 'px' : '0px',
                    'margin-left': step1FormData.pageMargin ? step1FormData.pageMargin + 'px' : '0px',
                    'margin-right': step1FormData.pageMargin ? step1FormData.pageMargin + 'px' : '0px'
                }" >
                    <div class="letterhead" [innerHTML]="letterheadDetails?.html"></div>
                    <div class="letter-content" [innerHTML]="step1HtmlFormData.html"></div>
                    <div class="letter-footer" [innerHTML]="letterFooterDetails?.html"></div>
                </div>
    
            </div>
        </div>
    </ng-template>


</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>
</mat-dialog-actions>