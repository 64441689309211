import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";

@Component({
    selector: 'app-icims-integration-details-tab',
    templateUrl: './icims-integration-details-tab.component.html',
    styleUrls: ['./icims-integration-details-tab.component.scss']
})
export class IcimsIntegrationDetailsTabComponent implements OnInit {

    public integrationId: string;
    public integrationConfig: any = null;

    // FORM
    formId: string = 'frm_Rci9DpUwVtfirg';
    formData: any;
    getFormData: boolean = false;
    formValid: boolean = false;
    formPristine: boolean = true;
    formLoaded: boolean = false;

    constructor(
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private integrationService: IntegrationsService,
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    formDataEmitted(formDataEmitted) {
        this.save(formDataEmitted);
    }

    formStatusUpdated(formValid) {
        if (!this.formLoaded) {
            this.formLoaded = true;
        }

        this.formValid = formValid;
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    getData() {
        this.integrationService.getIcimsIntegrationConfig().subscribe(res => {
            this.integrationConfig = res;

            this.buildFormData();
        })
    }

    buildFormData() {
        this.formData = {
            clientId: this.integrationConfig.clientId,
            clientSecret: this.integrationConfig.clientSecret,
            customerId: this.integrationConfig.customerId,
            skipDuplicates: this.integrationConfig.skipDuplicates,
            personalDetailsFormId: this.integrationConfig.personalDetailsFormId,
            additionalQuestionsFormId: this.integrationConfig.additionalQuestionsFormId,
            organization: this.integrationConfig.organization,
        }
    }

    save(formDataEmitted) {
        this.overlayService.show();

        let integrationConfigPayload = {
            organization: formDataEmitted.organization?.id,
            clientId: formDataEmitted.clientId,
            clientSecret: formDataEmitted.clientSecret,
            customerId: formDataEmitted.customerId,
            personalDetailsFormId: formDataEmitted.personalDetailsFormId,
            additionalQuestionsFormId: formDataEmitted.additionalQuestionsFormId,
            skipDuplicates: formDataEmitted.skipDuplicates,
        }

        this.integrationService.updateIcimsIntegrationConfig(integrationConfigPayload).subscribe(res => {
            this.overlayService.hide();
            this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdated'), 'clear', 'success');
        }, err => {
            this.overlayService.hide();
            this.snackbarService.openSnackBar(this.translate.instant('IntegrationConfigUpdateError'), 'clear', 'warn');
        });
    }
}
