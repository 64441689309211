import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { PaperSize } from '@app/modules/letters/models/letters.model';
import { LettersService } from '@app/modules/letters/services/letters.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-letter-paper-sizes-dropdown',
  templateUrl: './letter-paper-sizes-dropdown.component.html',
  styleUrls: ['./letter-paper-sizes-dropdown.component.scss']
})
export class LetterPaperSizesDropdownComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  isLoading: boolean;
  user$: any;
  filterString: string;
  options: Observable<PagedData<PaperSize>>;

  constructor(
    private lettersService: LettersService,
  ) { }

  ngOnInit(): void {
    this.options = from(this.lettersService.getTemplatePaperSizes(100, 0))
  }
}
