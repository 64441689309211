import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable, of } from 'rxjs';
import { ReviewPlan, ReviewPlanCreatedResponse, ReviewPlanEmployee, ReviewPlanEmployeeSubmit, ReviewPlanGoalPlan, ReviewPlanGoalPlanSubmit, ReviewPlanGoalSetting, ReviewPlanOwnerSubmit, ReviewPlanPublishHistory, ReviewPlanReviewer, ReviewPlanReviewerType, ReviewPlanSubmit, ReviewPlanVerbose, ReviewerSecurityPermission } from '../models/review-plan.model';
import { api_routes } from '@app/consts';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class ReviewPlansService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##.....######..########..##.....##.########.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##....##.##.....##.##.....##.##.....##
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##.......##.....##.##.....##.##.....##
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....##.......########..##.....##.##.....##
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##.......##...##...##.....##.##.....##
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##....##.##....##..##.....##.##.....##
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##.....######..##.....##..#######..########.
  */

  getReviewPlans(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlan>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}`, {
      headers: headers,
      params: params
    });
  }

  getReviewPlan(reviewPlanId: string, asOf?: string): Observable<ReviewPlanVerbose> {
    let params = new HttpParams();
    asOf ? params = params.append('asOf', asOf) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}`, {
      headers: headers,
      params: params
    });
  }

  createReviewPlan(reviewPlanSubmit: ReviewPlanSubmit): Observable<ReviewPlanCreatedResponse> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}`, reviewPlanSubmit, {
      headers: headers
    });
  }

  updateReviewPlan(reviewPlanId: string, reviewPlanSubmit: ReviewPlanSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}`, reviewPlanSubmit, {
      headers: headers
    });
  }

  deleteReviewPlan(reviewPlanId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}`, {
      headers: headers
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##....########.##.....##.########..##........#######..##....##.########.########..######.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##.......###...###.##.....##.##.......##.....##..##..##..##.......##.......##....##
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##.......####.####.##.....##.##.......##.....##...####...##.......##.......##......
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....######...##.###.##.########..##.......##.....##....##....######...######....######.
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##.......##.....##.##........##.......##.....##....##....##.......##.............##
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##.......##.....##.##........##.......##.....##....##....##.......##.......##....##
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##....########.##.....##.##........########..#######.....##....########.########..######.
  */

  getReviewPlanEmployees(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlanEmployee>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_EMPLOYEES}`, {
      headers: headers,
      params: params
    });
  }

  addReviewPlanEmployee(reviewPlanId: string, reviewPlanEmployeeSubmit: ReviewPlanEmployeeSubmit): Observable<ReviewPlanCreatedResponse> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_EMPLOYEES}`, reviewPlanEmployeeSubmit, {
      headers: headers
    });
  }

  deleteReviewPlanEmployee(reviewPlanId: string, reviewPlanEmployeeId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_EMPLOYEES}/${reviewPlanEmployeeId}`, {
      headers: headers
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##.....#######..##......##.##....##.########.########...######.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##.....##.##..##..##.###...##.##.......##.....##.##....##
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##.....##.##..##..##.####..##.##.......##.....##.##......
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....##.....##.##..##..##.##.##.##.######...########...######.
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##.....##.##..##..##.##..####.##.......##...##.........##
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##.....##.##..##..##.##...###.##.......##....##..##....##
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##.....#######...###..###..##....##.########.##.....##..######.
  */

  getReviewPlanOwners(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlanEmployee>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_OWNERS}`, {
      headers: headers,
      params: params
    });
  }

  addReviewPlanOwner(reviewPlanId: string, reviewPlanOwnerSubmit: ReviewPlanOwnerSubmit): Observable<ReviewPlanCreatedResponse> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.REVIEW_PLANS_OWNERS}`, reviewPlanOwnerSubmit, {
      headers: headers
    });
  }

  deleteReviewPlanOwner(reviewPlanId: string, reviewPlanEmployeeId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.REVIEW_PLANS_OWNERS}/${reviewPlanEmployeeId}`, {
      headers: headers
    });
  }

  
  /*
  .########..##.....##.########..##.......####..######..##.....##....########..########.##.....##.####.########.##......##....########..##..........###....##....##
  .##.....##.##.....##.##.....##.##........##..##....##.##.....##....##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##
  .##.....##.##.....##.##.....##.##........##..##.......##.....##....##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##
  .########..##.....##.########..##........##...######..#########....########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##
  .##........##.....##.##.....##.##........##........##.##.....##....##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####
  .##........##.....##.##.....##.##........##..##....##.##.....##....##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###
  .##.........#######..########..########.####..######..##.....##....##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##
  */
  publishReviewPlan(reviewPlanId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${api_routes.PUBLISH}/${reviewPlanId}`, {
      headers: headers
    });
  }

  getReviewPlanPublishHistory(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlanPublishHistory>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.PUBLISH_HISTORIES}`, {
      headers: headers,
      params: params
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##.....######....#######.....###....##..........########..##..........###....##....##..######.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##....##..##.....##...##.##...##..........##.....##.##.........##.##...###...##.##....##
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##........##.....##..##...##..##..........##.....##.##........##...##..####..##.##......
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....##...####.##.....##.##.....##.##..........########..##.......##.....##.##.##.##..######.
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##....##..##.....##.#########.##..........##........##.......#########.##..####.......##
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##....##..##.....##.##.....##.##..........##........##.......##.....##.##...###.##....##
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##.....######....#######..##.....##.########....##........########.##.....##.##....##..######.
  */

  getReviewPlanGoalPlans(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlanGoalPlan>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_GOAL_PLANS}`, {
      headers: headers,
      params: params
    });
  }

  getReviewGoalPlan(reviewPlanId: string, goalPlanId: string): Observable<ReviewPlanGoalPlan> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_GOAL_PLANS}/${goalPlanId}`, {
      headers: headers
    });
  }

  updateReviewPlanGoalPlan(reviewPlanId: string, reviewPlanGoalPlanId: string, reviewPlanGoalPlanSubmit: ReviewPlanGoalPlanSubmit): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_GOAL_PLANS}/${reviewPlanGoalPlanId}`, reviewPlanGoalPlanSubmit, {
      headers: headers
    });
  }

  addReviewPlanGoalPlan(reviewPlanId: string, reviewPlanGoalPlanSubmit: ReviewPlanGoalPlanSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_GOAL_PLANS}`, reviewPlanGoalPlanSubmit, {
      headers: headers
    });
  }

  deleteReviewPlanGoalPlan(reviewPlanId: string, reviewPlanGoalPlanId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEW_PLANS_GOAL_PLANS}/${reviewPlanGoalPlanId}`, {
      headers: headers
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##....########..########.##.....##.####.########.##......##.########.########.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##.....##.##.......##.....##..##..##.......##..##..##.##.......##.....##
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##.....##.##.......##.....##..##..##.......##..##..##.##.......##.....##
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....########..######...##.....##..##..######...##..##..##.######...########.
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##...##...##........##...##...##..##.......##..##..##.##.......##...##..
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##....##..##.........##.##....##..##.......##..##..##.##.......##....##.
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##....##.....##.########....###....####.########..###..###..########.##.....##
  */

  getReviewPlanReviewers(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlanReviewer>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEWERS}`, {
      headers: headers,
      params: params
    });
  }

  async getAllReviewPlanReviewers(reviewPlanId: string): Promise<PagedData<ReviewPlanReviewer>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getReviewPlanReviewers(reviewPlanId, take.toString(), skip).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getReviewPlanReviewer(reviewPlanId: string, reviewerId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEWERS}/${reviewerId}`, {
      headers: headers
    });
  }

  updateReviewPlanReviewer(reviewPlanId: string, reviewPlanReviewerId: string, reviewPlanReviewerSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEWERS}/${reviewPlanReviewerId}`, reviewPlanReviewerSubmit, {
      headers: headers
    });
  }

  addReviewPlanReviewer(reviewPlanId: string, reviewPlanReviewerSubmit: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEWERS}`, reviewPlanReviewerSubmit, {
      headers: headers
    });
  }

  deleteReviewPlanReviewer(reviewPlanId: string, reviewPlanReviewerId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}${api_routes.REVIEWERS}/${reviewPlanReviewerId}`, {
      headers: headers
    });
  }

  getReviewerTypes(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewPlanReviewerType>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWER_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  async getAllReviewerTypes(): Promise<PagedData<ReviewPlanReviewerType>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getReviewerTypes(take.toString(), skip).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getReviewerSecurityPermissions(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewerSecurityPermission>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEWER_SECURITY_PERMISSIONS}`, {
      headers: headers,
      params: params
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##.....######....#######.....###....##..........########.##....##.########..########....##......##.########.####..######...##.....##.########
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##....##..##.....##...##.##...##.............##.....##..##..##.....##.##..........##..##..##.##........##..##....##..##.....##....##...
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##........##.....##..##...##..##.............##......####...##.....##.##..........##..##..##.##........##..##........##.....##....##...
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....##...####.##.....##.##.....##.##.............##.......##....########..######......##..##..##.######....##..##...####.#########....##...
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##....##..##.....##.#########.##.............##.......##....##........##..........##..##..##.##........##..##....##..##.....##....##...
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##....##..##.....##.##.....##.##.............##.......##....##........##..........##..##..##.##........##..##....##..##.....##....##...
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##.....######....#######..##.....##.########.......##.......##....##........########.....###..###..########.####..######...##.....##....##...
  */

  getReviewPlanGoalTypeWeights(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_TYPES}`, {
      headers: headers,
      params: params
    });
  }

  getReviewPlanGoalTypeWeight(reviewPlanId: string, reviewerId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_TYPES}/${reviewerId}`, {
      headers: headers
    });
  }

  updateReviewPlanGoalTypeWeight(reviewPlanId: string, reviewPlanReviewerId: string, reviewPlanReviewerSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_TYPES}/${reviewPlanReviewerId}`, reviewPlanReviewerSubmit, {
      headers: headers
    });
  }

  addReviewPlanGoalTypeWeight(reviewPlanId: string, reviewPlanReviewerSubmit: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_TYPES}`, reviewPlanReviewerSubmit, {
      headers: headers
    });
  }

  deleteReviewPlanGoalTypeWeight(reviewPlanId: string, reviewPlanReviewerId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_TYPES}/${reviewPlanReviewerId}`, {
      headers: headers
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##....########.....###....########.####.##....##..######..
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##.....##...##.##......##.....##..###...##.##....##.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##.....##..##...##.....##.....##..####..##.##.......
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....########..##.....##....##.....##..##.##.##.##...####
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##...##...#########....##.....##..##..####.##....##.
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##....##..##.....##....##.....##..##...###.##....##.
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##....##.....##.##.....##....##....####.##....##..######..
  */
  
  getReviewPlanRatings(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.RATINGS}`, {
      headers: headers,
      params: params
    });
  }

  getReviewPlanRating(reviewPlanId: string, reviewerId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.RATINGS}/${reviewerId}`, {
      headers: headers
    });
  }

  updateReviewPlanRating(reviewPlanId: string, reviewPlanRatingId: string, reviewPlanRatingSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.RATINGS}/${reviewPlanRatingId}`, reviewPlanRatingSubmit, {
      headers: headers
    });
  }

  addReviewPlanRating(reviewPlanId: string, reviewPlanReviewerSubmit: any): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.RATINGS}`, reviewPlanReviewerSubmit, {
      headers: headers
    });
  }

  deleteReviewPlanRating(reviewPlanId: string, reviewPlanRatingId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.RATINGS}/${reviewPlanRatingId}`, {
      headers: headers
    });
  }

  /*
  .########..########.##.....##.####.########.##......##....########..##..........###....##....##.....######....#######.....###....##...........######..########.########.########.####.##....##..######....######.
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##.........##.##...###...##....##....##..##.....##...##.##...##..........##....##.##..........##.......##.....##..###...##.##....##..##....##
  .##.....##.##.......##.....##..##..##.......##..##..##....##.....##.##........##...##..####..##....##........##.....##..##...##..##..........##.......##..........##.......##.....##..####..##.##........##......
  .########..######...##.....##..##..######...##..##..##....########..##.......##.....##.##.##.##....##...####.##.....##.##.....##.##...........######..######......##.......##.....##..##.##.##.##...####..######.
  .##...##...##........##...##...##..##.......##..##..##....##........##.......#########.##..####....##....##..##.....##.#########.##................##.##..........##.......##.....##..##..####.##....##........##
  .##....##..##.........##.##....##..##.......##..##..##....##........##.......##.....##.##...###....##....##..##.....##.##.....##.##..........##....##.##..........##.......##.....##..##...###.##....##..##....##
  .##.....##.########....###....####.########..###..###.....##........########.##.....##.##....##.....######....#######..##.....##.########.....######..########....##.......##....####.##....##..######....######.
  */

  async getAllReviewGoalSettings(reviewPlanId: string, sort?: string): Promise<PagedData<ReviewPlanGoalSetting>> {
    let take = 100;
    let skip = 0;
    let allData = {
      skip: null,
      take: null,
      totalPages: null,
      total: null,
      data: []
    }

    while (allData.total === null || skip < allData.total) {
        const res = await this.getReviewPlanGoalSettings(reviewPlanId, take.toString(), skip, sort).toPromise();

        if (res && res.data && res.data.length > 0) {
          allData.data = allData.data.concat(res.data);
            skip += take;
            allData.total = res.total;
        } else {
            break;
        }
    }

    return allData;
  }

  getReviewPlanGoalSettings(reviewPlanId: string, take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_SETTINGS}`, {
      headers: headers,
      params: params
    });
  }

  getReviewPlanGoalSetting(reviewPlanId: string, goalId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_SETTINGS}/${goalId}`, {
      headers: headers
    });
  }

  updateReviewPlanGoalSetting(reviewPlanId: string, goalId: string, reviewPlanGoalSettingSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_SETTINGS}/${goalId}`, reviewPlanGoalSettingSubmit, {
      headers: headers
    });
  }

  // addReviewPlanGoalSetting(reviewPlanId: string, reviewPlanReviewerSubmit: any): Observable<any> {
  //   return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_SETTINGS}`, reviewPlanReviewerSubmit, {
  //     headers: headers
  //   });
  // }

  // deleteReviewPlanGoalSetting(reviewPlanId: string, goal: string): Observable<any> {
  //   return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}${api_routes.REVIEW_PLANS}/${reviewPlanId}/${api_routes.GOAL_SETTINGS}/${reviewPlanRatingId}`, {
  //     headers: headers
  //   });
  // }

  getFeedbackSettings(take?: string, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<any>> {
    let params = new HttpParams();
    sort ? params = params.append('sort', sort) : null;
    filter ? params = params.append('filter', filter) : null;
    asOf ? params = params.append('asOf', asOf) : null;
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.PERFORMANCE}/${api_routes.FEEDBACK_SETTINGS}`, {
      headers: headers,
      params: params
    });
  }
}
