<mat-card>
    <app-data-grid-comp
        [isLoading]="isLoading"
        [height]="750"
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [pageable]="false"
        category="ReviewStatusReport"
        export="excel"
        [sortable]="{
              mode: 'multiple'
          }"
        hideActionButtons="true"
        filterable="false"
        [filterCategories]="filterCategories"
        (emitFilterEvent)="filterCallback($event)"
        [filterToggleDetails]="null"
        (emitSortEvent)="sortChange($event)"
        [sortableColumns]="sortableColumns"
    ></app-data-grid-comp>
</mat-card>

