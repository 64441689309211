import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-performance-review-status-report',
    templateUrl: './review-status-report.component.html',
    styleUrls: ['./review-status-report.component.scss']
})
export class ReviewStatusReportComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
