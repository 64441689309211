<h5 mat-dialog-title>{{ 'AddRecipient' | translate: {Default: "Add Recipient"} }}</h5>

<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>{{ 'Letter-RecipientType' | translate: {Default: 'Recipient Type'} }}</mat-label>
        <mat-select [(ngModel)]="recipientType">
            <mat-option></mat-option>
            <mat-option 
                *ngFor="let type of recipientTypes" 
                [disabled]="isRecipientTypeDisabled(type.id)"
                [value]="type"
            >{{ type.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="recipientType?.id === 'SpecifiedUser'">
        <app-bootstrap-alert
            *ngIf="recipientAlreadySelected()"
            text="Recipient has already been selected"
            localizationCode="RecipientAlreadyChosenWarning"
            type="danger">
        </app-bootstrap-alert>
    
        <app-form-generator
            [formId]="formId"
            [formData]="formData"
            (emitFormDataChange)="formDataEmitted($event)"
            (emitFormStatus)="formStatusUpdated($event)"
            (emitFormPristine)="formPristineEmitted($event)"
        ></app-form-generator>
    </ng-container>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Cancel' | translate: {Default: "Cancel"} }}</button>

    <button 
        [disabled]="isSaveButtonDisabled()"
        mat-raised-button 
        setColor="primaryColour" 
        color="primary" 
        appPreventDoubleClick 
        (throttledClick)="save()"> 
    {{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>