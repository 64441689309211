import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SelectOrganizationsDialogComponent } from './components/select-organizations-dialog/select-organizations-dialog.component';
import { db_tables } from '@app/consts';
import { UntypedFormGroup } from '@angular/forms';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { Organization } from '@app/modules/organization-structure/models/organization-structure.model';

@Component({
  selector: 'app-multiple-organization-form-field',
  templateUrl: './multiple-organization-form-field.component.html',
  styleUrls: ['./multiple-organization-form-field.component.scss']
})
export class MultipleOrganizationFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  public db_tables = db_tables;
  isLoading: boolean;
  organizations: Organization[] = []
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getUsersRequest: any;
  selectOrganizationsDialogRef: MatDialogRef<SelectOrganizationsDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.organizations = this.parentGroup.controls[this.formElement.formControl].value;
    }
  }

  showAddModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      nonSelectableOrganizations: [ ...this.organizations]
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectOrganizationsDialogRef = this.dialog.open(SelectOrganizationsDialogComponent, dialogConfig);
    });

    const sub = this.selectOrganizationsDialogRef.componentInstance.emitSelectedOrganizations.subscribe((event) => {
      this.addOrganizations(event)
    });
  }

  addOrganizations(selectedOrganizations: any) {
    this.organizations = [...this.organizations, ...selectedOrganizations]

    this.setFormValue()

    this.selectOrganizationsDialogRef.close();
  }

  deleteAllSelected(organizationsToDelete: Organization[]) {
    this.organizations = this.organizations.filter( data => !organizationsToDelete.includes(data) )
    this.setFormValue();
    this.clearSelectedItems = !this.clearSelectedItems;
  }

  setFormValue() {
    this.parentGroup.controls[this.formElement.formControl].setValue(this.organizations)
  }

}
