import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'employeeListSearch' })
export class EmployeeListSearchPipe implements PipeTransform {
  public transform(employeeList: any[], searchText: any): any {
    if (searchText == '' || employeeList == null) {
      return employeeList;
    }

    let employees: any[] = []

    employeeList.forEach(
        item => {
          // If the employee name matches the search add the entire employee fields list to be returned
          if (
            item.employee?.firstName?.toUpperCase().includes(searchText.toUpperCase()) || 
            item.employee?.lastName?.toUpperCase().includes(searchText.toUpperCase()) || 
            item.employee?.firstname?.toUpperCase().includes(searchText.toUpperCase()) || 
            item.employee?.lastname?.toUpperCase().includes(searchText.toUpperCase())
          ) {
            employees.push(item);
          }
        }
    )

    return employees;
  }
}