<ng-container [formGroup]="propertyForm">

<!--    <pre>{{ propertyForm.value | json }}</pre>-->
<!--    <pre>{{ fields | json }}</pre>-->
<!--    <pre>{{ conditionTypes | json }}</pre>-->

    <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="Form">
        <mat-label>{{ 'Form' | translate: {Default: "Form"} }}</mat-label>
        <mat-select formControlName="tableId" disableOptionCentering>
            <ng-container *ngFor="let table of tables">
                <mat-option [value]="table.id">{{ table.name }}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="Field">
        <mat-label>{{ 'Field' | translate: {Default: "Field"} }}</mat-label>
        <mat-select formControlName="fieldId" disableOptionCentering [disabled]="!propertyForm.get('tableId')?.value">
            <ng-container *ngFor="let field of fields">
                <mat-option [value]="field.id">{{ field.name }}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" appLocalizationPopupDirective localizationCode="Condition">
        <mat-label>{{ 'Condition' | translate: {Default: "Condition"} }}</mat-label>
        <mat-select disableOptionCentering formControlName="conditionType" [disabled]="!propertyForm.get('fieldId')?.value">
            <ng-container *ngFor="let conditionType of conditionTypes">
                <mat-option [value]="conditionType.id">{{conditionType.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="propertyForm.get('conditionType')?.value != 'DateBefore' && propertyForm.get('conditionType')?.value != 'DateAfter'" appearance="fill" appLocalizationPopupDirective localizationCode="Value">
        <mat-label>{{ 'Value' | translate: {Default: "Value"} }}</mat-label>
        <input matInput formControlName="valueOne">
    </mat-form-field>

    <mat-form-field *ngIf="propertyForm.get('conditionType')?.value == 'NumberIsBetween'" appearance="fill" appLocalizationPopupDirective localizationCode="ValueTwo">
        <mat-label>{{ 'Value Two' | translate: {Default: "Value Two"} }}</mat-label>
        <input matInput formControlName="valueTwo">
    </mat-form-field>

    <!-- Datepicker Input -->
    <mat-form-field *ngIf="propertyForm.get('conditionType')?.value == 'DateBefore' || propertyForm.get('conditionType')?.value == 'DateAfter'" appearance="fill" appLocalizationPopupDirective localizationCode="DateValue">
        <mat-label>{{ 'Value' | translate: {Default: "Value"} }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="valueOne">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-icon 
        setColor 
        [setColorRules]="[
            {
                color: 'primaryColour',
                events: ['hover']
            }
        ]" 
        class="material-icons-outlined clickable-icon" 
        (click)="onDelete()" 
        matTooltip="Delete"
    >delete</mat-icon>

</ng-container>
