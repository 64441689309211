import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { defer, forkJoin } from 'rxjs';
import { LettersService } from '../../services/letters.service';
import { LetterFooter, LetterFooterVerbose } from '../../models/letters.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RowActionButtonInput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';

@Component({
  selector: 'app-letter-footers',
  templateUrl: './letter-footers.component.html',
  styleUrls: ['./letter-footers.component.scss']
})
export class LetterFootersComponent implements OnInit {
  public columns: any[] = [
    { field: "name", title: "Name", tableId: "tfi_LtrFooterName" },
    { field: "description", title: "description", tableId: "tfi_LtrFooterDescription", type: "longText", longTextLimit: 200 },
    { field: "status", subField: "name", tableId: "tfi_LtrFooterStatus", title: "Status", type: 'chip', chipValue: ['Published', 'Archived', 'Draft'] },
  ];

  filterCategories: any[] = [
    { field: "name", title: "Name", tableId: "tfi_LtrFooterName", type: "String", dataType: 'String' },
    { field: "description", title: "description", tableId: "tfi_LtrFooterDescription", type: "String", dataType: 'String' },
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description"},
    { field: "status", sortValue: "status.id"},
  ];

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'ShowArchived',
    checked: false
  }

  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'copy',
      name: this.translate.instant('Copy'),
      icon: 'content_copy'
    }
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_fu9SCSwpGc2fQH'
  changeReasonFormId: string = 'frm_LsW26hnVWoIdRl';
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsTypesRequest: any;
  sortString: string;
  filterString: string;
  error: boolean;
  getLetterFootersRequest: any;
  accessAndHistoryURL: string = `${api_routes.LETTERS}/${api_routes.LETTER_FOOTERS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    public lettersService: LettersService
  ) { }

  ngOnInit(): void {
    this.getLetterFooters();
  }

  getLetterFooters(letterFooterToOpen?: string) {
    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }
    if(this.filterToggleDetails.checked === false) {
      if(filter === undefined) {
        filter = `(Status.Id != "ARCHIVED")`;
      }
      else {
        filter = filter + `AND (Status.Id != "ARCHIVED")`;
      }
    }

    this.isLoading = true;

    this.getLetterFootersRequest = this.lettersService.getLetterFooters(this.pageSize, this.skip, this.sortString, filter)
    .subscribe(
      {
        next: (v) => {
          this.gridDataResult = {
            data: v.data,
            total: v.total,
          }

          if(letterFooterToOpen){
            this.getLetterFooter(letterFooterToOpen);
          }
        },
        error: (e) => {
          this.error = true;
          this.isLoading = false;
        },
        complete: () => this.isLoading = false
      }
    );
  }

  getLetterFooter(id: string) {
    this.overlayService.show('Loading...');

    this.getLetterFootersRequest = this.lettersService.getLetterFooter(id)
    .subscribe(
      {
        next: (v) => {
          this.openDialog(v);
        },
        error: (e) => this.overlayService.hide(),
        complete: () => this.overlayService.hide()
      }
    );
  }

  updateOrCreateLetterFooter(letterFooter: LetterFooter){
    if(letterFooter) {
      this.getLetterFooter(letterFooter.id);
    }
    else {
      this.openDialog();
    }
  }

  openDialog(letterFooter?: LetterFooterVerbose) {
    let formData = {
      id: letterFooter?.id || null,
      name: letterFooter?.name || null,
      description: letterFooter?.description || null,
      html: letterFooter?.html || null,
      height: letterFooter?.height || null,
      status: letterFooter?.status?.id || null,
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();

    if(formData.id === null){
      this.lettersService.createLetterFooter(formData)
      .subscribe(
        {
          next: (v) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
            this.dialogRef.close(true);
            this.getLetterFooters();
          },
          error: (e) => this.overlayService.hide(),
          complete: () => this.overlayService.hide()
        }
      );
    }
    else {
      this.lettersService.updateLetterFooter(formData.id, formData)
      .subscribe(
        {
          next: (v) => {
            this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            this.dialogRef.close(true);
            this.getLetterFooters();
          },
          error: (e) => this.overlayService.hide(),
          complete: () => this.overlayService.hide()
        }
      );
    }
  }

  deleteItems(ids: string[]) {
    const observables = ids.map(selectedItem => defer(() => this.lettersService.deleteLetterFooter(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
          this.clearSelectedItems = !this.clearSelectedItems;
          this.getLetterFooters();
        },
        error: (e) => this.overlayService.hide(),
        complete: () => this.overlayService.hide()
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getLetterFooters();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(name like "${variant}")`;
        }
    });

    this.getLetterFootersRequest.unsubscribe();
    this.getLetterFooters();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getLetterFooters();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getLetterFooters();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getLetterFooters();
  }

  copy(event) {
    let letterFooter = event.rowData;
    this.overlayService.show();

    this.lettersService.copyLetterFooter(letterFooter.id)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CopiedSuccessfully')}`, 'clear', 'success');
          this.getLetterFooters(v.id);
        },
        error: (e) => this.overlayService.hide(),
        complete: () => this.overlayService.hide()
      }
    );
  }
}

