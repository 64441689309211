<app-data-grid-comp
    [columns]="columns"
    [filterCategories]="filterCategories"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Letters-LetterDeliveryMethods"
    [rowSelectionSettings]="{
        canSelectRows: false
    }"
    clickableRows="true"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    searchable="true"
    hideActionButtons="true"
    [sortable]="{
        mode: 'multiple'
    }"
    [sortableColumns]="sortableColumns"
    (emitPageChangeEvent)="pageChange($event)"
    (emitSearchEvent)="search($event)"
    (emitOpenDialogEvent)="updateOrCreateLetterDeliveryMethod($event)"
    (emitFilterEvent)="filterCallback($event)"
    (emitSortEvent)="sortChange($event)"
></app-data-grid-comp>