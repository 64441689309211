import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { BusinessRuleAutomationNotification, BusinessRuleAutomationNotificationType, BusinessRuleAutomationRecipientType } from "@app/modules/business-rule-automation/models/business-rule-automation.model";
import { EmployeeSecurityService } from "@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service";


@Component({
    selector: 'app-notification-dialog',
    templateUrl: './notification-dialog.component.html',
    styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

    private notification: BusinessRuleAutomationNotification;

    form: UntypedFormGroup;
    // formId: string;
    cultures: Culture[];
    culture: Culture;
    loading: boolean;

    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;

    public notificationTypes: BusinessRuleAutomationNotificationType[] = [];
    public recipientTypes: BusinessRuleAutomationRecipientType[] = [];
    // public recipientTypes: { id: string, display: string }[] = [
    //     {id: "specific", display: 'Specific Recipients'},
    //     {id: "manager", display: 'Manager'},
    //     {id: "2ndManager", display: '2nd Level Manager'},
    //     {id: "hrAdmin", display: 'HR Administrator'},
    // ]

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    emails: string[] = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;

    notificationVariablesAvailable = [
        {
            label: 'Notification-Employee-Name',
            description: 'Notification-Employee-Name-Desc',
            value: '{employeeName}'
        },
        {
            label: 'Notification-Manager-Name',
            description: 'Notification-Manager-Name-Desc',
            value: '{managerName}'
        },
        {
            label: 'Notification-Position-Name',
            description: 'Notification-Position-Name-Desc',
            value: '{positionName}'
        },
        {
            label: 'Notification-Trigger-Data',
            description: 'Notification-Trigger-Data-Desc',
            value: '{triggerData}'
        },
    ]

    constructor(
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private metadataFormService: MetadataFormService,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private metadataTableService: MetadataTableService,
        private employeeSecurityService: EmployeeSecurityService,
        private dialogRef: MatDialogRef<NotificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

        this.notification = data.notification
        this.notificationTypes = data.notificationTypes;
        this.recipientTypes = data.recipientTypes;
    }

    ngOnInit(): void {
        this.loading = true;
        this.employeeSecurityService.getCurrentEmployeeCulture().subscribe(culture => {
            this.culture = culture;
            this.createForm();
            this.loading = false;
        });
    }

    createForm() {
        this.form = this.fb.group({
            type: [{ value: 'AutomationsNotificationEmail', disabled: true }, Validators.required],
            active: [{ value: true, disabled: false }],
            subject: [{ value: '', disabled: false }, Validators.required],
            recipientType: [{ value: 'specific', disabled: false }, Validators.required],
            // emails: [{ value: '', disabled: false }],
            body: [{ value: '', disabled: false }, Validators.required],
        });

        if (this.notification) {
            console.log(this.notification);


            let cultureSubject = this.notification.subject.find(s => s.culture == this.culture.id).text ?? '';
            let cultureBody = this.notification.body.find(s => s.culture == this.culture.id).text ?? '';
            this.emails = this.notification.notificationRecipients.map(r => r.emailAddress);

            this.form.patchValue({
                // type: this.notification.notificationType.id,
                active: this.notification.status,
                subject: cultureSubject,
                // recipientType: this.notification.recipients.length > 0 ? 'specific' : 'all',
                // recipientType: 'specific',
                recipientType: this.notification.notificationRecipientType?.id,
                // emails: this.notification.notificationRecipients.map(r => r.emailAddress).join(', '),
                body: cultureBody,
            })
        } else {
            // todo remove below, debug only
            // this.emails.push("lukasz@redstudio.ie");
        }
    }

    /**
     * Handles the drag event for a table and field.
     * 
     * @param event - The DragEvent object.
     * @param notificationVariable - the notificationVariable from notificationVariablesAvailable.
     */
    drag(event: DragEvent, notificationVariable: any) {
        if (event.dataTransfer) {
            event.dataTransfer.setData('text/plain', notificationVariable.value);
        }
    }


    save() {

        let subjectLocalization = {
            culture: this.culture.id,
            text: this.form.controls.subject.value,
        }

        let bodyLocalization = {
            culture: this.culture.id,
            text: this.form.controls.body.value,
        }

        let notificationRecipients = this.emails.map(email => {
            return {
                emailAddress: email,
            }
        });

        let notification: BusinessRuleAutomationNotification = {
            id: this.notification ? this.notification.id : null,
            notificationType: this.form.controls.type.value,
            status: this.form.controls.active.value,
            subject: [subjectLocalization],
            recipientType: this.form.controls.recipientType.value,
            notificationRecipientType: this.form.controls.recipientType.value,
            notificationRecipients: notificationRecipients,
            body: [bodyLocalization],
        }

        this.dialogRef.close({notification: notification});
    }

    close() {
        this.dialogRef.close();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if (value.trim().length == 0){
            return;
        }

        // Email validation regex pattern
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        // Add our email if it's valid
        if ((value || '').trim() && emailPattern.test(value.trim())) {
            this.emails.push(value.trim());
        } else {
            this.snackbarService.openSnackBar('Invalid email address', 'error');
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }


    remove(email: string): void {
        const index = this.emails.indexOf(email);

        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }
}
