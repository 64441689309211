import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { FeatureService } from '@app/core/services/feature.service';
import { features } from '@app/consts';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from "@angular/router";
import { BusinessRuleAutomationService } from "@app/modules/business-rule-automation/services/business-rule-automation.service";
import { BusinessRuleAutomation } from "@app/modules/business-rule-automation/models/business-rule-automation.model";
import { ConfirmDialogComponent } from "@app/shared/components/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-business-rule-automation',
    templateUrl: './business-rule-automation.component.html',
    styleUrls: ['./business-rule-automation.component.scss']
})
export class BusinessRuleAutomationComponent extends SecurityProtectedBase implements OnInit {
    @Input() refreshView: boolean;
    public bindingType: String = 'array';
    public view: Observable<GridDataResult>;
    public gridData: any;
    public gridDataResult: GridDataResult;
    public selectedItems: any[] = [];
    pageSize: number = 20;
    skip: number = 0;
    isLoading: boolean;
    dialogRef: any;
    getRequest: any;

    formId: string = 'frm_hOU10wBeRpuvho';
    changeReasonFormId: string = 'frm_pR8XieGVhvMIed';

    public columns: any[] = [
        {field: "name", title: "Name", type: "navigation"},
        {field: "description", title: "Description"},
        {field: "createdBy", title: "Created By", type: "employee"},
        {field: "createdOn", title: "Created On", type: "date"},
        {field: 'status', title: 'Status', type: 'booleanToggle'},
    ];

    filterCategories: any[] = [
        {field: "name", title: "Name", type: "String", dataType: 'String'},
        {field: "description", title: "Description", type: "String", dataType: 'String'},
        {field: "createdOn", title: "Created On", type: "date", dataType: 'Date'},
    ];

    sortableColumns: any[] = [
        {field: "name", sortValue: "name"},
        {field: "startDate", sortValue: "startDate"},
    ];

    filterToggleDetails: {
        text: string,
        checked: boolean
    } = {
        text: this.translate.instant('ActiveOnly'),
        checked: true
    }

    public searchFilterString: string;
    public searchValue: string;
    public sort: SortDescriptor[] = [];
    public sortString: string = 'name-asc';
    clearSelectedItems: boolean = false;
    filterString: string;

    constructor(
        private dialog: MatDialog,
        featureService: FeatureService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private service: BusinessRuleAutomationService,
        private router: Router,
    ) {
        super(featureService, features.BUSINESS_RULE_AUTOMATION)
    }

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        let filter;
        if (this.searchFilterString) {
            filter = this.searchFilterString;
        }
        if (this.filterString) {
            if (filter) {
                filter = `${filter} AND ${this.filterString}`;
            } else {
                filter = `${this.filterString}`;
            }
        }

        if (this.filterToggleDetails.checked) {
            if (filter) {
                filter = `(${filter}) AND (Status = "${this.filterToggleDetails.checked}")`;
            } else {
                filter = `(Status = "${this.filterToggleDetails.checked}")`;
            }
        }

        this.isLoading = true;

        this.getRequest = this.service.getBusinessRules(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked.toString())
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res.data,
                    total: res.total,
                };
            }
        );
    }

    getBusinessRule(businessRuleAutomation?: BusinessRuleAutomation) {

        if (businessRuleAutomation === undefined) {

            this.router.navigate(['SiteSettings/BusinessRuleAutomation/Form']);

        } else {

            this.isLoading = true;
            this.router.navigate(['SiteSettings/BusinessRuleAutomation/Form', businessRuleAutomation.id]);
        }
    }

    deleteBusinessRules(associationIds: string[]) {
        const observables = associationIds.map(selectedItem => defer(() => this.service.deleteBusinessRule(selectedItem)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(
            finalize(() => this.overlayService.hide())
        )
        .subscribe(
            (res) => {
                this.clearSelectedItems = !this.clearSelectedItems;
                this.getData();
                this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
            },
            err => {
                this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getData();
    }

    search(searchValue) {
        this.searchValue = searchValue;
        this.searchFilterString = `(Name like "${this.searchValue}") OR (Description like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getData();
    }

    sortChange(sortString: string) {
        this.sortString = sortString;
        this.getData();
    }

    updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
        this.filterToggleDetails.checked = toggleChange.checked;
        this.getData();
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        this.skip = 0;
        this.getData();
    }

    copyBusinessRule(bra: BusinessRuleAutomation) {
        console.log('copyBusinessRule', bra);
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to copy this Business Rule Automation? (${bra.name})`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    console.log('copy');
                    // todo trigger copy
                    this.isLoading = true;
                    this.service.copyBusinessRule(bra.id)
                    .subscribe(
                        res => {
                            this.getData();
                            this.snackbarService.openSnackBar('Copied successfully', 'clear', 'success');
                        },
                        err => {
                            this.snackbarService.openSnackBar(err, 'clear', 'warn');
                        }
                    );
                }
            }
        );
    }
}
