import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { api_routes, db_tables, routes } from '@app/consts';
import { defer, forkJoin } from 'rxjs';
import { LettersService } from '../../services/letters.service';
import { LetterGeneration } from '../../models/letters.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-letter-generations',
  templateUrl: './letter-generations.component.html',
  styleUrls: ['./letter-generations.component.scss']
})
export class LetterGenerationsComponent implements OnInit {
  public columns: any[] = [
    { field: "fileName", title: "File Name", tableId: "tfi_LtrGenFileName" },
    { field: "template", subField: "name", title: "Template", tableId: "tfi_LtrGenTemplate" },
    { field: "header", subField: "name", title: "Header", tableId: "tfi_LtrGenLetterhead" },
    { field: "footer", subField: "name", title: "Footer", tableId: "tfi_LtrGenFooter" },
    // { field: "emailSubject", title: "Email Subject", tableId: "tfi_LtrGenEmailSubject" },
    { field: "publishedBy", title: "Published By", tableId: "tfi_LtrGenPublishedBy", type: 'employee' },
    { field: "publishedOn", title: "Published On", tableId: "tfi_LtrGenPublishedOn", type: 'date' },
    { field: "status", subField: "name", tableId: "tfi_LtrGenStatus", title: "Status", type: 'chip', chipValue: ['Published', 'Archived', 'Draft', 'Queued'] },
  ];

  filterCategories: any[] = [
    { field: "fileName", title: "File Name", tableId: "tfi_LtrGenFileName", type: "String", dataType: 'String' },
  ];

  sortableColumns: any[] = [
    { field: "fileName", sortValue: "fileName"},
    { field: "template", sortValue: "template.name"},
    { field: "header", sortValue: "header.name"},
    { field: "footer", sortValue: "footer.name"},
    { field: "status", sortValue: "status.name"},
    { field: "publishedOn", sortValue: "publishedOn"},
  ];

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'ShowDraftOnly',
    checked: false
  }

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  sortString: string;
  filterString: string;
  error: boolean;
  getLetterGenerationsRequest: any;
  accessAndHistoryURL: string = `${api_routes.LETTERS}/${api_routes.LETTER_GENERATIONS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private router: Router,
    public lettersService: LettersService
  ) { }

  ngOnInit(): void {
    this.getLetterGenerations();
  }

  getLetterGenerations() {
    let filter;

    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }

    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    if(this.filterToggleDetails.checked === true) {
      if(filter === undefined) {
        filter = `(Status.Id = "Draft")`;
      }
      else {
        filter = filter + `AND (Status.Id = "Draft")`;
      }
    }

    this.isLoading = true;

    this.getLetterGenerationsRequest = this.lettersService.getLetterGenerations(this.pageSize, this.skip, this.sortString, filter)
    .subscribe(
      {
        next: (v) => {
          this.gridDataResult = {
            data: v.data,
            total: v.total,
          }
        },
        error: (e) => this.error = true,
        complete: () => this.isLoading = false
      }
    );
  }

  openEditor(letterGeneration: LetterGeneration) {
    if(letterGeneration) {
      this.router.navigate([`${routes.LETTERS}/${routes.LETTER_GENERATIONS}${routes.EDITOR}/${letterGeneration?.id}`]);
    }
    else{
      this.router.navigate([`${routes.LETTERS}/${routes.LETTER_GENERATIONS}${routes.EDITOR}`]);
    }
  }

  deleteItems(ids: string[]) {
    const observables = ids.map(selectedItem => defer(() => this.lettersService.deleteLetterGeneration(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .subscribe(
      {
        next: (v) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
          this.clearSelectedItems = !this.clearSelectedItems;
          this.getLetterGenerations();
        },
        error: (e) => {
          this.overlayService.hide()
        },
        complete: () => this.overlayService.hide()
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take
    this.getLetterGenerations();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getLetterGenerations();
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;

    let variants = this.searchValue.split(' ').filter(value => value);
    this.searchFilterString = '';
    variants.map(variant => {
        if (variant) {
            if (this.searchFilterString.length > 2) {
                this.searchFilterString += ' AND ';
            }
            this.searchFilterString += `(fileName like "${variant}")`;
        }
    });

    this.getLetterGenerationsRequest.unsubscribe();
    this.getLetterGenerations();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getLetterGenerations();
  }

  filterCallback(filterString: string) {
    this.filterString = filterString;
    this.skip = 0;;
    this.getLetterGenerations();
  }

}

