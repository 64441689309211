import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { fieldTypes } from '@app/consts';
import { SettingsService } from '@app/core/services/settings.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { TimezoneService } from '@app/core/services/timezone/timezone.service';
import { CostCenter } from '@app/modules/cost-centers/models/cost-center.model';
import { CostCentersService } from '@app/modules/cost-centers/services/cost-centers.service';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { Currency } from '@app/modules/lookup/models/currency.model';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { TimeOffListVariant } from '@app/modules/lookup/models/time-off-list-variant.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { Organization, OrganizationType } from '@app/modules/organization-structure/models/organization-structure.model';
import { OrganizationStructureService } from '@app/modules/organization-structure/services/organization-structure.service';
import { Position } from '@app/modules/positions/models/positions.model';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { RoleSecurityForField } from '@app/modules/security-setup/models/security-role.model';
import { TimeOffService } from '@app/modules/time-off/services/time-off.service';
import { WorkLocation } from '@app/modules/work-locations/models/work-location.model';
import { WorkLocationsService } from '@app/modules/work-locations/services/work-locations.service';
import { WorkRotation } from '@app/modules/work-rotations/models/work-rotation.model';
import { WorkRotationService } from '@app/modules/work-rotations/services/work-rotation.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { Timezone } from '@app/shared/models/timezone.model';
import { from, Observable } from 'rxjs';
import { EmployeeCompensationService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-compensation/services/employee-compensation.service'
import { CountryConfigurationService } from '@app/modules/country-configuration/services/country-configuration.service';
import { CountryConfiguration } from '@app/modules/country-configuration/models/country-configuration.model';
import { formats } from '@app/consts/formats';
import { StatutoryHolidaysService } from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import { HolidayGroup } from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import { TableFieldVerbose } from '@app/modules/security-setup/models/table-field.model';
import * as moment from 'moment';
import { EmploymentRecordsService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service';
import { ProbationPeriodUnit } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/models/employment-record.model';
import { TranslateService } from '@ngx-translate/core';
import { GoalTypeService } from '@app/modules/performance/components/goal-types/services/goal-type.service';
import { CompletionDateType, ExpectedCompletionDateType, FieldSetting, GoalType, GoalTypeStatusType } from '@app/modules/performance/components/goal-types/models/goal-type.model';
import { ReviewPlansService } from '@app/modules/performance/components/review-plans/services/review-plans.service';
import { ReviewPlanReviewerType } from '@app/modules/performance/components/review-plans/models/review-plan.model';
import { FilterExpandSettings } from '@progress/kendo-angular-treeview';
import { IntegrationSettings, IntegrationType } from "@app/modules/site-settings-integrations/models/integrations.model";
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";

@Component({
  selector: 'app-form-generator-form-field',
  templateUrl: './form-generator-form-field.component.html',
  styleUrls: ['./form-generator-form-field.component.scss']
})
export class FormGeneratorFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() formTableId: string;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @Input() currentCulture: Culture;
  @Input() cultures: Culture[] = [];
  @Output() emitAddtoFormArray: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitAddtoCostCenter: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitDeleteFromFormArray: EventEmitter<{index: number, formControl: string}> = new EventEmitter<{index: number, formControl: string}>();
  public fieldTypes: typeof fieldTypes = fieldTypes;
  public formats: typeof formats = formats;
  dropdownListOptions: Observable<PagedData<TableListFieldOption>>;
  timezoneOptions: Observable<PagedData<Timezone>>;
  timezoneOptionsFiltered: Timezone[];
  currencyOptions: Observable<Currency[]>;
  fieldRoleSecurity: RoleSecurityForField;
  workLocationOptions: Observable<PagedData<WorkLocation>>;
  workLocationsFiltered: WorkLocation[];
  workRotationOptions: Observable<PagedData<WorkRotation>>;
  workRotationsFiltered: WorkRotation[];
  costCentreOptions: Observable<PagedData<CostCenter>>;
  costCentresFiltered: CostCenter[];
  positionOptions: Observable<Position[]>;
  positionOptionsFiltered: Position[];
  timeOffTypeList: TimeOffListVariant[] = [];
  organizationTypes: OrganizationType[] = [];
  payRateIntervals: {id: string, text: string}[] = [];
  countryOptions: CountryConfiguration[] = [];
  countryOptionsFiltered: CountryConfiguration[];
  statutoryHolidayGroups: Observable<PagedData<HolidayGroup>>;
  goalTypes: Observable<PagedData<GoalType>>;
  integrationTypes: Observable<PagedData<IntegrationType>>;
  showPassword: boolean = false;

  // Slider variables
  sliderValue: number = 0;
  percentageSliderValue: number = 0;

  // Organization Dropdown Variables
  public parentDropdownData: any[] = [];
  public defaultDataItem: Object = null;
  public allOrgs: Organization[] = [];
  probationPeriodUnits: Observable<PagedData<ProbationPeriodUnit>>;
  organizationFilterExpandSettings: FilterExpandSettings = {
    expandMatches: true
  }

  // DB Table Field Variables
  loadingTableField: boolean;
  dbTableField: TableFieldVerbose;

  // Goal Type Variables
  completionDateTypes: Observable<PagedData<CompletionDateType>>;
  expectedCompletionDateTypes: Observable<PagedData<ExpectedCompletionDateType>>;
  goalTypeStatusTypes: Observable<PagedData<GoalTypeStatusType>>;
  fieldSettings: Observable<PagedData<FieldSetting>>;
  reviewerTypeOptions: Observable<PagedData<ReviewPlanReviewerType>>;

  constructor(
    private cultureService: CultureService,
    private lookupService: LookupService,
    private timezoneService: TimezoneService,
    private settingsService: SettingsService,
    private positionsService: PositionsService,
    private workLocationsService: WorkLocationsService,
    private workRotationService: WorkRotationService,
    private costCentresService: CostCentersService,
    private organizationService: OrganizationStructureService,
    private employeeCompensationService: EmployeeCompensationService,
    private timeOffService: TimeOffService,
    private countryConfigurationService: CountryConfigurationService,
    private statutoryHolidaysService: StatutoryHolidaysService,
    private translate: TranslateService,
    private employmentRecordsService: EmploymentRecordsService,
    private goalTypeService: GoalTypeService,
    private reviewPlansService: ReviewPlansService,
    private integrationsService: IntegrationsService
  ) {}

  ngOnInit(): void {
    // this.cultures = this.cultureService.getCultures();
    this.timezoneOptions = this.timezoneService.getTimezones();
    this.currencyOptions = this.settingsService.getCurrencies();
    this.positionOptions = this.positionsService.getPositionsAsync(0, "1000", null, 'name-asc');
    this.statutoryHolidayGroups = this.statutoryHolidaysService.getHolidayGroups(0, '1000');
    this.probationPeriodUnits = this.employmentRecordsService.getProbationPeriodUnits(0, '1000');
    this.goalTypes = this.goalTypeService.getGoalTypes('1000', 0);
    this.fieldSettings = this.goalTypeService.getFieldSettings('1000', 0);

    if(this.formElement.formElementType.id === fieldTypes.DROPDOWN){
      this.getDropdownListOptions();
    }
    else if(this.formElement.formElementType.id === fieldTypes.ORGANIZATION_DROPDOWN) {
      this.getAllOrganizations();
    }
    else if(this.formElement.formElementType.id === fieldTypes.TIME_OFF_TYPE_DROPDOWN) {
      this.getTimeOffTypes();
    }
    else if(this.formElement.formElementType.id === fieldTypes.ORGANIZATION_TYPE) {
      this.getOrganizationTypes();
    }
    else if(this.formElement.formElementType.id === fieldTypes.PAY_RATE_INTERVAL_DROPDOWN) {
      this.getPayRateIntervals();
    }
    else if(this.formElement.formElementType.id === fieldTypes.COST_CENTERS_SELECTION) {
      this.costCentreOptions = from(this.costCentresService.getAllCostCenters());
    }
    else if(this.formElement.formElementType.id === fieldTypes.COUNTRY_DROPDOWN) {
      this.getCountries(0, 100);
    }
    else if(this.formElement.formElementType.id === fieldTypes.WORK_LOCATION_DROPDOWN) {
      this.workLocationOptions = from(this.workLocationsService.getAllWorkLocations());
    }
    else if(this.formElement.formElementType.id === fieldTypes.WORK_ROTATION_DROPDOWN) {
      this.workRotationOptions = from(this.workRotationService.getAllWorkRotations());
    }
    else if(this.formElement.formElementType.id === fieldTypes.REVIEW_PLAN_REVIEWER_TYPE_DROPDOWN) {
      this.reviewerTypeOptions = from(this.reviewPlansService.getAllReviewerTypes());
    } else if(this.formElement.formElementType.id === fieldTypes.INTEGRATION_TYPE_DROPDOWN) {
        this.integrationTypes = this.integrationsService.getIntegrationTypes(0, 1000);
    }

    if(this.formElement.formElementType.id === fieldTypes.SLIDER_INPUT && this.formElement.isPercentageSlider === "true") {
      this.calculateSliderPercentage(this.formControl.value, parseInt(this.formElement.maxLength))
    }
  }

  get formControl() {
    return this.parentGroup.get(this.formElement.formControl)
  }

  setValue(value) {
    this.parentGroup.get(this.formElement.formControl).setValue(Math.round(value * 1e2) / 1e2)
  }

  getDropdownListOptions() {
    this.dropdownListOptions = from(this.lookupService.getListOptions(this.formElement?.lookupGroup));
  }

  addNewLocalization() {
    this.emitAddtoFormArray.emit(this.formElement.formControl);
  }

  addNewCostCenter() {
    this.emitAddtoCostCenter.emit(this.formElement.formControl);
  }

  deleteLocalization(index: number) {
    this.emitDeleteFromFormArray.emit(
      {
        index: index,
        formControl: this.formElement.formControl
      }
    );
  }

  getAllOrganizations() {
    from(this.organizationService.getAllOrganizations())
      .subscribe(
        res => {
          this.allOrgs = res.data;
          this.parentDropdownData = this.convertListToTreeViewData(res.data);
        }
      )
  }

  convertListToTreeViewData(orgs: Organization[]) {
    orgs.sort((a, b) => {
      if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    });
    let data = [];
    orgs.forEach(org => {
        data.push({id: org.id, name: org.name, parent: org.parentOrganization?.id, type: org.organizationType?.name})
    });
    return data;
  }

  getCountries(skip: number, take: number) {
    this.countryConfigurationService.getCountries(skip, take.toString(), null, 'name-asc')
    .subscribe(
      timeOffTypePager => {
        this.countryOptions.push(...timeOffTypePager.data);

        if(take < timeOffTypePager.total){
          this.getCountries(skip+100, take+100);
        }
      }
    );
  }

  getTimeOffTypes(){
    this.timeOffService.getTimeOffTypes().subscribe(
      timeOffTypePager => {
          this.timeOffTypeList = timeOffTypePager.data;
      }
    );
  }

  getOrganizationTypes() {
    this.organizationService.getOrganizationTypes()
        .subscribe(
            res => {
                this.organizationTypes = res.data;
            }
        );
  }

  getPayRateIntervals() {
    this.employeeCompensationService.getPayRateIntervals()
        .subscribe(
            res => {
                this.payRateIntervals = res;
            }
        );
  }

  onCountryDropdownSearchKeyReceived(value: string, data: CountryConfiguration[]) {
    if (value) {
      let filter = value.toLowerCase();
      this.countryOptionsFiltered = data.filter(option => option.name.toLowerCase().includes(filter));
    } else {
      this.countryOptionsFiltered = null;
    }
  }

  onTimezoneDropdownSearchKeyReceived(value: string, data: Timezone[]) {
    if (value) {
      let filter = value.toLowerCase();
      this.timezoneOptionsFiltered = data.filter(option => option.standardName.toLowerCase().includes(filter) || option.displayName.toLowerCase().includes(filter));
    } else {
      this.timezoneOptionsFiltered = null;
    }
  }

  onCostCenterDropdownSearchKeyReceived(value: string, data: CostCenter[]) {
    if (value) {
      let filter = value.toLowerCase();
      this.costCentresFiltered = data.filter(option => option.name.toLowerCase().includes(filter) || option.code.toLowerCase().includes(filter));
    } else {
      this.costCentresFiltered = null;
    }
  }

  onWorkLocationDropdownSearchKeyReceived(value: string, data: WorkLocation[]) {
    if (value) {
      let filter = value.toLowerCase();
      this.workLocationsFiltered = data.filter(option => option.name.toLowerCase().includes(filter));
    } else {
      this.workLocationsFiltered = null;
    }
  }

  onWorkRotationDropdownSearchKeyReceived(value: string, data: WorkRotation[]) {
    if (value) {
      let filter = value.toLowerCase();
      this.workRotationsFiltered = data.filter(option => option.name.toLowerCase().includes(filter));
    } else {
      this.workRotationsFiltered = null;
    }
  }

  onReportsToPositionDropdownSearchKeyReceived(value: string, data: Position[]) {
    if (value) {
      let filter = value.toLowerCase();
      this.positionOptionsFiltered = data.filter(option => option.name.toLowerCase().includes(filter));
    } else {
      this.positionOptionsFiltered = null;
    }
  }

  isCurrentLookupItem(endDate: string) {
    if(endDate === null) {
      return true;
    }

    return moment().diff(endDate) < 0;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  calculateSliderPercentage(value: number, max: number) {
    this.percentageSliderValue = Math.round((value/max) * (100/1));
  }

  isWorkLocationInactive(workLocation: WorkLocation): Boolean {
    const date = moment(workLocation.endDate);
    const now = moment(); // Current date and time

    if (date.isBefore(now)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkIfSelectedCostCenterIsInactive(costCenterId: string): Boolean {
    // if(costCenterId !== undefined) {
    //   let selectedCostCenter = this.costCentreOptions.find(costCenter => costCenter.id === costCenterId);

    //   if(selectedCostCenter !== undefined) {
    //     return this.isCostCenterInactive(selectedCostCenter);
    //   }
    // }

    return false;
  }

  isCostCenterInactive(costCenter: CostCenter): Boolean {
    const startDate = moment(costCenter.startDate);
    const endDate = moment(costCenter.endDate);
    const now = moment(); // Current date and time

    if(endDate.isBefore(now)){
      return true;
    }
    else if(startDate.isAfter(now)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkIfSelectedWorkLocationIsInactive(workLocationId: string): Boolean {
    // if(workLocationId !== undefined) {
    //   let selectedWorkLocation = this.workLocationOptions.find(workLocation => workLocation.id === workLocationId);

    //   if(selectedWorkLocation !== undefined) {
    //     return this.isWorkLocationInactive(selectedWorkLocation);
    //   }
    // }

    return false;
  }

}
