<h5 mat-dialog-title appLocalizationPopupDirective localizationCode="Notification">{{ 'Notification' | translate: {Default: "Notification"} }}</h5>

<mat-dialog-content class="bra-notification">
    <ng-container *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>

        

        <form class="form" *ngIf="form" [formGroup]="form">

            <mat-form-field appearance="fill">
                <mat-label appLocalizationPopupDirective localizationCode="ContactType">{{ 'ContactType' | translate: {Default: "Contact Type"} }}</mat-label>

                <mat-select disableOptionCentering formControlName="type">
                    <ng-container *ngFor="let option of notificationTypes">
                        <mat-option [value]="option.id">{{option.name}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <div class="slide-toggle-container">
                <mat-slide-toggle setColor="primaryColour" formControlName="active" appLocalizationPopupDirective localizationCode="Active">{{ 'Active' | translate: {Default: "Active"} }}</mat-slide-toggle>
            </div>

            <mat-form-field appearance="fill">
                <mat-label appLocalizationPopupDirective localizationCode="Subject">{{ 'Subject' | translate: {Default: "Subject"} }}</mat-label>

                <input matInput type="text" formControlName="subject">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label appLocalizationPopupDirective localizationCode="Recipient">{{ 'Recipient' | translate: {Default: "Recipient"} }}</mat-label>

                <mat-select disableOptionCentering formControlName="recipientType">
                    <ng-container *ngFor="let option of recipientTypes">
                        <mat-option [value]="option.id">{{option.name}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>


            <mat-form-field class="example-chip-list" appearance="fill" *ngIf="this.form.get('recipientType').value == 'AutomationsNotificationRecipientTypeSpecific'">
                <mat-label appLocalizationPopupDirective localizationCode="Emails">{{ 'Emails' | translate: {Default: "Emails"} }}</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(email)">
                        {{email}}
                        <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
                    </mat-chip>
                    <input
                        placeholder="New email..."
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </mat-form-field>

            <div class="body-container">
                <mat-card class="mat-elevation-z0 notification-variables-card">
                    <mat-card-title class="header">
                        <h5 appLocalizationPopupDirective [localizationCode]="'Notification-Variables'">
                            {{ 'Notification-Variables' | translate: {Default: 'Notification Variables'} }}
                        </h5>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <span appLocalizationPopupDirective [localizationCode]="'Notification-VariablesDragAndDrop'">
                            {{ 'Notification-VariablesDragAndDrop' | translate: {Default: 'Drag & Drop values into body'} }}
                        </span>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-list>
                            <ng-container *ngFor="let notificationVariable of notificationVariablesAvailable; let first">
                                <mat-divider></mat-divider>
                                <mat-list-item
                                    draggable="true" 
                                    (dragstart)="drag($event, notificationVariable)"
                                >
                                  <span 
                                    matListItemTitle
                                    appLocalizationPopupDirective 
                                    [localizationCode]="notificationVariable.label"
                                  >
                                    {{ notificationVariable.label | translate }}
                                  </span>
                                  <span 
                                    matListItemLine 
                                    appLocalizationPopupDirective 
                                    [localizationCode]="notificationVariable.description"
                                  >
                                    {{ notificationVariable.description | translate }}
                                    </span>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </mat-card-content>
                </mat-card>

                <mat-form-field appearance="fill">
                    <mat-label appLocalizationPopupDirective localizationCode="Body">{{ 'Body' | translate: {Default: "Body"} }}</mat-label>
    
                    <textarea matInput formControlName="body" rows="8"></textarea>
                </mat-form-field>
            </div>



        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()" appLocalizationPopupDirective localizationCode="Close">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form" appLocalizationPopupDirective localizationCode="Save">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>
