import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationsService } from "@app/modules/site-settings-integrations/services/integrations.service";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { finalize } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { IntegrationLogsDialogComponent } from "@app/modules/site-settings-integrations/components/integration-tab-container/components/integration-logs-dialog/integration-logs-dialog.component";

@Component({
    selector: 'app-integration-logs-tab',
    templateUrl: './integration-logs-tab.component.html',
    styleUrls: ['./integration-logs-tab.component.scss']
})
export class IntegrationLogsTabComponent implements OnInit {

    @Input() public integrationId: string;

    // GRID
    public columns: any[] = [
        {
            field: 'timestamp',
            title: this.translate.instant('Integrations-PayloadReceived'),
            localizationCode: 'Integrations-PayloadReceived',
            type: "dateTime",
            dataType: 'Date'
        },
        {
            field: 'processed',
            title: this.translate.instant('Integrations-Processing'),
            localizationCode: 'Integrations-Processing',
            type: 'chip', chipValue: [true, false, '',  ''], chipBooleanText: ["Successful", "Unsuccessful"]
        },
        {
            field: 'processedTimestamp',
            title: this.translate.instant('Integrations-PayloadProcessed'),
            localizationCode: 'Integrations-PayloadProcessed',
            type: "dateTime",
            dataType: 'Date'
        },
        {
            field: 'affectedUser',
            title: this.translate.instant('Integrations-AffectedUser'),
            localizationCode: 'Integrations-AffectedUser',
            type: 'employee'
        },

        
    ];
    gridDataResult: GridDataResult;
    isLoading: boolean = false;
    pageSize: number = 20;
    skip: number = 0;
    filterString: string;
    searchFilterString: string;
    getRequest: any;
    clearSelectedItems: boolean = false;
    searchValue: string;
    sortString: string = "";

    dialogRef: any;


    constructor(
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private cultureService: CultureService,
        private integrationService: IntegrationsService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.getData();
    }

    navigateToIntegration($event: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            integrationEvent: $event
        };

        this.dialogRef = this.dialog.open(IntegrationLogsDialogComponent, dialogConfig);
    }

    deleteAllSelected($event: any) {
        // todo
    }

    getData() {
        this.isLoading = true;

        this.integrationService.getIntegrationEvents(this.integrationId, this.skip, this.pageSize)
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(res => {
            this.gridDataResult = {
                data: res.data,
                total: res.total,
            };
        })
    }
}
